import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Box, DialogTitle, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setCookie } from '../../../../services/Helper';
import { BTN_TYPES, COOKIES } from '../../../../services/Constants';
import logo from '../../../../images/Logo.svg';
import CustomButton from '../../../button/components/CutomButton';
import { primary } from '../../../../styles/colors';
import { heading14_16_400, heading18_23_400, heading36_42_500 } from '../../../../styles/fonts';
import { setModalDataAction, toggleModalAction } from '../Actions';
import { setAgreement } from '../../../../pages/signIn/Actions';

const useStyles = makeStyles((theme) => ({
  mainDialogContainer: {
    '& .MuiPaper-root': {
      width: '1060px',
      height: '690px',
      maxWidth: '100%',
      overflow: 'hidden',
    },
  },
  modalContent: {
    padding: '114px 100px 50px',
    [theme.breakpoints.up('smd')]: {
      padding: '114px 100px 50px',
    },
  },
  logoBox: {
    position: 'absolute',
    top: '20px',
    left: '27px',
    height: '55px',
    [theme.breakpoints.up('smd')]: {
      left: '77px',
    },
  },
  logo: {
    height: '55px',
  },
  infoBlock: {
    height: '470px',
    paddingRight: '15px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      backgroundColor: primary.blue,
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: primary.coral,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: primary.blue,
    },
  },
  closeBtnBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
  },
  title: {
    marginBottom: '33px',
    ...heading36_42_500,
  },
  date: {
    marginBottom: '28px',
    ...heading18_23_400,
  },
  description: {
    ...heading14_16_400,
  },
}));

const CustomModal = ({ onClose }) => {
  const [isUserAgreementModal, setIsUserAgreementModal] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const modal = useSelector(({ customModalReducer }) => customModalReducer);

  const handleCloseWithCookie = () => {
    dispatch(toggleModalAction({ open: false }));
    onClose && onClose();
    modal.modalData.isAgreement &&
      dispatch(
        setModalDataAction({
          modalData: { ...modal.modalData, isAgreement: false },
        }),
      );

    return setCookie(COOKIES.agreement, true);
  };

  const handleClose = () => {
    dispatch(toggleModalAction({ open: false }));
    onClose && onClose();
    modal.modalData.isAgreement &&
      dispatch(
        setModalDataAction({
          modalData: { ...modal.modalData, isAgreement: false },
        }),
      );
  };

  const handleClick = () => {
    if (!isUserAgreementModal) {
      handleCloseWithCookie();

      return;
    }

    dispatch(setAgreement());
    setIsUserAgreementModal(false);
    handleClose();
  };

  return (
    <Dialog
      className={classes.mainDialogContainer}
      onClose={modal.modalData.isAgreement ? () => {} : handleClose}
      open={modal.open}>
      <Box position="relative">
        <DialogTitle className={classes.logoBox}>
          <img src={logo} alt="logo" className={classes.logo} />
        </DialogTitle>
      </Box>
      <Box className={classes.modalContent}>
        <Box className={classes.infoBlock}>
          <Typography className={classes.title}>{modal.modalData.title}</Typography>
          <Typography className={classes.date}>Effective Date: {modal.modalData.date}</Typography>
          <Box
            id="modal_desc"
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: modal.modalData.description }}
          />
          <Box className={classes.closeBtnBox}>
            {isUserAgreementModal && (
              <CustomButton
                title={modal.modalData.closeBtnTitle}
                typeBtn={BTN_TYPES.btn}
                color={primary.coral}
                colorSecond={primary.milk}
                onClick={handleClick}
              />
            )}
          </Box>
        </Box>
        <Box className={classes.closeBtnBox}>
          {!isUserAgreementModal && (
            <CustomButton
              title={modal.modalData.closeBtnTitle}
              typeBtn={BTN_TYPES.btn}
              color={primary.coral}
              colorSecond={primary.milk}
              onClick={handleClick}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

CustomModal.displayName = 'CustomModal';

export default CustomModal;
