import CheckBoxGroupItem from 'expert/common/components/checkBoxGroup/interfaces/checkBoxGroupItem';
import CheckboxGroupDictionaryRes from 'expert/sagas/interfaces/checkboxGroupDictionaryRes';

const resToCheckBoxGroupItem = (source: CheckboxGroupDictionaryRes): CheckBoxGroupItem => ({
  id: source.id,
  title: source.name,
  isSelected: source.is_selected,
  ...(source.other_text === undefined ? {} : { other: source.other_text }),
});

export default resToCheckBoxGroupItem;
