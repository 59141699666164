import { FILTER_ITEMS, MATCHES, PAYMENT_PACKAGES_RATING_NUM } from '../../services/Constants';
import { CARDS_LIMIT } from './Constants';

export const prepareQuery = (location, key, removeKey) => {
  const { search } = location;

  if (removeKey && key.indexOf('limit') === -1) {
    return `?${location.search.substr(1).split(`&${removeKey}&`).join('&')}`;
  }

  if (removeKey && key.indexOf('limit') !== -1) {
    let query = search;

    if (search) {
      query = `${search}${key}&`;
    } else {
      query = `?&${key}&`;
    }

    return `?${query.substr(1).split(`&${removeKey}&`).join('&')}`;
  }

  if (search) {
    return `${search}${key}&`;
  }

  return `?&${key}&`;
};

export const prepareFilters = (location) => {
  const selectedFilters = location.search.substr(1).split('&');

  return Object.entries(FILTER_ITEMS).reduce((acc, [key, value]) => {
    if (selectedFilters.includes(key)) {
      acc[key] = { ...value, selected: true };

      return acc;
    }

    acc[key] = value;

    return acc;
  }, {});
};

export const prepareLimit = (location) => {
  const limit = location.search.split('limit=')[1];

  if (!limit) return CARDS_LIMIT;

  return Number(limit.substr(0, limit.length - 1));
};

export const runConfetti = (mainWrapper, confetti) => {
  const colors = ['#bb0000', '#ffffff'];
  const end = Date.now() + 3 * 1000;

  const canvas = document.createElement('canvas');

  canvas.confetti = canvas.confetti || confetti.create(canvas, { resize: true });
  canvas.height = 0;
  mainWrapper.appendChild(canvas);
  (function frame() {
    confetti({
      particleCount: 2,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors,
    });
    confetti({
      particleCount: 2,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors,
    });

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    } else {
      canvas.remove();
    }
  })();
};

export const preparedReports = (data, payload, user) => {
  let reports = [...data];
  const itemWithYouChoice = reports.find((item) => item.your_choice);
  const yourChoiceTopMatch = itemWithYouChoice?.match_type === MATCHES.top;
  const ratingNum = user?.path_mapper_tariff?.rating_num - 1;
  const isBasicPackage = ratingNum === PAYMENT_PACKAGES_RATING_NUM.BASIC;
  const isPremiumPackage = ratingNum === PAYMENT_PACKAGES_RATING_NUM.PREMIUM;

  if (payload?.data?.is_favorite) {
    reports = reports.filter((item) => item.is_favorite);
  }

  if (isBasicPackage) {
    if (!itemWithYouChoice || yourChoiceTopMatch) {
      reports = reports.slice(0, 3);
    } else {
      reports = reports.slice(0, 4);
    }
  }

  if (isPremiumPackage) {
    if (!itemWithYouChoice || yourChoiceTopMatch) {
      reports = reports.slice(0, 10);
    }

    reports = reports.slice(0, 11);
  }

  return reports.filter((item) => !!item);
};
