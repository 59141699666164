import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import palette from 'expert/theme/expertPalette';
import typography from 'expert/theme/expertTypography';

const createTheme = createMuiTheme({
  palette,
  typography,
});

export default createTheme;
