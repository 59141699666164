import MESSAGES from 'expert/common/configs/messages';
import Feedback from 'expert/modules/expertFeedback/interfaces/feedback';
import {
  closeExpertFeedback,
  setExpertFeedback,
  setExpertFeedbackLoadingState,
} from 'expert/redux/expertFeedback/expertFeedbackActions';
import feedbackPaths from 'expert/sagas/feedback/feedbackPaths';
import { feedbackSagaActionTypes } from 'expert/sagas/feedback/feedbackSagasActions';
import resToFeedback from 'expert/sagas/utils/resToFeedback';
import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { processRequest } from 'services/Api';
import shouldHandleError from 'expert/sagas/utils/shouldHandleError';
import { notificationsSelector } from 'expert/redux/notifications/notificationsSelectors';
import { setNotifications } from 'expert/redux/notifications/notificationsActions';
import updateFeedbackNotifications from 'expert/sagas/utils/updateFeedbackNotifications';

const handleFeedbackUpdate = function* ({ payload }: { payload: Feedback }) {
  try {
    yield put(setExpertFeedbackLoadingState(true));
    yield call(processRequest, `${feedbackPaths.updateFeedback}/${payload.id}`, 'PATCH', {
      what_like: payload.liked,
      what_dont_like: payload.disliked,
      suggestion: payload.suggested,
      is_like: payload.customerRate,
    });

    yield put(setNotifications(updateFeedbackNotifications(yield select(notificationsSelector), payload)));
    toast.success(MESSAGES.success.feedbackUpdate);
    yield put(closeExpertFeedback());
  } catch (e: any) {
    if (!shouldHandleError(e)) return;
    toast.error(MESSAGES.errors.feedbackUpdate);
  } finally {
    yield put(setExpertFeedbackLoadingState(false));
  }
};

const handleFetchLastFeedback = function* () {
  try {
    const { data } = yield call(processRequest, feedbackPaths.lastFeedback);

    if (!data) return;

    yield put(setExpertFeedback({ feedback: resToFeedback(data), isFirst: true }));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;
    toast.error(MESSAGES.errors.lastFeedback);
  }
};

const handleRefuseFeedback = function* ({ payload }: { payload: number }) {
  try {
    yield call(processRequest, `${feedbackPaths.feedbackRefuse}/${payload}`, 'POST');
  } catch (e: any) {
    if (!shouldHandleError(e)) return;
    toast.error(MESSAGES.errors.refuseFeedback);
  }
};

const feedbackSagas = [
  takeLatest(feedbackSagaActionTypes.updateFeedback, handleFeedbackUpdate),
  takeLatest(feedbackSagaActionTypes.fetchLastFeedback, handleFetchLastFeedback),
  takeLatest(feedbackSagaActionTypes.refuseFeedback, handleRefuseFeedback),
];

export default feedbackSagas;
