import Notification from 'expert/modules/notifications/interfaces/notification';
import actionFn from 'expert/common/utils/actionFn';
import INIT_NOTIFICATIONS_STATE from 'expert/redux/notifications/configs/initNotificationsState';
import NotificationsState from 'expert/redux/notifications/interfaces/notificationsState';

export enum NotificationsActionTypes {
  SetNotifications = 'SetNotifications',
  ResetNotifications = 'ResetNotifications',
  SetNotificationsAndTotalCount = 'SetNotificationsAndTotalCount',
  SetNotificationsLoadingStatus = 'SetNotificationsLoadingStatus',
  SetNotificationsAndStopLoading = 'SetNotificationsAndStopLoading',
  SetUnreadNotificationsCount = 'SetUnreadNotificationsCount',
}

export type ResetNotificationsAction = {
  type: NotificationsActionTypes.ResetNotifications;
  payload: NotificationsState;
};

export const resetNotifications = (): ResetNotificationsAction => ({
  type: NotificationsActionTypes.ResetNotifications,
  payload: INIT_NOTIFICATIONS_STATE,
});

export type SetUnreadNotificationsCountAction = {
  type: NotificationsActionTypes.SetUnreadNotificationsCount;
  payload: number;
};

export const setUnreadNotificationsCount = actionFn<SetUnreadNotificationsCountAction>(
  NotificationsActionTypes.SetUnreadNotificationsCount,
);

export type SetNotificationsAndTotalCountAction = {
  type: NotificationsActionTypes.SetNotificationsAndTotalCount;
  payload: {
    items: Notification[];
    totalCount: number;
  };
};

export const setNotificationsAndTotalCount = actionFn<SetNotificationsAndTotalCountAction>(
  NotificationsActionTypes.SetNotificationsAndTotalCount,
);

export type SetNotificationsAction = {
  type: NotificationsActionTypes.SetNotifications;
  payload: Notification[];
};

export const setNotifications = actionFn<SetNotificationsAction>(NotificationsActionTypes.SetNotifications);

export type SetNotificationsLoadingStatusAction = {
  type: NotificationsActionTypes.SetNotificationsLoadingStatus;
  payload: boolean;
};

export const setNotificationsLoadingStatus = actionFn<SetNotificationsLoadingStatusAction>(
  NotificationsActionTypes.SetNotificationsLoadingStatus,
);

export type SetNotificationsAndStopLoadingAction = {
  type: NotificationsActionTypes.SetNotificationsAndStopLoading;
  payload: Notification[];
};

export const setNotificationsAndStopLoading = actionFn<SetNotificationsAndStopLoadingAction>(
  NotificationsActionTypes.SetNotificationsAndStopLoading,
);

export type NotificationsActions =
  | SetNotificationsAction
  | SetNotificationsLoadingStatusAction
  | SetUnreadNotificationsCountAction
  | SetNotificationsAndTotalCountAction
  | ResetNotificationsAction
  | SetNotificationsAndStopLoadingAction;
