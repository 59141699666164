import { exceptionCatcherWrap } from '../../../sagaWrapper';
import {
  eaFinishOnboardingError,
  eaFinishOnboardingSuccess,
  eaSaveAndRerunError,
  eaSetResume,
  eaStartOnboardingError,
  eaStartOnboardingSuccess,
  getExpertAssistOnboardingError,
  getExpertAssistOnboardingSuccess,
  saveAndExitSuccess,
  setExpertAssistOnboardingError,
  setExpertAssistOnboardingSuccess,
} from './actionCreators';
import { all, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { processRequest } from '../../../services/Api';
import { ExpertAssistOnboardingReducer } from './interfaces';
import * as signInActions from '../../signIn/Actions';
import { EA_ONBOARDING_PROGRESS } from '../../../constants/expertAssistOnboarding';
import { getExpertMatchesSuccess } from '../../expertAssistMatches/redux/actionCreators';

const url = 'customer/expert_assist_questionary/';
const rerunUrl = 'customer/rerun_expert_assist';

function* uploadResume(file: File) {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('filename', file.name);
  formData.append('type', 'ea_resume');
  yield call(processRequest, 'user/files', 'POST', { file: formData, type: 'application/pdf' }, true);

  return file.name;
}

function* getExpertAssistOnboardingSaga() {
  const response = yield call(processRequest, url);

  yield put(getExpertAssistOnboardingSuccess(response.data));
}

function* updateOnboardingAndUserSaga(onboardingPayload, userPayload) {
  const id = yield select(
    ({ expertAssistOnboarding }: ExpertAssistOnboardingReducer) => expertAssistOnboarding.data!.id,
  );

  return yield all([
    call(processRequest, `${url}${id}/`, 'PATCH', onboardingPayload),
    call(processRequest, 'user/', 'PUT', userPayload),
  ]);
}

function* setExpertAssistOnboardingSaga({ payload, currentStepProgress, nextUrl }) {
  const isSaveAndExit = yield select(
    ({ expertAssistOnboarding }: ExpertAssistOnboardingReducer) => expertAssistOnboarding.isSaveAndExit,
  );
  const onboardingProgress = yield select(({ signInReducer }: any) => signInReducer.user?.ea_onboarding_progress);
  const isCompleted = onboardingProgress === EA_ONBOARDING_PROGRESS.completed;

  if (isSaveAndExit) {
    const userPayload = {
      can_rerun: isCompleted,
    };
    const [onboarding, user] = yield* updateOnboardingAndUserSaga(payload, userPayload);

    return yield all([
      put(signInActions.getUserSuccess({ user: user.data })),
      put(setExpertAssistOnboardingSuccess(onboarding.data)),
      // put(push('/expert-assist/overview')),
    ]);
  }

  const userPayload = {
    ea_onboarding_progress: onboardingProgress > currentStepProgress ? onboardingProgress : currentStepProgress + 1,
    can_rerun: isCompleted,
  };
  const [onboarding, user] = yield* updateOnboardingAndUserSaga(payload, userPayload);

  yield all([
    put(signInActions.getUserSuccess({ user: user.data })),
    ...(nextUrl ? [put(push(nextUrl))] : []),
    put(setExpertAssistOnboardingSuccess(onboarding.data)),
  ]);
}

function* submitOnboarding(userPayload, redirectUrl) {
  const congratulationsShowed = yield select(
    ({ expertAssistOnboarding }: ExpertAssistOnboardingReducer) => expertAssistOnboarding.data!.congratulations_showed,
  );

  const user = yield call(processRequest, 'user/', 'PUT', userPayload);

  yield call(processRequest, rerunUrl, 'POST');
  const expertMatches = yield call(processRequest, 'customer/get_matches_expert');

  const showCongratulations = !congratulationsShowed && !!expertMatches.data.count;

  yield all([
    put(getExpertMatchesSuccess(expertMatches.data, true)),
    put(signInActions.getUserSuccess({ user: user.data })),
    put(eaFinishOnboardingSuccess(showCongratulations)),
    ...(showCongratulations ? [] : [put(push(redirectUrl))]),
  ]);
}

interface eaFinishProps {
  payload: File | null;
  isSaveAndExit: boolean;
}

function* expertAssistFinishOnboardingSaga({ payload, isSaveAndExit }: eaFinishProps) {
  const onboardingProgress = yield select(({ signInReducer }: any) => signInReducer.user?.ea_onboarding_progress);
  const isCompleted = onboardingProgress === EA_ONBOARDING_PROGRESS.completed;

  if (payload) {
    const fileName = yield* uploadResume(payload);

    yield put(eaSetResume(fileName));
  }

  if (isSaveAndExit) {
    let user;

    if (isCompleted) {
      user = yield call(processRequest, 'user/', 'PUT', { can_rerun: true });
    }

    yield all([
      put(saveAndExitSuccess()),
      ...(user ? [put(signInActions.getUserSuccess({ user: user.data }))] : []),
      // put(push('/expert-assist/overview')),
    ]);
  } else {
    const userPayload = {
      ea_onboarding_progress: EA_ONBOARDING_PROGRESS.completed,
    };

    yield* submitOnboarding(userPayload, '/expert-assist/expert-matches');
  }
}

interface eaSaveAndRerunProps {
  payload: File | null;
}

function* expertAssistSaveAndRerunSaga({ payload }: eaSaveAndRerunProps) {
  if (payload) {
    const fileName = yield* uploadResume(payload);

    yield put(eaSetResume(fileName));
  }

  // const userPayload = {
  //   can_rerun: false,
  // };
  //
  // yield* submitOnboarding(userPayload, '/expert-assist/overview');
}

function* expertAssistStartOnboardingSaga() {
  const userPayload = {
    ea_onboarding_progress: EA_ONBOARDING_PROGRESS.goalsAndInterests,
  };

  const response = yield call(processRequest, 'user/', 'PUT', userPayload);

  yield all([
    put(signInActions.getUserSuccess({ user: response.data })),
    put(eaStartOnboardingSuccess()),
    put(push('/expert-assist/onboarding/goals-and-interests')),
  ]);
}

function* expertAssistSetCongratulationsSaga() {
  const id = yield select(
    ({ expertAssistOnboarding }: ExpertAssistOnboardingReducer) => expertAssistOnboarding.data!.id,
  );

  yield call(processRequest, `${url}${id}/`, 'PATCH', { congratulations_showed: true });
}

export const getExpertAssistOnboarding = exceptionCatcherWrap(
  getExpertAssistOnboardingSaga,
  getExpertAssistOnboardingError,
);
export const setExpertAssistOnboarding = exceptionCatcherWrap(
  setExpertAssistOnboardingSaga,
  setExpertAssistOnboardingError,
);
export const expertAssistFinishOnboarding = exceptionCatcherWrap(
  expertAssistFinishOnboardingSaga,
  eaFinishOnboardingError,
);
export const expertAssistSaveAndRerun = exceptionCatcherWrap(expertAssistSaveAndRerunSaga, eaSaveAndRerunError);
export const expertAssistStartOnboarding = exceptionCatcherWrap(
  expertAssistStartOnboardingSaga,
  eaStartOnboardingError,
);
export const expertAssistSetCongratulations = exceptionCatcherWrap(expertAssistSetCongratulationsSaga, null);
