import Expert from 'expert/common/interfaces/expert';
import ExpertRes from 'expert/sagas/interfaces/expertRes';

const resToExpert = (source: ExpertRes): Expert => ({
  id: source.id,
  avatar: source.avatar,
  isTemporaryPass: source.is_temporary_pass,
  isAgreement: source.is_agreement,
  email: source.email,
  expertId: source.profile_details.id,
  expertProfileStep: source.profile_details.expert_profile_step,
  firstName: source.first_name,
  lastName: source.last_name,
  payoutsEnabled: source.profile_details.payouts_enabled,
  phoneNumber: source.phone_number,
  timeZone: source.timezone,
  timeZoneLabel: source.timezone_label,
  isPaused: source.profile_details.is_paused,
  linkedInUrl: source.linkedin_url || '',
});

export default resToExpert;
