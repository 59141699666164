import NotificationSourceTypes from 'expert/sagas/enums/notificationSourceTypes';

const notificationTitlesMap: Record<NotificationSourceTypes, string> = {
  [NotificationSourceTypes.CustomerLeftAFeedback]: 'Customer review',
  [NotificationSourceTypes.CustomerBookedTheMeeting]: 'Zoom session',
  [NotificationSourceTypes.MeetingIn5Minutes]: 'Zoom session',
  [NotificationSourceTypes.CustomerRescheduledTheMeeting]: 'Meeting time was changed',
  [NotificationSourceTypes.CustomerCanceledTheMeeting]: 'Zoom session',
  [NotificationSourceTypes.GotAPayout]: 'Receive new payout',
  [NotificationSourceTypes.HaveToSetAvailability]: 'Please set your availability!',
  [NotificationSourceTypes.ShouldLeaveAFeedbackForCustomer]: 'Please leave a feedback for customer',
};

export default notificationTitlesMap;
