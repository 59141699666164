import { paymentPackagesActionTypes } from './Constants';

export function getPaymentPackagesRequest(data) {
  return {
    type: paymentPackagesActionTypes.GET_PAYMENT_PACKAGES_REQUEST,
    payload: {
      data,
    },
  };
}

export function getPaymentPackagesSuccess(data) {
  return {
    type: paymentPackagesActionTypes.GET_PAYMENT_PACKAGES_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getPaymentPackagesError(error) {
  return {
    type: paymentPackagesActionTypes.GET_PAYMENT_PACKAGES_ERROR,
    payload: {
      error,
    },
  };
}
