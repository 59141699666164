export const GET_EXPERT_ASSIST_ONBOARDING = 'GET_EXPERT_ASSIST_ONBOARDING';
export const GET_EXPERT_ASSIST_ONBOARDING_SUCCESS = `${GET_EXPERT_ASSIST_ONBOARDING}_SUCCESS`;
export const GET_EXPERT_ASSIST_ONBOARDING_ERROR = `${GET_EXPERT_ASSIST_ONBOARDING}_ERROR`;

export const SET_EXPERT_ASSIST_ONBOARDING = 'SET_EXPERT_ASSIST_ONBOARDING';
export const SET_EXPERT_ASSIST_ONBOARDING_SUCCESS = `${SET_EXPERT_ASSIST_ONBOARDING}_SUCCESS`;
export const SET_EXPERT_ASSIST_ONBOARDING_ERROR = `${SET_EXPERT_ASSIST_ONBOARDING}_ERROR`;

export const EA_FINISH_ONBOARDING = 'EA_FINISH_ONBOARDING';
export const EA_FINISH_ONBOARDING_SUCCESS = `${EA_FINISH_ONBOARDING}_SUCCESS`;
export const EA_FINISH_ONBOARDING_ERROR = `${EA_FINISH_ONBOARDING}_ERROR`;

export const EA_SAVE_AND_RERUN = 'EA_SAVE_AND_RERUN';
export const EA_SAVE_AND_RERUN_ERROR = `${EA_SAVE_AND_RERUN}_ERROR`;

export const SET_SAVE_AND_EXIT = 'SET_SAVE_AND_EXIT';
export const CLEAR_SAVE_AND_EXIT = 'CLEAR_SAVE_AND_EXIT';
export const EA_CLEAR_CONGRATULATION = 'EA_CLEAR_CONGRATULATION';
export const EA_SAVE_AND_EXIT_SUCCESS = 'EA_SAVE_AND_EXIT_SUCCESS';

export const EA_START_ONBOARDING = 'EA_START_ONBOARDING';
export const EA_START_ONBOARDING_SUCCESS = `${EA_START_ONBOARDING}_SUCCESS`;
export const EA_START_ONBOARDING_ERROR = `${EA_START_ONBOARDING}_ERROR`;

export const EA_CONGRATULATIONS_VIEWED = 'EA_CONGRATULATIONS_VIEWED';
export const EA_SET_RESUME = 'EA_SET_RESUME';
