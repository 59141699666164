import RescheduleAvailabilityState from 'expert/redux/rescheduleAvailability/interfaces/rescheduleAvailabilityState';
import INIT_RESCHEDULE_AVAILABILITY_STATE from 'expert/redux/rescheduleAvailability/configs/initRescheduleAvailabilityState';
import {
  RescheduleAvailabilityActions,
  RescheduleAvailabilityActionTypes,
} from 'expert/redux/rescheduleAvailability/rescheduleAvailabilityActions';

const rescheduleAvailabilityReducer = (
  state: RescheduleAvailabilityState = INIT_RESCHEDULE_AVAILABILITY_STATE,
  action: RescheduleAvailabilityActions,
): RescheduleAvailabilityState => {
  switch (action.type) {
    case RescheduleAvailabilityActionTypes.SetRescheduleAvailability:
      return {
        ...state,
        isAbleToReschedule: action.payload,
      };
    case RescheduleAvailabilityActionTypes.SetRescheduleAvailabilityLoading:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default rescheduleAvailabilityReducer;
