import KeyMirror from 'keymirror';

export const generalActionTypes = KeyMirror({
  SEND_ACTION_EVENT_REQUEST: null,
  SEND_ACTION_EVENT_ERROR: null,
  CHECK_DISCOUNT_REQUEST: null,
  CHECK_DISCOUNT_SUCCESS: null,
  CHECK_DISCOUNT_ERROR: null,
});

export const EVENT_TYPES = {
  CLICK_ON_THE_INDUSTRY_TREND: 'click_on_the_industry_trend',
  CLICK_ON_THE_SAMPLE_ROLE: 'click_on_the_sample_role',
  CLICK_ON_THE_SKILL_TO_STRENGTHEN: 'click_on_the_skill_to_strengthen',
  CLICK_ON_THE_COURSES: 'click_on_the_courses',
  CLICK_ON_THE_JOB_POSTINGS_TO_EXPAND: 'click_on_the_job_postings_to_expand',
  CLICK_ON_THE_SEE_MORE_BUTTON: 'click_on_the_see_more_button',
  CLICK_ON_APPLY_BUTTON: 'click_on_the_apply_button',
};

export const MAPPED_EVENTS_TO_LOG = {
  [EVENT_TYPES.CLICK_ON_THE_INDUSTRY_TREND]: 'trend_link_click_web',
  [EVENT_TYPES.CLICK_ON_THE_SAMPLE_ROLE]: 'role_click_web',
  [EVENT_TYPES.CLICK_ON_THE_COURSES]: 'course_click_web',
};
