import { customModalTypes } from './Constants';

export function toggleModalAction(data) {
  return {
    type: customModalTypes.TOGGLE_CUSTOM_MODAL,
    payload: {
      data,
    },
  };
}

export function setModalDataAction(data) {
  return {
    type: customModalTypes.SET_CUSTOM_MODAL_DATA,
    payload: {
      data,
    },
  };
}
