import { all } from 'redux-saga/effects';
import landingSagas from './pages/landing/Sagas';
import signUpSagas from './pages/signUp/Sagas';
import signInSagas from './pages/signIn/Sagas';
import onboardingSagas from './pages/onboarding/Sagas';
import careerMapperSagas from './pages/careerMapper/Sagas';
import careerMaperDetailsSagas from './pages/careerMapperDetails/Sagas';
import jobPostingsSaagas from './pages/careerMapperDetails/components/liveJobPostings/Sagas';
import coursesSagas from './pages/strengthenPage/Sagas';
import paymentsSagas from './pages/payments/Sagas';
import paymentPackagesSagas from './commonComponents/paymentPackages/Sagas';
import articlesSagas from './commonComponents/articlesBlock/Sagas';
import featuredExpertsSagas from './commonComponents/featuredExperts/Sagas';
import activityCoursesSagas from './pages/activity/components/coursesSection/Sagas';
import feedbackSagas from './commonComponents/feedbackModalContent/Sagas';
import profileSagas from './pages/userProfile/Sagas';
import generalSagas from './pages/general/Sagas';
import expertSagas from './expert/sagas/experts/expertSagas';
import notificationsSagas from './expert/sagas/notifications/notificationsSagas';
import scheduleSagas from './expert/sagas/schedule/scheduleSagas';
import dashboardSagas from './expert/sagas/dashboard/dashboardSagas';
import feedbackExpertSagas from './expert/sagas/feedback/feedbackSagas';
import googleSagas from './expert/sagas/google/googleSagas';
import webSagas from './expert/sagas/web/webSagas';
import userSagas from './expert/sagas/user/userSagas';
import expertAssistOnboardingWatchers from './pages/expertAssistOnboarding/redux/sagaWatchers';
import expertAssistMatchesWatchers from './pages/expertAssistMatches/redux/sagaWatchers';
import expertAssistSessionBookingSagas from './pages/expertAssistSessionBooking/redux/sagaWatchers';

export default function* rootSaga() {
  yield all([
    ...landingSagas,
    ...signUpSagas,
    ...userSagas,
    ...googleSagas,
    ...webSagas,
    ...feedbackExpertSagas,
    ...dashboardSagas,
    ...signInSagas,
    ...scheduleSagas,
    ...onboardingSagas,
    ...careerMapperSagas,
    ...careerMaperDetailsSagas,
    ...jobPostingsSaagas,
    ...paymentPackagesSagas,
    ...articlesSagas,
    ...featuredExpertsSagas,
    ...paymentsSagas,
    ...coursesSagas,
    ...activityCoursesSagas,
    ...feedbackSagas,
    ...profileSagas,
    ...generalSagas,
    ...expertSagas,
    ...notificationsSagas,
    ...expertAssistOnboardingWatchers,
    ...expertAssistMatchesWatchers,
    ...expertAssistSessionBookingSagas,
  ]);
}
