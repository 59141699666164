import Review from 'expert/common/components/reviewItem/interfaces/review';

interface SerReviewUpdatingStateProps {
  reviews: Review[];
  id: number;
  isUpdating: boolean;
}

const serReviewUpdatingState: (props: SerReviewUpdatingStateProps) => Review[] = ({ reviews, isUpdating, id }) =>
  reviews.map((review) => (review.id === id ? { ...review, isUpdating } : review));

export default serReviewUpdatingState;
