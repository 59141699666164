export const EA_GET_EXPERT_PROFILE = 'EA_GET_EXPERT_PROFILE';
export const EA_GET_EXPERT_PROFILE_SUCCESS = `${EA_GET_EXPERT_PROFILE}_SUCCESS`;

export const EA_GET_TIMEZONES = 'EA_GET_TIMEZONES';
export const EA_GET_TIMEZONES_SUCCESS = `${EA_GET_TIMEZONES}_SUCCESS`;
export const EA_GET_TIMEZONES_ERROR = `${EA_GET_TIMEZONES}_ERROR`;

export const EA_GET_MONTH_AVAILABILITY = 'EA_GET_MONTH_AVAILABILITY';
export const EA_GET_MONTH_AVAILABILITY_SUCCESS = `${EA_GET_MONTH_AVAILABILITY}_SUCCESS`;

export const EA_GET_DAY_AVAILABILITY = 'EA_GET_DAY_AVAILABILITY';
export const EA_GET_DAY_AVAILABILITY_SUCCESS = `${EA_GET_DAY_AVAILABILITY}_SUCCESS`;

export const EA_SET_DURATION = 'EA_SET_DURATION';
export const EA_SET_TIME = 'EA_SET_TIME';
export const EA_SELECT_TIMEZONE = 'EA_SELECT_TIMEZONE';

export const EA_CONFIRM_SESSION = 'EA_CONFIRM_SESSION';
export const EA_CONFIRM_SESSION_SUCCESS = `${EA_CONFIRM_SESSION}_SUCCESS`;
export const EA_CONFIRM_SESSION_ERROR = `${EA_CONFIRM_SESSION}_ERROR`;

export const EA_EDIT_SESSION = 'EA_EDIT_SESSION';

export const EA_GET_SESSION = 'EA_GET_SESSION';
export const EA_GET_SESSION_SUCCESS = `${EA_GET_SESSION}_SUCCESS`;
export const EA_GET_SESSION_ERROR = `${EA_GET_SESSION}_ERROR`;

export const EA_CLEAR_BOOKING_DATA = 'EA_CLEAR_BOOKING_DATA';
