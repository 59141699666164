import { put, call, takeLatest } from 'redux-saga/effects';
import { processRequest } from '../../../../services/Api';
import * as jobPostingsActions from './Actions';
import { jobPostingsTypes } from './Constans';

export function* handleGetJobPostingsDataRequest({ payload }) {
  try {
    const { data } = yield call(processRequest, 'customer/get_job_postings', 'GET', payload.data.params);

    yield put(
      jobPostingsActions.getJobPostingsSuccess({
        jobPostingsData: data,
      }),
    );
  } catch (e) {
    yield put(jobPostingsActions.getJobPostingsError({ error: e }));
  }
}

export function* handlePostJobPostongsFavoriteRequest({ payload }) {
  try {
    const data = yield call(processRequest, 'customer/post_job_postings_favorite', 'POST', {
      job_posting_id: payload.data.job_posting_id,
      is_favorite: payload.data.is_favorite,
    });

    yield put(
      jobPostingsActions.postJobPostingsFavoriteSuccess({
        data,
        id: payload.data.job_posting_id,
      }),
    );
  } catch (e) {
    yield put(jobPostingsActions.postJobPostingsFavoriteError({ error: e }));
  }
}

const liveJobPostingsSagas = [
  takeLatest(jobPostingsTypes.GET_JOB_POSTINGS_REQUEST, handleGetJobPostingsDataRequest),
  takeLatest(jobPostingsTypes.POST_JOB_POSTINGS_FAVORITE_REQUEST, handlePostJobPostongsFavoriteRequest),
];

export default liveJobPostingsSagas;
