import TimeZonesState from './interfaces/timeZonesState';
import { TimeZonesActions, TimeZonesActionTypes } from './timeZonesActions';
import INIT_TIME_ZONES_STATE from './configs/initTimeZonesState';

const timeZonesReducer = (state: TimeZonesState = INIT_TIME_ZONES_STATE, action: TimeZonesActions): TimeZonesState => {
  switch (action.type) {
    case TimeZonesActionTypes.ResetTimeZones:
      return action.payload;
    case TimeZonesActionTypes.SetTimeZones:
      return {
        ...state,
        timeZones: action.payload,
      };
    case TimeZonesActionTypes.SetTimeZonesAndStopLoading:
      return {
        ...state,
        timeZones: action.payload,
        isLoading: false,
      };
    case TimeZonesActionTypes.SetTimeZonesLoadingState:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default timeZonesReducer;
