import { put, call, takeLatest } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import { expertsActionTypes } from './Constants';
import * as expertsActions from './Actions';

export function* handleGetExpertsRequest() {
  try {
    const { data } = yield call(processRequest, 'landing/experts/expert');

    yield put(expertsActions.getExpertsSuccess({ experts: data.results }));
  } catch (e) {
    yield put(expertsActions.getExpertsError({ error: e }));
  }
}

const featuredExpertsSagas = [takeLatest(expertsActionTypes.GET_EXPERTS_REQUEST, handleGetExpertsRequest)];

export default featuredExpertsSagas;
