import React, { FC } from 'react';
import TextDialog from 'expert/common/components/textDialog/TextDialog';
import AgreementTypes from 'expert/redux/agreement/enums/agreementTypes';
import UserAgreement from 'expert/common/components/userAgreement/UserAgreement';
import useAgreementDialog from 'expert/modules/agreementDialog/hooks/useAgreementDialog';
import ExpertAgreement from 'expert/common/components/expertAgreement/ExpertAgreement';

const AgreementDialog: FC = () => {
  const { isOpened, agreementType, onAccept } = useAgreementDialog();

  return (
    <TextDialog isOpened={isOpened}>
      {agreementType === AgreementTypes.UserAgreement && <UserAgreement onAccept={onAccept} />}
      {agreementType === AgreementTypes.ExpertAgreement && <ExpertAgreement onAccept={onAccept} />}
    </TextDialog>
  );
};

export default AgreementDialog;
