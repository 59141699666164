import Review from 'expert/common/components/reviewItem/interfaces/review';
import updateReview from 'expert/sagas/utils/updateReview';

interface AddReviewProps {
  reviews: Review[];
  newReview: Review;
}

const addReview: (props: AddReviewProps) => Review[] = ({ newReview, reviews }) =>
  reviews.findIndex(({ id }) => id === newReview.id) > -1
    ? updateReview({ newReview, reviews })
    : [...reviews, newReview];

export default addReview;
