import keyMirror from 'keymirror';
import UpdatePasswordData from 'expert/sagas/interfaces/updatePasswordData';

export const userSagaActionTypes = keyMirror({
  FETCH_EXPERT: null,
  CHANGE_PASSWORD: null,
  SET_EXPERT_AGREEMENT: null,
  FETCH_RESUME: null,
  UPDATE_PASSWORD: null,
  DELETE_USER: null,
  UPDATE_TIME_ZONE: null,
  FETCH_TIMEZONES: null,
});

export function fetchResume() {
  return {
    type: userSagaActionTypes.FETCH_RESUME,
  };
}

export function fetchExpert() {
  return {
    type: userSagaActionTypes.FETCH_EXPERT,
  };
}

export function changePassword(password: string) {
  return {
    type: userSagaActionTypes.CHANGE_PASSWORD,
    payload: password,
  };
}

export function setAgreement() {
  return {
    type: userSagaActionTypes.SET_EXPERT_AGREEMENT,
  };
}

export function updatePassword(payload: UpdatePasswordData) {
  return {
    type: userSagaActionTypes.UPDATE_PASSWORD,
    payload,
  };
}

export function deleteUser() {
  return {
    type: userSagaActionTypes.DELETE_USER,
  };
}

export function updateTimeZone(payload: string) {
  return {
    type: userSagaActionTypes.UPDATE_TIME_ZONE,
    payload,
  };
}

export function fetchTimeZones() {
  return {
    type: userSagaActionTypes.FETCH_TIMEZONES,
  };
}
