import ReviewRes from 'expert/sagas/interfaces/reviewRes';
import Review from 'expert/common/components/reviewItem/interfaces/review';
import moment from 'moment';

const resToReview = (source: ReviewRes): Review => ({
  id: source.id,
  isUpdating: false,
  isPublished: source.is_publish,
  createdAt: moment(source.created_at),
  user: {
    firstName: source.customer.first_name,
    lastName: source.customer.last_name || '',
    avatar: source.customer.avatar,
    position: source.customer.position || undefined,
  },
  well: source.what_like,
  notWell: source.what_dont_like,
  rating: source.rate,
});

export default resToReview;
