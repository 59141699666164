import KeyMirror from 'keymirror';

export const careerMapperTypes = KeyMirror({
  GET_CAREER_MAPPER_REPORT_REQUEST: null,
  GET_CAREER_MAPPER_REPORT_SUCCESS: null,
  GET_CAREER_MAPPER_REPORT_ERROR: null,

  UPDATE_REPORT_PATH_REQUEST: null,
  UPDATE_REPORT_PATH_SUCCESS: null,
  UPDATE_REPORT_PATH_ERROR: null,

  GET_UPGRADE_CARDS_REQUEST: null,
  GET_UPGRADE_CARDS_SUCCESS: null,
  GET_UPGRADE_CARDS_ERROR: null,
});

export const CARDS_LIMIT_FOR_UNLIMITED = 10;
export const CARDS_LIMIT = 8;
export const MIN_CARDS_LIMIT = 4;

export const COUNT_OF_TILES_PER_MATCH_TYPE = 5;
