import { ExpertInfoActions, ExpertInfoActionTypes } from 'expert/redux/expertInfo/expertInfoActions';
import ExpertInfoState from 'expert/redux/expertInfo/interfaces/expertInfoState';
import INIT_EXPERT_INFO_STATE from 'expert/redux/expertInfo/configs/initExpertInfoState';

const expertInfoReducer = (
  state: ExpertInfoState = INIT_EXPERT_INFO_STATE,
  action: ExpertInfoActions,
): ExpertInfoState => {
  switch (action.type) {
    case ExpertInfoActionTypes.ResetExpertInfo:
      return action.payload;
    case ExpertInfoActionTypes.SetExpertInfo:
      return {
        ...state,
        data: action.payload.value,
      };
    case ExpertInfoActionTypes.SetExpertIsLoading:
      return {
        ...state,
        isLoading: action.payload.value,
      };
    case ExpertInfoActionTypes.SetExpertInfoAndStopLoading:
      return {
        ...state,
        data: action.payload.value,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default expertInfoReducer;
