import { createSelector } from 'reselect';

const getSignInState = (state) => state.signInReducer;
const getSnackbarState = (state) => state.snackbarReducer;
const getCareerMapperState = (state) => state.careerMapperReducer;
const getCareerMapperDetailsState = (state) => state.careerMapperDetailsReducer;
const getjobPostingsState = (state) => state.jobPostingsReducer;
const getFeedbackState = (state) => state.feedbackReducer;
const getGeneralState = (state) => state.generalReducer;

export const getUserSelector = createSelector([getSignInState], (signInState) => signInState.user);

export const getSnackBarSelector = createSelector([getSnackbarState], (snackbarState) => ({ ...snackbarState }));

export const getCareerMapperReportsSelector = createSelector(
  [getCareerMapperState],
  (careerMapperState) => careerMapperState.reports,
);

export const getSignInErrorSelector = createSelector([getSignInState], (signInState) => signInState.error);

export const getCareerMapperDetailsSelector = createSelector(
  [getCareerMapperDetailsState],
  (careerMapperDetailsState) => careerMapperDetailsState.detailsData,
);

export const getjobPostingsSelector = createSelector(
  [getjobPostingsState],
  (jobPostingsState) => jobPostingsState.jobPostingsData,
);

export const getFeedbackSelector = createSelector([getFeedbackState], (feedbackState) => feedbackState);

export const getGeneralSelector = createSelector([getGeneralState], (generalState) => generalState);
