import actionFn from '../../common/utils/actionFn';
import ActionPayload from '../../common/interfaces/actionPayload';
import ResumeState from 'expert/redux/resume/interfaces/resumeState';
import INIT_RESUME_STATE from 'expert/redux/resume/configs/initResumeState';

export enum ResumeActionTypes {
  SetResumeFileName = 'SetResumeFileName',
  ResetResume = 'ResetResume',
}

export type ResetResumeAction = {
  type: ResumeActionTypes.ResetResume;
  payload: ResumeState;
};

export const resetResume = (): ResetResumeAction => ({
  type: ResumeActionTypes.ResetResume,
  payload: INIT_RESUME_STATE,
});

export type SetResumeFileNameAction = {
  type: ResumeActionTypes.SetResumeFileName;
  payload: ActionPayload<string>;
};

export const setResumeFileName = actionFn<SetResumeFileNameAction>(ResumeActionTypes.SetResumeFileName);

export type ResumeActions = SetResumeFileNameAction | ResetResumeAction;
