import { logEvent } from 'firebase/analytics';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import { careerMapperTypes } from './Constants';
import * as careerMapperActions from './Actions';
import { getReports } from './Selectors';
import { preparedReports } from './Services';
import analytics from '../../services/firebase';
import { normalizePathTitle } from './helpers';

export function* handleGetReportRequest({ payload }) {
  try {
    const { user } = payload.data;
    const { data } = yield call(processRequest, 'customer/path/');

    if (payload.isFirstPathsFetch) {
      data.forEach((path) => {
        const title = normalizePathTitle(path.title);

        logEvent(analytics, 'job_families_event_web', { [title]: path.id });
      });
    }

    const reports = preparedReports(data, payload, user);

    yield put(careerMapperActions.getCareerMapperReportSuccess({ reports }));
  } catch (e) {
    yield put(careerMapperActions.getCareerMapperReportError({ error: e }));
  }
}

export function* handleUpdateReportPathRequest({ payload }) {
  try {
    const reports = yield select(getReports);
    const { data } = yield call(processRequest, `customer/path/${payload.data.pathId}/`, 'PUT', {
      is_favorite: payload.data.is_favorite,
    });

    const updatedReports = reports.reduce((acc, item) => {
      if (item.id === data.id) {
        acc.push(data);
      } else {
        acc.push(item);
      }

      return acc;
    }, []);

    yield put(careerMapperActions.getCareerMapperReportSuccess({ reports: updatedReports }));
  } catch (e) {
    yield put(careerMapperActions.updateReportPathError({ error: e }));
  }
}

export function* handleGetUpgradeCardsRequest({ payload }) {
  try {
    const { data } = yield call(processRequest, `customer/get_${payload.data.product}_possible_tariffs`);

    yield put(careerMapperActions.getUpgradeCardsSuccess({ upgradeCards: data }));
  } catch (e) {
    yield put(careerMapperActions.updateReportPathError({ error: e }));
  }
}

const careerMapperSagas = [
  takeLatest(careerMapperTypes.GET_CAREER_MAPPER_REPORT_REQUEST, handleGetReportRequest),
  takeLatest(careerMapperTypes.UPDATE_REPORT_PATH_REQUEST, handleUpdateReportPathRequest),
  takeLatest(careerMapperTypes.GET_UPGRADE_CARDS_REQUEST, handleGetUpgradeCardsRequest),
];

export default careerMapperSagas;
