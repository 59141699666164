import { articlesActionTypes } from './Constants';

export function getArticlesRequest(data) {
  return {
    type: articlesActionTypes.GET_ARTICLES_REQUEST,
    payload: {
      data,
    },
  };
}

export function getArticlesSuccess(data) {
  return {
    type: articlesActionTypes.GET_ARTICLES_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getArticlesError(error) {
  return {
    type: articlesActionTypes.GET_ARTICLES_ERROR,
    payload: {
      error,
    },
  };
}
