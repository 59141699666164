import INIT_EXPERT_FEEDBACK_STATE from 'expert/redux/expertFeedback/configs/initExpertFeedbackState';
import { ExpertFeedbackActions, ExpertInfoActionsTypes } from 'expert/redux/expertFeedback/expertFeedbackActions';
import ExpertFeedbackState from 'expert/redux/expertFeedback/interfaces/expertFeedbackState';

const expertFeedbackReducer = (
  state: ExpertFeedbackState = INIT_EXPERT_FEEDBACK_STATE,
  action: ExpertFeedbackActions,
): ExpertFeedbackState => {
  switch (action.type) {
    case ExpertInfoActionsTypes.ResetExpertFeedback:
      return action.payload;
    case ExpertInfoActionsTypes.CloseExpertFeedback:
      return {
        ...state,
        feedback: null,
        isLoading: false,
        isFirstPopup: false,
      };
    case ExpertInfoActionsTypes.SetExpertFeedback:
      return {
        ...state,
        feedback: action.payload.feedback,
        isFirstPopup: action.payload.isFirst,
      };
    case ExpertInfoActionsTypes.SetExpertFeedbackLoadingState:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default expertFeedbackReducer;
