import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import MESSAGES from 'expert/common/configs/messages';
import {
  setExpert,
  setExpertPauseState,
  setExpertProfileStep,
  setPauseUpdatingState,
} from 'expert/redux/expert/expertActions';
import { setExpertInfo, setExpertIsLoading } from 'expert/redux/expertInfo/expertInfoActions';
import { setNotifications } from 'expert/redux/notifications/notificationsActions';
import { notificationsSelector } from 'expert/redux/notifications/notificationsSelectors';
import { setPastSessions } from 'expert/redux/pastSessions/pastSessionsActions';
import { pastSessionsSelector } from 'expert/redux/pastSessions/pastSessionsSelectors';
import {
  setLoadingReviewsIds,
  setReviews,
  setReviewsAndStopLoading,
  setReviewsLoadingState,
} from 'expert/redux/reviews/reviewsActions';
import { loadingReviewsIdsSelector, reviewsSelector } from 'expert/redux/reviews/reviewsSelectors';
import {
  setCalendarSessions,
  setCalendarSessionsLoadingState,
  setCancelingSessions,
  setReschedulingSessions,
} from 'expert/redux/sessions/sessionsActions';
import { sessionsCancelingIdsSelector, sessionsReschedulingIdsSelector } from 'expert/redux/sessions/sessionsSelectors';
import { setWidgetsAndStopLoading, setWidgetsLoading } from 'expert/redux/widgets/widgetsActions';
import ReviewUpdateTypes from 'expert/sagas/enums/reviewUpdateTypes';
import ExpertRes from 'expert/sagas/interfaces/expertRes';
import FetchCalendarSessionsProps from 'expert/sagas/interfaces/fetchCalendarSessionsProps';
import RescheduleSessionProps from 'expert/sagas/interfaces/rescheduleSessionProps';
import ReviewPublishUpdateData from 'expert/sagas/interfaces/reviewPublishUpdateData:';
import UpdateExpertProps from 'expert/sagas/interfaces/updateExpertProps';
import addIdToArray from 'expert/sagas/utils/addIdToArray';
import addReview from 'expert/sagas/utils/addReview';
import resToExpert from 'expert/sagas/utils/resToExpert';
import resToExpertInfo from 'expert/sagas/utils/resToExpertInfo';
import profileFormDataToParams from 'expert/sagas/utils/profileFormDataToParams';
import removeIdFromArray from 'expert/sagas/utils/removeIdFormArray';
import resToReview from 'expert/sagas/utils/resToReview';
import resToSession from 'expert/sagas/utils/resToSession';
import serReviewUpdatingState from 'expert/sagas/utils/setReviewUpdatingState';
import updateNotificationReview from 'expert/sagas/utils/updateNotificationReview';
import updatePastSessionReview from 'expert/sagas/utils/updatePastSessionReview';
import updateReview from 'expert/sagas/utils/updateReview';
import resToWidgetsData from 'expert/sagas/utils/resToWidgetsData';
import { processRequest } from 'services/Api';
import EXPERT_PATHS from './expertPaths';
import { expertSagaActionTypes } from './expertSagasActions';
import { handleFetchResume, handleSaveUserProfileRequest, uploadUserFile } from 'expert/sagas/user/userSagas';
import {
  setCustomerFeedback,
  setCustomerFeedbackLoadingState,
} from 'commonComponents/customerSessionFeedback/redux/customerFeedback/customerFeedbackActions';
import resToCustomerFeedback from 'expert/sagas/utils/resToCustomerFeedback';
import SaveCustomerFeedbackProps from 'expert/sagas/interfaces/saveCustomerFeedbackProps';
import shouldHandleError from 'expert/sagas/utils/shouldHandleError';
import CheckIsRescheduleAvailableProps from 'expert/sagas/interfaces/checkIsRescheduleAvailableProps';
import {
  setRescheduleAvailability,
  setRescheduleAvailabilityLoading,
} from 'expert/redux/rescheduleAvailability/rescheduleAvailabilityActions';
import { setStripeSetUpUrl, setStripeSetUpUrlLoading } from 'expert/redux/payout/payoutActions';

export const handleSetExpertProfileStepRequest = function* ({ payload }: { payload: number }) {
  try {
    yield call(processRequest, EXPERT_PATHS.setExpertProfileStep, 'POST', {
      step: payload,
    });
    yield put(setExpertProfileStep({ value: payload }));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.setExpertProfileStep);
  }
};

const handleCancelSession = function* ({ payload: sessionId }: { payload: number }) {
  try {
    yield put(setCancelingSessions(addIdToArray(yield select(sessionsCancelingIdsSelector), sessionId)));

    yield call(processRequest, EXPERT_PATHS.cancelSession, 'POST', {
      session_id: sessionId,
    });
    toast.success(MESSAGES.success.cancelSession);
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.cancelSession);
  } finally {
    yield put(setCancelingSessions(removeIdFromArray(yield select(sessionsCancelingIdsSelector), sessionId)));
  }
};

const handleFetchCalendarSessions = function* ({ payload }: { payload: FetchCalendarSessionsProps }) {
  try {
    yield put(setCalendarSessionsLoadingState(true));

    const res = yield call(
      processRequest,
      `${EXPERT_PATHS.fetchCalendarSessions}?date_from=${payload.from}&date_to=${
        payload.to
      }&session_status=${payload.statuses.join(',')}`,
      'GET',
    );

    yield put(setCalendarSessions(res.data.map(resToSession)));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.fetchCalendarSessions);
  } finally {
    yield put(setCalendarSessionsLoadingState(false));
  }
};

const handleFetchExpertInfo = function* () {
  try {
    const { data: expertInfo } = yield call(processRequest, EXPERT_PATHS.getExpertInfo);

    yield put(
      setExpertInfo({
        value: resToExpertInfo(expertInfo),
      }),
    );
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.fetchExpertInfo);
  }
};

const handleFetchReview = function* ({ payload }: { payload: number }) {
  try {
    yield put(setLoadingReviewsIds(addIdToArray(yield select(loadingReviewsIdsSelector), payload)));
    const { data } = yield call(processRequest, `${EXPERT_PATHS.fetchReview}/${payload}`);

    yield put(
      setReviews({
        value: addReview({ newReview: resToReview(data), reviews: yield select(reviewsSelector) }),
      }),
    );
    yield put(setLoadingReviewsIds(removeIdFromArray(yield select(loadingReviewsIdsSelector), payload)));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.fetchReviews);
  } finally {
    yield put(setLoadingReviewsIds(removeIdFromArray(yield select(loadingReviewsIdsSelector), payload)));
  }
};

const handleFetchReviews = function* () {
  try {
    yield put(setReviewsLoadingState({ value: true }));

    const { data } = yield call(processRequest, EXPERT_PATHS.fetchReviews);

    yield put(setReviewsAndStopLoading({ value: data.map(resToReview) }));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.fetchReviews);
  } finally {
    yield put(setReviewsLoadingState({ value: false }));
  }
};

const handleFetchWidgets = function* () {
  try {
    yield put(setWidgetsLoading(true));
    const { data } = yield call(processRequest, EXPERT_PATHS.fetchWidgets);

    yield put(setWidgetsAndStopLoading(resToWidgetsData(data)));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.fetchWidgets);
  } finally {
    yield put(setWidgetsLoading(false));
  }
};

const handlePauseExpert = function* ({ payload: isPaused }: { payload: boolean }) {
  try {
    yield put(setPauseUpdatingState(true));

    yield call(processRequest, EXPERT_PATHS.pauseExpert, 'POST', {
      is_paused: isPaused,
    });
    yield put(setExpertPauseState(isPaused));

    toast.success(isPaused ? MESSAGES.success.pauseExpert : MESSAGES.success.unPauseExpert);
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(isPaused ? MESSAGES.errors.pauseExpert : MESSAGES.errors.unPauseExpert);
  } finally {
    yield put(setPauseUpdatingState(false));
  }
};

const handleRescheduleSession = function* ({ payload }: { payload: RescheduleSessionProps }) {
  try {
    yield put(setReschedulingSessions(addIdToArray(yield select(sessionsReschedulingIdsSelector), payload.id)));

    yield call(processRequest, EXPERT_PATHS.rescheduleSession, 'POST', {
      zoom_session_id: payload.id,
      propose: payload.proposition,
    });

    toast.success(MESSAGES.success.rescheduleSession);
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.rescheduleSession);
  } finally {
    yield put(setReschedulingSessions(removeIdFromArray(yield select(sessionsReschedulingIdsSelector), payload.id)));
  }
};

const handleReviewPublishUpdate = function* ({ payload }: { payload: ReviewPublishUpdateData }) {
  try {
    yield put(
      setReviews({
        value: serReviewUpdatingState({ reviews: yield select(reviewsSelector), isUpdating: true, id: payload.id }),
      }),
    );

    const { data } = yield call(processRequest, `${EXPERT_PATHS.updateReviewPublish}${payload.id}`, 'PATCH', {
      is_publish: payload.isPublished,
    });

    if (payload.place === ReviewUpdateTypes.Reviews) {
      yield put(
        setReviews({
          value: updateReview({ newReview: resToReview(data), reviews: yield select(reviewsSelector) }),
        }),
      );
    }

    if (payload.place === ReviewUpdateTypes.Notifications) {
      yield put(
        setNotifications(
          updateNotificationReview({
            newReview: resToReview(data),
            notifications: yield select(notificationsSelector),
          }),
        ),
      );
    }

    if (payload.place === ReviewUpdateTypes.PastSessions) {
      yield put(
        setPastSessions(
          updatePastSessionReview({ newReview: resToReview(data), pastSessions: yield select(pastSessionsSelector) }),
        ),
      );
    }

    toast.success(payload.isPublished ? MESSAGES.success.updatePublishState : MESSAGES.success.updateArchiveState);
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(payload.isPublished ? MESSAGES.errors.updatePublishState : MESSAGES.errors.updateArchiveState);
  } finally {
    yield put(
      setReviews({
        value: serReviewUpdatingState({ reviews: yield select(reviewsSelector), isUpdating: false, id: payload.id }),
      }),
    );
  }
};

const handleSaveExpertProfileRequest = function* ({ payload }: { payload: UpdateExpertProps }) {
  try {
    const { isQuestionnaire, data } = payload;

    yield put(setExpertIsLoading({ value: true }));

    if (data.avatar) yield uploadUserFile(data.avatar, 'avatar');

    if (data.resume) {
      yield uploadUserFile(data.resume, 'resume');
      yield handleFetchResume();
    }

    const user = yield handleSaveUserProfileRequest({ payload });

    const { data: profileDetails } = yield call(
      processRequest,
      `${EXPERT_PATHS.saveExpert}${data.expertId}/`,
      'PATCH',
      profileFormDataToParams(data),
    );

    yield all([
      put(
        setExpert({
          value: resToExpert(user as ExpertRes),
        }),
      ),
      put(
        setExpertInfo({
          value: resToExpertInfo(profileDetails),
        }),
      ),
    ]);

    if (isQuestionnaire) {
      yield call(processRequest, EXPERT_PATHS.setExpertProfileStep, 'POST', {
        step: 2,
      });
      yield put(setExpertProfileStep({ value: 2 }));
    }

    toast.success(MESSAGES.success.updateProfileSettings);
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.updateProfileSettings);
  } finally {
    yield put(setExpertIsLoading({ value: false }));
  }
};

const handleFetchLastCustomerFeedback = function* () {
  try {
    yield put(setCustomerFeedbackLoadingState(true));
    const { data } = yield call(processRequest, EXPERT_PATHS.lastCustomerFeedback);

    if (!data) return;

    yield put(setCustomerFeedback({ feedback: resToCustomerFeedback(data), isFirst: true }));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.fetchLastCustomerFeedback);
  } finally {
    yield put(setCustomerFeedbackLoadingState(false));
  }
};

const handleGetCustomerFeedback = function* ({ payload }: { payload: number }) {
  try {
    const { data } = yield call(processRequest, `${EXPERT_PATHS.getCustomerFeedback}/${payload}`);

    if (!data) return;

    if (data.rate) {
      toast.error(MESSAGES.info.getCustomerFeedback);

      return;
    }

    yield put(setCustomerFeedback({ feedback: resToCustomerFeedback(data), isFirst: false }));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.getCustomerFeedback);
  } finally {
    const url = new URL(window.location.href);

    url.searchParams.delete('customer_feedback_id');

    window.history.replaceState(null, '', url.toString());
  }
};

const handleRefuseCustomerFeedback = function* ({ payload }: { payload: number }) {
  try {
    yield call(processRequest, `${EXPERT_PATHS.refuseCustomerFeedback}/${payload}`, 'POST');
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.refuseCustomerFeedback);
  }
};

const handleSaveCustomerFeedback = function* ({ payload }: { payload: SaveCustomerFeedbackProps }) {
  try {
    yield put(setCustomerFeedbackLoadingState(true));
    yield call(processRequest, `${EXPERT_PATHS.saveCustomerFeedback}/${payload.id}`, 'PATCH', {
      rate: payload.data.rate,
      what_like: payload.data.liked,
      what_dont_like: payload.data.disliked,
      suggestion: payload.data.suggested,
    });
    toast.success(MESSAGES.success.saveCustomerFeedback);
    yield put(setCustomerFeedback({ feedback: null, isFirst: false }));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.saveCustomerFeedback);
  } finally {
    yield put(setCustomerFeedbackLoadingState(false));
  }
};

const handleCheckIsRescheduleAvailable = function* ({ payload }: { payload: CheckIsRescheduleAvailableProps }) {
  try {
    yield put(setRescheduleAvailabilityLoading(true));
    const {
      data: { is_available: isAvailable },
    } = yield call(
      processRequest,
      `${EXPERT_PATHS.checkIsRescheduleAvailable}?datetime_from=${payload.from}&datetime_to=${payload.to}&session_type=${payload.type}`,
    );

    yield put(setRescheduleAvailability(isAvailable));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.checkIsAvailable);
  } finally {
    yield put(setRescheduleAvailabilityLoading(false));
  }
};

const handleGetStripeSignUpLink = function* ({ payload }: { payload: boolean }) {
  try {
    yield put(setStripeSetUpUrlLoading(true));
    const { data } = yield call(
      processRequest,
      `${EXPERT_PATHS.getStripeSignUpLink}?refresh_url=${window.location.origin}/expert/new&return_url=${
        window.location.origin
      }/expert/${payload ? 'new' : 'payment-settings'}${payload ? `&is_onboarding=${payload}` : ''}`,
    );

    yield put(setStripeSetUpUrl(data.url || ''));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.getStripeSignUpLink);
  } finally {
    yield put(setStripeSetUpUrlLoading(false));
  }
};

const expertSagas = [
  takeLatest(expertSagaActionTypes.FETCH_EXPERT_INFO, handleFetchExpertInfo),
  takeLatest(expertSagaActionTypes.SET_EXPERT_PROFILE_STEP, handleSetExpertProfileStepRequest),
  takeLatest(expertSagaActionTypes.SET_EXPERT_PROFILE, handleSaveExpertProfileRequest),
  takeLatest(expertSagaActionTypes.FETCH_REVIEWS, handleFetchReviews),
  takeLatest(expertSagaActionTypes.FETCH_REVIEW, handleFetchReview),
  takeLatest(expertSagaActionTypes.UPDATE_REVIEW_PUBLISH, handleReviewPublishUpdate),
  takeLatest(expertSagaActionTypes.FETCH_WIDGETS, handleFetchWidgets),
  takeLatest(expertSagaActionTypes.CANCEL_SESSION, handleCancelSession),
  takeLatest(expertSagaActionTypes.RESCHEDULE_SESSION, handleRescheduleSession),
  takeLatest(expertSagaActionTypes.PAUSE_EXPERT, handlePauseExpert),
  takeLatest(expertSagaActionTypes.CHECK_IS_RESCHEDULE_AVAILABLE, handleCheckIsRescheduleAvailable),
  takeLatest(expertSagaActionTypes.FETCH_CALENDAR_SESSIONS, handleFetchCalendarSessions),
  takeLatest(expertSagaActionTypes.FETCH_LAST_CASTOMER_FEEDBACK, handleFetchLastCustomerFeedback),
  takeLatest(expertSagaActionTypes.REFUSE_CUSTOMER_FEEDBACK, handleRefuseCustomerFeedback),
  takeLatest(expertSagaActionTypes.SAVE_CUSTOMER_FEEDBACK, handleSaveCustomerFeedback),
  takeLatest(expertSagaActionTypes.GET_CUSTOMER_FEEDBACK, handleGetCustomerFeedback),
  takeLatest(expertSagaActionTypes.GET_STRIPE_SIGN_UP_LINK, handleGetStripeSignUpLink),
];

export default expertSagas;
