import ProfileDetailsRes from 'expert/sagas/interfaces/profileDetailsRes';
import ExpertInfo from 'expert/common/interfaces/expertInfo';
import resToCheckBoxGroupItem from 'expert/sagas/utils/resToCheckBoxGroupItem';

const resToExpertInfo = (source: ProfileDetailsRes): ExpertInfo => {
  return {
    skillsAndCompetencies: source.skills_and_competencies,
    currentCompany: source.current_company_name,
    currentIndustry: source.current_industry_name,
    currentJobTitle: source.current_job_title,
    aboutMe: source.about_me,
    price30: source.price_30,
    price60: source.price_60,
    typeOfServicesOffered: source.type_of_services_offered.filter(({ visible }) => visible).map(resToCheckBoxGroupItem),
    areasOfExpertise: source.areas_of_expertise.filter(({ visible }) => visible).map(resToCheckBoxGroupItem),
  };
};

export default resToExpertInfo;
