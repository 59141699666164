import INIT_PAST_SESSIONS_STATE from 'expert/redux/pastSessions/configs/initPastSessionsState';
import PastSessionsState from 'expert/redux/pastSessions/interfaces/pastSessionsState';
import { PastSessionsActions, PastSessionsActionTypes } from 'expert/redux/pastSessions/pastSessionsActions';

const pastSessionsReducer = (
  state: PastSessionsState = INIT_PAST_SESSIONS_STATE,
  action: PastSessionsActions,
): PastSessionsState => {
  switch (action.type) {
    case PastSessionsActionTypes.ResetPastSessions:
      return action.payload;
    case PastSessionsActionTypes.SetPastSessionsRes:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case PastSessionsActionTypes.SetPastSessions:
      return {
        ...state,
        sessions: action.payload,
      };
    case PastSessionsActionTypes.SetPastSessionsLoading:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default pastSessionsReducer;
