import { makeStyles } from '@material-ui/core/styles';
import { heading14_16_400, heading18_23_400, heading36_42_500 } from 'styles/fonts';

export default makeStyles(
  (theme) => ({
    title: {
      marginBottom: theme.spacing(4),
      textAlign: 'center',
      textDecoration: 'underline',
      ...heading36_42_500,
    },
    date: {
      marginBottom: theme.spacing(3.5),
      ...heading18_23_400,
    },
    text: heading14_16_400,
    buttonWrap: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      color: '#fff',
      width: 257,
      height: 45,
    },
  }),
  {
    name: 'Privacy',
  },
);
