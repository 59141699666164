import React from 'react';
import { SvgIconProps } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      '& path:not(.no-current), & path[fill]:not(.no-current)': {
        fill: 'currentColor',
        transition: theme.transitions.create(['color']),
      },
    },
  });

const createSvgIcon = (
  component: React.ElementType,
  other?: any,
  noStyles: boolean = false,
): React.ComponentType<SvgIconProps<React.ElementType>> => {
  const Icon = Object.create(SvgIcon);

  Icon.defaultProps = {
    component,
    ...other,
  };

  if (noStyles) return Icon;

  return withStyles(styles)(Icon);
};

export default createSvgIcon;
