import { paymentPackagesActionTypes } from './Constants';

const initialState = {
  loading: false,
  paymentPackages: [],
};

export default function paymentPackagesReducer(state = initialState, action) {
  const { type, payload } = action;
  const { data } = payload || {};

  switch (type) {
    case paymentPackagesActionTypes.GET_PAYMENT_PACKAGES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case paymentPackagesActionTypes.GET_PAYMENT_PACKAGES_SUCCESS:
      return {
        ...state,
        ...data,
      };

    default:
      return state;
  }
}
