import Session from 'expert/common/interfaces/session';
import actionFn from 'expert/common/utils/actionFn';
import UpcomingSessionsState from 'expert/redux/upcomingSessions/interfaces/upcomingSessionsState';
import INIT_UPCOMING_SESSIONS_STATE from 'expert/redux/upcomingSessions/configs/initUpcomingSessionsState';

export enum UpcomingSessionsActionTypes {
  SetUpcomingSessionsLoading = 'SetUpcomingSessionsLoading',
  SetUpcomingSessions = 'SetUpcomingSessions',
  ResetUpcomingSessions = 'ResetUpcomingSessions',
  SetUpcomingSessionsRes = 'SetUpcomingSessionsRes',
}

interface ResetUpcomingSessionsAction {
  type: UpcomingSessionsActionTypes.ResetUpcomingSessions;
  payload: UpcomingSessionsState;
}

export const resetUpcomingSessions = (): ResetUpcomingSessionsAction => ({
  type: UpcomingSessionsActionTypes.ResetUpcomingSessions,
  payload: INIT_UPCOMING_SESSIONS_STATE,
});

interface SetUpcomingSessionsLoadingAction {
  type: UpcomingSessionsActionTypes.SetUpcomingSessionsLoading;
  payload: boolean;
}

export const setUpcomingSessionsLoading = actionFn<SetUpcomingSessionsLoadingAction>(
  UpcomingSessionsActionTypes.SetUpcomingSessionsLoading,
);

interface SetUpcomingSessionsAction {
  type: UpcomingSessionsActionTypes.SetUpcomingSessions;
  payload: Session[];
}

export const setUpcomingSessions = actionFn<SetUpcomingSessionsAction>(UpcomingSessionsActionTypes.SetUpcomingSessions);

interface SetUpcomingSessionsResAction {
  type: UpcomingSessionsActionTypes.SetUpcomingSessionsRes;
  payload: {
    sessions: Session[];
    totalCount: number;
  };
}

export const setUpcomingSessionsRes = actionFn<SetUpcomingSessionsResAction>(
  UpcomingSessionsActionTypes.SetUpcomingSessionsRes,
);

export type UpcomingSessionsActions =
  | SetUpcomingSessionsLoadingAction
  | SetUpcomingSessionsResAction
  | ResetUpcomingSessionsAction
  | SetUpcomingSessionsAction;
