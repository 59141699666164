import * as types from './actionTypes';
import { ExpertAssistOnboarding } from './interfaces';

const initialState = {
  data: null,
  isFetching: false,
  isUpdating: false,
  isSaveAndExit: false,
  showCongratulations: false,
  error: null,
};

const expertAssistOnboardingReducer = (state: ExpertAssistOnboarding = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_EXPERT_ASSIST_ONBOARDING:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case types.GET_EXPERT_ASSIST_ONBOARDING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
      };
    case types.GET_EXPERT_ASSIST_ONBOARDING_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.SET_EXPERT_ASSIST_ONBOARDING:
    case types.EA_SAVE_AND_RERUN:
    case types.EA_FINISH_ONBOARDING:
    case types.EA_START_ONBOARDING:
      return {
        ...state,
        isUpdating: true,
        error: null,
      };
    case types.SET_EXPERT_ASSIST_ONBOARDING_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        data: payload,
        isSaveAndExit: false,
      };
    case types.SET_EXPERT_ASSIST_ONBOARDING_ERROR:
    case types.EA_SAVE_AND_RERUN_ERROR:
    case types.EA_FINISH_ONBOARDING_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: payload,
        isSaveAndExit: false,
      };
    case types.EA_SAVE_AND_EXIT_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isSaveAndExit: false,
      };
    case types.EA_FINISH_ONBOARDING_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isSaveAndExit: false,
        showCongratulations: payload,
      };
    case types.EA_CLEAR_CONGRATULATION:
      return {
        ...state,
        showCongratulations: false,
      };
    case types.SET_SAVE_AND_EXIT:
      return {
        ...state,
        isSaveAndExit: true,
      };
    case types.CLEAR_SAVE_AND_EXIT:
      return {
        ...state,
        isSaveAndExit: false,
      };
    case types.EA_START_ONBOARDING_SUCCESS:
      return {
        ...state,
        isUpdating: false,
      };
    case types.EA_START_ONBOARDING_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    case types.EA_SET_RESUME:
      return {
        ...state,
        data: {
          ...state.data,
          q14: payload,
        },
      };
    default:
      return state;
  }
};

export default expertAssistOnboardingReducer;
