import keyMirror from 'keymirror';

export const feedbackActionTypes = keyMirror({
  POST_FEEDBACK_REQUEST: null,
  POST_FEEDBACK_SUCCESS: null,
  POST_FEEDBACK_ERROR: null,

  POST_CANCEL_FEEDBACK_REQUEST: null,
  POST_CANCEL_FEEDBACK_SUCCESS: null,
  POST_CANCEL_FEEDBACK_ERROR: null,

  POST_FEEDBACK_INFO_REQUEST: null,
  POST_FEEDBACK_INFO_SUCCESS: null,
  POST_FEEDBACK_INFO_ERROR: null,

  GET_FEEDBACK_INFO_REQUEST: null,
  GET_FEEDBACK_INFO_SUCCESS: null,
  GET_FEEDBACK_INFO_ERROR: null,

  SET_FEEDBACK_TYPE: null,

  TOGGLE_MODAL: null,
});

export const GENERAL_FEEDBACK_FIELDS = {
  what_like: 'what_like',
  what_dont_like: 'what_dont_like',
  suggestion: 'suggestion',
};
