import { put, call, takeLatest, select } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import * as coursesActions from './Actions';
import { coursesTypes } from './Constants';

export function* handleGetCoursesDataRequest() {
  try {
    const { data } = yield call(processRequest, 'customer/get_job_courses');

    yield put(
      coursesActions.getJobPostingsSuccess({
        coursesData: data,
      }),
    );
  } catch (e) {
    yield put(coursesActions.getJobPostingsError({ error: e }));
  }
}

export function* handlePostCoursesFavoriteRequest({ payload }) {
  try {
    yield call(processRequest, 'customer/post_job_courses_favorite', 'POST', payload.data);

    const coursesData = yield select(({ activityCoursesReducer }) => activityCoursesReducer.coursesData);
    const updatedCourses = coursesData.map((item) => {
      if (item.id === payload.data.course_id) {
        return { ...item, is_favorite: payload.data.is_favorite };
      }

      return item;
    });

    yield put(
      coursesActions.getJobPostingsSuccess({
        coursesData: updatedCourses,
      }),
    );
  } catch (e) {
    yield put(coursesActions.postCoursesFavoriteError({ error: e }));
  }
}

const getCoursesSagas = [
  takeLatest(coursesTypes.GET_COURSES_REQUEST, handleGetCoursesDataRequest),
  takeLatest(coursesTypes.POST_JOB_COURSES_FAVORITE_REQUEST, handlePostCoursesFavoriteRequest),
];

export default getCoursesSagas;
