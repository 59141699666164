import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const prodConfig = {
  apiKey: 'AIzaSyCzrFmmiBlzeyt--L5SfQWUj8dBqnepyxU',
  authDomain: 'pave-333114.firebaseapp.com',
  projectId: 'pave-333114',
  storageBucket: 'pave-333114.appspot.com',
  messagingSenderId: '211677993433',
  appId: '1:211677993433:web:b03c84252ee1cb5e01e1c8',
  measurementId: 'G-HNCFG2RSGF',
};

const devConfig = {
  apiKey: 'AIzaSyAtbxHNfuvVvj1Lqn_VhPqnGQZq9-j1spI',
  authDomain: 'pave-dev-69f2d.firebaseapp.com',
  projectId: 'pave-dev-69f2d',
  storageBucket: 'pave-dev-69f2d.appspot.com',
  messagingSenderId: '201065289741',
  appId: '1:201065289741:web:98429926c08d40d9ba9d44',
  measurementId: 'G-FZMJSJ1NZR',
};

const config = process.env.REACT_APP_ENV === 'production' ? prodConfig : devConfig;

// Initialize Firebase
const app = initializeApp(config);
const analytics = getAnalytics(app);

export default analytics;
