import actionFn from '../../common/utils/actionFn';
import ActionPayload from '../../common/interfaces/actionPayload';
import ExpertInfo from 'expert/common/interfaces/expertInfo';
import ExpertInfoState from 'expert/redux/expertInfo/interfaces/expertInfoState';
import INIT_EXPERT_INFO_STATE from 'expert/redux/expertInfo/configs/initExpertInfoState';

export enum ExpertInfoActionTypes {
  SetExpertInfo = 'SetExpertInfo',
  ResetExpertInfo = 'ResetExpertInfo',
  SetExpertInfoAndStopLoading = 'SetExpertInfoAndStopLoading',
  SetExpertIsLoading = 'SetExpertIsLoading',
}

export type ResetExpertInfoAction = {
  type: ExpertInfoActionTypes.ResetExpertInfo;
  payload: ExpertInfoState;
};

export const resetExpertInfo = (): ResetExpertInfoAction => ({
  type: ExpertInfoActionTypes.ResetExpertInfo,
  payload: INIT_EXPERT_INFO_STATE,
});

export type SetExpertInfoAction = {
  type: ExpertInfoActionTypes.SetExpertInfo;
  payload: ActionPayload<ExpertInfo | null>;
};

export const setExpertInfo = actionFn<SetExpertInfoAction>(ExpertInfoActionTypes.SetExpertInfo);

export type SetExpertInfoAndStopLoadingAction = {
  type: ExpertInfoActionTypes.SetExpertInfoAndStopLoading;
  payload: ActionPayload<ExpertInfo | null>;
};

export const setExpertInfoAndStopLoading = actionFn<SetExpertInfoAndStopLoadingAction>(
  ExpertInfoActionTypes.SetExpertInfoAndStopLoading,
);

export type SetExpertIsLoadingAction = {
  type: ExpertInfoActionTypes.SetExpertIsLoading;
  payload: ActionPayload<boolean>;
};

export const setExpertIsLoading = actionFn<SetExpertIsLoadingAction>(ExpertInfoActionTypes.SetExpertIsLoading);

export type ExpertInfoActions =
  | SetExpertInfoAction
  | SetExpertIsLoadingAction
  | ResetExpertInfoAction
  | SetExpertInfoAndStopLoadingAction;
