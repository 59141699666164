import { COOKIES, ACCESS_TOKEN_EXPIRATION_DAYS } from './Constants';
import { getCookie, setCookie, isSecure, removeCookie } from './Helper';

export const isAuthenticated = () => !!getCookie(COOKIES.accessToken);

export const getAccessToken = () => getCookie(COOKIES.accessToken);

export const removeAccessToken = () => {
  removeCookie(COOKIES.accessToken);
};

export const setAccessToken = (token) => {
  setCookie(COOKIES.accessToken, token, { expires: ACCESS_TOKEN_EXPIRATION_DAYS }, { secure: isSecure() });
};
