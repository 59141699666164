import { takeLatest } from 'redux-saga/effects';
import {
  GET_EXPERT_ASSIST_ONBOARDING,
  SET_EXPERT_ASSIST_ONBOARDING,
  EA_FINISH_ONBOARDING,
  EA_SAVE_AND_RERUN,
  EA_START_ONBOARDING,
  EA_CONGRATULATIONS_VIEWED,
} from './actionTypes';
import {
  getExpertAssistOnboarding,
  setExpertAssistOnboarding,
  expertAssistFinishOnboarding,
  expertAssistSaveAndRerun,
  expertAssistStartOnboarding,
  expertAssistSetCongratulations,
} from './sagas';

const expertAssistOnboardingWatchers = [
  takeLatest(GET_EXPERT_ASSIST_ONBOARDING, getExpertAssistOnboarding),
  takeLatest(SET_EXPERT_ASSIST_ONBOARDING, setExpertAssistOnboarding),
  takeLatest(EA_FINISH_ONBOARDING, expertAssistFinishOnboarding),
  takeLatest(EA_SAVE_AND_RERUN, expertAssistSaveAndRerun),
  takeLatest(EA_START_ONBOARDING, expertAssistStartOnboarding),
  takeLatest(EA_CONGRATULATIONS_VIEWED, expertAssistSetCongratulations),
];

export default expertAssistOnboardingWatchers;
