export const noHeaderRoutes = [
  '/privacy-policy',
  '/terms-of-use',
  '/user-agreement',
  '/expert-agreement',
  '/sign-up',
  '/sign-up/expert',
  '/sign-in',
  '/confirm-email',
  '/confirm-email-expert',
  '/expert-get-started',
  '/verification-code',
  '/forgot-password',
  '/restore-password',
  '/expert-assist/onboarding/',
];
