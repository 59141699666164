const USER_PATHS = {
  user: 'user/',
  changePassword: 'user/password/change',
  postExpertAgreement: 'user/post_agreement',
  setExpertAvatar: 'user/files',
  fetchResume: 'user/files',
  updatePassword: 'user/',
  deleteUser: 'user/',
  updateTimeZone: 'user/timezone/',
  fetchTimeZoneList: 'user/timezone/',
};

export default USER_PATHS;
