import actionFn from 'expert/common/utils/actionFn';
import Feedback from 'expert/modules/expertFeedback/interfaces/feedback';
import ExpertFeedbackState from 'expert/redux/expertFeedback/interfaces/expertFeedbackState';
import INIT_EXPERT_FEEDBACK_STATE from 'expert/redux/expertFeedback/configs/initExpertFeedbackState';

export enum ExpertInfoActionsTypes {
  SetExpertFeedback = 'SetExpertFeedback',
  ResetExpertFeedback = 'ResetExpertFeedback',
  SetExpertFeedbackLoadingState = 'SetExpertFeedbackLoadingState',
  CloseExpertFeedback = 'CloseExpertFeedback',
}

export interface ResetExpertFeedbackAction {
  type: ExpertInfoActionsTypes.ResetExpertFeedback;
  payload: ExpertFeedbackState;
}

export const resetExpertFeedback = (): ResetExpertFeedbackAction => ({
  type: ExpertInfoActionsTypes.ResetExpertFeedback,
  payload: INIT_EXPERT_FEEDBACK_STATE,
});

export interface SetExpertFeedbackAction {
  type: ExpertInfoActionsTypes.SetExpertFeedback;
  payload: { feedback: Feedback | null; isFirst: boolean };
}

export const setExpertFeedback = actionFn<SetExpertFeedbackAction>(ExpertInfoActionsTypes.SetExpertFeedback);

export interface SetExpertFeedbackLoadingStateAction {
  type: ExpertInfoActionsTypes.SetExpertFeedbackLoadingState;
  payload: boolean;
}

export const setExpertFeedbackLoadingState = actionFn<SetExpertFeedbackLoadingStateAction>(
  ExpertInfoActionsTypes.SetExpertFeedbackLoadingState,
);

export interface CloseExpertFeedbackAction {
  type: ExpertInfoActionsTypes.CloseExpertFeedback;
}

export const closeExpertFeedback = () => ({
  type: ExpertInfoActionsTypes.CloseExpertFeedback,
});

export type ExpertFeedbackActions =
  | SetExpertFeedbackAction
  | CloseExpertFeedbackAction
  | ResetExpertFeedbackAction
  | SetExpertFeedbackLoadingStateAction;
