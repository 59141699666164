import ExpertSkillsState from 'expert/redux/expertSkills/interfaces/expertSkillsState';
import INIT_EXPERT_SKILLS from 'expert/redux/expertSkills/configs/initExpertSkills';
import { ExpertSkillsActions, ExpertSkillsActionTypes } from 'expert/redux/expertSkills/expertSkillsActions';

const expertSkillsReducer = (
  state: ExpertSkillsState = INIT_EXPERT_SKILLS,
  action: ExpertSkillsActions,
): ExpertSkillsState => {
  switch (action.type) {
    case ExpertSkillsActionTypes.ResetExpertSkills:
      return action.payload;
    case ExpertSkillsActionTypes.SetExpertSkills:
      return action.payload.value;
    default:
      return state;
  }
};

export default expertSkillsReducer;
