import { expertsActionTypes } from './Constants';

export function getExpertsRequest() {
  return {
    type: expertsActionTypes.GET_EXPERTS_REQUEST,
  };
}

export function getExpertsSuccess(data) {
  return {
    type: expertsActionTypes.GET_EXPERTS_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getExpertsError(error) {
  return {
    type: expertsActionTypes.GET_EXPERTS_ERROR,
    payload: {
      error,
    },
  };
}
