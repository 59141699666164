import KeyMirror from 'keymirror';

export const profileActionTypes = KeyMirror({
  SAVE_AVATAR_REQUEST: null,
  SAVE_AVATAR_SUCCESS: null,
  SAVE_AVATAR_ERROR: null,
});

export const fileTypes = [
  'image/apng',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
  'image/x-icon',
];
