import { signUpActionTypes } from './Constants';

export function signUpRequest(data) {
  return {
    type: signUpActionTypes.SIGN_UP_REQUEST,
    payload: {
      data,
    },
  };
}

export function signUpSuccess(data) {
  return {
    type: signUpActionTypes.SIGN_UP_SUCCESS,
    payload: {
      data,
    },
  };
}

export function signUpError(data) {
  return {
    type: signUpActionTypes.SIGN_UP_ERROR,
    payload: {
      data,
    },
  };
}

export function getDesiredRoleRequest(data) {
  return {
    type: signUpActionTypes.GET_DESIRED_ROLE_REQUEST,
    payload: {
      data,
    },
  };
}

export function getDesiredRoleSuccess(data) {
  return {
    type: signUpActionTypes.GET_DESIRED_ROLE_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getDesiredRoleError() {
  return {
    type: signUpActionTypes.GET_DESIRED_ROLE_ERROR,
  };
}

export function getDesiredCompanyRequest(data) {
  return {
    type: signUpActionTypes.GET_DESIRED_COMPANY_REQUEST,
    payload: {
      data,
    },
  };
}

export function getDesiredCompanySuccess(data) {
  return {
    type: signUpActionTypes.GET_DESIRED_COMPANY_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getDesiredCompanyError() {
  return {
    type: signUpActionTypes.GET_DESIRED_COMPANY_ERROR,
  };
}

export function setFeatureRequest(data) {
  return {
    type: signUpActionTypes.SET_FEATURE_REQUEST,
    payload: {
      data,
    },
  };
}

export function setFeatureSuccess(data) {
  return {
    type: signUpActionTypes.SET_FEATURE_SUCCESS,
    payload: {
      data,
    },
  };
}

export function setFeatureError() {
  return {
    type: signUpActionTypes.SET_FEATURE_ERROR,
  };
}
