import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    title: {
      marginBottom: theme.spacing(4),
    },
    date: {
      marginBottom: theme.spacing(3.5),
    },
    buttonWrap: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      color: '#fff',
      width: 257,
      height: 45,
    },
  }),
  {
    name: 'ExpertAgreement',
  },
);
