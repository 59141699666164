import NotificationsState from '../interfaces/notificationsState';

const INIT_NOTIFICATIONS_STATE: NotificationsState = {
  isLoading: false,
  items: [],
  totalCount: 0,
  unreadCount: 0,
};

export default INIT_NOTIFICATIONS_STATE;
