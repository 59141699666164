import NotificationTypes from 'expert/modules/notifications/enums/notificationTypes';
import NotificationSourceTypes from 'expert/sagas/enums/notificationSourceTypes';

const notificationTypeMap: Record<NotificationSourceTypes, NotificationTypes> = {
  [NotificationSourceTypes.CustomerLeftAFeedback]: NotificationTypes.Review,
  [NotificationSourceTypes.CustomerBookedTheMeeting]: NotificationTypes.Session,
  [NotificationSourceTypes.MeetingIn5Minutes]: NotificationTypes.Session,
  [NotificationSourceTypes.CustomerRescheduledTheMeeting]: NotificationTypes.Session,
  [NotificationSourceTypes.CustomerCanceledTheMeeting]: NotificationTypes.Session,
  [NotificationSourceTypes.GotAPayout]: NotificationTypes.Payout,
  [NotificationSourceTypes.HaveToSetAvailability]: NotificationTypes.Availability,
  [NotificationSourceTypes.ShouldLeaveAFeedbackForCustomer]: NotificationTypes.Feedback,
};

export default notificationTypeMap;
