import {
  setGoogleEvents,
  setGoogleEventsLoadingState,
  setGoogleLoginURL,
  setIsConnectedToGoogle,
} from 'expert/redux/googleEvents/googleEventsActions';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { processRequest } from 'services/Api';
import MESSAGES from 'expert/common/configs/messages';
import googlePaths from 'expert/sagas/google/googlePaths';
import { googleSagaActionTypes } from 'expert/sagas/google/googleSagasActions';
import FetchGoogleCalendarEventsProps from 'expert/sagas/interfaces/fetchGoogleCalendarEventsProps';
import shouldHandleError from 'expert/sagas/utils/shouldHandleError';

const handleGetGoogleLoginUrl = function* () {
  try {
    const { data } = yield call(processRequest, googlePaths.getGoogleLoginUrl, 'GET', {
      success_redirect_url: `${window.location.origin}/expert/calendar`,
    });

    yield put(setGoogleLoginURL(data.url));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.setRefreshToken);
  }
};

const handleFetchGoogleCalendarEvents = function* ({ payload }: { payload: FetchGoogleCalendarEventsProps }) {
  try {
    yield put(setGoogleEventsLoadingState(true));
    const { data } = yield call(
      processRequest,
      `${googlePaths.fetchGoogleCalendarEvents}?date_start=${payload.start}&date_end=${payload.end}`,
    );

    yield put(setGoogleEvents(data));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.fetchCalendarEvents);
  } finally {
    yield put(setGoogleEventsLoadingState(false));
  }
};

const handleCheckIsConnectedToGoogle = function* () {
  try {
    const { data } = yield call(processRequest, googlePaths.checkIsConnectedToGoogle);

    yield put(setIsConnectedToGoogle(data.connected_to_google));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.fetchCalendarEvents);
  } finally {
    yield put(setGoogleEventsLoadingState(false));
  }
};

const googleSagas = [
  takeLatest(googleSagaActionTypes.getGoogleLoginUrl, handleGetGoogleLoginUrl),
  takeLatest(googleSagaActionTypes.fetchGoogleCalendarEvents, handleFetchGoogleCalendarEvents),
  takeLatest(googleSagaActionTypes.checkIsConnectedToGoogle, handleCheckIsConnectedToGoogle),
];

export default googleSagas;
