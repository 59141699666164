import CustomerFeedbackState from './interfaces/customerFeedbackState';
import INIT_CUSTOMER_FEEDBACK_STATE from './configs/initCustomerFeedbackState';
import { CustomerFeedbackActions, CustomerInfoActionsTypes } from './customerFeedbackActions';

const customerFeedbackReducer = (
  state: CustomerFeedbackState = INIT_CUSTOMER_FEEDBACK_STATE,
  action: CustomerFeedbackActions,
): CustomerFeedbackState => {
  switch (action.type) {
    case CustomerInfoActionsTypes.ResetCustomerFeedback:
      return action.payload;
    case CustomerInfoActionsTypes.CloseCustomerFeedback:
      return {
        ...state,
        feedback: null,
        isLoading: false,
        isFirstPopup: false,
      };
    case CustomerInfoActionsTypes.SetCustomerFeedback:
      return {
        ...state,
        feedback: action.payload.feedback,
        isFirstPopup: action.payload.isFirst,
      };
    case CustomerInfoActionsTypes.SetCustomerFeedbackLoadingState:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default customerFeedbackReducer;
