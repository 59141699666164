import {
  AccountSettingsActions,
  AccountSettingsActionTypes,
} from 'expert/redux/accountSettings/accountSettingsActions';
import INIT_ACCOUNT_SETTINGS_STATE from 'expert/redux/accountSettings/configs/intAccountSettingsState';
import AccountSettingsState from 'expert/redux/accountSettings/interfaces/accountSettingsState';

const accountSettingsReducer = (
  state: AccountSettingsState = INIT_ACCOUNT_SETTINGS_STATE,
  action: AccountSettingsActions,
): AccountSettingsState => {
  switch (action.type) {
    case AccountSettingsActionTypes.ResetAccountSettings:
      return action.payload;
    case AccountSettingsActionTypes.SetPasswordError:
      return {
        ...state,
        passwordError: action.payload,
      };
    case AccountSettingsActionTypes.SetIsPasswordUpdating:
      return {
        ...state,
        isPasswordUpdating: action.payload,
      };
    case AccountSettingsActionTypes.SetIsUserDeleting:
      return {
        ...state,
        isUserDeleting: action.payload,
      };
    default:
      return state;
  }
};

export default accountSettingsReducer;
