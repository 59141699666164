import actionFn from 'expert/common/utils/actionFn';

export enum RescheduleAvailabilityActionTypes {
  SetRescheduleAvailabilityLoading = 'SetRescheduleAvailabilityLoading',
  SetRescheduleAvailability = 'SetRescheduleAvailability',
}

export type SetRescheduleAvailabilityLoadingAction = {
  type: RescheduleAvailabilityActionTypes.SetRescheduleAvailabilityLoading;
  payload: boolean;
};

export const setRescheduleAvailabilityLoading = actionFn<SetRescheduleAvailabilityLoadingAction>(
  RescheduleAvailabilityActionTypes.SetRescheduleAvailabilityLoading,
);

export type SetRescheduleAvailabilityAction = {
  type: RescheduleAvailabilityActionTypes.SetRescheduleAvailability;
  payload: boolean;
};

export const setRescheduleAvailability = actionFn<SetRescheduleAvailabilityAction>(
  RescheduleAvailabilityActionTypes.SetRescheduleAvailability,
);

export type RescheduleAvailabilityActions = SetRescheduleAvailabilityAction | SetRescheduleAvailabilityLoadingAction;
