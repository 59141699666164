const MESSAGES = {
  success: {
    setUpPaymentMethod: 'Payment method successfully set',
    updateUpPaymentMethod: 'Payment method successfully updated',
    updateProfileSettings: 'Profile successfully updated',
    updatePassword: 'Password successfully updated',
    deleteUser: 'User successfully deleted',
    updatePublishState: 'Review successfully published',
    updateArchiveState: 'Review successfully archived',
    addAvailability: 'Availability successfully added',
    editAvailability: 'Availability successfully edited',
    deleteAvailability: 'Availability successfully deleted',
    unPauseExpert: 'Successfully Reactivated Expert Status',
    pauseExpert: 'Successfully Disabled Expert Status',
    feedbackUpdate: 'Feedback was successfully added.',
    saveCustomerFeedback: 'Feedback was left successfully.',
    rescheduleSession: 'Success! Reschedule request was sent!',
    cancelSession: 'Success! Your session was canceled!',
  },
  info: {
    getCustomerFeedback: 'Feedback was left earlier.',
  },
  errors: {
    getExpertSkills: "Can't get expert skills! Please try again later.",
    getStripeSignUpLink: "Can't get stripe setup link! Please try again later.",
    checkIsAvailable: "Can't check reschedule availability! Please try again later.",
    fetchLastCustomerFeedback: "Can't get fetch last customer feedback! Please try again later.",
    refuseCustomerFeedback: "Can't refuse customer feedback! Please try again later.",
    getCustomerFeedback: "Can't get customer feedback! Please try again later.",
    saveCustomerFeedback: "Can't save customer feedback! Please try again later.",
    fetchResume: "Can't get resume! Please try again later.",
    setExpertProfileStep: "Can't set expert profile step! Please try again later.",
    fetchExpertInfo: "Can't get expert! Please try again later.",
    createPasswordAccepted: 'Please accept the Terms of Use and Privacy Policy',
    fetchExpert: "Can't get expert! Please try again later.",
    postExpertAgreement: "Can't post expert agreement! Please try again later.",
    saveUserProfile: "Can't update user profile! Please try again later.",
    updateProfileSettings: 'Something went wrong! Please try again later.',
    setAvailability: 'Something went wrong! Please try again later.',
    setUpPaymentMethod: 'Something went wrong! Please try again later.',
    updateUpPaymentMethod: 'Something went wrong! Please try again later.',
    getUpPaymentMethod: "Can't get payout methods! Please try again later.",
    fetchReviews: "Can't fetch reviews! Please try again later.",
    fetchReview: "Can't fetch review! Please try again later.",
    fetchWidgets: "Can't fetch widgets! Please try again later.",
    updatePublishState: "Can't publish review! Please try again later.",
    updateArchiveState: "Can't archive review! Please try again later.",
    updatePassword: "Can't update password! Please try again later.",
    deleteUser: "Can't delete user! Please try again later.",
    fetchNotifications: "Can't get notifications! Please try again later.",
    markAsRead: "Can't read notification! Please try again later.",
    updateTimeZone: "Can't update user timezone! Please try again later.",
    fetchTimeZone: "Can't get timezones! Please try again later.",
    fetchAvailabilities: "Can't get expert availability! Please try again later.",
    addAvailability: "Can't get expert availability! Please try again later.",
    editAvailability: "Can't edit expert availability! Please try again later.",
    deleteAvailability: "Can't delete expert availability! Please try again later.",
    checkIsAvailabilitySet: "Can't check if availability set! Please try again later.",
    saveEmptyAvailability: 'At least one availability range should be added',
    fetchPayouts: "Can't get payouts history! Please try again later.",
    fetchUpcomingSessions: "Can't get upcoming sessions! Please try again later.",
    fetchPastSessions: "Can't get past sessions! Please try again later.",
    tooBigFileSize: 'This file is too big! File size should be up to 5.0 MB',
    cancelSession: "Can't cancel session! Please try again later.",
    rescheduleSession: "Can't reschedule session! Please try again later.",
    pauseExpert: "Can't pause expert state! Please try again later.",
    unPauseExpert: "Can't unpause expert state! Please try again later.",
    fetchCalendarSessions: "Can't fetch sessions! Please try again later.",
    feedbackUpdate: "Can't left feedback! Please try again later.",
    refuseFeedback: "Can't refuse feedback! Please try again later.",
    lastFeedback: "Can't fetch last feedback! Please try again later.",
    fetchCalendarEvents: "Can't fetch calendar events! Please try again later.",
    setRefreshToken: "Can't connect to google! Please try again later.",
    removeRefreshToken: "Can't disconnect from google! Please try again later.",
    googleScriptLoad: "Can't get access to google! Please try again later.",
    googleAuth: "Can't auth via google! Please try again later.",
  },
};

export default MESSAGES;
