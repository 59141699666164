import React, { FC } from 'react';
import { Button, Dialog } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import useStyles from './TextDialog.styles';

import textDialogTheme from 'expert/common/components/textDialog/theme/textDialogTheme';
import Logo from 'expert/common/icons/Logo';
import noop from 'expert/common/utils/noop';

interface TextDialogProps {
  isOpened: boolean;
  onClose?: () => void;
}

const TextDialog: FC<TextDialogProps> = ({ children, onClose, isOpened }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={textDialogTheme}>
      <Dialog onClose={onClose || noop} open={isOpened}>
        <div className={classes.root}>
          <Logo className={classes.logo} />
          {children}
          {onClose && (
            <div className={classes.buttonWrap}>
              <Button type="submit" color="secondary" variant="contained" onClick={onClose} className={classes.button}>
                Accept
              </Button>
            </div>
          )}
        </div>
      </Dialog>
    </ThemeProvider>
  );
};

export default TextDialog;
