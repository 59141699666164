import AvailabilitiesState from '../interfaces/availabilitiesState';

const INIT_AVAILABILITIES_STATE: AvailabilitiesState = {
  isLoading: false,
  isAdding: false,
  isDeleting: false,
  isEditing: false,
  items: [],
};

export default INIT_AVAILABILITIES_STATE;
