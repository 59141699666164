import { generalActionTypes } from './Constants';

export function sendActionEventRequest(data) {
  return {
    type: generalActionTypes.SEND_ACTION_EVENT_REQUEST,
    payload: {
      data,
    },
  };
}

export function sendActionEventError(data) {
  return {
    type: generalActionTypes.SEND_ACTION_EVENT_ERROR,
    payload: {
      data,
    },
  };
}

export function checkDiscountRequest(data) {
  return {
    type: generalActionTypes.CHECK_DISCOUNT_REQUEST,
    payload: {
      data,
    },
  };
}

export function checkDiscountSuccess(data) {
  return {
    type: generalActionTypes.CHECK_DISCOUNT_SUCCESS,
    payload: {
      data,
    },
  };
}

export function checkDiscountError(data) {
  return {
    type: generalActionTypes.CHECK_DISCOUNT_ERROR,
    payload: {
      data,
    },
  };
}
