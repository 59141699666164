import DialogTypes from 'expert/redux/privacyTerms/enums/dialogTypes';
import { useDispatch, useSelector } from 'react-redux';
import { privacyTermsSelector } from 'expert/redux/privacyTerms/privacyTermsSelectors';
import { setPrivacyTermsClosed } from 'expert/redux/privacyTerms/privacyTermsActions';

interface UseTextDialogRes {
  isOpened: boolean;
  dialogType: DialogTypes;
  close: () => void;
}

const usePrivacyTermsDialog: () => UseTextDialogRes = () => {
  const dispatch = useDispatch();
  const { isOpened, dialogType } = useSelector(privacyTermsSelector);

  const close = () => {
    dispatch(setPrivacyTermsClosed());
  };

  return {
    isOpened,
    close,
    dialogType,
  };
};

export default usePrivacyTermsDialog;
