import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MenuItem, MenuList, ClickAwayListener, Paper, Grow, Popper } from '@material-ui/core';
import { primary } from '../../styles/colors';
import { isAuthenticated } from '../../services/AccessToken';
import { heading_no_line_height_18_400 } from '../../styles/fonts';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  profileBtn: {
    backgroundColor: 'transparent',
    color: ({ isCareerMapperTab }) => (isCareerMapperTab ? primary.milk : primary.coral),
    textTransform: 'capitalize',
    boxShadow: 'none',
    ...heading_no_line_height_18_400,
  },
  profileBox: {
    width: '200px',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 0 12px rgba(0, 0, 0, 0.13)',
    background: '#D1E0F0',
  },
  profileBtnLogOut: {
    marginTop: 20,
    marginBottom: 20,
    boxShadow: 'none',
    color: '#085DDD',
    borderColor: '#085DDD',
  },
  popper: {
    top: '20px !important',
    left: '-32px !important',
  },
  menuItem: {
    color: primary.coral,
  },
}));

const PopoverProfile = ({ buttonTitle, isCareerMapperTab, popperItems }) => {
  const location = useLocation();
  const isLoggedIn = isAuthenticated();
  const classes = useStyles({ isCareerMapperTab });
  const anchorEl = useRef();
  const [open, setOpen] = useState(false);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorEl.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      setOpen(false);
    }
  };

  return (
    <div>
      <Button
        onClick={handleToggle}
        endIcon={<ExpandMoreIcon />}
        className={`${classes.profileBtn}`}
        id="profileBtn"
        ref={anchorEl}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true">
        {buttonTitle}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl.current}
        className={classes.popper}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={(e) => handleClose(e)}>
                <MenuList autoFocusItem={Boolean(open)} id="menu-list-grow" onKeyDown={(e) => handleListKeyDown(e)}>
                  {popperItems.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        item.handler();
                        handleClose();
                      }}
                      className={classNames({
                        [classes.menuItem]:
                          open && isLoggedIn && ['/home', '/about', '/profile'].includes(location.pathname),
                      })}>
                      {item.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default PopoverProfile;
