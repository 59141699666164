import INIT_AVAILABILITIES_STATE from './configs/initAvailabilitiesState';
import { AvailabilitiesActions, AvailabilitiesActionsTypes } from './availabilitiesActions';
import AvailabilitiesState from './interfaces/availabilitiesState';

const availabilitiesReducer = (
  state: AvailabilitiesState = INIT_AVAILABILITIES_STATE,
  action: AvailabilitiesActions,
): AvailabilitiesState => {
  switch (action.type) {
    case AvailabilitiesActionsTypes.ResetAvailabilities:
      return action.payload;
    case AvailabilitiesActionsTypes.SetAvailabilities:
      return {
        ...state,
        items: action.payload,
      };
    case AvailabilitiesActionsTypes.SetAvailabilitiesLoadingState:
      return {
        ...state,
        isLoading: action.payload,
      };
    case AvailabilitiesActionsTypes.SetAvailabilitiesAddingState:
      return {
        ...state,
        isAdding: action.payload,
      };
    case AvailabilitiesActionsTypes.SetAvailabilitiesEditingState:
      return {
        ...state,
        isEditing: action.payload,
      };
    case AvailabilitiesActionsTypes.SetAvailabilitiesDeletingState:
      return {
        ...state,
        isDeleting: action.payload,
      };
    case AvailabilitiesActionsTypes.SetAvailabilitiesAndStopLoading:
      return {
        ...state,
        items: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default availabilitiesReducer;
