export const heading48_56_700 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '48px',
  fontWeight: 700,
  lineHeight: '56px',
  letterSpacing: '0',
};

export const heading48_56_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '48px',
  fontWeight: 500,
  lineHeight: '56px',
  letterSpacing: '0',
};
export const heading48_56_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '48px',
  fontWeight: 400,
  lineHeight: '56px',
  letterSpacing: '0',
};

export const heading48 = {
  fontFamily: 'Mulish',
  fontSize: '48px',
  fontWeight: 400,
  lineHeight: '56px',
  letterSpacing: '0',
};

export const heading38_45_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '38px',
  fontWeight: 500,
  lineHeight: '45px',
  letterSpacing: '0',
};

export const heading36_80_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '36px',
  fontWeight: 500,
  lineHeight: '80px',
  letterSpacing: '0',
};

export const heading36_48_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '36px',
  fontWeight: 400,
  lineHeight: '48px',
  letterSpacing: '0',
};

export const heading24_36_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '36px',
  letterSpacing: '0',
};

export const heading36_42_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '36px',
  fontWeight: 500,
  lineHeight: '42px',
  letterSpacing: '0',
};

export const heading36_45_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '36px',
  fontWeight: 500,
  lineHeight: '45px',
  letterSpacing: '0',
};

export const heading36_28_400_noe = {
  fontFamily: 'Mulish-Regular',
  fontSize: '36px',
  fontWeight: 400,
  lineHeight: '28px',
  letterSpacing: '0',
};

export const heading24_24_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0',
};

export const heading24_40_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '40px',
  letterSpacing: '0',
};

export const heading24_32_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '32px',
  letterSpacing: '0',
};

export const heading24_30_700 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '30px',
  letterSpacing: '0',
};

export const heading24_30_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '30px',
  letterSpacing: '0',
};

export const heading24_30_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '30px',
  letterSpacing: '0',
};

export const heading28_36_400_noe = {
  fontFamily: 'Mulish-Regular',
  fontSize: '28px',
  fontWeight: 400,
  lineHeight: '36px',
  letterSpacing: '-1',
};

export const heading18_20_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0',
};

export const heading18_23_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '23px',
  letterSpacing: '0',
};

export const heading24_28_700 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '28px',
  letterSpacing: '0',
};

export const heading24_28_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '28px',
  letterSpacing: '0',
};

export const heading20_30_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '30px',
  letterSpacing: '0',
};

export const heading20_30_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '30px',
  letterSpacing: '0',
};

export const heading64_40_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '64px',
  fontWeight: 500,
  lineHeight: '40px',
  letterSpacing: '0',
};

export const heading64_75_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '64px',
  fontWeight: 500,
  lineHeight: '75.5px',
  letterSpacing: '0',
};

export const heading24_28_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '28px',
  letterSpacing: '0',
};

export const heading36_28_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '36px',
  fontWeight: 400,
  lineHeight: '28px',
  letterSpacing: '0',
};

export const heading36_80_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '36px',
  fontWeight: 400,
  lineHeight: '80px',
  letterSpacing: '0',
};

export const heading48_80_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '48px',
  fontWeight: 400,
  lineHeight: '80px',
  letterSpacing: '0',
};

export const heading114_80_700 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '114px',
  fontWeight: 700,
  lineHeight: '80px',
  letterSpacing: '0',
};

export const heading14_20_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0',
};

export const heading16_19_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '19px',
  letterSpacing: '-0.3px',
};
export const heading18_32_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '32px',
  letterSpacing: '0',
};
export const heading18_24_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '-0.3px',
};
export const heading16_22_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',
  letterSpacing: '-0.3px',
};
export const heading32_32_500 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '32px',
  fontWeight: 500,
  lineHeight: '32px',
  letterSpacing: '0',
};
export const heading10_12_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: '12px',
};
export const heading14_28_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '28px',
};
export const heading18_28_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '28px',
};
export const heading36_90_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '36px',
  fontWeight: 500,
  lineHeight: '90px',
  letterSpacing: '0',
};
export const heading36_50_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '36px',
  fontWeight: 500,
  lineHeight: '50px',
  letterSpacing: '0',
};
export const heading18_30_400_noe = {
  fontFamily: 'Mulish-Regular',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '30px',
  letterSpacing: '0',
};

export const heading18_21_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '21px',
  letterSpacing: '0',
};

export const heading18_21_500 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '21px',
  letterSpacing: '0',
};

export const heading18_23_400_noe = {
  fontFamily: 'Mulish-Regular',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '23px',
  letterSpacing: '0',
};

export const heading36_56_400_noe = {
  fontFamily: 'Mulish-Regular',
  fontSize: '36px',
  fontWeight: 400,
  lineHeight: '56px',
  letterSpacing: '0',
};

export const heading18_28_400_noe = {
  fontFamily: 'Mulish-Regular',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '28px',
  letterSpacing: '0',
};

export const heading16_30_700 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '30px',
  letterSpacing: '0',
};

export const heading16_30_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '30px',
  letterSpacing: '0',
};
export const heading16_18_400_noe = {
  fontFamily: 'Mulish-Regular',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '18px',
  letterSpacing: '0',
};

export const heading16_21_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '21px',
  letterSpacing: '0',
};

export const heading22_34_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '22px',
  fontWeight: 400,
  lineHeight: '34px',
  letterSpacing: '0',
};

export const heading14_16_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0',
};

export const heading_48_57_500 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '48px',
  fontWeight: 500,
  lineHeight: '57px',
  letterSpacing: '1',
};

export const heading56_64_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '56px',
  fontWeight: 400,
  lineHeight: '64px',
  letterSpacing: '0',
};

export const heading28_30_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '28px',
  fontWeight: 500,
  lineHeight: '30px',
  letterSpacing: '0',
};

export const heading18_30_500 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '30px',
  letterSpacing: '0',
};

export const heading18_30_600 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '30px',
  letterSpacing: '0',
};

export const heading32_38_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '32px',
  fontWeight: 500,
  lineHeight: '38px',
  letterSpacing: '0',
};

export const heading18_22_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '22px',
  letterSpacing: '0',
};

export const heading16_24_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0',
};

export const heading16_21_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '21px',
  letterSpacing: '0',
};

export const heading16_18_700 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '18px',
  letterSpacing: '0',
};

export const heading16_18_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '18px',
  letterSpacing: '0',
};

export const heading14_17_700 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '17px',
  letterSpacing: '0',
};

export const heading14_30_700 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '30px',
  letterSpacing: '0',
};

export const heading14_30_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '30px',
  letterSpacing: '0',
};

export const heading18_24_500 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '-0.3',
};

export const heading14_17_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '17px',
  letterSpacing: '0',
};

export const heading14_30_400_noe = {
  fontFamily: 'Mulish-Regular',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '30px',
  letterSpacing: '0',
};

export const heading16_20_400_noe = {
  fontFamily: 'Mulish-Regular',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0',
};

export const heading16_22_400_noe = {
  fontFamily: 'Mulish-Regular',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',
  letterSpacing: '0',
};

export const heading14_19_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '19px',
  letterSpacing: '0',
};

export const heading14_24_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0',
};

export const heading14_19_700 = {
  fontFamily: 'Mulish-Bold',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '17px',
  letterSpacing: '0',
};

export const heading14_17_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  letterSpacing: '0',
};

export const heading13_22_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '22px',
  letterSpacing: '0',
};

export const heading16_20_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0',
};

export const heading13_18_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '18px',
  letterSpacing: '0',
};

export const heading24_32_400_noe = {
  fontFamily: 'Mulish-Regular',
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '32px',
  letterSpacing: '0',
};

export const heading12_24_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0',
};

export const heading12_16_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0',
};

export const heading12_14_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '14px',
  letterSpacing: '0',
};

export const heading12_15_500 = {
  fontFamily: 'Mulish-Medium',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '15px',
  letterSpacing: '0',
};

export const heading12_15_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '15px',
  letterSpacing: '0',
};

export const heading_no_line_height_18_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '18px',
  fontWeight: 400,
  letterSpacing: '0',
};

export const heading_no_line_height_14_400 = {
  fontFamily: 'Mulish-Regular',
  fontSize: '14px',
  fontWeight: 400,
  letterSpacing: '0',
};
