const createTypography = () => ({
  h1: {
    fontSize: 48,
    lineHeight: '56px',
    fontFamily: 'Mulish-Medium',
    fontWeight: 500,
  },
  h2: {
    fontSize: 36,
    fontWeight: 400,
    lineHeight: '42px',
    fontFamily: 'Mulish-Medium',
  },
  h3: {
    fontSize: 36,
    fontWeight: 400,
    lineHeight: '30px',
    fontFamily: 'NoeDisplay-Regular',
  },
  h4: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '28px',
    fontFamily: 'Mulish-Medium',
  },
  h5: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '21px',
    fontFamily: 'Mulish-Medium',
  },
  h6: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '21px',
    fontFamily: 'Mulish-Medium',
    letterSpacing: 'normal',
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '21px',
    fontFamily: 'Mulish-Medium',
    letterSpacing: 'normal',
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '16px',
    fontFamily: 'Mulish-Medium',
    letterSpacing: 'normal',
  },
  body1: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',
    fontFamily: 'Mulish-Regular',
    letterSpacing: 'normal',
  },
  body2: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '21px',
    fontFamily: 'Mulish-Regular',
    letterSpacing: 'normal',
  },
  caption: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '14px',
    fontFamily: 'Mulish-Regular',
    letterSpacing: 'normal',
  },
  button: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '16px',
    fontFamily: 'Mulish-Medium',
    letterSpacing: 'normal',
  },
  overline: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '21px',
    fontFamily: 'Mulish-Regular',
    letterSpacing: 'normal',
  },
});

export default createTypography();
