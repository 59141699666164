export const COOKIES = {
  accessToken: 'token',
  pin: 'pin',
  tempEm: 'temp_e',
  tempP: 'temp_p',
  temporaryToken: 'temporaryToken',
  renewTime: 'renewTime',
  loggedIn: 'loggedIn',
  coupon: 'coupon',
  deviceToken: 'deviceToken',
  tariff: 'tariff',
  tariff_id: 'tariff_id',
  price: 'price',
  agreement: 'agreement',
  showFeedbackAfterLogout: 'showFeedbackAfterLogout',
};

export const LOCAL_STORAGE = {
  showCongratulations: 'showCongratulations',
  featureName: 'featureName',
  email: 'email',
};

export const ACCESS_TOKEN_EXPIRATION_DAYS = 365;

export const MATCHES = {
  top: 'top',
  good: 'good',
  low: 'low',
};

export const MATCH_TYPES = {
  top: {
    type: MATCHES.top,
    label: 'Top match',
  },
  good: {
    type: MATCHES.good,
    label: 'Good match',
  },
  low: {
    type: MATCHES.low,
    label: 'Low match',
  },
};

export const PAYMENT_PACKAGES_RATING_NUM = {
  BASIC: 0,
  PREMIUM: 1,
};

export const PRODUCT_CONSTANTS_NAME_FOR_API = {
  PMAPPER: 'pmapper',
  INTWIZARD: 'intwizard',
  EXPERTS: 'experts',
};

export const FILTER_ITEMS = {
  topMatch: {
    title: 'Top Match',
    field: 'match_type',
    type: 'top',
    selected: false,
  },
  goodMatch: {
    title: 'Good Match',
    field: 'match_type',
    type: 'good',
    selected: false,
  },
  lowMatch: {
    title: 'Low Match',
    field: 'match_type',
    type: 'low',
    selected: false,
  },
  favorite: {
    title: 'Favorite',
    field: 'is_favorite',
    selected: false,
  },
};

export const QUESTIONS_TYPES = {
  INTEGER: 'integer',
  BOOLEAN: 'bool',
  LOCATION_AUTOCOMPLETE: 'location_autocomplete',
  MULTI_CHOICE: 'multi_choice',
  STRING_3: 'string_3',
  STRING_5: 'string_5',
  MULTILINE_STRING_3: 'multiline_string_3',
  MULTILINE_LOCATION_AUTOCOMPLETE: 'multiline_location_autocomplete',
  MULTI_AUTOCOMPLETE_5: 'multi_autocomplete_5',
  FILE_DOC: 'file_doc',
  LINKEDIN_URL: 'linkedin_url',
};

export const ANSWERS_TYPES = {
  SINGLE_CHOICE_CUSTOM: 'single_choice_custom',
  SINGLE_CHOICE: 'single_choice',
  MULTI_CHOICE_CUSTOM: 'multi_choice_custom',
  MULTI_CHOICE: 'multi_choice',
  LOCATION_AUTOCOMPLETE: 'location_autocomplete',
  INTEGER_RANGE_5: 'integer_range_5',
  INTEGER_RANGE_5_CUSTOM: 'integer_range_5_custom',
};

export const EXCLUDED_PATHS_FOR_MODAL = ['/privacy-policy', '/user-agreement', '/expert-agreement', '/terms-of-use'];

export const phoneRegExp = /1?\W*([0-9][0-9][0-9])\W*([0-9][0-9]{2})\W*([0-9]{4})(\se?x?t?(\d*))?/;

export const linkedinRegExp =
  /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;

export const headers = {
  home: {
    title: {
      part1: 'Align work with',
      part2: 'self.',
    },
    description: ['Pave helps you find what you love to do ', 'and succeed in it.'],
  },
  pathMapper: {
    title: {
      part1: 'Career ',
      part2: 'Mapper',
    },
    description: [
      'Celebrate your quirks, leverage your skills, lean into ',
      'your experiences to identify the strongest matching ',
      'career paths & job families, customized based on ',
      'your goals.',
    ],
  },
  expertsOnDemand: {
    title: {
      part1: 'Expert',
      part2: 'Assist',
    },
    description: [
      'Tap into a network thats here for you. Chat with ',
      'trusted experts, knowledgeable in roles you’re ',
      'interested in. Discuss daily life for specific paths, ',
      'practice interviews, or have expert on speed dial.',
    ],
  },
  interviewWizard: {
    title: {
      part1: 'Interview ',
      part2: 'Boost',
    },
    description: [
      'Remove the stress & hassle of research and interview ',
      'prep. We’ll  have you dazzling during the interview ',
      'with company specific, role specific cheat sheets that ',
      'serve up exactly what you need to know and expect.',
    ],
  },
  signUp: {
    title: {
      part1: 'Align work ',
      part2: 'with',
      last: ' self.',
    },
    description: ['Pave helps you find what you love to do ', 'and succeed in it.'],
  },
  becomeAnExpert: {
    title: {
      part1: 'Share your Expertise',
      part2: '',
    },
    description: ['We believe that we can go farther together.'],
  },
};

export const ourProducts = [
  {
    topIcon: 'icon-numericKeypad',
    title: 'Career mapper',
    description:
      "Based data from a variety of roles, we'll give you step-by-step actions on how to successfully transition into a new career and to increase your job fit. We partner and guide you through self-analysis & personalized planning.",
    bottomIcon: '',
    path: '/landing/career-mapper',
  },
  // {
  //   topIcon: 'icon-bookReading',
  //   title: 'Expert assist',
  //   description:
  //     'Access to experts for support as you explore and transition into your desired career path. Gain confidence and knowledge through 1:1 interview prep and insider tips to feel prepared from people who have the inside scoop.',
  //   bottomIcon: '',
  //   path: '/landing/experts-assist',
  // },
  // {
  //   topIcon: 'icon-info',
  //   title: 'Interview boost',
  //   description:
  //     'Remove the stress & hassle of interview research and prep with company and role specific cheat sheets that tell you what you need to know and expect to help you dazzle in your interviews.',
  //   bottomIcon: '',
  //   path: '/landing/interview-boost',
  // },
];

export const LOGIN_MENU_ITEMS = [
  {
    text: 'home',
    isDisabled: false,
    path: '/home',
    allowPathes: ['/', '/home'],
  },
  {
    text: 'about',
    isDisabled: false,
    path: '/about',
    allowPathes: ['/about'],
  },
  // {
  //     text: 'activity',
  //     isDisabled: false,
  //     path: '/activity',
  //     allowPathes: ['/activity']
  // },
  {
    text: 'career mapper',
    isDisabled: false,
    path: '/career-mapper/overview',
    allowPathes: [
      '/career-mapper',
      '/career-mapper/:id/job-family-overview',
      '/career-mapper/:id/about-your-fit',
      '/career-mapper/:id/live-job-posting',
      '/career-mapper/:id/strengthen-courses/:title',
      '/career-mapper/list',
      '/onboarding-progress',
      '/career-mapper/overview',
      '/career-mapper/path-matches',
    ],
  },
  // {
  //   text: 'expert assist',
  //   isDisabled: false,
  //   path: '/expert-assist/overview',
  //   allowPathes: ['/expert-assist/overview'],
  // },
  // {
  //   text: 'interview boost',
  //   isDisabled: false,
  //   path: '/interview-boost/overview',
  //   allowPathes: ['/interview-boost/overview'],
  // },
  {
    text: 'Become an Expert',
    isDisabled: false,
    path: '/become-an-expert',
    allowPathes: ['/become-an-expert'],
  },
];

export const PROFILE_HEADER_ITEMS = [
  {
    text: 'My Activity',
    path: '/activity',
    allowPathes: ['/activity'],
  },
  {
    text: 'My Profile',
    path: '/profile',
    allowPathes: ['/profile'],
  },
];

export const TWO_BLOCKS_INFO = {
  congratulations: {
    title: 'Congratulations, ',
    subtitle: 'Your results are in!',
    description: 'Click below to see your career path and job family matches!',
    firstBtn: 'View paths',
  },
  home: {
    title: 'Master the future of work with our suite of products. ',
    subtitle: '',
    // description: 'With Career Mapper and Expert Assist you can cover all the bases for the future. ',
    description: 'With Career Mapper you can cover all the bases for the future. ',
    firstBtn: 'Sign up',
    isButtonVisible: true,
  },
  cmLanding: {
    title: 'Career Mapper',
    subtitle: '',
    description:
      'Celebrate your quirks, leverage your skills, lean into your experiences to identify the strongest matching career paths & job families, customized based on your goals.',
    firstBtn: 'Get started',
  },
  ibLanding: {
    title: 'Interview Boost',
    subtitle: '',
    description:
      'Remove the stress & hassle of research and interview prep. We’ll  have you dazzling during the interview with company specific, role specific cheat sheets that serve up exactly what you need to know and expect.',
    firstBtn: 'Get started',
  },
  eaLanding: {
    title: 'Expert Assist',
    subtitle: '',
    description:
      'Tap into a network thats here for you. Chat with trusted experts, knowledgeable in roles you’re interested in. Discuss daily life for specific paths, practice interviews, or have expert on speed dial.',
    firstBtn: 'Get started',
  },
};

export const BTN_TYPES = {
  btnWithOutBorderWithArrow: 'btnWithOutBorderWithArrow',
  btnWithArrow: 'btnWithArrow',
  btn: 'btn',
  outLinearBtn: 'outLinearBtn',
};

export const PROGRESS_ONBOARDING_TEXT = {
  title: 'How it works',
  description:
    'Through our patent pending AI / ML model, we will seek to understand you holistically, across your goals, interests, skills, work style and values, to analyze your data against thousands of trending jobs and possible career paths to serve up the best aligned ones.  (Like dating but for career paths)',
};

export const VERIFY_CODE_LENGTH = 6;

export const PROGRESS_BAR_PERCENT = 100;

export const CLICK_MODAL_WINDOW_WIDTH = 425;

export const HOVER_MODAL_WINDOW_WIDTH = 260;

export const MIN_PERCENT_FOR_DEVIDE_YOUR_HARD_SKILLS = 70;

export const FEEDBACKS_TYPE = {
  general: 'general',
  careerMapper: 'pmapper',
};

export const PRIVACY_TERMS_AGREEMENT = {
  agreement: {
    isAgreement: true,
    title: 'User agreement',
    date: 'November 1, 2021',
    description: `
            <div>
                <span class="reqular__text"><span class="bold">
                    PLEASE READ THIS USER AGREEMENT AND ALL OTHER DOCUMENTS
                    REFERENCED HEREIN AS THE "TERMS OF SERVICE" CAREFULLY AS
                    THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL
                    RIGHTS, REMEDIES, AND OBLIGATIONS. THESE INCLUDE VARIOUS
                    LIMITATIONS AND EXCLUSIONS AND A BINDING ARBITRATION
                    AGREEMENT AND CLASS ACTION WAIVER.
                </span> </span>
                <br />
                <br />
                <span class="reqular__text">This User Agreement (this “<span class="bold">Agreement</span>”)
                is a contract between you (“<span class="bold">you</span>”) and
                Arable Ventures LLC doing business as Pave (“
                <span class="bold">Pave,</span>” “<span class="bold">we,</span>”
                or “<span class="bold">us</span>”). You must read, agree to, and
                accept all of the terms and conditions contained in this
                Agreement to use any of our password-protected features of our
                website located at www.joinpave.com (the “
                <span class="bold">Site</span>”), our mobile application, or the
                Services (defined in the{' '}
                <a class="link" href="/terms-of-use">
                    Site Terms of Use
                </a>
                ). </span>
                <br />
                <br />
                <span class="reqular__text">This Agreement includes and hereby incorporates by reference
                the <a class="link" href="/terms-of-use">
                    Site Terms of Use
                </a> and <a class="link" href="/terms-of-use">
                    Privacy Policy
                </a>, as they may be in
                effect and modified from time to time. These documents are
                collectively, with this Agreement, called the “<span class="bold">Terms of
                Service</span>”. </span>
                <br />
                <br />
                <span class="reqular__text">If you are an expert or a provider utilizing the Site or
                Services to provide services to individuals (each a “<span class="bold">Provider</span>”),
                your use of the Site and Services is subject to this Agreement
                and the Provider Agreement. Users that use the Site or Services
                to seek services from Providers are referred to as a “<span class="bold">Client.</span>”
                Any individual that uses the Site or Services, including you,
                any Client, any other user, and any Provider, is referred to as
                a “<span class="bold">User</span>”. </span>
                <br />
                <br />
                <span class="reqular__text">Subject to the conditions set forth herein, Pave may, in its
                sole discretion, amend this Agreement and any of the other
                agreements that comprise the Terms of Service at any time by
                posting a revised version on the Site. Pave will provide
                reasonable advance notice of any change to the Terms of Service
                that reduces your rights or increases your responsibilities, by
                posting the updated Terms of Service on the Site or Services,
                providing notice on the Site or Services, and/or sending you
                notice by email. Any revisions to the Terms of Service will take
                effect on the noted effective date (each, as applicable, the
                “<span class="bold">Effective Date</span>”). </span>
                <br />
                <br />
                <span class="reqular__text">YOU UNDERSTAND THAT BY CLICKING “I ACCEPT”, YOU AGREE TO BE
                BOUND BY THE TERMS OF SERVICE, INCLUDING THE ARBITRATION
                AGREEMENT IN SECTION 13 OF THIS AGREEMENT (SUBJECT TO YOUR RIGHT
                TO OPT OUT OF THE ARBITRATION AGREEMENT AS PROVIDED IN SECTION
                12). IF YOU DO NOT ACCEPT THE TERMS OF SERVICE IN ITS ENTIRETY,
                YOU MUST NOT ACCESS OR USE THE SITE OR THE SERVICES AFTER THE
                EFFECTIVE DATE EXCEPT AS PERMITTED BY THE SITE TERMS OF USE. </span>
                <br />
                <br />
                <span class="reqular__text">IF YOU AGREE TO THE TERMS OF SERVICE ON BEHALF OF AN ENTITY OR
                AGENCY, OR IN CONNECTION WITH PROVIDING OR RECEIVING SERVICES ON
                BEHALF OF AN ENTITY OR AGENCY, YOU REPRESENT AND WARRANT THAT
                YOU HAVE THE AUTHORITY TO BIND THAT ENTITY OR AGENCY TO THE
                TERMS OF SERVICE AND AGREE THAT YOU ARE BINDING BOTH YOU AND
                THAT ENTITY OR AGENCY TO THE TERMS OF SERVICE. IN THAT EVENT,
                “YOU” AND “YOUR” WILL REFER AND APPLY TO YOU AND THAT ENTITY OR
                AGENCY. </span>
                <br />
                <br />
                <span class="reqular__text">You hereby represent that you are either a legal entity or an
                individual who is 18 years or older (or have otherwise reached
                the age of majority in the jurisdiction in which you conduct
                business) in each case who can form legally binding contracts. </span>
                <br />
                <br />
                <span class="bold">1. PAVE ACCOUNTS AND PASSWORDS</span>
                <br />
                <br />
                <span class="reqular__text">To access and use certain portions of the Site and the Services, Users must register for an account (an “Account”). Subject to the Site Terms of Use, certain portions of the Site are available to Site visitors, including those portions before your Account registration is accepted. Pave reserves the right to decline a registration to join Pave or to create an Account. To register for an Account to join the Site or Services, each User must complete a User profile (each, a “Profile”), which you consent to be shown to other Users and, unless you change your privacy settings, to be shown to the public. You agree to provide true, accurate, and complete information on your Profile and all registration and other forms you access on the Site and Services or provide to us and to update your information to maintain its truthfulness, accuracy, and completeness. You agree not to provide any false or misleading information about your identity or location, your business, your skills, or the services your business provides and to correct any such information that is or becomes false or misleading. </span>
                <br />
                <br />
                <span class="reqular__text">If you create an Account as an employee or agent on behalf of a company, you represent and warrant that you are authorized to enter into binding contracts, including the Terms of Service, on behalf of yourself and the company. </span>
                <br />
                <br />
                <span class="reqular__text">Pave offers accounts for Providers (“Provider Accounts”) and for Clients (“Client Accounts”). Each of the Provider Accounts and Client Accounts is referred to as an “Account.” Once you register for one Account type, you can add other Account types. For example, if you already have a Provider Account, you can add a Client Account as a separate account type. You agree not to have or register for more than one Account without express written permission from us. We reserve the right to revoke the privileges of the Account or access to or use of the Site or Services, and those of any and all linked Accounts without warning if, in our sole discretion, false or misleading information has been provided in creating, marketing, or maintaining your Profile or Account. You agree not to request or allow another person to create an Account on your behalf, for your use, or for your benefit. Each person that uses the Site and Services must register for their own Account. </span>
                <br />
                <br />
                <span class="reqular__text">When you register for an Account and from time to time thereafter, your Account may be subject to verification, including, but not limited to, validation against third-party databases or the verification of one or more official government or legal documents that confirm, as applicable and without limitation, your identity, your location, and your ability to act on behalf of your business on Pave. You authorize Pave, directly or through third parties, to make any inquiries necessary to validate your identity, your location, and confirm your ownership of your email address or financial accounts, subject to applicable law. When requested, you must timely provide us with information we request.  </span>
                <br />
                <br />
                <span class="reqular__text">When you register for an Account, you will be asked to choose a username and password for the Account. You are entirely responsible for safeguarding and maintaining the confidentiality of your username and password. You agree not to share your username or password with any person, and, if you are a legal entity who is not a natural person, to only share your username and password with a person who is authorized to use your Account. You authorize Pave to assume that any person using the Site or Services with your username and password, either is you or is authorized to act for you. You agree to notify us immediately if you suspect or become aware of any unauthorized use of your Account or any unauthorized access to the password for any Account. You further agree not to use the Account or log in with the username and password of another User of the Site if (a) you are not authorized to use both or (b) the use would violate the Terms of Service. </span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">2. RELATIONSHIP WITH PAVE </span> </span>
                <br />
                <br />
                <span class="reqular__text">The Site is a marketplace for services where Clients and Providers can identify each other and advertise, buy, and sell services online. Pave uses the Site, digital technology, marketing, and networking knowledge to connect individuals seeking career and job advice (Clients) to providers of expert and coaching services (Providers). Subject to the Terms of Service, Pave provides the Services to Users, including hosting and maintaining the Site, facilitating the formation of Service Contracts (as defined below), and assisting Users in resolving disputes which may arise in connection with those Service Contracts. When a User enters a Service Contract, the User uses the Site to invoice and pay any amounts owed under the Service Contract. </span>
                <br />
                <br />
                <span class="reqular__text">Pave makes the Site and Services available to enable Providers and Clients to find and engage with each other through the Site and Services. When a Provider and a User interact with each other through our Site and Services, they have an “Engagement”. Pave also offers other services on the Site and Services for Users related to career development and interviewing. Pave does not introduce Providers to Clients, select Engagements for Providers, or select Providers for Clients. Through the Site and Services, Providers may be notified of Clients that may be seeking the services they offer, and Clients may be notified of Providers that may offer the services they seek.  </span>
                <br />
                <br />
                <span class="reqular__text">Without limitation, Users are solely responsible for (a) determining the suitability of other Users for a Service Contract (such as any interviews, vetting, background checks, or similar actions); (b) negotiating, agreeing to, and executing any terms or conditions of Service Contracts; (c) performing Provider Services; and/or (d) paying for Provider Services. Pave does not make any representations about or guarantee the truth or accuracy of any Provider’s or Client’s listings or other User Content (as defined below) on the Site and Services; does not verify any feedback or information provided by Users about Providers or Clients; and does not perform background checks on or guarantee the work of Providers or Clients. You acknowledge, agree, and understand that Pave does not, in any way, supervise, direct, control, or evaluate Providers or their work and is not responsible for any Engagement or work product.  </span>
                <br />
                <br />
                <span class="reqular__text">Pave makes no representations about and does not guarantee, and you agree not to hold Pave responsible for, the quality, safety, or legality of the services provided by the Provider (“Provider Services”); the qualifications, background, or identities of Users; the ability of Providers to deliver Provider Services; the ability of Clients to pay for Provider Services; or any content and statements or posts made by Users (“Content”). While Pave may provide certain ratings on Provider or Client Profiles, such ratings are not a guarantee or warranty of the quality or ability or willingness of the Provider or Client to complete a Service Contract and is not a guarantee of any kind, including, the quality of Provider Services or Client Engagement. </span>
                <br />
                <br />
                <span class="reqular__text">You also acknowledge, agree, and understand that Providers are solely responsible for determining, and have the sole right to determine, which Engagements to accept; the time, place, manner, and means of providing any Provider Services; the type of services they provide; and the price they charge for their services or how that pricing is determined or set. </span>
                <br />
                <br />
                <span class="reqular__text">You further acknowledge, agree, and understand that: (i) you are not an employee of Pave, and you are not eligible for any of the rights or benefits of employment (including unemployment and/or workers compensation insurance); (ii) Pave will not have any liability or obligations, including under or related to Service Contracts and/or Provider Services for any acts or omissions by you or other Users; and (iii) Pave does not, in any way, supervise, direct, or control any Provider or Provider Services; and does not impose quality standards or a deadline for completion of any Provider Services. Pave does not provide Providers with training or any equipment, labor, tools, or materials related to any Service Contract. Pave does not provide the premises at which Providers will perform the work; and Pave does not provide shipping services for any physical Work Product. </span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">Service Contracts </span></span>
                <br />
                <br />
                <span class="reqular__text">If Client and a Provider decide to enter into an Engagement, the relationship is between the Client and the Provider. The Client and the Provider have complete discretion in determining whether to enter into an Engagement. With respect to the Engagement, Clients and Providers may enter into any written agreements that they deem appropriate (e.g., confidentiality agreements, invention assignment agreements, assignment of rights, etc.) (each, a “Service Contract”) provided that any such agreements do not conflict with, narrow, or expand Pave’s rights and obligations under the Terms of Service, including this Agreement. Pave is not a party to any Service Contract and is not responsible for, and expressly disclaims any and all liability for, either party’s performance, non-performance, acts, or omissions under any Service Contract, including for negligence or willful misconduct.  Users are solely responsible for ensuring that their Service Contract complies with all applicable laws, rules, and regulations, and that their Service Contract is enforceable. Each situation is highly fact-specific and requirements vary by situation and jurisdiction and therefore you should seek legal advice from a licensed attorney in the relevant jurisdictions. </span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">Classification as Contractor </span></span>
                <br />
                <br />
                <span class="reqular__text">Nothing in this Agreement is intended to or should be construed to create a partnership, joint venture, franchisor/franchisee or employer-employee relationship between Pave and you. Clients are solely responsible for and have complete discretion with regard to selection of any Provider for any Engagement. Clients are solely responsible for and assume all liability for determining that Providers should be engaged as independent contractors and engaging them accordingly.  </span>
                <br />
                <br />
                <span class="reqular__text">You warrant that your decisions regarding classification are correct and your manner of engaging as independent contractors complies with applicable laws, regulations, and rules. Pave will have no input into, or involvement in, worker classification as between Clients and a Provider and all Users agree that Pave has no involvement in and will have no liability arising from or relating to the classification of a Provider generally or with regard to a particular Engagement. </span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">Contact Information </span></span>
                <br />
                <br />
                <span class="reqular__text">Users agree to use the communication services available on the Site and Services to communicate with other Users prior to entering into a Service Contract. Pave discourages you from providing your contact information to any other User. </span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">3. FEEDBACK AND CONTENT </span></span>
                <br />
                <br />
                <span class="reqular__text">You hereby acknowledge and agree that Users publish and request Pave to publish on their behalf information on the Site and Services about other Users, such as feedback, composite feedback, geographical location, or verification of identity or credentials. However, such information is based solely on unverified data that Providers or Clients voluntarily submit to Pave and does not constitute and will not be construed as an introduction, endorsement, or recommendation by Pave; Pave provides such information as a convenience to Users. </span>
                <br />
                <br />
                <span class="reqular__text">You acknowledge and agree that feedback benefits the marketplace, all Users, and the efficiency of the Site and Services, and you specifically request that Pave post composite or compiled feedback about Users, including yourself, on Profiles and elsewhere on the Site and Services. You acknowledge and agree that feedback results and other Content on the Site, Services, or otherwise (“Composite Information”), if any, may include comments, ratings, indicators of satisfaction, and other feedback left exclusively by other Users. You further acknowledge and agree that Pave will make Composite Information available to other Users, including Composite Information or compiled feedback. Pave provides its feedback system as a means through which Users can share their opinions of other Users publicly, and Pave does not monitor, influence, contribute to or censor these opinions. You acknowledge and agree that posted composite or compiled feedback and any other Composite Information relates only to the business advertised in the Profile and not to any individual person. You agree not to use the Composite Information to make any employment, credit, credit valuation, underwriting, or other similar decision about any other User. </span>
                <br />
                <br />
                <span class="reqular__text">Pave does not generally investigate any remarks posted by Users or other Content for accuracy or reliability and does not guarantee that Content is accurate. You are solely responsible for Content that you post, including the accuracy of any such Content, and are solely responsible for any legal action that may be instituted by other Users or third parties as a result of or in connection with Content that you post. Pave is not legally responsible for any feedback or comments posted or made available on the Site or Services by any Users or third parties, even if that information is defamatory or otherwise legally actionable. Pave reserves the right (but is under no obligation) to remove posted feedback or information that, in Pave’s sole judgment, violates the Terms of Service or negatively affects our marketplace, diminishes the integrity of the feedback system or otherwise is inconsistent with the business interests of Pave. You acknowledge and agree that you will notify Pave of any error or inaccurate statement in your feedback results, including the Composite Information, and that if you do not do so, Pave may rely on the accuracy of such information. </span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">4. CONTRACTUAL RELATIONSHIP BETWEEN CLIENT AND PROVIDER </span></span>
                <br />
                <br />
                <span class="reqular__text">If a Client and Provider decide to enter into a Service Contract, the Service Contract is a contractual relationship directly between the Client and Provider. Client and Provider have complete discretion both with regard to whether to enter into a Service Contract with each other and with regard to the terms of any Service Contract. You acknowledge, agree, and understand that Pave is not a party to any Service Contract, that the formation of a Service Contract between Users will not, under any circumstance, create an employment or other service relationship between Pave and any User or a partnership or joint venture between Pave and any User. </span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">5. CONFIDENTIAL INFORMATION </span></span>
                <br />
                <br />
                <span class="reqular__text">Clients and Providers may agree to any terms they deem appropriate with respect to confidentiality. If and to the extent that Clients and Provider do not articulate any different agreement about confidentiality, then you agree that this Section 5 (Confidential Information) applies. </span>
                <br />
                <br />
                <span class="reqular__text">To the extent a User provides Confidential Information to another User, the recipient will protect the secrecy of the discloser’s Confidential Information with the same degree of care as it uses to protect its own Confidential Information, but in no event with less than due care. On a User’s written request, the party that received Confidential Information will promptly destroy or return the disclosing party’s Confidential Information and any copies thereof contained in or on its premises, systems, or any other equipment otherwise under its control. </span>
                <br />
                <br />
                <span class="reqular__text">“<span class="bold">Confidential Information</span>” means any material or information provided to, or created by, a User or a Provider to evaluate an Engagement or the suitability of the other party to the Engagement, regardless of whether the information is in tangible, electronic, verbal, graphic, visual, or other form. Confidential Information does not include material or information that: (a) is generally known by third parties as a result of no act or omission of Provider or User; (b) was lawfully received by the recipient without restriction from a third party having the right to disseminate the information; (c) was already known to the recipient prior to receiving it from the other party and was not received from a third party in breach of that third party’s obligations of confidentiality; or (d) was independently developed by the recipient without use of another person’s Confidential Information. </span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">6. PAVE FEES </span></span>
                <br />
                <br />
                <span class="reqular__text">Clients shall pay Pave for access to the Services and for Engagements as set forth in Pave’s [rate sheet] (“Fees”). In addition, you may be required to pay Pave a fee for payment processing and administration related to the Fees. From time to time, Pave may offer premium membership plans for additional fees for access to additional features and Services. </span>
                <br />
                <br />
                <span class="reqular__text">Pave may be required by applicable law to collect taxes or levies including, without limitation, withholding income tax or VAT (while some countries may refer to VAT using other terms, e.g. GST, we’ll just refer to VAT, GST and any local sales taxes collectively as “VAT”) in the jurisdiction of the Provider (the “Taxes”). In such instances, any amounts Pave is required to collect or withhold for the payment of any such Taxes shall be collected in addition to the Fees owed to Pave under the Terms of Service. </span>
                <br />
                <br />
                <span class="reqular__text">Pave does not introduce Clients to Providers and does not help Providers secure Engagements. Pave merely makes the Site and Services available to enable Providers to do so themselves and may from time to time highlight Engagements that may be of interest. Therefore, Pave does not charge a fee when a Provider finds a suitable Client or finds an Engagement. In addition, Pave does not charge any fee or dues for posting public feedback and composite or compiled feedback, including Composite Information. </span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">7. PAYMENT TERMS</span></span>
                <br />
                <br />
                <span class="reqular__text">Pave provides third-party financial services, such as Stripe, to Users to deliver and/or receive payment for Engagements and to pay Fees (“Financial Services”). The Financial Services are intended for business use, and you agree to use the Financial Services only for business purposes  </span>
                <br />
                <br />
                <span class="reqular__text">Pave and its financial administrator, Stripe, will collect and disburse payments only in accordance with this Agreement. You agree to abide by any standard agreement of Pave or Stripe regarding the collection and disbursement of payments in accordance with the Terms of Service. Client and Provider may access current information regarding the status of payments on the Site, Services, or other location authorized by Pave. </span>
                <br />
                <br />
                <span class="reqular__text">Client will book an Engagement with Provider through the Pave website and pay Fees at that time through the connected Stripe payment portal. Users authorize Stripe to immediately disburse the payment to Pave as required by this Agreement.  All payments are nonrefundable. </span>
                <br />
                <br />
                <span class="reqular__text">To the extent permitted by applicable law, Client agrees not to ask its credit card company, bank, or other payment method provider to charge back any Fees charged pursuant to the Terms of Service for any reason. A chargeback in breach of the foregoing obligation is a material breach of the Terms of Service. If Client initiates a chargeback in violation of this Agreement, Client agrees that Pave, its affiliates, or Stripe may dispute or appeal the chargeback and institute collection action against Client and take such other action it deems appropriate. </span>
                <br />
                <br />
                <span class="reqular__text">In order to use certain Services, Client must provide account information for at least one valid payment method. Client hereby authorizes Pave and Stripe, as applicable, to run credit card authorizations on all credit cards provided by Client, to store credit card and banking or other financial details as Client’s method of payment consistent with our Privacy Policy, and to charge Client’s credit card (or any other payment method) for the Fees and any other amounts owed under the Terms of Service. To the extent permitted by applicable law and subject to our Privacy Policy, you acknowledge and agree that we may use certain third-party vendors and service providers such as Stripe to process payments and manage your payment method information. </span>
                <br />
                <br />
                <span class="reqular__text">By providing payment method information through the Site and authorizing payments with the Payment Method, Client represents, warrants, and covenants that: (a) Client is legally authorized to provide such information; (b) Client is legally authorized to make payments using the payment method(s); (c) if Client is an employee or agent of a company or person that owns the payment method, that Client is authorized by the company or person to use the payment method to make payments on Pave; and (d) such actions do not violate the terms and conditions applicable to Client’s use of such payment method(s) or applicable law. </span>
                <br />
                <br />
                <span class="reqular__text">When Client authorizes a payment using a payment method via the Site or Services, Client represents and warrants that there are sufficient funds or credit available to complete the payment using the designated payment method. To the extent that any amounts owed under this Agreement or the other Terms of Service cannot be collected from Client’s payment method(s), Client is solely responsible for paying such amounts by other means. </span>
                <br />
                <br />
                <span class="reqular__text">Pave is not liable to any User if Pave does not complete a transaction as a result of any limit by applicable law or your financial institution, or if a financial institution fails to honor any credit or debit to or from an account associated with such payment method. Pave will make commercially reasonable efforts to work with any such affected Users to resolve such transactions in a manner consistent with this Agreement. </span>
                <br />
                <br />
                <span class="reqular__text">The Site and the Services operate in U.S. Dollars.</span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">8. WARRANTY DISCLAIMER</span></span>
                <br />
                <br />
                <span class="reqular__text">THE SERVICES, THE SITE, THE CONTENT, THE WORK PRODUCT, PROVIDER SERVICES, AND ALL OTHER PAVE PRODUCTS AND SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT ANY OTHER WARRANTIES WHATSOEVER. ALL OTHER EXPRESS, IMPLIED, AND STATUTORY WARRANTIES, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, ACCURACY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, ARE EXPRESSLY DISCLAIMED. TO THE FULLEST EXTENT PERMITTED BY LAW, PAVE DISCLAIMS ANY WARRANTIES FOR THE SECURITY, RELIABILITY, TIMELINESS, AND PERFORMANCE OF ITS SERVICES.  </span>
                <br />
                <br />
                <span class="reqular__text">THE SERVICES, THE SITE, THE CONTENT, THE WORK PRODUCT, PROVIDER SERVICES, AND ALL OTHER PAVE PRODUCTS AND SERVICES ARE FOR INFORMATIONAL PURPOSES ONLY AND ARE NOT INTENDED AS A SUBSTITUTE FOR PROFESSIONAL CAREER GUIDANCE OR THE SERVICES OF AN EMPLOYMENT AGENT. YOUR USE OF THE SITE OR SERVICES ARE FOR INFORMATION PURPOSES ONLY AND DO NOT CREATE AN AGENT-CLIENT RELATIONSHIP WITH PAVE OR ITS ADVISORS. PAVE DOES NOT GUARANTEE THE ACCURACY OF ANY OF THE CONTENT ON THIS SITE OR SERVICES, AND YOUR RELIANCE OR USE OF THE CONTENT IS AT YOUR OWN SOLE RISK. </span>
                <br />
                <br />
                <span class="reqular__text">SOME JURISDICTIONS MAY NOT ALLOW FOR ALL OF THE FOREGOING LIMITATIONS ON WARRANTIES, SO TO THAT EXTENT, SOME OR ALL OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. SECTIONS 9B (LIMITATION OF LIABILITY) AND 12 (AGREEMENT TERM AND TERMINATION) STATE YOUR SOLE AND EXCLUSIVE REMEDIES AGAINST PAVE WITH RESPECT TO ANY DEFECTS, NON-CONFORMANCES, OR DISSATISFACTION. </span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">9. LIMITATION OF LIABILITY</span></span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">a.</span> PAVE, ITS AFFILIATES, EMPLOYEES, OFFICERS, REPRESENTATIVES, SERVICE PROVIDERS AND ITS SUPPLIERS, SHALL NOT UNDER ANY CIRCUMSTANCES, OR ANY LEGAL THEORY, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, TORTS, OR STRICT LIABILITY BE RESPONSIBLE FOR NOR BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR OTHER DAMAGES OR LOSSES (INCLUDING, WITHOUT LIMITATION, LITIGATION COSTS, LOST PROFITS, OR LOSS OF DATA) ARISING OUT OF OR RELATING IN ANY WAY TO THE SITE, THE SERVICES, THE PROVIDER SERVICES, THE PAVE PROGRAMS, ANY THIRD-PARTY MATERIALS OR OTHER INFORMATION OR INFORMATION CONTAINED WITHIN THE SITE OR SERVICES, INCLUDING, BUT NOT LIMITED TO, YOUR DOWNLOADING OR UPLOADING OF ANY MATERIAL OR DOCUMENTS.</span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">b.</span> PAID PRODUCT OR SERVICE: SOLE REMEDY. YOUR SOLE AND EXCLUSIVE REMEDY FOR FAILURE TO DELIVER A PAID PRODUCT OR SERVICE OR BREACH OF ANY OBLIGATION WITH RESPECT TO A PAID PRODUCT OR SERVICE SHALL BE A REFUND OF THE AMOUNT PAID FOR THE SPECIFIC PAID PRODUCT OR SERVICE AT ISSUE AND IN NO EVENT SHALL PAVE’S LIABILITY FOR FAILURE TO DELIVER SUCH ITEM EXCEED SUCH AMOUNT. </span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">c.</span> TO THE MAXIMUM EXTENT ALLOWED BY LAW, THE DISCLAIMERS, WAIVERS AND LIMITATIONS CONTAINED IN THE TERMS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. APPLICABLE LAW MAY NOT ALLOW THE LIMITATIONS OF LIABILITY SET FORTH IN THIS SECTION 10, SO THIS LIMITATION OF LIABILITY MAY NOT APPLY TO YOU. IF THIS LIMITATION ON LIABILITY IS FOUND TO BE INVALID OR UNENFORCEABLE FOR ANY REASON, THEN THE AGGREGATE LIABILITY OF PAVE UNDER SUCH CIRCUMSTANCES FOR LIABILITIES THAT OTHERWISE WOULD HAVE BEEN LIMITED SHALL NOT EXCEED THE GREATER OF ONE HUNDRED DOLLARS ($100.00) OR THE LOWEST AMOUNT ALLOWED BY LAW. </span>
                <br />
                <br />
                <span class="reqular__text">Without limiting the generality of the foregoing, Pave is not liable, and you agree not to hold us responsible, for any damages or losses arising out of or in connection with the Terms of Service, including, but not limited to: </span>
                <br />
                <br />
                <span class="reqular__text">your use of or your inability to use our Site or Services;</span>
                <br />
                <span class="reqular__text">delays or disruptions in our Site or Services;</span>
                <br />
                <span class="reqular__text">viruses or other malicious software obtained through our Site or Services;</span>
                <br />
                <span class="reqular__text">glitches, bugs, errors, or inaccuracies of any kind in our Site or Services;</span>
                <br />
                <span class="reqular__text">damage to your hardware device from the use of the Site or Services;</span>
                <br />
                <span class="reqular__text">the content, actions, or inactions of third parties’ use of the Site or Services; </span>
                <br />
                <span class="reqular__text">a suspension or other action taken with respect to your Account; and</span>
                <br />
                <span class="reqular__text">your reliance on the quality, accuracy, or reliability of job postings, Profiles, ratings, recommendations, and feedback (including their content, order, and display), Composite Information, or metrics found on, used on, or made available through the Site or Services. </span>
                <br />
                <br />
                <span class="reqular__text">SOME STATES AND JURISDICTIONS DO NOT ALLOW FOR ALL OF THE FOREGOING EXCLUSIONS AND LIMITATIONS, SO TO THAT EXTENT, SOME OR ALL OF THESE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU. </span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">10. RELEASE </span></span>
                <br />
                <br />
                <span class="reqular__text">In addition to the recognition that Pave is not a party to any Service Contract, you hereby release Pave, our affiliates, and our respective officers, directors, agents, subsidiaries, joint ventures, employees and service providers from claims, demands, and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with any dispute you have with another User, whether it be at law or in equity that exist as of the time you enter into this agreement. This release includes, for example and without limitation, any disputes regarding the performance, functions, and quality of the Provider Services provided to Client by a Provider and requests for refunds based upon disputes.</span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">TO THE EXTENT APPLICABLE, YOU HEREBY WAIVE THE PROTECTIONS OF CALIFORNIA CIVIL CODE § 1542 (AND ANY ANALOGOUS LAW IN ANY OTHER APPLICABLE JURISDICTION) WHICH SAYS: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.”</span></span>
                <br />
                <br />
                <span class="reqular__text">This release will not apply to a claim that Pave failed to meet our obligations under the Terms of Service. </span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">11. INDEMNIFICATION</span></span>
                <br />
                <br />
                <span class="reqular__text">In addition to the indemnification obligations in the Site Terms of Use, you agree to indemnify, defend, and hold harmless Pave, its officers, directors, employees, agents, licensors, suppliers and any third-party service providers to the Site and/or Services from and against all losses, expenses, damages and costs, including reasonable attorneys’ fees, arising out of or arising from or relating to any claim, suit, proceeding, demand, or action brought by you or a third party arising out of (i) any payment obligations or default; (ii) the Provider Services; (iii) use of any work product or Content developed, provided, or otherwise related to your use of the Site or Services; (iv) any Service Contract, including, but not limited to, the classification of a Provider as an independent contractor or non-compliance with labor laws regarding classification of workers; the classification of Pave as an employer or joint employer of Provider; any employment-related claims, such as those relating to employment termination, employment discrimination, harassment, or retaliation; and any claims for unpaid wages or other compensation, overtime pay, sick leave, holiday or vacation pay, retirement benefits, worker’s compensation benefits, unemployment benefits, or any other employee benefits; (v) any unauthorized use of your Account; (vi) any violation of law; (vii) any breach of this User Agreement; (viii) negligence, willful misconduct, or fraud by you or your agents; (ix) defamation, libel, violation of privacy rights, unfair competition, or infringement of Intellectual Property Rights or allegations thereof to the extent caused by you; and/or (x) any claim by any other User. </span>
                <br />
                <br />
                <span class="reqular__text">For purposes of this User Agreement, “Intellectual Property Rights” means all patent rights, copyright rights, mask work rights, moral rights, rights of publicity, trademark, trade dress and service mark rights, goodwill, trade secret rights and other intellectual property rights as may now exist or hereafter come into existence, and all applications therefore and registrations, renewals and extensions thereof, in each case, under the laws of any state, country, territory or other jurisdiction. </span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">12. AGREEMENT TERM AND TERMINATION</span></span>
                <br />
                <br />
                <span class="reqular__text">Unless both you and Pave expressly agree otherwise in writing, either of us may terminate this Agreement in our sole discretion, at any time, without explanation, upon written notice to the other, which will result in the termination of the other Terms of Service as well, except as otherwise provided herein. You may provide written notice to legalnotices@pave.com.  </span>
                <br />
                <br />
                <span class="reqular__text">In the event you properly terminate this Agreement, your right to use the Site and Services is automatically revoked, and your Account will be closed. Pave is not a party to any Service Contract between Users. Consequently, you understand and acknowledge that termination of this Agreement (or attempt to terminate this Agreement) does not terminate or otherwise impact any Service Contract or Engagement entered into between Users. If you attempt to terminate this Agreement while having one or more open Engagements, you agree that: (a) you hereby instruct Pave to close any open contracts; (b) you will continue to be bound by this Agreement and the other Terms of Service until all such Engagements have closed on the Site or Services; (c) Pave will continue to perform those Services necessary to complete any open Engagement or related transaction between you and another User; and (d) you will continue to be obligated to pay any amounts accrued but unpaid as of the date of termination or as of the closure of any open Service Contracts, whichever is later, to Pave for any Services or such other amounts owed under the Terms of Service and to any Providers for any Provider Services. </span>
                <br />
                <br />
                <span class="reqular__text">Without limiting Pave’s other rights or remedies, we may, but are not obligated to, temporarily or indefinitely revoke or limit access to the Site or Services, deny your registration, or permanently revoke your access to the Site and Services and refuse to provide any or all Services to you if: (i) you breach the letter or spirit of any terms and conditions of this Agreement or any other provisions of the Terms of Service; (ii) we suspect or become aware that you have provided false or misleading information to us; (iii) we believe, in our sole discretion, that your actions may cause legal liability for you, other Users, or Pave or our affiliates; may be contrary to the interests of the Site, Services, or Pave community; or may involve illicit or illegal activity; or (iv) we are required to by law, legal process, or law enforcement. If your Account is temporarily or permanently closed, you may not use the Site or Services under the same Account or a different Account or re-register under a new Account without Pave’s prior written consent. If you attempt to use the Site under a different Account, we reserve the right to reclaim available funds in that Account and/or use an available payment method to pay for any amounts owed by you to the extent permitted by applicable law. </span>
                <br />
                <br />
                <span class="reqular__text">You acknowledge and agree that the value, reputation, and goodwill of the Site and Services depend on transparency of User’s Account status to all Users, including both yourself and other Users who have entered into Service Contracts with you. You therefore agree as follows: <span class="bold">IF PAVE DECIDES TO TEMPORARILY OR PERMANENTLY CLOSE YOUR ACCOUNT, PAVE HAS THE RIGHT WHERE ALLOWED BY LAW BUT NOT THE OBLIGATION TO: (A) NOTIFY OTHER USERS THAT HAVE ENTERED INTO SERVICE CONTRACTS WITH YOU TO INFORM THEM OF YOUR CLOSED ACCOUNT STATUS, AND (B) PROVIDE THOSE USERS WITH A SUMMARY OF THE REASONS FOR YOUR ACCOUNT CLOSURE. YOU AGREE THAT PAVE WILL HAVE NO LIABILITY ARISING FROM OR RELATING TO ANY NOTICE THAT IT MAY PROVIDE TO ANY USER REGARDING CLOSED ACCOUNT STATUS OR THE REASON(S) FOR THE CLOSURE.</span></span>
                <br />
                <br />
                <span class="reqular__text">Except as otherwise required by law, if your Account is closed for any reason, you will no longer have access to data, messages, files, or other material you keep on the Site or Services and any closure of your Account may involve deletion of any content stored in your Account for which Pave will have no liability whatsoever. Pave, in its sole discretion and as permitted or required by law, may retain some or all of your Account information.
After this Agreement terminates, the terms of this Agreement and the other Terms of Service that expressly or by their nature contemplate performance after this Agreement terminates or expires will survive and continue in full force and effect. Without limiting any other provisions of the Terms of Service, the termination of this Agreement for any reason will not release you or Pave from any obligations incurred prior to termination of this Agreement or that thereafter may accrue in respect of any act or omission prior to such termination.</span>
                <br />
                <br />
                <span class="reqular__text">For disputes arising between Clients and Providers, you agree to abide by the dispute process that is explained in your particular Service Contract. If the dispute process does not resolve your dispute, you may pursue your dispute independently, but you acknowledge and agree that Pave will not and is not obligated to provide any dispute assistance beyond what is provided in the Terms of Service. </span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">13. DISPUTE PROCESS AND ARBITRATION </span></span>
                <br />
                <br />
                <span class="reqular__text">Informal Dispute Resolution.  </span>
                <br />
                <br />
                <span class="reqular__text">Before serving a demand for arbitration of a Claim, you and Pave agree to first notify each other of the Claim. You agree to notify Pave of the Claim at Attn: Legal, 122 Vanderbilt Ave, Suite 2A Brooklyn NY 11205 or by email to legalnotices@pave.com, and Pave agrees to provide to you a notice at your email address on file (in each case, a “Notice”). You and Pave then will seek informal voluntary resolution of the Claim. Any Notice must include pertinent account information, a brief description of the Claim, and contact information, so that you or Pave, as applicable, may evaluate the Claim and attempt to informally resolve the Claim. Both you and Pave will have 60 days from the date of the receipt of the Notice to informally resolve the other party’s Claim, which, if successful, will avoid the need for further action. </span>
                <br />
                <br />
                <span class="reqular__text">Arbitration Agreement. </span>
                <br />
                <br />
                <span class="reqular__text">By agreeing to the Terms of Service, you agree that you are required to resolve any claim that you may have against Pave in arbitration, as set forth in this Section (referred to herein as the “Arbitration Agreement”). This will preclude you from bringing any class, collective, or representative action against Page, and also preclude you from participating in or recovering relief under any current or future class, collective, consolidated, or representative action brought against Pave by someone else. You acknowledge and agree that you and Pave are each waiving the right to a trial by jury or to participate as a plaintiff or class member in any purported class action or representative proceeding. Unless both you and Pave otherwise agree in writing, any arbitration will be conducted only on an individual basis and not in a class, collective, consolidated, or representative proceeding. </span>
                <br />
                <br />
                <span class="reqular__text">The arbitration will be administered by JAMS, Inc. (“JAMS”) pursuant to its Streamlined Arbitration Rules and Procedures (the “Rules”).  The Rules are available at https://www.jamsadr.com/rules-streamlined-arbitration/. </span> 
                <br />
                <br />
                <span class="reqular__text">Any arbitration must be commenced by filing a demand for arbitration within two years after the date the party asserting the claim first knows or reasonably should know of the act, omission or default giving rise to the claim. If applicable law provides a different limitation period for asserting claims, any claim must be asserted within the shortest time period permitted by applicable law. </span>
                <br />
                <br />
                <span class="reqular__text">These Terms of Service (including this Arbitration Agreement) shall be governed by and interpreted in accordance with the laws of the State of New York and United States federal laws applicable therein, without regard to principles of conflicts of laws. Any arbitration hearing (“Hearing”) will be located at a site in New York, New York. The specific site will be selected by the arbitrator, pursuant to the Rules.  The Hearing may be conducted telephonically or through a video conference, upon request from either party. The Hearing will be conducted in English. </span>
                <br />
                <br />
                <span class="reqular__text">The arbitration will be conducted in front of a single arbitrator (the “Arbitrator”), selected from the appropriate list of JAMS arbitrators pursuant to the Rules. The parties agree that the Arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve any disputes relating to the interpretation, applicability, enforceability or formation of these Terms of Service, including any claim that all or any part of these Terms of Service (including this Arbitration Agreement) is void or voidable. The Arbitrator shall also be responsible for determining all threshold arbitrability issues, including issues relating to whether these Terms of Service (including the Arbitration Agreement) are unconscionable or illusory and any defense to arbitration, including waiver, delay, laches, or estoppel. </span>
                <br />
                <br />
                <span class="reqular__text">If your claim does not exceed $10,000, then the arbitration will be conducted solely on the basis of the written submissions of the parties, unless the Arbitrator determines that a Hearing is necessary. If your claim exceeds $10,000, your right to a Hearing will be determined by the Rules.</span>
                <br />
                <br />
                <span class="reqular__text">The Arbitrator may issue an arbitration award in accordance with the Rules and any limitations of liability set out in these Terms of Service. Judgment on the arbitration award may be entered in any court having competent jurisdiction to do so. The Arbitrator may award declaratory or injunctive relief only in favor of the claimant and only to the extent necessary to provide relief warranted by the claimant’s individual claim. An Arbitrator’s decision shall be final and binding on all parties. An Arbitrator’s decision and judgment thereon shall have no precedential effect. Attorneys’ fees and costs shall be borne by the unsuccessful party, as determined by the Arbitrator, and shall be awarded as part of the Arbitrator’s award. </span>
                <br />
                <br />
                <span class="reqular__text">Your responsibility to pay any filing, administrative, and arbitrator fees will be solely as set forth in the Rules.  However, if your claim for damages does not exceed $50,000, we will pay all such fees, unless the Arbitrator finds that the substance of your claim or the relief sought by you was frivolous or was brought for an improper purpose (determined with respect to Federal Rule of Civil Procedure 11(b)). </span>
                <br />
                <br />
                <span class="reqular__text">Notwithstanding the provisions in these Terms of Service regarding consent to be bound by amendments to these Terms of Service, if Pave changes this Arbitration Agreement after the date you first agreed to the Arbitration Agreement (or to any subsequent changes to the Arbitration Agreement), you may reject any such change by providing Pave written notice of such rejection within thirty (30) calendar days of the date such change became effective. This written notice must be provided by email from the email address associated with your account to: admin@joinpave.com. In order to be effective, the notice must include your full name and clearly indicate your intent to reject changes to this Arbitration Agreement. By rejecting changes, you are agreeing that you will arbitrate any dispute between you and Pave in accordance with the provisions of this Arbitration Agreement as of the date you first agreed to the Arbitration Agreement (or to any subsequent changes to the Arbitration Agreement). </span>
                <br />
                <br />
                <span class="reqular__text">If any portion of this Arbitration Agreement is found to be unenforceable or unlawful for any reason, (1) the unenforceable or unlawful provision shall be severed from these Terms of Service; (2) severance of the unenforceable or unlawful provision shall have no impact whatsoever on the remainder of the Terms of Service (including this Arbitration Agreement) or the parties’ ability to compel arbitration of any remaining claims on an individual basis pursuant to the Arbitration Agreement; and (3) to the extent that any claims must therefore proceed on a class, collective, consolidated, or representative basis, such claims must be litigated in a civil court of competent jurisdiction and not in arbitration, and the parties agree that litigation of those claims shall be stayed pending the outcome of any individual claims in arbitration. </span>
                <br />
                <br />
                <span class="reqular__text">If and only if this Arbitration Agreement is found not to apply to you or your claim, you and Pave agree that any judicial proceeding must be brought exclusively in the state courts and federal courts located within New York, New York, and you and Pave consent to venue and exclusive jurisdiction in those courts. Any claim not subject to arbitration must be commenced within two years after the date the party asserting the claim first knows or reasonably should know of the act, omission or default giving rise to the claim. If applicable law provides for a different limitation period for asserting claims, any claim must be asserted within the shortest time period permitted by applicable law. </span>
                <br />
                <br />
                <span class="reqular__text"><span class="bold">14. GENERAL </span></span>
                <br />
                <br />
                <span class="reqular__text">Entire Agreement </span>
                <br />
                <br />
                <span class="reqular__text">This Agreement, together with the other Terms of Service, sets forth the entire agreement and understanding between you and Pave relating to the subject matter hereof and thereof and cancels and supersedes any prior or contemporaneous discussions, agreements, representations, warranties, and other communications between you and us, written or oral, to the extent they relate in any way to the subject matter hereof and thereof. The section headings in the Terms of Service are included for ease of reference only and have no binding effect. Even though Pave drafted the Terms of Service, you represent that you had ample time to review and decide whether to agree to the Terms of Service. If an ambiguity or question of intent or interpretation of the Terms of Service arises, no presumption or burden of proof will arise favoring or disfavoring you or Pave because of the authorship of any provision of the Terms of Service. </span>
                <br />
                <br />
                <span class="reqular__text">Modifications; Waiver </span>
                <br />
                <br />
                <span class="reqular__text">No modification or amendment to the Terms of Service will be binding upon Pave unless they are agreed in a written instrument signed by a duly authorized representative of Pave or posted on the Site by Pave. Email will not constitute a written instrument as contemplated by this Section 15.2. Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches. We do not guarantee we will take action against all breaches of this User Agreement. </span>
                <br />
                <br />
                <span class="reqular__text">Assignability.  </span>
                <br />
                <br />
                <span class="reqular__text">User may not assign the Terms of Service, or any of its rights or obligations hereunder, without Pave’s prior written consent in the form of a written instrument signed by a duly authorized representative of Pave. Pave may freely assign this Agreement and the other Terms of Service without User’s consent. Any attempted assignment or transfer in violation of this subsection will be null and void. Subject to the foregoing restrictions, the Terms of Service are binding upon and will inure to the benefit of the successors, heirs, and permitted assigns of the parties. </span>
                <br />
                <br />
                <span class="reqular__text">Severability; Interpretation </span>
                <br />
                <br />
                <span class="reqular__text">If and to the extent any provision of this Agreement or the other Terms of Service is held illegal, invalid, or unenforceable in whole or in part under applicable law, such provision or such portion thereof will be ineffective as to the jurisdiction in which it is illegal, invalid, or unenforceable to the extent of its illegality, invalidity, or unenforceability and will be deemed modified to the extent necessary to conform to applicable law so as to give the maximum effect to the intent of the parties. The illegality, invalidity, or unenforceability of such provision in that jurisdiction will not in any way affect the legality, validity, or enforceability of such provision in any other jurisdiction or of any other provision in any jurisdiction. </span>
                <br />
                <br />
                <span class="reqular__text">Force Majeure </span>
                <br />
                <br />
                <span class="reqular__text">The parties to this Agreement will not be responsible for the failure to perform, or any delay in performance of, any obligation hereunder for a reasonable period due to labor disturbances, accidents, fires, floods, telecommunications or Internet failures, strikes, wars, riots, rebellions, blockades, acts of government, pandemics, governmental requirements and regulations or restrictions imposed by law or any other conditions beyond the reasonable control of such party. </span>
                <br />
                <br />
                <span class="reqular__text">Location of Services </span>
                <br />
                <br />
                <span class="reqular__text">Pave makes no representations that the Site is appropriate or available for use outside of the United States. Those who access or use the Site from other jurisdictions do so at their own risk and are entirely responsible for compliance with all applicable foreign, United States, state, and local laws and regulations, including, but not limited to, export and import regulations, including the Export Administration Regulations maintained by the United States Department of Commerce and the sanctions programs maintained by the Department of the Treasury Office of Foreign Assets Control. You must not directly or indirectly sell, export, re-export, transfer, divert, or otherwise dispose of any software or service to any end user without obtaining any and all required authorizations from the appropriate government authorities. You also warrant that you are not prohibited from receiving U.S. origin products, including services or software. </span>
                <br />
                <br />
                <span class="reqular__text">In order to access or use the Site or Site Services, you must and hereby represent that you are not: (a) a citizen or resident of a geographic area in which access to or use of the Site or Site Services is prohibited by applicable law, decree, regulation, treaty, or administrative act; (b) a citizen or resident of, or located in, a geographic area that is subject to U.S. or other sovereign country sanctions or embargoes; or (c) an individual, or an individual employed by or associated with an entity, identified on the U.S. Department of Commerce Denied Persons or Entity List, the U.S. Department of Treasury Specially Designated Nationals or Blocked Persons Lists, or the U.S. Department of State Debarred Parties List or otherwise ineligible to receive items subject to U.S. export control laws and regulations or other economic sanction rules of any sovereign nation. You agree that if your country of residence or other circumstances change such that the above representations are no longer accurate, that you will immediately cease using the Site and Site Services and your license to use the Site or Site Services will be immediately revoked. </span>
                <br />
                <br />
                <span class="reqular__text">Consent To Use Electronic Records </span>
                <br />
                <br />
                <span class="reqular__text">In connection with the Site Terms of Use, you may be entitled to receive, or we may otherwise provide, certain records from Pave or our Affiliates, such as contracts, notices, and communications, in writing. To facilitate your use of the Site and the Services, you give us permission to provide these records to you electronically instead of in paper form. </span>
                <br />
                <br />
            </div>
        `,
    closeBtnTitle: 'I agree',
  },
  privacy: {
    isAgreement: false,
    title: 'Privacy Policy',
    date: 'November 1, 2021',
    description: `<div>
                <span class="reqular__text">
                    Arable Ventures LLC, a New York company doing business as Pave Careers (“PAVE”), respects
                    your privacy and is committed to protecting your personal data. This privacy policy will inform 
                    you as to how we look after your personal data and tell you about your privacy rights and how 
                    the law protects you. This privacy policy applies to personal data that you may submit through 
                    PAVE’s website or by other means.
                    <br />
                    <br />
                </span>
                <span class="reqular__bold__text">
                    Summary
                    <br />
                    <br />
                </span>
                <span class="reqular__text">
                    1.  IMPORTANT INFORMATION AND WHO WE ARE. <br /> 2. THE
                    INFORMATION WE COLLECT ABOUT YOU. <br /> 3. USE OF PERSONAL
                    DATA. <br /> 4. DISCLOSURE OF PERSONAL DATA. <br />
                    5. CHILDREN. <br /> 6. MARKETING EMAILS. <br /> 7. SECURITY. <br /> 8. RETENTION OF YOUR PERSONAL DATA. 
                    <br /> 9. EXTERNAL LINKS. <br /> 10.YOUR RIGHTS. <br /> 11.  DISPUTE RESOLUTION AND OTHER LEGAL MATTERS. <br /> 12. CHANGES TO THIS POLICY.
                    <br />
                    <br />
                    1. 
                    <span class="bold underline">
                        Important information and who we are.
                    </span> 
                    <br />
                    <br />
                    <span class="bold underline">
                        Purpose of this privacy notice
                    </span> 
                    <br />
                    <br />
                    This privacy policy aims to give you information on how PAVE collects and processes your 
                    personal data through your use of our website, <span class="link">https://joinpave.com</span>, our technology systems, our
                    apps or other PAVE portals which make reference to this policy. For purposes of this privacy 
                    policy, the terms “user”, “participant”, “customer”, “you” and “your” are meant to refer to the 
                    individuals about whom we may collect data.
                    <br />
                    <br />
                    Our data operations and privacy policy endeavor to comply with the latest US and international 
                    privacy and data protection regulations. These regulations include those promulgated under the 
                    European Union’s General Data Protection Regulation (“GDPR”) and the California Consumer 
                    Privacy Act (“CCPA”) where applicable. While GDPR regulations and CCPA regulations may 
                    not apply to PAVE, we have decided to voluntarily adopt and implement these protections for all
                    individuals who submit personal data to PAVE. By doing this, along with implementing strict 
                    data protection standards from other jurisdictions such as California, we provide you with 
                    expansive rights with respect to any personal data that we collect from you. Some of these rights 
                    include the following:
                    <br />
                    <br />
                    <div class="sub_points">
                        1. The right to have PAVE permanently delete any of your personal data. <br /> <br /> 
                        2. The right to review any personal data that we’ve collected from you. <br /> <br />
                        3. The right to voluntarily and affirmatively “opt-in” to any newsletters, emails, or marketing materials sent to you by PAVE. <br /> <br /> 
                        4. The right to receive a copy of your personal data. <br /> <br />
                        5. The right to have control and access over your personal data. <br /> <br /> 
                        6. The right to have your personal data protected, transferred and stored in compliance with data protection requirements required by law.
                        <br /> <br /> 
                        7. The right to have your personal data automatically destroyed after it is no longer being actively used by PAVE. <br /> <br /> 
                        8. The right to only receive communications from PAVE pursuant to a “Lawful Basis”. The two most common Lawful Bases pursuant to which you may receive PAVE communications are: (i) that you requested, subscribed or affirmatively consented to the receipt of the communications, and (ii) the communications are necessary for PAVE’s “legitimate interests” that are not overridden by your interests or fundamental rights and freedoms that require protection of personal data.<br /> <br /> 
                        9. The right, if applicable, to have PAVE appoint a Data Protection Officer (“DPO”) to manage PAVE’s compliance with this privacy policy and to respond within 72 hours to any inquiries, complaints, or requests that you make regarding your personal data. At any time, you may contact our DPO at <span class="enail">admin@joinpave.com</span>. We consider and respond to all of your inquiries, complaints, or requests in accordance with applicable law.<br /> <br /> 
                        10. Our authorized subscribers have affirmatively and voluntarily “opted in” to receive newsletters, emails, and marketing communications from PAVE. If you believe that you did not opt in to receive these communications from PAVE, please contact our DPO as soon as possible at <span class="email">admin@joinpave.com</span> and we will arrange for you to either affirmatively opt in or not receive any further communications from us. Additionally, all our newsletters, emails to subscribers, and other related communications contain an opt-out/unsubscribe button at the bottom. At any time, you may unsubscribe from all PAVE communications by clicking on the button. <br /> <br />
                        11. To the extent that any applicable international, federal, or state laws that apply to us are more restrictive than this privacy policy with respect to protecting your personal data and privacy, we strive to comply with such stricter regulations. If for any reason you believe we are not in compliance with any provision of these laws, please advise us immediately in writing so that we can review the laws in question and take immediate corrective action if necessary. You can advise us at <span class="email">admin@joinpave.com</span>.
                    </div>
                    <br />
                    <br />
                    We take your personal data and privacy seriously. If you have any questions regarding your rights, your personal data, or PAVE’s obligations, please contact PAVE’s Data Protection Officer at <span class="email">admin@joinpave.com</span>.
                    <br />
                    <br />
                    Your rights under GDPR are further described below in Section 10. Some of your rights described in this policy may be subject to certain rights of PAVE such as legal, accounting, and archiving requirements.
                    <br />
                    <br />
                    <span class="bold underline">Controller</span>
                    <br />
                    <br />
                    When we mention "PAVE", "we", "us" or "our" in this privacy policy, we are referring to PAVE, the company that is responsible for processing your data. For individuals located in the European Economic Area, PAVE is the data controller, as that term is defined by GDPR.
                    <br />
                    <br />
                    <span class="link">Contact details</span>
                    <br />
                    <br />
                    PAVE’s Data Protection Officer (“DPO”) is responsible for overseeing questions in relation to this privacy policy. If you have any questions about this privacy policy, including any requests to exercise any rights that may be afforded to you, please contact the DPO at <span class="email">admin@joinpave.com</span> or using the details set out below:
                    <br />
                    <br />
                    Attention: Data Processing Officer<br />
                    Arable Ventures LLC DBA Pave Careers<br />
                    244 Madison Ave, Suite #173<br />
                    New York, NY 10016<br />
                    <br />
                    <br />
                    <span class="bold underline">The PAVE Website and Portals.</span>
                    <br />
                    <br />
                    PAVE’s website, digital platform, apps, technology systems, content, services, trademark, and other intellectual property, are owned or licensed by PAVE. PAVE’s mission is to use proprietary content and technology to help people “find what they love to do and succeed in it.” This includes aligning work with self and using real-time market data across industries to discover new and unexpected ways to put talents to work. PAVE uses its technology, expertise, and research to recommend options and provide guidance based not only an individual’s skills and experience, but also on their personal aspirations and work style. The company uses technology, programs, expertise, and research to provide actionable next steps and multiple approaches to help its clients achieve their career objectives.
                    <br />
                    <br />
                    Customers consisting of businesses and individuals register on the PAVE website and submit information that PAVE uses to assist them in achieving their career aspirations and goals. Customers license from PAVE its proprietary career guidance technology, programs and content according to various licensing terms or subscriptions that PAVE makes available. Your privacy in engaging with the PAVE website is very important to us. With that in mind, we have established information handling practices for PAVE intended to guard and respect your privacy. We believe these practices are consistent with GDPR and the best practices of websites providing career guidance services. This privacy policy applies to the PAVE career guidance platform including the information we collect about you, when and how we collect that information, how that information is used, how we safeguard that information, and our procedures for archiving and deleting that information.
                    <br />
                    <br />
                    <span class="bold underline">
                        2. The Information We Collect About You
                    </span> 
                    <br />
                    <br />
                    <span class="bold underline">
                        Information We Collect Automatically
                    </span> 
                    <br />
                    <br />
                    When you visit our website, our servers automatically collect certain browser or device generated information, which may in some cases constitute personal data, including but not limited to:
                    <br />
                    <br />
                    <div class="sub_points">
                        • your domain;
                        • your IP address;
                        • your log in
                        • your acceptance of our privacy policy and terms of use;
                        • the date, time and duration of your visit;
                        • your browser type;
                        • your operating system;
                        • your page visits;
                        • information from third parties;
                        • other information about your computer or device;
                        • Internet traffic.
                    </div>
                    <br />
                    <br />
                    <span class="bold underline">
                        Information You Provide
                    </span>
                    <br />
                    <br /> 
                    In order to access or use certain portions of PAVE’s website, the PAVE digital platform, other PAVE portals, or otherwise engage in activities with us, you may be prompted to provide certain personal data to us in the following ways:
                    <br />
                    <br /> 
                    <div class="sub_points">
                        • by filling in forms (for example, a 'Contact us' form) on our website or at a trade show or anywhere else we conduct business;
                        • by downloading materials from our website;
                        • by subscribing to newsletters or other communications;
                        • by corresponding with us by phone, e-mail or otherwise using our contact detail
                        • by registering or participating in PAVE webcasts, webinars, seminars, classes, or conferences;
                        • by submitting questions or comments to us or requesting any other information from us; or
                        • by participating in a PAVE webinar or program.
                    </div>
                    <br />
                    <br /> 
                    Typically, the personal data you give us may include name, address, telephone number, email address, work history, and aspirations, and any personal details required to resolve any inquiries or complaints. Your personal data may also include your login name and passcode, and information that you supply, and upload to PAVE digital servers as part of how the platform is designed to be used.
                    <br />
                    <br /> 
                    Personal data may also be required to enter into an agreement with you or to perform an agreement with you (such as to provide services at your request), and failure to provide any information may result in our inability to provide requested services or products.
                    <br />
                    <br /> 
                    <span class="bold underline">
                        Third-Party Servers
                    </span>
                    <br />
                    <br />
                    PAVE relies on established and trustworthy third-party vendors located in the United States to provide much of the data storage and usage requirements for PAVE. The personal data you submit to us through our Contact Us page and email sign-ups on our website and other digital platforms are stored and processed at secure servers in compliance with GDPR. PAVE may change the third-party data processors it uses. PAVE and its technology team will perform due diligence on any new processors that it uses, and make sure that they meet the highest quality of privacy and security standards, including GDPR.
                    <br />
                    <br />
                    <span class="bold underline">
                        Information From Other Sources
                    </span>
                    <br />
                    <br />
                    We may receive information about you if you use any of the other websites and portals we operate, any other services we provide, seminars, webinars, or classes we teach, or from our business partners or data processors instructed to collect information on our behalf.
                    <br />
                    <br />
                    <span class="bold underline">
                        Cookies
                    </span>
                    <br />
                    <br />
                    Our website may use cookies. A cookie is a small file of letters and numbers that we put on your computer or smartphone if you agree. These cookies allow us to distinguish you from other users of our website, which helps us to provide you with a good experience when you browse our website or watch our videos, and also allows us to improve our site and platform. If you have any questions regarding our use of cookies, please contact our DPO at <span class="email">admin@joinpave.com</span>.
                    <br />
                    <br />
                    <span class="bold underline">
                        3. Use of Personal Data
                    </span> 
                    <br />
                    <br />
                    The following is an overview of our purposes for using your personal data. Additional details on how we process your personal data may be provided to you in a separate notice or agreement.
                    <br />
                    <br />
                    In accordance with GDPR, all of our processing and use of your personal data is justified by a "condition" for processing. In the majority of cases, processing will be justified on the basis that:
                    <br />
                    <br /> 
                    <div class="sub_points">
                        • you have consented to the processing;
                        • the processing is in our legitimate interests that are not overridden by your interests and fundamental rights; our legitimate interests are to use supplier, customer, client, student, program, website and digital platform user data to conduct and develop our business activities with them and with others while limiting the use of their personal data to purposes that support the conduct and development of our business;
                        • the processing is part of our educational and research programs;
                        • the processing is necessary to perform an agreement with you or take steps to enter into an agreement at your request, such as to fulfil an order, or to provide product information you have requested; or
                        • the processing is necessary for us to comply with a relevant legal obligation, such as keeping accounting, legal, and archival records.
                        We use the personal data we collect to conduct and develop our business with you and with others, as more fully described below:
                        • provide you with any services or products you have contracted for;
                        • administer and manage performance of purchase or sales agreements with our suppliers and customers;
                        • provide after-sales support;
                        • process, evaluate and complete certain transactions involving the website, the platform, and more generally transactions involving PAVE's products and services;
                        • operate, evaluate, maintain, improve and develop the website and PAVE digital platform (including by monitoring and analyzing trends, access to, and use of the website for advertising and marketing, and analyzing how our digital platform is functioning and delivering results);
                        • research and educational purposes;
                        • evaluate, improve and develop our products and services generally;
                        • customize our website and digital platforms to users' needs;
                        • engage you about events, promotions, the website, and PAVE's products and services;
                        • provide you with documentation or communications which you have requested or which we believe you would be interested in;
                        • correspond with users to resolve their queries or complaints;
                        • send you marketing communications, where it is lawful for us to do so;
                        • protect and ensure safety of the website, PAVE confidential and proprietary information, and PAVE employees;
                        • manage, protect against and investigate fraud, risk exposure, claims and other liabilities, including but not limited to violation of our agreements or laws or regulations; and
                        • share your personal data with third parties in connection with potential or actual sale of our company or any of our assets, in which case personal data held by us about our users may be one of the transferred assets.
                    </div>
                    <br />
                    <br />
                    PAVE respects and safeguards your personal data and will never sell or rent it to third parties.
                    <br />
                    <br />
                    <span class="bold underline">
                        4. Disclosure of Personal Data
                    </span>
                    <br />
                    <br /> 
                    PAVE will share personal data with its appointed third-party service providers (who will operate under our instructions) to assist us in providing information, products or services to you, in conducting and managing our business, or in managing and improving our products, services, the website, or our PAVE digital platform and the licensing of PAVE content. PAVE may share your personal data with these affiliates and third parties to perform services that the third parties have been engaged by PAVE to perform on PAVE's behalf, subject to appropriate contractual restrictions and security measures, or if we believe it is reasonably necessary to prevent harm or loss, or if we believe that the disclosure will further an investigation of suspected or actual illegal activities.
                    <br />
                    <br />
                    We are also subject to the investigatory and enforcement powers of the Federal Trade Commission (FTC). We may be required to disclose personal information that we handle in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.
                    <br />
                    <br />
                    If your personal data is transferred outside the EU to PAVE or its third-party service providers, we will take steps to ensure that your personal data receives the same level of protection as if it remained within the EU. The list of all the countries to which your personal data is transferred is set forth here: The United States. You hereby consent to the transfer of your personal data to the United States. You have a right to obtain details of the mechanism under which your personal data is transferred outside of the EU by contacting <span class="email">admin@joinpave.com</span>.
                    <br />
                    <br />
                    PAVE reserves the right to share any information that you provide which is not deemed personal data or is not otherwise subject to contractual restrictions. These restrictions on the disclosure of your personal data will not affect our use of your data as stated in Section 3, above.
                    <br />
                    <br />
                    <span class="bold underline">
                        5. Children
                    </span>
                    <br />
                    <br />
                    The website is not for use by children under the age of 16 years and PAVE does not knowingly collect, store, share or use the personal data of children under 16 years. If you are under the age of 16 years, please do not provide any personal data, even if prompted by the website to do so. If you are under the age of 16 years and you have provided personal data, please ask your parent(s) or guardian(s) to notify PAVE and PAVE will delete all such personal data.
                    <br />
                    <br />
                    <span class="bold underline">
                        6. Marketing Emails
                    </span>
                    <br />
                    <br />
                    Where lawful to do so, and subject to your consent where required, we may communicate with you by email to tell you about our products and services. If you wish to opt-out of receiving marketing communications, please use the 'unsubscribe' link provided in our emails, or otherwise contact us directly and we will stop sending you communications.
                    <br />
                    <br />
                    <span class="bold underline">
                        7. Security
                    </span> 
                    <br />
                    <br />
                    PAVE strives to safeguard and protect your personal data from unauthorized access, improper use or disclosure, unauthorized modification or unlawful destruction or accidental loss, and PAVE utilizes and maintains certain reasonable processes, systems, and technologies to do so. However, you acknowledge that no transmission over the Internet is completely secure or error-free, and that these processes, systems, and technologies utilized and maintained by PAVE are subject to compromise. Accordingly, we cannot be held responsible for unauthorized or unintended access that is beyond our reasonable control.
                    <br />
                    <br />
                    <span class="bold underline">
                        8. Retention of Your Personal Data
                    </span>
                    <br />
                    <br /> 
                    We apply a general rule of keeping personal data only for as long as required to fulfil the purposes for which it was collected. We also retain your personal data for a period of time corresponding to a statute of limitations, for example to maintain an accurate record of your dealings with us including licenses that you have entered with us.
                    <br />
                    <br />
                    However, in some circumstances we may retain personal data for other periods of time, for instance where we are required to do so in accordance with legal, tax and accounting requirements, or if required to do so by a legal process, legal authority, or other governmental entity having authority to make the request, for so long as required.
                    <br />
                    <br />
                    At any time, you can request our DPO to permanently delete your data not subject to legal or other requirements. You can also always unsubscribe to our emails, in which case we will delete your personal information other than licenses and other matters that survive your request for deletion.
                    <br />
                    <br />
                    <span class="bold underline">
                        9. External Links
                    </span>
                    <br />
                    <br /> 
                    The website may contain links to third party sites. Since PAVE does not control nor is responsible for the privacy practices of those websites, we encourage you to review the privacy policies of these third-party sites. This policy applies solely to personal data collected by our website or in the course of our business activities.
                    <br />
                    <br />
                    <span class="bold underline">
                        10. Your Rights
                    </span>
                    <br />
                    <br />
                    Under applicable law, you may have the following rights:
                    <br />
                    <br />
                    <div class="sub_points">
                        • Request access to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.
                        • Request correction of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.
                        • Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.
                        • Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
                        • Request restriction of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data's accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.
                        • Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.
                        • Withdraw consent at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.
                    </div>  
                    <br />
                    <br />
                    <span class="bold underline">
                        11. Dispute Resolution and Other Legal Matters
                    </span>
                    <br />
                    <br />
                    You may also have the right to lodge a complaint with the supervisory authority of your habitual residence, place of work or place of alleged infringement, if you consider that the processing of your personal data infringes applicable law and PAVE is subject to the jurisdiction of such supervisory authority.
                    <br />
                    <br />
                    Further, PAVE commits to promptly resolve complaints about privacy and our collection or use of personal information. Individuals with questions or concerns about the use of their personal data should contact us at: <span class="link">admin@joinpave.com</span> and identify the company or other organization with whom they are affiliated or for whom their data was collected, if collection was for a PAVE customer. 
                    <br />
                    <br />
                    If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, we will have the matter submitted to our US-based third-party dispute resolution provider, JAMS, Inc. in New York City. If GDPR or other applicable laws exclusively apply to the dispute and require us to pay for the arbitration, we shall do so.
                    <br />
                    <br />
                    Governing Law. By choosing to visit our website, subscribe to our career guidance services, view or download content, or provide information to us, you agree that any dispute over privacy or the terms contained in this Privacy Policy will be governed by the laws of the State of New York and the United States of America. You also agree to abide by any limitation on damages contained in our Terms of Use, license agreements, or other agreements that we have with you.
                    <br />
                    <br />
                    <span class="bold underline">
                        12. Changes to this Policy
                    </span>
                    <br />
                    <br />
                    Any changes or updates we may make to this policy will be posted as a revised policy and noted at the top of this document. You are responsible for checking back to review any revised policy. PAVE reserves the right to change this Policy at any time. 
                </span>
            </div>`,
    closeBtnTitle: 'Accept',
  },
  terms: {
    isAgreement: false,
    title: 'Terms of Use – PAVE Services and Website',
    date: 'November 1, 2021',
    description: `<div><span class="reqular__text">
        This Terms of Use (the “Terms” or “Agreement”) constitutes a binding agreement between you (“you” or “client”) and Arable Ventures LLC doing business as Pave Careers (“PAVE”, “we”, "us", and "our") with respect to your use of PAVE’s website (“Site”) and our related mobile application (the “App”), including all information, products and services promoted therein. PAVE provides the Site and App as a unique centralized network for individuals to find the career options that fit their skills, passions, and working needs. PAVE uses technology, expertise, research, and proprietary materials to help people align passion and purpose, navigating the ocean of careers through pivots, new starts, and transitions. This includes providing tailored career options, role specific deep dives, insider tips to interviews, access to industry experts, and career insights for the future.
        <br />
        <br />
    </span> 
    <span class="reqular__text">
    The Site and App may also be provided as a portal to review, select, and purchase products and services from PAVE or its affiliates. The Site and App offer an on-line technology platform and access to and use of PAVE’s programs, information, services, and products, including an extensive network related to the Site and App.
        <br />
        <br />
    </span> 
    <span class="reqular__text">
    PAVE may also provide clients and others with an on-line platform via a PAVE account. This Site and the App provide you with access to and use of PAVE programs, information, services, and products, including the opportunity to purchase certain products or other services (collectively, with the App, the “Services”). Before you continue using the Site, the Services, or any of the PAVE products or services, it is important that you review the Terms.
        <br />
        <br /> 1. 
        <span class="bold underline">
        Ownership of Site and Services.
        </span> 
        The Services and the Site are owned and operated by PAVE. Except as permitted under this Agreement, copying, storing, displaying, reproducing, distributing, selling, licensing, sublicensing, publishing or creating derivative works of any programs, content or materials on the Site or available through the Services are expressly prohibited without the prior written permission from PAVE. All programs, images, media, and content created by PAVE (“Content”) are the sole property of PAVE. PAVE retains ownership and licensing rights to all Content.
        <br />
        <br /> 2. 
        <span class="bold underline">
            Acceptance of Terms.
        </span> 
        By entering and using the Site or Services, you accept these Terms and agree to be bound by them. Acceptance of these Terms creates a binding contract between you and PAVE that you will use the Services only in a manner consistent with these Terms. Your use of the Site and the Services is entirely conditioned on and subject to your compliance with these Terms. If you do not agree with these Terms, do not access or use the Site or Services. Specific pages on the Site or features of the Services may set out additional terms and conditions which, in addition to these Terms, shall be binding upon you with respect to those pages or features. In the case of inconsistencies between these Terms and information included in other materials which are not agreements (for example, promotional materials and mailers), these Terms will control with respect to the Site and Services. We require that you be 18 years or older to use the Site or Services; if you are under 18 years old, you should not access or use the Site or Services.
        <br />
        <br /> ‍ 3. 
        <span class="bold underline">
            Privacy Policy.
        </span> 
        The PAVE Privacy Policy is located here. The terms and conditions of our Privacy Policy are incorporated into and considered part of these Terms as if fully set forth herein. We take your privacy seriously, and our Privacy Policy complies with all applicable laws.
        <br />
        <br /> 4. 
        <span class="bold underline">
            Patent Information.
        </span> 
        Inventions, features, and services within PAVE products may be the subject matter of future patents. Such subject matter is confidential and protected by the Uniform Trade Secrets Act. 
        <br />
        <br /> ‍ 5. 
        <span class="bold underline">
            Trademark Information.
        </span> 
        The following are some of the trademarks of PAVE: PAVE™, ARABLE™, and ARABLE VENTURES™. PAVE’s trademarks may be registered in the United States and other countries. PAVE reserves all rights with respect to its trademarks, service marks, logos, trade names, trade dress and other indicia (“Marks”). PAVE does not routinely accept or review specific requests for guidance on the use of its Marks. Any unauthorized use of any PAVE-owned Mark, or any use of a mark that is confusingly similar to, or likely to cause confusion with, a PAVE-owned Mark, would constitute infringement of PAVE’s exclusive trademark rights. All trademarks, service marks, and trade names are proprietary to PAVE and may not be exploited without the express permission of the owner of such marks. You may not utilize framing techniques to enclose any trademark, logo, or other materials on the Site and Services or use any meta tags or any other “hidden text” utilizing PAVE’s name or trademarks. <br />
        <br />
        All brand, product, and service names used on the Site or in these Services which identify PAVE or third parties and their products and services are proprietary marks of PAVE and/or the relevant third parties. Nothing in the Terms shall be deemed to confer on any person any license or right on the part of PAVE or any third party with respect to any such image, logo or name.
   
        <br />
        <br /> 6. 
        <span class="bold underline">
            Copyright Information.
        </span> 
        PAVE’s products, services, videos, content, and related materials (collectively, “Offerings”) are owned by PAVE and/or its licensors, and all rights in such Offerings are reserved by PAVE and/or its licensors. For information regarding permission to use screen displays, media, and other content, please email a request for PAVE’s Permissions Policy to 
        <span class="email">
            admin@joinpave.com
        </span>
        . In your request, please state the reason(s) why you are requesting the information.
        <br />
        <br /> ‍ 7. 
        <span class="bold underline">
            Notices of Infringement.
        </span> 
        Persons who believe that their copyrights have been infringed by PAVE’s Site or Services or otherwise may notify PAVE’s Designated Copyright Agent. All notices must comply with the requirements of U.S. Copyright Act 17 U.S.C. § 512(c)(3) and regulations promulgated thereunder, each as amended. Such notices not complying with these requirements will be returned unprocessed. No other notices or inquiries of any type, including requests for information, purchase or technical support questions, notices of infringement of third-party trademark or patent rights, employment inquiries, press inquiries, or investor inquiries, will be read or answered under this Agent and Agent Address. 
        <br />
        <br />
        Designated Copyright Agent and Address of Agent:
        <br />
        <br />
        Attn: David M. Slater, Esq. <br />
        Slater Law
        <br /> 240 West 73rd Street, Suite 701
        <br /> New York, NY 10023
        <br /> Phone: 212-489-6500
        <br /> Email: 
        <span class="email">
            mail@davidmslater.com
        </span>
        . ‍
        <br />
        <br /> 8. 
        <span class="bold underline">
            Licensing and Support.
        </span> 
        For licensing and legal inquiries, please refer
        such inquiries to 
        <span class="email">
            admin@joinpave.com
        </span>
        . <br />
        <br />
        9. 
        <span class="bold underline">
            Amendment of Terms; Termination.
        </span>
        <div class="sub_points">
            <br />
            <br /> a. We reserve the right to change these Terms at any time. If we make any material changes to these Terms, we will post the new Terms on the Site and Services and update the effective date set forth above.‍
            <br />
            <br /> b. This Agreement and the Services provided hereunder shall continue until terminated as provided herein. You agree that PAVE may at any time without notice to you: terminate this Agreement; suspend or terminate the Services or Site; or terminate your account and delete any content stored in your account; and you agree that PAVE may take the foregoing actions for any reason, including if, in PAVE’s sole discretion, you fail to comply with any of these Terms or if a competent regulatory authority requires us to do so, or we may do so without cause.
            <br />
            <br /> c. Upon termination of this Agreement or termination or suspension of the Services, all rights and licenses granted by PAVE to you hereunder shall immediately terminate; however, termination or suspension shall not affect any services which have been agreed to and arranged separate and apart from these Terms of Use.
        </div>
        <br />
        <br />
        10. 
        <span class="bold underline">
        Personal Use of Website and Services.
        </span> 
        PAVE grants you a non-exclusive, non-transferable, limited right to access, view, use, display and print information on this Site and the Services, including all the materials provided hereon, for your personal, informational, noncommercial use only. You may not copy, modify, reproduce, create derivative works from, distribute, transmit, license, or sell any content or any portion of this Site or the Services without the prior express written consent of PAVE. PAVE will sometimes grant permission for such use so long as PAVE is properly acknowledged, but the prior express written consent of PAVE is required. ‍ ‍<br />
        <br />
        11. 
        <span class="bold underline">
            Password.
        </span> 
        If we provide a function on the Site or Services which permits you to register as a PAVE user, you may be asked to provide a password. If so, you are the only person permitted to use this password to access and use the Services. As you will be responsible for all activities that occur under your password, you are responsible for maintaining the confidentiality of your passwords. You must notify PAVE immediately of any unauthorized use of your password or if you believe that your password is no longer confidential. We reserve the right to require you to alter your password if we believe that your password is no longer secure. We may rely on the authority of anyone using your password, and in no event and under no circumstances shall we be liable for any compromise of the confidentiality of your password or any unauthorized access using your password. ‍ ‍<br />
        <br />
        12. 
        <span class="bold underline">
            Information Provided by You.
        </span> 
        You agree that the information that you provide to us on registration, if any, and at all other times, including all information you provide when using the Services, will be true, accurate, current and complete. You also agree that you will ensure that this information is always kept accurate and up to date. This is especially important with respect to your email address, since that is the primary way in which we will communicate with you about your account, your correspondence, and your orders, if applicable. You agree that PAVE is free to use any such information without further compensation, acknowledgement, or payment to you for any purpose whatsoever including, but not limited to, developing, modifying, manufacturing, and m ‍<br />

        <br /> 13. 
        <span class="bold underline">
            Prohibited Conduct.
        </span> 
        You agree that you will not:
        <div className="sub_points">
            <br />
            <br />
            a. use the Site or Services:
            <br />
            <br />
            <div class="sub_points">
                i. for any unlawful purposes;
                <br />
                <br />
                ii. to create, produce, or distribute any material that is obscene, offensive, blasphemous, pornographic, unlawful, threatening, menacing, malicious, fraudulent, misleading, abusive, harmful to any person or property, false, an invasion of privacy or publicity rights, defamatory, libelous, vulgar, illegal or otherwise objectionable;
                <br />
                <br />
                iii. to create, produce, or distribute any material that you do not have a right to transmit or communicate under any contractual or fiduciary relationship or which infringes any copyright, trademark, patent or other intellectual property right or any moral right of any party including, but not limited to PAVE; or
                <br />
                <br />
                iv. to harm minors in any way, including, but not limited to, content that violates child pornography laws, child sexual exploitation laws and laws prohibiting the depiction of minors engaged in sexual conduct;
            </div>
            <br />
            <br />
            b. interfere with, harm or disrupt (or attempt to interfere with or disrupt) the Site or Services or servers or networks connected to the Site or Services, or disobey any laws, requirements, procedures, policies or regulations governing access to or use of the Site or Services or networks connected to the Site or Services;
            <br />
            <br />
            c. access or attempt to access any information, documents or material that you are not authorized to access; or
            <br />
            <br />
            d. use any robot, spider, or other such programmatic or automatic device to obtain information from the Site or Services or otherwise monitor or copy any portion of the Site or Services;
        </div>
        <br />
        <br /> 14. 
        <span class="bold underline">
            Disclaimers
        </span>
        <br />
        <br /> THE SERVICES, THE SITE, THE PAVE CONTENT, AND ALL OTHER PAVE PRODUCTS AND SERVICES ARE PROVIDED BY US “AS IS” WITHOUT ANY OTHER WARRANTIES WHATSOEVER. ALL OTHER EXPRESS, IMPLIED, AND STATUTORY WARRANTIES, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT, ARE EXPRESSLY DISCLAIMED. TO THE FULLEST EXTENT PERMITTED BY LAW, PAVE DISCLAIMS ANY WARRANTIES FOR THE SECURITY, RELIABILITY, TIMELINESS, AND PERFORMANCE OF ITS SERVICES. THIS WARRANTY GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE.
        <br />
        <br />
        THE PAVE CONTENT AND PROGRAMS, AND ALL OTHER INFORMATION ON THIS SITE AND SERVICES ARE FOR INFORMATIONAL PURPOSES ONLY AND ARE NOT INTENDED AS A SUBSTITUTE FOR PROFESSIONAL CAREER GUIDANCE OR THE SERVICES OF AN EMPLOYMENT AGENT. YOUR USE OF THE SITE OR SERVICES ARE FOR INFORMATION PURPOSES ONLY AND DO NOT CREATE AN AGENT-CLIENT RELATIONSHIP WITH PAVE OR ITS ADVISORS. PAVE DOES NOT GUARANTEE THE ACCURACY OF ANY OF THE CONTENT ON THIS SITE OR SERVICES, AND YOUR RELIANCE OR USE OF THE CONTENT IS AT YOUR OWN SOLE RISK.
        <br />
        <br />
        Submitted Content Disclaimer. – YOU MAY BE EXPOSED TO SUBMITTED CONTENT AND OTHER THIRD-PARTY CONTENT (“SUBMITTED CONTENT”) THAT IS INACCURATE, OFFENSIVE, INDECENT, OR OTHERWISE OBJECTIONABLE. PAVE DOES NOT ENDORSE ANY SUBMITTED CONTENT OR ANY OPINION, RECOMMENDATION OR ADVICE EXPRESSED THEREIN. UNDER NO CIRCUMSTANCES WILL PAVE BE LIABLE IN ANY WAY FOR OR IN CONNECTION WITH THE SUBMITTED CONTENT, INCLUDING, BUT NOT LIMITED TO, LIABILITY FOR ANY INACCURACIES, ERRORS, OR OMISSIONS IN ANY SUBMITTED CONTENT, FOR ANY INTELLECTUAL PROPERTY INFRINGEMENT WITH REGARD TO ANY SUBMITTED CONTENT, OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY SUBMITTED CONTENT. YOU BEAR ALL RISKS ASSOCIATED WITH THE USE OF ANY SUBMITTED CONTENT, INCLUDING ANY RELIANCE ON THE ACCURACY, COMPLETENESS, USEFULNESS, LEGALITY OF SUCH CONTENT OR ACCURATE IDENTIFICATION OF THE AUTHOR (AND/ OR THEIR AFFILIATION WITH ANY THIRD-PARTY).
        <br />
        <br />
        Submissions. – PAVE IS PLEASED TO HEAR FROM YOU AND WELCOMES YOUR COMMENTS REGARDING PAVE’S PROGRAMS AND SERVICES. IF YOU SEND COMMENTS, SUGGESTIONS, IDEAS, FEEDBACK, NOTES OR CONCEPTS OR OTHER MATERIALS TO PAVE (COLLECTIVELY, “SUBMISSIONS”), THEY SHALL BE DEEMED, AND SHALL REMAIN, THE PROPERTY OF PAVE. PAVE IS AND SHALL BE UNDER NO OBLIGATION TO: (I) MAINTAIN ANY OF YOUR SUBMISSIONS IN CONFIDENCE; (II) PAY TO YOU ANY COMPENSATION FOR ANY SUBMISSION; OR (III) RESPOND TO ANY OF YOUR SUBMISSIONS.
        <br />
        <br />
        15. 
        <span class="bold underline">
            Limitations of Liability and Damages.
        </span>
        <br />
        <br />
        <div class="sub_points">
            a. PAVE, ITS AFFILIATES, EMPLOYEES, OFFICERS, REPRESENTATIVES, SERVICE PROVIDERS AND ITS SUPPLIERS, SHALL NOT UNDER ANY CIRCUMSTANCES, OR ANY LEGAL THEORY, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, TORTS, OR STRICT LIABILITY BE RESPONSIBLE FOR NOR BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR OTHER DAMAGES OR LOSSES ARISING OUT OF OR RELATING IN ANY WAY TO THE SITE, THE SERVICES, THE PRODUCTS SOLD, THE PAVE PROGRAMS, ANY THIRD-PARTY MATERIALS OR OTHER INFORMATION OR INFORMATION CONTAINED WITHIN THE SITE OR SERVICES, INCLUDING, BUT NOT LIMITED TO, YOUR DOWNLOADING OR UPLOADING OF ANY MATERIAL OR DOCUMENTS.
            <br />
            <br />
            b. PAID PRODUCT OR SERVICE: SOLE REMEDY. YOUR SOLE AND EXCLUSIVE REMEDY FOR FAILURE TO DELIVER A PAID PRODUCT OR SERVICE OR BREACH OF ANY OBLIGATION WITH RESPECT TO A PAID PRODUCT OR SERVICE SHALL BE A REFUND OF THE AMOUNT PAID FOR THE SPECIFIC PAID PRODUCT OR SERVICE AT ISSUE AND IN NO EVENT SHALL PAVE’S LIABILITY FOR FAILURE TO DELIVER SUCH ITEM EXCEED SUCH AMOUNT.
            <br />
            <br />
            c. TO THE MAXIMUM EXTENT ALLOWED BY LAW, THE DISCLAIMERS, WAIVERS AND LIMITATIONS CONTAINED IN THE TERMS, INCLUDING WITHOUT LIMITATION SECTIONS 14 AND 15, SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. APPLICABLE LAW MAY NOT ALLOW THE LIMITATIONS OF LIABILITY SET FORTH IN THIS SECTION 15, SO THIS LIMITATION OF LIABILITY MAY NOT APPLY TO YOU. IF THIS LIMITATION ON LIABILITY IS FOUND TO BE INVALID OR UNENFORCEABLE FOR ANY REASON, THEN THE AGGREGATE LIABILITY OF PAVE UNDER SUCH CIRCUMSTANCES FOR LIABILITIES THAT OTHERWISE WOULD HAVE BEEN LIMITED SHALL NOT EXCEED THE GREATER OF ONE HUNDRED DOLLARS ($100.00) OR THE LOWEST AMOUNT ALLOWED BY LAW.
        </div>
        <br />
        <br />
        16. 
        <span class="bold underline">
            Indemnification
        </span>
        . You agree to indemnify, defend, and hold harmless PAVE, its officers, directors, employees, agents, licensors, suppliers and any third-party service providers to the Site and/or Services from and against all losses, expenses, damages and costs, including reasonable attorneys’ fees, arising out of (i) your access to, use or misuse of the Site or Services; (ii) your Submitted Content; (iii) your violation of the rights of any other person or entity; (iv) any unauthorized use of your Account; (v) any breach of these Terms or of any representation, warranty, or covenant you make herein; (vi) your use of the Services or purchase of Products; (vii) your participation in any PAVE program; (viii) your use of third-party content; and (ix) your grant of rights and licenses to PAVE pursuant to these Terms.
        <br />
        <br />
        You consent to receiving certain electronic communications from PAVE as further described in PAVE’s Privacy Policy. Any notices, agreements, disclosures, or other communications that PAVE sends to you electronically will satisfy any legal communication requirements, including that those communications be in writing.
        <br />
        <br />
        17. 
        <span class="bold underline">
            Paid Products and Services:
            Prices/Cancellation. 
        </span>
        The rate for any Paid Products or Services, if any, may be set forth in a PAVE proposal, order form, or other document. All rates are generally stated in U.S. dollars and are valid until altered by us. If a Paid Product or Service is mistakenly listed at an incorrect rate, PAVE reserves the right to refuse or cancel any orders placed for the Paid Product or Service listed at the incorrect rate. PAVE reserves the right to refuse or cancel any such orders whether the order has been confirmed and your credit card charged. If your credit card has already been charged for the purchase and your order is cancelled, PAVE shall issue a credit to your credit card account in the amount of the incorrect rate. Risk of loss and title to any products that you may purchase from us passes to you upon our delivery of such products to the carrier.
        <br />
        <br />
        18. 
        <span class="bold underline">General.</span>
        <br />
        <br />
        <div class="sub_points">
            a. Entire Agreement. These Terms, including the Privacy Policy incorporated by reference, constitute the entire agreement between PAVE and you with respect to your use of the Site and the Services and the purchase of any Products or Services. PAVE’s failure to enforce any right or provision in these Terms shall not constitute a waiver of such right or provision. If a court should find that one or more rights or provisions contained in these Terms are invalid, you agree that the remainder of the Terms shall be enforceable.
            <br />
            <br />
            b. Choice of Law and Venue. This Agreement shall be governed and interpreted in accordance with the substantive law of the State of New York without regard to its conflict of law provisions. The parties agree that any litigation arising out of this Agreement or relating to the operation of the Site or Services or content appearing therein, shall be brought only in the state or federal courts located in New York, New York. The parties irrevocably submit to the exclusive jurisdiction of such courts and hereby waive any challenge to the propriety or convenience of jurisdiction or venue of such courts. Without limiting the effect of any disclaimer contained herein, any cause of action you may have with respect to your use of the Site or Services must be commenced within one (1) year after the claim or cause of action arises.
            <br />
            <br />
            c. Assignment. You may not assign the Agreement and/or any of the rights or obligations contained herein. PAVE may freely assign this Agreement and the rights and obligations contained herein.
            <br />
            <br />
            d. Notices. PAVE may notify you of certain events relating to the Site or Services, or your use of the Site or Services. We may choose to notify you of these matters via a general notice on the Site, electronic mail to your e-mail address on record in PAVE’s account information, or by written communication sent by U.S. mail, postage pre-paid, to your address on record in PAVE’s account information. If we notify you by mail, mail notice shall be deemed to have been given upon the expiration of three days after mailing; if we send you notice by email, such notice shall be deemed to have been given upon the expiration of twenty-four (24) hours after sending. You may give notice to PAVE (such notice shall be deemed given when received by PAVE) at any time only by letter delivered by nationally recognized overnight delivery service or first-class postage-prepaid mail to PAVE at the following address: Attn: General Counsel’s Office, Arable Ventures LLC DBA Pave Careers, 244 Madison Ave, Suite
              #173, New York, NY 10016.
            <br />
            <br />
            e. Miscellaneous
        </div>
        <br />
        <br />
        If any provision of these Terms shall be unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions. No joint venture, partnership, employment, or agency relationship exists between you and PAVE as a result of these Terms or use of the Site or Services. All provisions which logically should survive these Terms shall survive the expiration or termination of these Terms. No waiver of any provision or any right granted hereunder shall be effective unless set forth in a written instrument signed by the waiving party. No waiver by either party of any breach or default hereunder shall be deemed a waiver of any subsequent breach or default. This is the entire agreement between you and PAVE relating to the subject matter herein and supersedes all previous communications, representations, understandings and agreements, either oral or written, between the parties with respect to said subject matter. 
        <br />
        <br />
        If you have any comments or questions about these Terms, the Site or our Services, please contact us by email at 
        <span class="email">
            admin@joinpave.com
        </span>
        .
    </span></div>`,
    closeBtnTitle: 'Accept',
  },
};

export const KEYWORDS_ACTIVITIES = {
  'Accounting + Audit': [
    'resolve roadblocks',
    'growth initiatives',
    'inventory accounting',
    'advise senior management',
    'center of excellence',
    'Jira\r\n ',
    'environmentalist',
    'experience building or managing product',
    'scrum',
    'research institutes',
  ],
  Admin: [
    'mitigation or regulatory & compliance risk',
    'leveraging data to identify opportunities',
    'hardworking',
    'securities council',
    'inventory accounting',
    'leveraging technology to advance business outcomes',
    'marketing entertainment',
    'obsession',
    'customization',
    'irregularities',
  ],
  'Brand + Design': [
    'screen applicants',
    'shape requirements',
    'aging of invoices',
    'interactive learning',
    'trailblazer',
    'synthesis',
    'data operations',
    'insights and measurement',
    'Jira\r\n ',
    'complex operations',
  ],
  'Business Development + Sales + Sales Management': [
    'create & manage project plans',
    'stage gates',
    'tracking & implementation',
    'executive presentation skills',
    'user insights',
    'distributed workforce',
    'verbal)',
    'creating brand partnerships across content',
    'progressive HR organization',
    'partner with senior executives',
  ],
  'Client Experience + Digital Transformation': [
    'adwords',
    'alternative investment diligence experience',
    'aging of invoices',
    'interactive learning',
    'handling payroll',
    'data & analytics',
    'water quality',
    'Jira\r\n ',
    'employer of choice',
    'green building',
  ],
  'Corporate Finance + Finance Management': [
    'timely follow up',
    'energy manager',
    'research approach',
    'venture community',
    'annual budget cycle',
    'support fundraising efforts',
    'water quality',
    'progressive HR organization',
    'green initiatives',
    'talent identification & development',
  ],
  'Data + Analytics': [
    'client base',
    'treasurer/treasury',
    'interoffice',
    'conversion optimization',
    'turn user feedback into product requirements/enhancement',
    'vetting',
    'organic & inorganic growth',
    'brand tracking',
    'support growth',
    'launch/exit paths',
  ],
  'Diversity, Equity & Inclusion': [
    'knowledge in operations & supply chain',
    'daily operations',
    'leveraging data to identify opportunities',
    'insights and measurement',
    'monitor competitive landscape',
    'endowments',
    'support fundraising efforts',
    'sell side earnings models. annual report',
    'lead existing operations',
    'data drive metrics',
  ],
  Entrepreneurship: [
    'onboarding',
    'create & manage project plans',
    'digital platforms',
    'hyperscaling',
    'deepen share of wallet',
    'executive presentation skills',
    'audit metrics',
    'white space opps',
    'trailblazer',
    'internal reporting',
  ],
  'General Management + Leadership': [
    'water quality',
    'venture community',
    'potential acquisitions & partnerships',
    'progressive HR organization',
    'aging of invoices',
    'onboarding',
    'hyperscaling',
    'CBInsights',
    'hardworking',
    'energy manager',
  ],
  'Human Resources + Human Capital': [
    'not afraid to fail',
    'define & execute on a vision for the marketing/analytics team',
    'corporate venture capital',
    '3rd party tools & insights',
    'achieve organizational KPIs',
    'key initiatives & KPIs',
    'deal analysis',
    'marketing performance management & indicators',
    'manage regional accounts',
    'up/down/across',
  ],
  'IT + Engineering ': [
    'data & analytics',
    'complex operations',
    'adwords',
    'timely delivery',
    'marketing entertainment',
    'interactive learning',
    'green building',
    'water quality',
    'alternative investment diligence experience',
    'fundamental analysis',
  ],
  'Innovation + Innovation Management': [
    'interactive learning',
    'measure & track key strategic initiatives',
    'complex operations',
    'sales development & partnership',
    'green initiatives',
    'strategic design',
    'progressive HR organization',
    'aging of invoices',
    'digital learning',
    'green building',
  ],
  'Learning & Development': [
    'potential acquisitions & partnerships',
    'endowments',
    'synthesis',
    'mitigation or regulatory & compliance risk',
    'data & analytics',
    'operating & execution framework',
    'food security',
    'execution & tracking of KPIs',
    'leveraging technology to advance business outcomes',
    'interactive learning',
  ],
  'Legal + Compliance + Risk': [
    'launch campaign',
    'finance',
    'analyzing data & performance trends',
    'enlighten/delight',
    'sales development & partnership',
    'leveraging data to identify opportunities',
    'analytical skilld',
    'monitor competitive landscape',
    'knowledge in operations & supply chain',
    'growth initiatives',
  ],
  'M&A + Corp Dev': [
    'tracking & implementation',
    'green initiatives',
    'research approach',
    'conduct annual plans',
    'executive presentation skills',
    'new deal origination',
    'create & manage project plans',
    'green building',
    'water quality',
    'verbal & written',
  ],
  'Marketing + Communications': [
    'aging of invoices',
    'digital learning',
    'data operations',
    'collateral',
    'progressive experience advancing diversity',
    'partner with engineering',
    'diverse perspectives',
    'distributed workforce',
    'work in close collaboration with the CEO',
    'user insights',
  ],
  'Operations Management': [
    'green building',
    'maintain vendor list',
    'water quality',
    'new deal origination',
    'executive presentation skills',
    'venture community',
    'onboarding',
    'versatile',
    'energy efficient',
    'own budget',
  ],
  'Partnerships + Relationship Management + Customer Success + Customer Service': [
    'synthesis',
    'screen applicants',
    'insights and measurement',
    'aging of invoices',
    'complex operations',
    'interactive learning',
    'collateral',
    'endowments',
    'data operations',
    'shape requirements',
  ],
  'Product Management + Product Development': [
    'organize team events',
    'scale & efficiency',
    'fundamental analysis',
    'market entry',
    'enterprise architecture',
    'oversee projects',
    'trusted advisor',
    'produce award-winning creative advertising',
    'adjust schedules & plans as necessary to meet project goals',
    'uncover root causes',
  ],
  'Project Management + Program Management': [
    'Kanban',
    'energy efficient',
    'handling payroll',
    'efficiency & effectiveness of',
    'proactive & reactive media strategy',
    'email campaigns',
    'maintain vendor list',
    'energy manager',
    'client loyalty/satisfaction/retention',
    'green building',
  ],
  'Public Relations + Investor Relations': [
    'insights and measurement',
    'Jira\r\n ',
    'progressive HR organization',
    'adwords',
    'potential acquisitions & partnerships',
    'enlighten/delight',
    'tracking & implementation',
    'shape requirements',
    'partner with engineering',
    'support fundraising efforts',
  ],
  Research: [
    'creating brand partnerships across content',
    'user insights',
    'uncover root causes',
    'trusted advisor',
    'data operations',
    'brand measurement',
    'digital learning',
    'green building',
    'employer of choice',
    'financial models & ad hoc analysis',
  ],
  'Strategy + Management Consulting': [
    'up to date knowledge',
    'expand sales channel',
    'product estimates',
    'financial advisor',
    'meet budget/cost',
    'internal audit function',
    'partner with cross function',
    'water supply',
    'multi initiatives',
    'negotiating agreements',
  ],
  Sustainability: [
    'lead gen',
    'litigation',
    'investing communications',
    'provides direction to financial analysts',
    'drive strategy',
    'lead cross functional teams across all aspects of transactions',
    'humble leader',
    'resource allocation',
    'executive presence',
    'attrition',
  ],
  'Venture Capital + Private Equity': [
    'work with product teams',
    'relationship manager',
    'identity process enhancement opportunities',
    'creative agency',
    'can do attitude',
    'proactively identify',
    'economic development',
    'new accounts',
    'fast paced',
    'backlog',
  ],
  'Wealth Management + Investment Management + Asset Management': [
    'media optimization',
    'supply chain',
    'hardworking',
    'identiy critical issues',
    'sales pitches & presentations',
    'first line communications',
    'client success',
    'strategic goals',
    'improve/challenge status quo',
    'regional markets',
  ],
};
