import {
  setAvailabilitiesAddingState,
  setAvailabilitiesAndStopLoading,
  setAvailabilitiesDeletingState,
  setAvailabilitiesEditingState,
  setAvailabilitiesLoadingState,
} from 'expert/redux/availabilities/availabilitiesActions';
import { handleSetExpertProfileStepRequest } from 'expert/sagas/experts/expertSagas';
import DeleteAvailabilityProps from 'expert/sagas/interfaces/deleteAvailabilityProps';
import EditAvailabilityProps from 'expert/sagas/interfaces/editAvailabilityProps';
import EditScheduleProps from 'expert/sagas/interfaces/editScheduleProps';
import editAvailabilityPropsToParams from 'expert/sagas/utils/editAvailabilityPropsToParams';
import editSchedulePropsToParams from 'expert/sagas/utils/editSchedulePropsToParams';
import resToAvailabilities from 'expert/sagas/utils/resToAvailabilities';
import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { processRequest } from 'services/Api';
import MESSAGES from 'expert/common/configs/messages';
import AddAvailabilityProps from 'expert/sagas/interfaces/addAvailabilityProps';
import addAvailabilityPropsToQuery from 'expert/sagas/utils/addAvailabilityPropsToQuery';
import schedulePaths from './schedulePaths';
import FetchAvailabilitiesProps from 'expert/sagas/interfaces/fetchAvailabilitiesProps';
import { scheduleSagaActionTypes } from './scheduleSagasActions';
import shouldHandleError from 'expert/sagas/utils/shouldHandleError';

const handleFetchAvailabilities = function* ({ payload }: { payload: FetchAvailabilitiesProps }) {
  try {
    yield put(setAvailabilitiesLoadingState(true));
    const { data } = yield call(
      processRequest,
      `${schedulePaths.fetchAvailabilities}?start=${payload.start}&end=${payload.end}`,
    );

    yield put(setAvailabilitiesAndStopLoading(data.map(resToAvailabilities)));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.fetchAvailabilities);
  } finally {
    yield put(setAvailabilitiesLoadingState(false));
  }
};

const handleAddAvailability = function* ({ payload }: { payload: AddAvailabilityProps }) {
  try {
    yield put(setAvailabilitiesAddingState(true));

    yield call(processRequest, schedulePaths.addAvailability, 'POST', addAvailabilityPropsToQuery(payload));

    toast.success(MESSAGES.success.addAvailability);
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.addAvailability);
  } finally {
    yield put(setAvailabilitiesAddingState(false));
  }
};

const handleEditAvailability = function* ({ payload }: { payload: EditAvailabilityProps }) {
  try {
    yield put(setAvailabilitiesEditingState(true));

    yield call(
      processRequest,
      `${schedulePaths.editAvailability}${payload.eventId}`,
      'PATCH',
      editAvailabilityPropsToParams(payload),
    );

    toast.success(MESSAGES.success.editAvailability);
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.editAvailability);
  } finally {
    yield put(setAvailabilitiesEditingState(false));
  }
};

const handleEditSchedule = function* ({ payload }: { payload: EditScheduleProps }) {
  try {
    yield put(setAvailabilitiesEditingState(true));

    yield call(
      processRequest,
      `${schedulePaths.editSchedule}${payload.scheduleId}`,
      'PUT',
      editSchedulePropsToParams(payload),
    );

    toast.success(MESSAGES.success.editAvailability);
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.editAvailability);
  } finally {
    yield put(setAvailabilitiesEditingState(false));
  }
};

const handleDeleteAvailability = function* ({ payload }: { payload: DeleteAvailabilityProps }) {
  try {
    yield put(setAvailabilitiesDeletingState(true));

    yield call(processRequest, `${schedulePaths.deleteAvailability}${payload.eventId}`, 'DELETE', {
      modify: payload.editVariant,
    });

    toast.success(MESSAGES.success.deleteAvailability);
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.deleteAvailability);
  } finally {
    yield put(setAvailabilitiesDeletingState(false));
  }
};

const handleCheckIsAvailabilitySet = function* () {
  try {
    yield put(setAvailabilitiesLoadingState(true));

    const { data } = yield call(processRequest, schedulePaths.checkIsAvailabilitySet);

    if (!data.result) {
      toast.error(MESSAGES.errors.saveEmptyAvailability);

      return;
    }

    yield handleSetExpertProfileStepRequest({ payload: 4 });
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.checkIsAvailabilitySet);
  } finally {
    yield put(setAvailabilitiesLoadingState(false));
  }
};

const scheduleSagas = [
  takeLatest(scheduleSagaActionTypes.fetchAvailabilities, handleFetchAvailabilities),
  takeLatest(scheduleSagaActionTypes.addAvailability, handleAddAvailability),
  takeLatest(scheduleSagaActionTypes.deleteAvailability, handleDeleteAvailability),
  takeLatest(scheduleSagaActionTypes.editAvailability, handleEditAvailability),
  takeLatest(scheduleSagaActionTypes.editSchedule, handleEditSchedule),
  takeLatest(scheduleSagaActionTypes.checkIsAvailabilitySet, handleCheckIsAvailabilitySet),
];

export default scheduleSagas;
