import AddAvailabilityProps from 'expert/sagas/interfaces/addAvailabilityProps';
import AddAvailabilityQuery from 'expert/sagas/interfaces/addAvailabilityQuery';

const addAvailabilityPropsToQuery = (source: AddAvailabilityProps): AddAvailabilityQuery => ({
  start: source.start,
  end: source.end,
  repeat_start: source.repeatStart,
  weekdays: source.weekdays,
});

export default addAvailabilityPropsToQuery;
