import ActionPayload from 'expert/common/interfaces/actionPayload';
import actionFn from 'expert/common/utils/actionFn';
import Review from 'expert/common/components/reviewItem/interfaces/review';
import ReviewsState from 'expert/redux/reviews/interfaces/reviewsState';
import INIT_REVIEWS_STATE from 'expert/redux/reviews/configs/initReviewsState';

export enum ReviewsActionTypes {
  SetReviews = 'SetReviews',
  ResetReviews = 'ResetReviews',
  SetReviewsAndStopLoading = 'SetReviewsAndStopLoading',
  SetReviewsLoadingState = 'SetReviewsLoadingState',
  SetLoadingReviewsIds = 'SetLoadingReviewsIds',
}

export type ResetReviewsAction = {
  type: ReviewsActionTypes.ResetReviews;
  payload: ReviewsState;
};

export const resetReviews = (): ResetReviewsAction => ({
  type: ReviewsActionTypes.ResetReviews,
  payload: INIT_REVIEWS_STATE,
});

export type SetLoadingReviewsIdsAction = {
  type: ReviewsActionTypes.SetLoadingReviewsIds;
  payload: number[];
};

export const setLoadingReviewsIds = actionFn<SetLoadingReviewsIdsAction>(ReviewsActionTypes.SetLoadingReviewsIds);

export type SetReviewsAction = {
  type: ReviewsActionTypes.SetReviews;
  payload: ActionPayload<Review[]>;
};

export const setReviews = actionFn<SetReviewsAction>(ReviewsActionTypes.SetReviews);

export type SetReviewsAndStopLoadingAction = {
  type: ReviewsActionTypes.SetReviewsAndStopLoading;
  payload: ActionPayload<Review[]>;
};

export const setReviewsAndStopLoading = actionFn<SetReviewsAndStopLoadingAction>(
  ReviewsActionTypes.SetReviewsAndStopLoading,
);

export type SetReviewsLoadingStateAction = {
  type: ReviewsActionTypes.SetReviewsLoadingState;
  payload: ActionPayload<boolean>;
};

export const setReviewsLoadingState = actionFn<SetReviewsLoadingStateAction>(ReviewsActionTypes.SetReviewsLoadingState);

export type ReviewsActions =
  | SetReviewsAction
  | SetReviewsLoadingStateAction
  | SetReviewsAndStopLoadingAction
  | ResetReviewsAction
  | SetLoadingReviewsIdsAction;
