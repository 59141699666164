import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  makeStyles,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import classNames from 'classnames';
import { useHistory, useLocation, Link } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import logoHeader from '../../../../images/logoHeader.svg';
import { heading18_28_400_noe } from '../../../../styles/fonts';
import { primary } from '../../../../styles/colors';
import useDesktop from '../../../../common/hooks/useBreakpoints';
import { TOOLBAR_HEIGHT } from '../../../../constants/global';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: primary.dark_purple,
    boxShadow: '4px 4px 35px rgba(0, 0, 0, 0.1)',
  },
  toolBarContainer: {
    color: primary.coral,
    zIndex: theme.zIndex.drawer + 1,
    padding: theme.spacing(0, 3),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 10),
    },
  },
  toolBar: {
    height: TOOLBAR_HEIGHT,
  },
  menuItem: {
    ...heading18_28_400_noe,
    color: primary.coral,
    textTransform: 'none',
    cursor: 'pointer',
  },
  item: {
    padding: '10px 0',
    color: primary.coral,
    '& button': {
      color: primary.coral,
    },
  },
  activeItem: {
    borderBottom: `3px solid ${primary.coral}`,
  },
  ourServicesMobile: {
    marginLeft: 'auto',
    color: primary.milk,
  },
}));

const mobileNavItems = [
  { title: 'Career Mapper', path: '/landing/career-mapper' },
  // { title: 'Expert Assist', path: '/landing/experts-assist' },
  // { title: 'Interview Boost', path: '/landing/interview-boost' },
  { title: 'About', path: '/about' },
  { title: 'Sign in', path: '/sign-in' },
  { title: 'Sign up', path: '/sign-up' },
];

const Header = ({ autBlock = true }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { isDesktop } = useDesktop();
  const anchorRef = useRef(null);
  const anchorOutProductsRef = useRef(null);
  const [open, setOpen] = useState({
    products: false,
    ourProducts: false,
  });
  const prevOpen = useRef(open);

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = (menu) => {
    setOpen({ ...open, [menu]: !open[menu] });
  };

  const handleClose = (event, menu) => {
    setOpen({ ...open, [menu]: false });
  };

  const handleChooseMenu = (e, path, menu) => {
    history.push(path);
    handleClose(e, menu);
  };

  const handleClickSignIn = () => {
    history.push('/sign-in');
  };

  const handleListKeyDown = (e, menu) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      setOpen({ ...open, [menu]: false });
    }
  };

  console.log('isDesktop', isDesktop);

  return (
    <>
      <AppBar classes={{ root: classes.appBar }} elevation={0}>
        <Toolbar className={classNames(classes.toolBarContainer, classes.toolBar)}>
          <div className={'header__content _container_landing'}>
            <div className="logo">
              <Link to="/home">
                <img src={logoHeader} alt="Pave" />
              </Link>
            </div>
            {isDesktop ? (
              <>
                <div className="top_menu">
                  <div
                    className={classNames('top_menu_item', classes.item, {
                      [classes.activeItem]: [
                        '/landing/career-mapper',
                        '/landing/experts-assist',
                        '/landing/interview-boost',
                      ].includes(location.pathname),
                    })}>
                    <Button
                      onClick={() => handleToggle('products')}
                      ref={anchorRef}
                      aria-controls={open.products ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      endIcon={<ExpandMoreIcon />}>
                      Services
                    </Button>
                    <Popper open={open.products} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                          }}>
                          <Paper>
                            <ClickAwayListener onClickAway={(e) => handleClose(e, 'products')}>
                              <MenuList
                                autoFocusItem={!!open}
                                id="menu-list-grow"
                                onKeyDown={(e) => handleListKeyDown(e, 'products')}>
                                <MenuItem
                                  onClick={(e) => handleChooseMenu(e, '/landing/career-mapper', 'products')}
                                  className={classes.menuItem}>
                                  Career Mapper
                                </MenuItem>
                                {/*<MenuItem*/}
                                {/*  onClick={(e) => handleChooseMenu(e, '/landing/experts-assist', 'products')}*/}
                                {/*  className={classes.menuItem}>*/}
                                {/*  Expert Assist*/}
                                {/*</MenuItem>*/}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                  <div
                    className={classNames('top_menu_item', classes.item, {
                      [classes.activeItem]: location.pathname === '/about',
                    })}
                    onClick={(e) => handleChooseMenu(e, '/about', 'products')}>
                    About
                  </div>
                  <div
                    className={classNames(
                      'top_menu_item',
                      classes.item,
                      {
                        become_an_expert: location.pathname !== '/become-an-expert',
                      },
                      {
                        [classes.activeItem]: location.pathname === '/become-an-expert',
                      },
                    )}
                    onClick={(e) => handleChooseMenu(e, '/become-an-expert', 'products')}>
                    Become an Expert
                  </div>
                </div>
                {autBlock && (
                  <div className="auth_block">
                    <button type="button" className="sign_in_btn" onClick={handleClickSignIn}>
                      Sign in
                    </button>
                    <button
                      type="button"
                      className="sign_up_btn"
                      onClick={(e) => handleChooseMenu(e, '/sign-up', 'products')}>
                      Sign up
                    </button>
                  </div>
                )}
              </>
            ) : (
              <>
                <Button
                  className={classes.ourServicesMobile}
                  onClick={() => handleToggle('ourProducts')}
                  ref={anchorOutProductsRef}
                  aria-controls={open.ourProducts ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  endIcon={<ExpandMoreIcon />}>
                  Our Services
                </Button>
                <Popper
                  open={open.ourProducts}
                  anchorEl={anchorOutProductsRef.current}
                  role={undefined}
                  transition
                  disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}>
                      <Paper>
                        <ClickAwayListener onClickAway={(e) => handleClose(e, 'ourProducts')}>
                          <MenuList
                            autoFocusItem={!!open}
                            id="menu-list-grow"
                            onKeyDown={(e) => handleListKeyDown(e, 'ourProducts')}>
                            {mobileNavItems.map(({ title, path }) => (
                              <MenuItem
                                key={title}
                                onClick={(e) => handleChooseMenu(e, path, 'ourProducts')}
                                className={classes.menuItem}>
                                {title}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar className={classes.toolBar} />
    </>
  );
};

export default Header;
