import React, { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { setUserId, setUserProperties } from 'firebase/analytics';
import { isAuthenticated } from './services/AccessToken';
import { getUserSelector } from './selectors';
import { getUserRequest } from './pages/signIn/Actions';
import Loader from './common/components/Loader';
import analytics from './services/firebase';

const CustomerRoutes = lazy(() => import('./route-stacks/CustomerRoutes'));
const ExpertRoutes = lazy(() => import('./route-stacks/ExpertRoutes'));
const UnauthorizedRoutes = lazy(() => import('./route-stacks/UnauthorizedRoutes'));

const Router = () => {
  const isLoggedIn = isAuthenticated();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(getUserSelector);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserRequest());
    }
  }, []);

  useEffect(() => {
    if (user) {
      setUserId(analytics, user.id);
      setUserProperties(analytics, { email: user.email });
    }
  }, [user]);

  useEffect(() => {
    if (location.hash) {
      history.replace(location.hash.replace('#', ''));
    }
  }, [history, location.hash, location.pathname]);

  if (!isLoggedIn) {
    return (
      <Suspense fallback={<Loader fullPage delay={300} />}>
        <UnauthorizedRoutes />
      </Suspense>
    );
  }

  if (isLoggedIn && !user) {
    return <Loader fullPage />;
  }

  if (user?.user_type === 'expert') {
    return (
      <Suspense fallback={<Loader fullPage delay={300} />}>
        <ExpertRoutes />
      </Suspense>
    );
  }

  if (user?.user_type === 'customer') {
    return (
      <Suspense fallback={<Loader fullPage delay={300} />}>
        <CustomerRoutes />
      </Suspense>
    );
  }

  return null;
};

export default Router;
