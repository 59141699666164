import { fileTypes } from './Constants';
import { MAX_FILE_SIZE } from '../onboarding/Constants';

/* eslint-disable no-plusplus */
const getFirstChart = (word) => {
  return word.charAt(0);
};

const getWordForChart = (splittedName) => {
  if (splittedName.length === 1) {
    return getFirstChart(splittedName[0]);
  }

  let initialName = '';

  for (let i = 0; i < splittedName; i++) {
    initialName = `${initialName}${getFirstChart(splittedName[i])}`;
  }

  return initialName;
};

export const getInitials = (first_name, last_name) => {
  const splittedFirstName = first_name.split(' ');
  const splittedSecondName = last_name.split(' ');
  const initialsFirstName = getWordForChart(splittedFirstName);
  const initialsSecondName = getWordForChart(splittedSecondName);

  return `${initialsFirstName}${initialsSecondName}`;
};

export const validationFile = (file) => {
  if (!file.type) {
    return 'Invalid file format. Please upload an image';
  }

  if (file.size > MAX_FILE_SIZE) {
    return 'This file is too big! File size should be up to 5.0 MB';
  }

  return !fileTypes.includes(file.type);
};
