import { useDispatch, useSelector } from 'react-redux';
import { agreementSelector } from 'expert/redux/agreement/agreementSelectors';
import AgreementTypes from 'expert/redux/agreement/enums/agreementTypes';
import { getUserSelector } from 'selectors';
import { useEffect } from 'react';
import { setAgreementClosed, setAgreementOpened } from 'expert/redux/agreement/agreementActions';
import { setAgreement } from 'pages/signIn/Actions';
import { useLocation } from 'react-router-dom';
import { EXCLUDED_PATHS_FOR_MODAL } from '../../../../services/Constants';

interface UseTextDialogRes {
  isOpened: boolean;
  agreementType: AgreementTypes;
  onAccept: () => void;
}

const useAgreementDialog: () => UseTextDialogRes = () => {
  const dispatch = useDispatch();
  const { isOpened, agreementType } = useSelector(agreementSelector);
  const user = useSelector(getUserSelector);
  const location = useLocation();

  useEffect(() => {
    if (
      !user ||
      user.is_agreement ||
      user.user_type !== 'customer' ||
      EXCLUDED_PATHS_FOR_MODAL.includes(location.pathname)
    ) {
      return;
    }

    dispatch(
      setAgreementOpened({
        value: {
          agreementType: AgreementTypes.UserAgreement,
          isOpened: true,
        },
      }),
    );
  }, [user]);

  const onAccept = () => {
    dispatch(setAgreement());
    dispatch(setAgreementClosed());
  };

  return {
    isOpened,
    onAccept,
    agreementType,
  };
};

export default useAgreementDialog;
