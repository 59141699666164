import { ExpertAssistMatches } from './interfaces';
import * as types from './actionTypes';
import ExpertListTypes from '../enums/expertListTypes';

const initialState = {
  expertMatches: {
    data: null,
    isFetching: false,
    error: null,
  },
  activeTab: ExpertListTypes.ExpertMatches,
  otherExperts: {
    data: null,
    isFetching: false,
    error: null,
    filters: {
      data: {
        areas_of_expertise: null,
        type_of_services_offered: null,
      },
      selectedFilters: {
        areas_of_expertise: [],
        type_of_services_offered: [],
      },
      isFetching: false,
      error: null,
    },
  },
  favoriteExperts: {
    data: null,
    isFetching: false,
    error: null,
  },
  toggleFavorite: {
    data: null,
    error: null,
  },
};

const expertAssistMatchesReducer = (state: ExpertAssistMatches = initialState, { type, payload, isInitialLoad }) => {
  switch (type) {
    case types.SELECT_TAB:
      return {
        ...state,
        activeTab: payload,
      };
    case types.EXPERT_MATCHES_REQUEST:
      return {
        ...state,
        expertMatches: {
          ...state.expertMatches,
          isFetching: true,
          error: null,
        },
      };
    case types.EXPERT_MATCHES_REQUEST_SUCCESS:
      return {
        ...state,
        expertMatches: {
          ...state.expertMatches,
          isFetching: false,
          data: {
            ...payload,
            results: isInitialLoad
              ? payload.results
              : [...(state.expertMatches.data?.results ?? []), ...payload.results],
          },
        },
      };
    case types.EXPERT_MATCHES_REQUEST_ERROR:
      return {
        ...state,
        expertMatches: {
          ...state.expertMatches,
          isFetching: false,
          error: payload,
        },
      };
    case types.OTHER_EXPERTS_REQUEST:
      return {
        ...state,
        otherExperts: {
          ...state.otherExperts,
          isFetching: true,
          error: null,
        },
      };
    case types.OTHER_EXPERTS_REQUEST_SUCCESS:
      return {
        ...state,
        otherExperts: {
          ...state.otherExperts,
          isFetching: false,
          data: {
            ...payload,
            results: isInitialLoad
              ? payload.results
              : [...(state.otherExperts.data?.results ?? []), ...payload.results],
          },
        },
      };
    case types.OTHER_EXPERTS_REQUEST_ERROR:
      return {
        ...state,
        otherExperts: {
          ...state.otherExperts,
          isFetching: false,
          error: payload,
        },
      };
    case types.FAVORITE_EXPERTS_REQUEST:
      return {
        ...state,
        favoriteExperts: {
          ...state.favoriteExperts,
          isFetching: true,
          error: null,
        },
      };
    case types.FAVORITE_EXPERTS_REQUEST_SUCCESS:
      return {
        ...state,
        favoriteExperts: {
          ...state.favoriteExperts,
          isFetching: false,
          data: {
            ...payload,
            results: isInitialLoad
              ? payload.results
              : [...(state.favoriteExperts.data?.results ?? []), ...payload.results],
          },
        },
      };
    case types.FAVORITE_EXPERTS_REQUEST_ERROR:
      return {
        ...state,
        favoriteExperts: {
          ...state.favoriteExperts,
          isFetching: false,
          error: payload,
        },
      };
    case types.TOGGLE_FAVORITE_EXPERT:
      return {
        ...state,
        toggleFavorite: {
          ...state.toggleFavorite,
          error: null,
          data: payload,
        },
      };
    case types.TOGGLE_FAVORITE_EXPERT_SUCCESS: {
      if (payload.reducerKey === 'favoriteExperts') {
        return {
          ...state,
          toggleFavorite: {
            ...state.toggleFavorite,
            data: null,
          },
        };
      }

      return {
        ...state,
        toggleFavorite: {
          ...state.toggleFavorite,
          data: null,
        },
        [payload.reducerKey]: {
          ...state[payload.reducerKey],
          data: {
            ...state[payload.reducerKey].data,
            results: state[payload.reducerKey].data.results.map((item) => {
              if (item.id === payload.id) {
                return { ...item, is_favorite: payload.is_favorite };
              }

              return item;
            }),
          },
        },
      };
    }

    case types.TOGGLE_FAVORITE_EXPERT_ERROR:
      return {
        ...state,
        toggleFavorite: {
          ...state.toggleFavorite,
          data: null,
          error: payload,
        },
      };
    case types.OTHER_EXPERTS_FILTERS_REQUEST:
      return {
        ...state,
        otherExperts: {
          ...state.otherExperts,
          filters: {
            ...state.otherExperts.filters,
            isFetching: true,
            error: null,
          },
        },
      };
    case types.OTHER_EXPERTS_FILTERS_REQUEST_SUCCESS:
      return {
        ...state,
        otherExperts: {
          ...state.otherExperts,
          filters: {
            ...state.otherExperts.filters,
            isFetching: false,
            data: {
              areas_of_expertise: payload.areas_of_expertise || [],
              type_of_services_offered: payload.type_of_services_offered || [],
            },
          },
        },
      };
    case types.OTHER_EXPERTS_FILTERS_REQUEST_ERROR:
      return {
        ...state,
        otherExperts: {
          ...state.otherExperts,
          filters: {
            ...state.otherExperts.filters,
            isFetching: false,
            error: payload,
          },
        },
      };
    case types.EA_SET_SELECTED_FILTERS:
      return {
        ...state,
        otherExperts: {
          ...state.otherExperts,
          filters: {
            ...state.otherExperts.filters,
            selectedFilters: {
              ...state.otherExperts.filters.selectedFilters,
              ...payload,
            },
          },
        },
      };
    case types.CLEAR_OTHER_EXPERTS_DATA:
      return {
        ...state,
        otherExperts: {
          ...initialState.otherExperts,
          filters: {
            ...initialState.otherExperts.filters,
            data: state.otherExperts.filters.data,
          },
        },
      };
    case types.CLEAR_EXPERT_MATCHES_TAB:
      return {
        ...state,
        [payload]: initialState?.[payload],
      };
    default:
      return state;
  }
};

export default expertAssistMatchesReducer;
