import { all, call, put, select } from 'redux-saga/effects';
import { exceptionCatcherWrap } from '../../../sagaWrapper';
import {
  getExpertMatchesError,
  getExpertMatchesSuccess,
  getFavoriteExpertsError,
  getFavoriteExpertsSuccess,
  getOtherExpertsError,
  getOtherExpertsFiltersError,
  getOtherExpertsFiltersSuccess,
  getOtherExpertsSuccess,
  toggleFavoriteExpertError,
  toggleFavoriteExpertSuccess,
} from './actionCreators';
import { processRequest } from '../../../services/Api';
import { ExpertAssistMatchesReducer } from './interfaces';

const limit = 10;

interface PaginationQueries {
  queries?: {
    offset?: number;
    limit?: number;
  };
  isInitialLoad?: boolean;
}

function* getExpertMatchesSaga({ queries, isInitialLoad }) {
  const response = yield call(processRequest, 'customer/get_matches_expert', 'GET', {
    limit: queries?.limit || limit,
    offset: queries?.offset || 0,
  });

  yield put(getExpertMatchesSuccess(response.data, !!isInitialLoad));
}

function* getOtherExpertsSaga({ queries, isInitialLoad }) {
  const params = yield select(
    ({ expertAssistMatches }: ExpertAssistMatchesReducer) => expertAssistMatches.otherExperts.filters.selectedFilters,
  );
  const response = yield call(processRequest, 'customer/get_filtered_matches_expert', 'GET', {
    limit,
    offset: queries?.offset || 0,
    ...params,
  });

  yield put(getOtherExpertsSuccess(response.data, !!isInitialLoad));
}

function* getOtherExpertsFiltersSaga() {
  const [areas_of_expertise, type_of_services_offered] = yield all([
    call(processRequest, 'cms/expert_dict/areas_of_expertise'),
    call(processRequest, 'cms/expert_dict/services_offered?is_other=false'),
  ]);

  yield put(
    getOtherExpertsFiltersSuccess({
      areas_of_expertise: areas_of_expertise.data,
      type_of_services_offered: type_of_services_offered.data,
    }),
  );
}

function* getFavoriteExpertsSaga({ queries, isInitialLoad }: PaginationQueries) {
  const response = yield call(processRequest, 'customer/get_favorite_matches_expert', 'GET', {
    limit: queries?.limit || limit,
    offset: queries?.offset || 0,
  });

  yield put(getFavoriteExpertsSuccess(response.data, !!isInitialLoad));
}

function* toggleFavoriteExpertSaga({ payload }) {
  const favoriteExpertsData = yield select(
    ({ expertAssistMatches }: ExpertAssistMatchesReducer) => expertAssistMatches.favoriteExperts.data,
  );

  yield call(processRequest, 'customer/mark_expert_favorite', 'POST', {
    expert_id: payload.id,
    is_favorite: payload.is_favorite,
  });
  yield put(toggleFavoriteExpertSuccess(payload));

  if (payload.reducerKey === 'favoriteExperts' && favoriteExpertsData) {
    yield* getFavoriteExpertsSaga({
      queries: { limit: favoriteExpertsData.results.length, offset: 0 },
      isInitialLoad: true,
    });
  }
}

export const getExpertMatches = exceptionCatcherWrap(getExpertMatchesSaga, getExpertMatchesError);
export const getOtherExperts = exceptionCatcherWrap(getOtherExpertsSaga, getOtherExpertsError);
export const getFavoriteExperts = exceptionCatcherWrap(getFavoriteExpertsSaga, getFavoriteExpertsError);
export const toggleFavoriteExpert = exceptionCatcherWrap(toggleFavoriteExpertSaga, toggleFavoriteExpertError);
export const getOtherExpertsFilters = exceptionCatcherWrap(getOtherExpertsFiltersSaga, getOtherExpertsFiltersError);
