import { logEvent } from 'firebase/analytics';
import { put, call, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { processRequest } from '../../services/Api';
import { generalActionTypes, MAPPED_EVENTS_TO_LOG } from './Constants';
import * as generalActions from './Actions';
import * as signInActions from '../signIn/Actions';
import analytics from '../../services/firebase';

export function* handleSendActionEventRequest({ payload }) {
  try {
    const { event_type } = payload.data;

    yield call(processRequest, 'user/post_event', 'POST', payload.data);

    if (MAPPED_EVENTS_TO_LOG[event_type]) {
      logEvent(analytics, MAPPED_EVENTS_TO_LOG[event_type]);
    }
  } catch (e) {
    yield put(generalActions.sendActionEventError());
  }
}

export function* handleCheckDiscountRequest({ payload }) {
  try {
    const update = payload?.data?.update;
    const { data } = yield call(processRequest, 'payment/check_discount', 'GET', payload.data, { update });

    yield put(generalActions.checkDiscountSuccess({ discount: data.discount, loadingNewPath: false }));
    if (update !== false && data.discount) {
      const { data: user } = yield call(processRequest, 'user/', 'PUT', { is_started_onboarding: false });

      yield put(signInActions.getUserSuccess({ user }));
      yield put(push('/career-mapper'));
    }
  } catch (e) {
    yield put(generalActions.checkDiscountError());
  }
}

const generalSagas = [
  takeLatest(generalActionTypes.SEND_ACTION_EVENT_REQUEST, handleSendActionEventRequest),
  takeLatest(generalActionTypes.CHECK_DISCOUNT_REQUEST, handleCheckDiscountRequest),
];

export default generalSagas;
