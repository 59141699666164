import ProfileFormData from 'expert/common/interfaces/profileFormData';
import ProfileDetailsInput from 'expert/sagas/interfaces/profileDetailsInput';

const profileFormDataToParams = (source: ProfileFormData): ProfileDetailsInput => ({
  current_company_name: source.currentCompany,
  current_industry_name: source.currentIndustry,
  current_job_title: source.currentJobTitle,
  about_me: source.aboutMe,
  price_30: source.price30,
  price_60: source.price60,
  skills_and_competencies: source.skillsAndCompetencies,
  areas_of_expertise: source.areasOfExpertise.map(({ id, isSelected, other }) => ({
    id,
    is_selected: isSelected,
    ...(other === undefined ? {} : { is_other: true, other_text: other }),
  })),
  type_of_services_offered: source.typeOfServicesOffered.map(({ id, isSelected, other }) => ({
    id,
    is_selected: isSelected,
    ...(other === undefined ? {} : { is_other: true, other_text: other }),
  })),
});

export default profileFormDataToParams;
