import { customModalTypes } from './Constants';

const initialState = {
  open: false,
  modalData: {},
};

export default function customModalReducer(state = initialState, action) {
  const { type, payload } = action;
  const { data } = payload || {};

  switch (type) {
    case customModalTypes.TOGGLE_CUSTOM_MODAL:
      return {
        ...state,
        ...data,
      };
    case customModalTypes.SET_CUSTOM_MODAL_DATA:
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
}
