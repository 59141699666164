import Availability from 'expert/modules/schedule/interfaces/availability';
import AvailabilitiesRes from 'expert/sagas/interfaces/availabilitiesRes';
import moment from 'moment-timezone';

const resToAvailabilities = (source: AvailabilitiesRes): Availability => ({
  id: source.id,
  repeat: source.schedule.weekdays || [],
  start: moment(source.start),
  end: moment(source.end),
  scheduleId: source.schedule.id,
  isRegular: source.is_regular,
  repeatStart: source.schedule.repeat_start ? moment(source.schedule.repeat_start) : undefined,
});

export default resToAvailabilities;
