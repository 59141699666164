import { put, call, takeLatest } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { processRequest } from '../../services/Api';
import { signUpActionTypes } from './Constants';
import * as signUpActions from './Actions';

export function* handleSignUpRequest({ payload }) {
  try {
    const { data } = yield call(processRequest, 'user/', 'POST', payload.data);

    yield put(
      signUpActions.signUpSuccess({
        user: data.user,
        emailExist: {
          is: false,
          message: '',
        },
      }),
    );
  } catch (e) {
    if (
      e.response.status === 400 &&
      e.response.data &&
      e.response.data.email &&
      e.response.data.email[0].indexOf('exists') !== -1
    ) {
      yield put(
        signUpActions.signUpError({
          error: e,
          emailExist: {
            is: true,
            message: `${payload.data.email} already exist`,
          },
        }),
      );
    } else {
      yield put(signUpActions.signUpError({ error: e }));
    }
  }
}

export function* handleDesiredRolepRequest() {
  try {
    const { data } = yield call(processRequest, 'web/dict/roles');

    yield put(signUpActions.getDesiredRoleSuccess({ desiredRoles: data }));
  } catch (e) {
    yield put(signUpActions.getDesiredRoleError({ error: e }));
  }
}

export function* handleDesiredCompanyRequest() {
  try {
    const { data } = yield call(processRequest, 'web/dict/companies');

    yield put(signUpActions.getDesiredCompanySuccess({ desiredCompanies: data }));
  } catch (e) {
    yield put(signUpActions.getDesiredCompanyError({ error: e }));
  }
}

export function* handleSetFeatureRequest({ payload }) {
  try {
    const { data } = yield call(processRequest, 'user/', 'PUT', payload.data);

    yield put(
      signUpActions.signUpSuccess({
        user: data.user,
        emailExist: {
          is: false,
          message: '',
        },
      }),
    );
    yield put(replace('/onboarding/overview'));
  } catch (e) {
    yield put(signUpActions.setFeatureError({ error: e }));
  }
}

const signUpSagas = [
  takeLatest(signUpActionTypes.SIGN_UP_REQUEST, handleSignUpRequest),
  takeLatest(signUpActionTypes.GET_DESIRED_ROLE_REQUEST, handleDesiredRolepRequest),
  takeLatest(signUpActionTypes.GET_DESIRED_COMPANY_REQUEST, handleDesiredCompanyRequest),
  takeLatest(signUpActionTypes.SET_FEATURE_REQUEST, handleSetFeatureRequest),
];

export default signUpSagas;
