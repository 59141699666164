import { profileActionTypes } from './Constants';

export function saveAvatarRequest(data) {
  return {
    type: profileActionTypes.SAVE_AVATAR_REQUEST,
    payload: {
      data,
    },
  };
}

export function saveAvatarSuccess(data) {
  return {
    type: profileActionTypes.SAVE_AVATAR_SUCCESS,
    payload: {
      data,
    },
  };
}

export function saveAvatarError() {
  return {
    type: profileActionTypes.SAVE_AVATAR_ERROR,
  };
}
