import { all, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { push } from 'connected-react-router';
import { exceptionCatcherWrap } from '../../../sagaWrapper';
import { processRequest } from '../../../services/Api';
import {
  confirmSessionError,
  confirmSessionSuccess,
  getDayAvailabilitySuccess,
  getExpertProfileSuccess,
  getMonthAvailabilitySuccess,
  getSessionError,
  getSessionSuccess,
  getTimezonesError,
  getTimezonesSuccess,
} from './actionCreators';
import { DurationTypes, ExpertAssistSessionBookingReducer } from './interfaces';
import { SessionStatuses } from '../../activity/components/SessionsList/interfaces';

function setEndOfSession(from: string, duration: boolean) {
  const to = moment(from).add(duration ? 30 : 60, 'minutes');

  return moment(to).format();
}

function* eaGetExpertProfileSaga({ payload }) {
  try {
    const response = yield call(processRequest, `customer/get_expert/${payload}/`);

    yield put(getExpertProfileSuccess(response.data));
  } catch {
    // yield put(push('/expert-assist/overview'));
  }
}

function setPaymentPageState(id, from, to) {
  return {
    expert_profile: id,
    suggested_time_from: from,
    suggested_time_to: to,
  };
}

function* eaConfirmSessionSaga({ payload, sessionId, callback }) {
  try {
    const eaBooking = yield select(
      ({ expertAssistSessionBooking }: ExpertAssistSessionBookingReducer) => expertAssistSessionBooking,
    );
    const { timezone } = eaBooking.schedule.selectedTimezone;
    const { id } = eaBooking.expertProfile.data;

    if (sessionId) {
      const is30minSession = eaBooking.editedSession.data?.session_type === DurationTypes.HalfAnHour;
      const formattedTo = setEndOfSession(eaBooking.schedule.selectedTime, is30minSession);
      const sessionPayload = {
        suggested_time_from: moment.tz(eaBooking.schedule.selectedTime, timezone).utc().format(),
        suggested_time_to: moment(formattedTo).utc().format(),
        explanation: payload.comment,
        zoom_session_id: sessionId,
        customer_timezone: timezone,
      };

      const { data } = yield call(processRequest, 'customer/session_reschedule', 'POST', sessionPayload);

      if (data.client_secret) {
        yield all([
          put(confirmSessionSuccess(data)),
          put(
            push(
              `/expert-assist/expert-matches/${id}/checkout/${sessionId}`,
              setPaymentPageState(id, sessionPayload.suggested_time_from, sessionPayload.suggested_time_to),
            ),
          ),
        ]);
      } else if (callback) {
        yield put(confirmSessionSuccess(data));
        callback();
      }
    } else {
      const is30minSession = eaBooking.schedule.selectedDuration === DurationTypes.HalfAnHour;
      const formattedTo = setEndOfSession(eaBooking.schedule.selectedTime, is30minSession);
      const sessionPayload = {
        session_datetime_from: moment(eaBooking.schedule.selectedTime).utc().format(),
        session_datetime_to: moment(formattedTo).utc().format(),
        session_type: eaBooking.schedule.selectedDuration,
        expert_profile: id,
        customer_notes: payload.comment,
        customer_timezone: timezone,
      };

      const { data } = yield call(processRequest, 'session/book_session', 'POST', sessionPayload);

      yield all([
        put(confirmSessionSuccess(data)),
        put(
          push(
            `/expert-assist/expert-matches/${id}/checkout`,
            setPaymentPageState(id, sessionPayload.session_datetime_from, sessionPayload.session_datetime_to),
          ),
        ),
      ]);
    }
  } catch (error: any) {
    const errorMessage = error.response?.data?.detail || 'Failed to book a session';

    toast.error(errorMessage);
    yield put(confirmSessionError(errorMessage));
  }
}

function* eaGetTimezonesSaga() {
  const timezones = yield call(processRequest, 'user/timezone/');
  const currentTimezone = moment.tz.guess();
  const defaultTimezone = currentTimezone ? timezones.data.find((item) => item.timezone === currentTimezone) : null;

  yield put(getTimezonesSuccess({ timezones: timezones.data, timezone: defaultTimezone }));

  yield* eaGetMonthAvailabilitySaga({ queries: {} });
}

function* eaGetMonthAvailabilitySaga({ queries }) {
  try {
    const id = yield select(
      ({ expertAssistSessionBooking }: ExpertAssistSessionBookingReducer) =>
        expertAssistSessionBooking.expertProfile?.data?.id,
    );
    const schedule = yield select(
      ({ expertAssistSessionBooking }: ExpertAssistSessionBookingReducer) => expertAssistSessionBooking.schedule,
    );

    const { data } = yield call(processRequest, `customer/get_expert/${id}/availability_month`, 'GET', {
      time_zone: schedule.selectedTimezone.timezone,
      session_type: schedule.selectedDuration,
      date: moment(queries.date || schedule.selectedDate).format('YYYY-MM-DD'),
    });

    const firstAvailableDay = schedule.availability.day
      ? null
      : data.find((item) => item.is_available && moment(item.date).isAfter(moment()));

    yield put(getMonthAvailabilitySuccess(data, firstAvailableDay?.date ?? null));

    if (firstAvailableDay) {
      yield* eaGetDayAvailabilitySaga({ queries: { date: moment(firstAvailableDay.date) } });
    }
  } catch {
    toast.error('Failed to load expert availability');
  }
}

function* eaGetDayAvailabilitySaga({ queries }) {
  try {
    const schedule = yield select(
      ({ expertAssistSessionBooking }: ExpertAssistSessionBookingReducer) => expertAssistSessionBooking.schedule,
    );
    const id = yield select(
      ({ expertAssistSessionBooking }: ExpertAssistSessionBookingReducer) =>
        expertAssistSessionBooking.expertProfile?.data?.id,
    );
    const date = queries?.date || schedule.selectedDate;

    if (date && !schedule.availability.month.find((item) => item.date === moment(date).format('YYYY-MM-DD'))) {
      yield put(getDayAvailabilitySuccess([]));
    } else {
      const { data } = yield call(processRequest, `customer/get_expert/${id}/availability_day`, 'GET', {
        time_zone: schedule.selectedTimezone.timezone,
        session_type: schedule.selectedDuration,
        date: moment(date).format('YYYY-MM-DD'),
      });

      yield put(getDayAvailabilitySuccess(data));
    }
  } catch {
    toast.error('Failed to load day availability');
  }
}

function* eaGetSessionSaga({ payload }) {
  try {
    const response = yield call(processRequest, `customer/session/${payload}/`);
    const userId = yield select(({ signInReducer }: any) => signInReducer.user.id);
    const { customer_user, session_status } = response.data;

    if (
      customer_user.id === userId &&
      (session_status === SessionStatuses.Pending || session_status === SessionStatuses.Upcoming)
    ) {
      yield put(getSessionSuccess(response.data));
    } else {
      toast.error('Invalid session');
      yield put(push('/activity'));
    }
  } catch {
    yield [put(getSessionError()), put(push('/activity'))];
    toast.error('Invalid session');
  }
}

export const eaGetExpertProfile = exceptionCatcherWrap(eaGetExpertProfileSaga, null);
export const eaGetTimezones = exceptionCatcherWrap(eaGetTimezonesSaga, getTimezonesError);
export const eaConfirmSession = exceptionCatcherWrap(eaConfirmSessionSaga, null);
export const eaGetMonthAvailability = exceptionCatcherWrap(eaGetMonthAvailabilitySaga, null);
export const eaGetDayAvailability = exceptionCatcherWrap(eaGetDayAvailabilitySaga, null);
export const eaGetSession = exceptionCatcherWrap(eaGetSessionSaga, null);
