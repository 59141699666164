import keyMirror from 'keymirror';

import FetchCalendarSessionsProps from 'expert/sagas/interfaces/fetchCalendarSessionsProps';
import RescheduleSessionProps from 'expert/sagas/interfaces/rescheduleSessionProps';
import UpdateExpertProps from 'expert/sagas/interfaces/updateExpertProps';
import ReviewPublishUpdateData from 'expert/sagas/interfaces/reviewPublishUpdateData:';
import SaveCustomerFeedbackProps from 'expert/sagas/interfaces/saveCustomerFeedbackProps';
import CheckIsRescheduleAvailableProps from 'expert/sagas/interfaces/checkIsRescheduleAvailableProps';

export const expertSagaActionTypes = keyMirror({
  SET_EXPERT_PROFILE_STEP: null,
  SET_EXPERT_PROFILE: null,
  FETCH_EXPERT_INFO: null,
  SET_PAYOUT_METHOD: null,
  GET_PAYOUT_METHODS: null,
  UPDATE_PAYOUT_METHOD: null,
  FETCH_REVIEWS: null,
  FETCH_REVIEW: null,
  UPDATE_REVIEW_PUBLISH: null,
  FETCH_WIDGETS: null,
  CANCEL_SESSION: null,
  RESCHEDULE_SESSION: null,
  PAUSE_EXPERT: null,
  FETCH_CALENDAR_SESSIONS: null,
  FETCH_LAST_CASTOMER_FEEDBACK: null,
  REFUSE_CUSTOMER_FEEDBACK: null,
  SAVE_CUSTOMER_FEEDBACK: null,
  GET_CUSTOMER_FEEDBACK: null,
  CHECK_IS_RESCHEDULE_AVAILABLE: null,
  GET_STRIPE_SIGN_UP_LINK: null,
});

export function setProfileStep(step: number) {
  return {
    type: expertSagaActionTypes.SET_EXPERT_PROFILE_STEP,
    payload: step,
  };
}

export function saveExpertProfileRequest(data: UpdateExpertProps) {
  return {
    type: expertSagaActionTypes.SET_EXPERT_PROFILE,
    payload: data,
  };
}

export function fetchReviews() {
  return {
    type: expertSagaActionTypes.FETCH_REVIEWS,
  };
}

export function updateReviewPublish(payload: ReviewPublishUpdateData) {
  return {
    type: expertSagaActionTypes.UPDATE_REVIEW_PUBLISH,
    payload,
  };
}

export function fetchWidgets() {
  return {
    type: expertSagaActionTypes.FETCH_WIDGETS,
  };
}

export function cancelSession(payload: number) {
  return {
    type: expertSagaActionTypes.CANCEL_SESSION,
    payload: payload,
  };
}

export function rescheduleSession(payload: RescheduleSessionProps) {
  return {
    type: expertSagaActionTypes.RESCHEDULE_SESSION,
    payload: payload,
  };
}

export function pauseExpert(payload: boolean) {
  return {
    type: expertSagaActionTypes.PAUSE_EXPERT,
    payload: payload,
  };
}

export function fetchCalendarSessions(payload: FetchCalendarSessionsProps) {
  return {
    type: expertSagaActionTypes.FETCH_CALENDAR_SESSIONS,
    payload: payload,
  };
}

export function fetchLastCustomerFeedback() {
  return {
    type: expertSagaActionTypes.FETCH_LAST_CASTOMER_FEEDBACK,
  };
}

export function refuseCustomerFeedback(customerId: number) {
  return {
    type: expertSagaActionTypes.REFUSE_CUSTOMER_FEEDBACK,
    payload: customerId,
  };
}

export function getCustomerFeedback(customerId: number) {
  return {
    type: expertSagaActionTypes.GET_CUSTOMER_FEEDBACK,
    payload: customerId,
  };
}

export function saveCustomerFeedback(data: SaveCustomerFeedbackProps) {
  return {
    type: expertSagaActionTypes.SAVE_CUSTOMER_FEEDBACK,
    payload: data,
  };
}

export function checkIsRescheduleAvailable(data: CheckIsRescheduleAvailableProps) {
  return {
    type: expertSagaActionTypes.CHECK_IS_RESCHEDULE_AVAILABLE,
    payload: data,
  };
}

export function getStripeSignUpLink(isNew: boolean) {
  return {
    type: expertSagaActionTypes.GET_STRIPE_SIGN_UP_LINK,
    payload: isNew,
  };
}
