import Notification from 'expert/modules/notifications/interfaces/notification';
import Feedback from 'expert/modules/expertFeedback/interfaces/feedback';

const updateFeedbackNotifications = (
  notifications: Notification[],
  { id, liked, suggested, customerRate, disliked }: Feedback,
): Notification[] =>
  notifications.map<Notification>((item) =>
    item.feedback && item.feedback.id === id
      ? { ...item, feedback: { ...item.feedback, disliked, liked, suggested, customerRate } }
      : item,
  );

export default updateFeedbackNotifications;
