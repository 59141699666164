import WidgetsRes from 'expert/sagas/interfaces/widgetsRes';
import WidgetsData from 'expert/redux/widgets/interfaces/widgetsData';

const resToWidgetsData = (source: WidgetsRes): WidgetsData => ({
  averageRating: {
    count: source.average_rating.count,
    title: source.average_rating.title,
  },
  receivedPayouts: {
    count: source.received_payouts.count,
    title: source.received_payouts.title,
  },
  pastSessions: {
    count: source.past_sessions.count,
    title: source.past_sessions.title,
    min30: source.past_sessions.min_30,
    min60: source.past_sessions.min_60,
  },
  upcomingSessions: {
    count: source.upcoming_sessions.count,
    title: source.upcoming_sessions.title,
    min30: source.upcoming_sessions.min_30,
    min60: source.upcoming_sessions.min_60,
  },
});

export default resToWidgetsData;
