import { setPastSessionsLoading, setPastSessionsRes } from 'expert/redux/pastSessions/pastSessionsActions';
import {
  setUpcomingSessionsLoading,
  setUpcomingSessionsRes,
} from 'expert/redux/upcomingSessions/upcomingSessionsActions';
import resToSession from 'expert/sagas/utils/resToSession';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { processRequest } from 'services/Api';
import MESSAGES from 'expert/common/configs/messages';
import { setPayoutHistoryLoadingState, setPayoutHistoryRes } from 'expert/redux/payout/payoutActions';
import dashboardPaths from './dashboardPaths';
import { dashboardSagaActionTypes } from './dashboardSagasActions';
import FetchDashboardProps from 'expert/sagas/interfaces/fetchDashboardProps';
import fetchDashboardPropsToParams from 'expert/sagas/utils/fetchDashboardPropsToParams';
import resToPayoutsHistoryData from 'expert/sagas/utils/resToPayoutsHistoryData';
import shouldHandleError from 'expert/sagas/utils/shouldHandleError';

const handleFetchPayouts = function* ({ payload }: { payload: FetchDashboardProps }) {
  try {
    yield put(setPayoutHistoryLoadingState(true));
    const { data } = yield call(
      processRequest,
      dashboardPaths.fetchPayouts,
      'GET',
      fetchDashboardPropsToParams(payload),
    );

    yield put(
      setPayoutHistoryRes({ historyTotalCount: data.count, history: data.results.map(resToPayoutsHistoryData) }),
    );
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.fetchPayouts);
  } finally {
    yield put(setPayoutHistoryLoadingState(false));
  }
};

const handleFetchUpcomingSessions = function* ({ payload }: { payload: FetchDashboardProps }) {
  try {
    yield put(setUpcomingSessionsLoading(true));
    const { data } = yield call(
      processRequest,
      dashboardPaths.fetchUpcomingSessions,
      'GET',
      fetchDashboardPropsToParams(payload),
    );

    yield put(setUpcomingSessionsRes({ totalCount: data.count, sessions: data.results.map(resToSession) }));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.fetchUpcomingSessions);
  } finally {
    yield put(setUpcomingSessionsLoading(false));
  }
};

const handleFetchPastSessions = function* ({ payload }: { payload: FetchDashboardProps }) {
  try {
    yield put(setPastSessionsLoading(true));
    const { data } = yield call(
      processRequest,
      dashboardPaths.fetchPastSessions,
      'GET',
      fetchDashboardPropsToParams(payload),
    );

    yield put(setPastSessionsRes({ totalCount: data.count, sessions: data.results.map(resToSession) }));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.fetchPastSessions);
  } finally {
    yield put(setPastSessionsLoading(false));
  }
};

const dashboardSagas = [
  takeLatest(dashboardSagaActionTypes.FETCH_PAYOUTS, handleFetchPayouts),
  takeLatest(dashboardSagaActionTypes.FETCH_UPCOMING_SESSIONS, handleFetchUpcomingSessions),
  takeLatest(dashboardSagaActionTypes.FETCH_PAST_SESSIONS, handleFetchPastSessions),
];

export default dashboardSagas;
