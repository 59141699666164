import createMuiPalette from '@material-ui/core/styles/createPalette';
import { alpha } from '@material-ui/core';

const createPalette = () => {
  const white = '#FAF7F2';
  const blue = '#D1E0F0';
  const black = '#0B1D2E';
  const coral = '#FF575A';
  const text = '#6E6E6E';
  const grey50 = '#ffffff';
  const grey100 = '#E5E5E5';
  const grey200 = '#E7EFF7';
  const grey300 = '#C7C7C7';
  const green = '#61DA8A';
  const googleEvent = '#3C8AD5';

  const palette = createMuiPalette({
    primary: {
      main: black,
    },
    action: {
      hover: alpha(grey200, 0.5),
      selected: grey200,
      disabled: grey300,
    },
    background: {
      default: blue,
      paper: white,
    },
    error: {
      main: coral,
      light: alpha(coral, 0.1),
      contrastText: alpha(coral, 0.5),
    },
    text: {
      primary: black,
      secondary: text,
      hint: coral,
    },
    secondary: {
      main: coral,
      light: grey200,
    },
    info: {
      main: alpha(green, 0.2),
      light: green,
      contrastText: googleEvent,
    },
  });

  palette.grey[500] = alpha(palette.common.white, 0.5);
  palette.grey[400] = alpha(palette.common.black, 0.08);
  palette.grey[300] = grey300;
  palette.grey[200] = grey200;
  palette.grey[100] = grey100;
  palette.grey[50] = grey50;

  return palette;
};

export default createPalette();
