import { put, call, takeLatest, select, all } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import { feedbackActionTypes } from './Constants';
import * as feedbackActions from './Actions';
import { getFeedbackType } from './Selectors';
import { handleGetUserRequest } from '../../pages/signIn/Sagas';

export function* handlePostFeedbackRequest({ payload }) {
  try {
    const feedbackType = yield select(getFeedbackType);

    yield call(processRequest, 'customer/post_feedback', 'POST', { ...payload.data, feedback_type: feedbackType });
    yield all([put(feedbackActions.postFeedbackSuccess()), put(feedbackActions.toggleModal({ open: false }))]);
    yield* handleGetUserRequest();
  } catch (e) {
    yield put(feedbackActions.postFeedbackError({ error: e }));
  }
}

export function* handlePostCancelFeedbackRequest({ payload }) {
  try {
    yield call(processRequest, 'customer/post_cancel_feedback', 'POST', payload.data);
    yield all([put(feedbackActions.postCancelFeedbackSuccess()), put(feedbackActions.toggleModal({ open: false }))]);
    yield* handleGetUserRequest();
  } catch (e) {
    yield put(feedbackActions.postCancelFeedbackError({ error: e }));
  }
}

export function* handlePostFeedbackInfoRequest() {
  try {
    yield call(processRequest, 'customer/feedback_info', 'POST', { feedbakcinfoTime: new Date() });
    yield put(feedbackActions.toggleModal({ open: false }));
  } catch (e) {
    yield put(feedbackActions.postFeedbackInfoError({ error: e }));
  }
}

export function* handleGetFeedbackInfoRequest() {
  try {
    const { data } = yield call(processRequest, 'customer/feedback_info');

    yield put(feedbackActions.getFeedbackInfoSuccess({ timeForSecond: data.feedbakcinfoTime }));
  } catch (e) {
    yield put(feedbackActions.getFeedbackInfoError({ error: e }));
  }
}

const feedbackModalContentSagas = [
  takeLatest(feedbackActionTypes.POST_FEEDBACK_REQUEST, handlePostFeedbackRequest),
  takeLatest(feedbackActionTypes.POST_CANCEL_FEEDBACK_REQUEST, handlePostCancelFeedbackRequest),
  takeLatest(feedbackActionTypes.POST_FEEDBACK_INFO_REQUEST, handlePostFeedbackInfoRequest),
  takeLatest(feedbackActionTypes.GET_FEEDBACK_INFO_REQUEST, handleGetFeedbackInfoRequest),
];

export default feedbackModalContentSagas;
