import { ANSWERS_TYPES, linkedinRegExp, QUESTIONS_TYPES } from '../../services/Constants';
import { SAVE_FROM } from './Constants';
import { isEmpty } from 'lodash';

const isItIntegerRange5WithoutAllAnswers = (question) => {
  if (
    !question.possible_question_answers[0] ||
    question.possible_question_answers[0].answer_type !== ANSWERS_TYPES.INTEGER_RANGE_5
  ) {
    return false;
  }

  const userAnswersLength = question.user_answers.length;
  const possibleAnswersLength = question.possible_question_answers.length;
  const countOfIntegerRange5Custom = question.possible_question_answers.filter(
    (item) => item.answer_type === ANSWERS_TYPES.INTEGER_RANGE_5_CUSTOM,
  ).length;

  if (countOfIntegerRange5Custom) {
    if (possibleAnswersLength === userAnswersLength) {
      return false;
    }

    if (
      possibleAnswersLength > userAnswersLength &&
      possibleAnswersLength - countOfIntegerRange5Custom === userAnswersLength
    ) {
      return false;
    }

    return true;
  }

  return possibleAnswersLength !== userAnswersLength;

  // return (userAnswersLength !== (isPresentIntegerRange5Custom ? possibleAnswersLength - 1 : possibleAnswersLength)) && question.possible_question_answers.length && question.possible_question_answers[0].answer_type === ANSWERS_TYPES.INTEGER_RANGE_5;
};

export function setSaveFromValue(onboarding, inprogressGroupId, inprogressPageId, isEdit) {
  if (
    inprogressGroupId === onboarding.length - 1 &&
    inprogressPageId === onboarding[onboarding.length - 1].pages.length - 1
  ) {
    if (isEdit) {
      return SAVE_FROM.SAVE_AND_RERUN;
    }

    return SAVE_FROM.FINISH;
  }

  return SAVE_FROM.NEXT;
}

function setInProgressPageId(isEdit, doWeHaveAtleastOneInprogressPage, inprogressPageId, lastPage) {
  if (isEdit) {
    return 0;
  }

  if (doWeHaveAtleastOneInprogressPage) {
    return inprogressPageId;
  }

  return lastPage;
}

function setInProgressGroupId(isEdit, doWeHaveAtleastOneInprogressPage, inprogressGroupId, lastId) {
  if (isEdit) {
    return 0;
  }

  if (doWeHaveAtleastOneInprogressPage) {
    return inprogressGroupId;
  }

  return lastId;
}

export const prepareOnboarding = (data, isEdit = false) => {
  let inprogressPageId = 0;
  let inprogressGroupId = 0;
  let pagesCount = 0;
  let finishedPagesCount = 0;
  let previousPageId = 0;
  let previousGroupId = 0;
  let doWeHaveAtleastOneInprogressPage = false;
  let inprogressPerPage;

  const preparedOnboarding = data.reduce((acc, group, indexGroup) => {
    const pages = group.pages.reverse().reduce((pagesAcc, page, index) => {
      inprogressPerPage = false;
      pagesCount += 1;
      for (let i = 0; i < page.questions.length; i++) {
        if (page.questions[i].possible_question_answers.length) {
          page.questions[i].possible_question_answers = page.questions[i].possible_question_answers.reverse();
        }

        // when we don`t have user_answers and field is required (for pages where we have required and optional fields)
        if (
          (!page.questions[i].user_answers.length || isItIntegerRange5WithoutAllAnswers(page.questions[i])) &&
          !doWeHaveAtleastOneInprogressPage &&
          page.questions[i].is_required
        ) {
          doWeHaveAtleastOneInprogressPage = true;
          inprogressPageId = index;
          inprogressGroupId = indexGroup;
        }

        if (
          (!page.questions[i].user_answers.length || isItIntegerRange5WithoutAllAnswers(page.questions[i])) &&
          page.questions[i].is_required
        ) {
          inprogressPerPage = true;
        }

        // still inprogress === false after previous sections
        if (!doWeHaveAtleastOneInprogressPage) {
          previousPageId = page.id;
          previousGroupId = group.id;
        }
      }

      if (!inprogressPerPage) {
        finishedPagesCount += 1;
      }

      // return mapped page
      pagesAcc.push({
        ...page,
        questions: page.questions.reverse(),
        inprogress: inprogressPerPage,
      });

      return pagesAcc;
    }, []);

    // return mapped group
    acc.push({
      ...group,
      pages,
      inprogress: inprogressPerPage,
    });

    return acc;
  }, []);

  return {
    preparedOnboarding,
    inprogressPageId: setInProgressPageId(
      isEdit,
      doWeHaveAtleastOneInprogressPage,
      inprogressPageId,
      preparedOnboarding[preparedOnboarding.length - 1].pages.length - 1,
    ),
    inprogressGroupId: setInProgressGroupId(
      isEdit,
      doWeHaveAtleastOneInprogressPage,
      inprogressGroupId,
      preparedOnboarding.length - 1,
    ),
    pagesCount,
    finishedPagesCount,
    previousGroupId,
    previousPageId,
  };
};

export const prepareIndexes = (options) => {
  const { inprogressPageId, inprogressGroupId, previousGroupId, previousPageId, onboarding } = options;
  let inprogressPageIndex = inprogressPageId;
  let inprogressGroupIndex = inprogressGroupId;

  if (inprogressGroupIndex === previousGroupId && inprogressPageIndex - previousPageId > 1) {
    inprogressPageIndex = previousPageId + 1;
  }

  if (inprogressGroupIndex !== previousGroupId) {
    inprogressPageIndex = previousPageId + 1;
    if (inprogressPageIndex > onboarding[previousGroupId].pages.length - 1) {
      inprogressGroupIndex = previousGroupId + 1;
      inprogressPageIndex = 0;
    } else {
      inprogressGroupIndex = previousGroupId;
    }
  }

  return {
    inprogressPageIndex,
    inprogressGroupIndex,
  };
};

const checkIfItIsPositiveNumber = (value, item) => {
  if (item.title.toLowerCase().indexOf('age') !== -1) {
    return Boolean(Number(value)) && Number(value) > 0;
  }

  if (Number(value) >= 0) {
    return `${Boolean(Number(value))}`;
  }

  return '';
};

const checkLinkedinUrl = (value) => Boolean(value.match(linkedinRegExp));

function multiInputsAreNotFilled(answer, value, possibleItem) {
  return (
    !answer ||
    isEmpty(answer) ||
    !Object.keys(answer).some((key) =>
      (answer[key].possible_answer_id === possibleItem.id ? value : answer[key].answer_value).trim(),
    )
  );
}

export const returnValueOrSetError = ({ errors_, value, text, item, setErrors, errors, possibleItem, answers }) => {
  // block check integer type

  if (value && item.type === QUESTIONS_TYPES.INTEGER && !checkIfItIsPositiveNumber(value, item)) {
    const number = Number.parseInt(value);

    return number || '';
  }

  if (
    typeof value === 'string' &&
    !value.trim() &&
    item.is_required &&
    ((item.type !== QUESTIONS_TYPES.STRING_3 &&
      item.type !== QUESTIONS_TYPES.STRING_5 &&
      item.type !== QUESTIONS_TYPES.MULTILINE_STRING_3) ||
      multiInputsAreNotFilled(answers[item.id], value, possibleItem))
  ) {
    setErrors((prevErrors) => ({ ...prevErrors, [item.id]: 'Is required' }));

    return value;
  }

  // block where we check do we have or not value for all types except Bool
  if (
    !value &&
    (!text || !text.trim()) &&
    item.type !== QUESTIONS_TYPES.BOOLEAN &&
    Object.keys(answers[item.id]).length === 1 &&
    item.is_required
  ) {
    errors_[item.id] = 'Is required';
    setErrors(errors_);
  }

  // block where we check value for bool, we have case with false
  if (
    (value || (!value && item.type === QUESTIONS_TYPES.BOOLEAN)) &&
    errors[item.id] &&
    (!possibleItem || possibleItem.answer_type !== ANSWERS_TYPES.SINGLE_CHOICE_CUSTOM) &&
    typeof errors_[item.id] === 'string'
  ) {
    delete errors_[item.id];
  }

  // linkedin

  if (value && item.type === QUESTIONS_TYPES.LINKEDIN_URL && !checkLinkedinUrl(value)) {
    errors_[item.id] = 'It seems that the link is invalid. Please check the URL';
    setErrors(errors_);
  }

  // block for radio buttons with input field
  if (
    (!answers[item.id] || (answers[item.id]?.[item.id] && !answers[item.id][item.id].custom_answer_value?.trim?.())) &&
    possibleItem &&
    (possibleItem.answer_type === ANSWERS_TYPES.SINGLE_CHOICE_CUSTOM ||
      possibleItem.answer_type === ANSWERS_TYPES.MULTI_CHOICE_CUSTOM)
  ) {
    errors_[item.id] = 'Is required';
    setErrors(errors_);
  }

  const isCustomChoiceType =
    possibleItem?.answer_type === ANSWERS_TYPES.SINGLE_CHOICE_CUSTOM ||
    possibleItem?.answer_type === ANSWERS_TYPES.MULTI_CHOICE_CUSTOM;

  if (isCustomChoiceType && errors_[item.id] && ((text && text.trim()) || !value)) {
    delete errors_[item.id];
  }

  if (
    answers[item.id] &&
    answers[item.id][item.id] &&
    possibleItem &&
    isCustomChoiceType &&
    text &&
    text.trim() &&
    !answers[item.id][item.id].custom_answer_value
  ) {
    delete errors_[item.id];
  }

  // block integer range 5
  if (value && possibleItem && possibleItem.answer_type === ANSWERS_TYPES.INTEGER_RANGE_5 && item.is_required) {
    errors_[item.id] && delete errors_[item.id][possibleItem.id];

    if (errors_[item.id] && !Object.keys(errors_[item.id]).length) {
      delete errors_[item.id];
    }
  }

  setErrors({ ...errors_ });

  return value;
};

function isStringValue(type) {
  return (
    type === QUESTIONS_TYPES.STRING_3 ||
    type === QUESTIONS_TYPES.STRING_5 ||
    type === QUESTIONS_TYPES.MULTILINE_STRING_3
  );
}

const checkDoWeHaveAnswer = (answers, question) => {
  const field = answers[question.id]?.[question.id];
  const ifItIsInputStringN = isStringValue(question.type);
  const questionAnswers = Object.keys(answers[question.id] ?? []);
  const ifItIsBoolType = field?.question_type === QUESTIONS_TYPES.BOOLEAN;
  const isSingleChoiceAnswer = question.possible_question_answers[0]?.answer_type === ANSWERS_TYPES.SINGLE_CHOICE;
  const answerValue = field?.answer_value;

  /*
    in some cases we do not have nested ids, like '215: { 215: {} }', but '215: { 130: {} }'. I think it was related
    with complex and unhandled form, so this is mostly like an edge case, in best scenario all these strange conditions
    should be removed and entire form should be re-written
   */
  const firstKey = questionAnswers[0];
  const hasAnswer =
    answers[question.id]?.[firstKey]?.answer_value ||
    (question.type === QUESTIONS_TYPES.MULTI_CHOICE &&
      questionAnswers.some((key) => answers[question.id]?.[key]?.answer_value));

  return (
    (field &&
      (answerValue ||
        (isSingleChoiceAnswer && field.possible_answer_id) ||
        (ifItIsBoolType && typeof answerValue === 'boolean'))) ||
    (answers[question.id] && (hasAnswer || ifItIsInputStringN))
  );
};

const checkDoWeHaveText = (answers, question) => {
  return !Object.keys(answers[question.id] ?? []).find((key) => {
    const item = answers[question.id]?.[key];

    if (!item) {
      return true;
    }

    return (
      (!item.custom_answer_value?.trim?.() || !item.answer_value) &&
      (item.answer_type === ANSWERS_TYPES.SINGLE_CHOICE_CUSTOM ||
        item.answer_type === ANSWERS_TYPES.MULTI_CHOICE_CUSTOM)
    );
  });
};

const checkIfItIsLinkedinUrl = (answers, question) => {
  if (
    answers[question.id] &&
    answers[question.id][question.id] &&
    answers[question.id][question.id].question_type === QUESTIONS_TYPES.LINKEDIN_URL
  ) {
    return checkLinkedinUrl(answers[question.id][question.id].answer_value);
  }

  return true;
};

const returnErrorsIfDoNotWeHaveAllAnswers = (answers, question) => {
  return question.possible_question_answers.reduce((acc, item) => {
    if (question.is_required && item.answer_type === ANSWERS_TYPES.INTEGER_RANGE_5) {
      const isAlreadyAnswered = answers[question.id] && answers[question.id][item.id];

      if (!isAlreadyAnswered) {
        acc[question.id] = { ...acc[question.id], [item.id]: true };
      }
    }

    if (item.answer_type === ANSWERS_TYPES.MULTI_CHOICE_CUSTOM) {
      const otherFieldIsRequiredWhenChecked =
        answers[question.id]?.[item.id] &&
        answers[question.id][item.id].answer_value &&
        !answers[question.id][item.id].custom_answer_value.trim();

      if (otherFieldIsRequiredWhenChecked) {
        acc[question.id] = { ...acc[question.id], [item.id]: true };
      }
    }

    return acc;
  }, {});
};

export const validationDoWeHaveAllRequiresValue = ({ tab, answers }) => {
  return tab.questions.reduce((acc, i) => {
    if (i.is_required && (!checkDoWeHaveAnswer(answers, i) || !checkDoWeHaveText(answers, i))) {
      acc[i.id] = 'Is required';
    }

    if (
      (i.is_required && i.possible_question_answers[0]?.answer_type === ANSWERS_TYPES.INTEGER_RANGE_5) ||
      i.type === QUESTIONS_TYPES.MULTI_CHOICE
    ) {
      const errors = returnErrorsIfDoNotWeHaveAllAnswers(answers, i);

      acc = { ...acc, ...errors };
    }

    if (!checkIfItIsLinkedinUrl(answers, i)) {
      acc[i.id] = 'It seems that the link is invalid. Please check the URL';
    }

    return acc;
  }, {});
};

function setAnswerValue(item) {
  if (item.custom_answer_value && item.question_type === QUESTIONS_TYPES.MULTI_CHOICE) {
    return true;
  }

  if (typeof item.possible_answer_id === 'object') {
    return item.possible_answer_id;
  }

  return `${item.possible_answer_id}`;
}

export const getMappedDataForSave = (answers) => {
  let children = [];

  Object.entries(answers).map(([, v]) => {
    children = [
      ...children,
      ...Object.entries(v).reduce((acc, [, v1]) => {
        if (v1) {
          if (!v1.answer_value && v1.possible_answer_id) {
            acc.push({
              ...v1,
              answer_value: setAnswerValue(v1),
              custom_answer_value: v1.custom_answer_value ? v1.custom_answer_value.trim() : v1.custom_answer_value,
            });
          } else {
            acc.push({
              ...v1,
              answer_value: typeof v1.answer_value === 'string' ? v1.answer_value.trim() : v1.answer_value,
              custom_answer_value: v1.custom_answer_value ? v1.custom_answer_value.trim() : v1.custom_answer_value,
            });
          }
        }

        return acc;
      }, []),
    ];

    return v;
  });

  return children;
};

export const getMappedDataFromResponse = (questions) => {
  let children = {};

  return questions.reduce((accum, item) => {
    if (item.user_answers.length) {
      children = item.user_answers.reduce((acc, value, index) => {
        if (
          value.answer_type &&
          (value.answer_type === QUESTIONS_TYPES.STRING_3 ||
            value.answer_type === QUESTIONS_TYPES.STRING_5 ||
            value.answer_type === QUESTIONS_TYPES.MULTILINE_STRING_3 ||
            value.answer_type === QUESTIONS_TYPES.MULTI_AUTOCOMPLETE_5 ||
            value.answer_type === QUESTIONS_TYPES.MULTILINE_LOCATION_AUTOCOMPLETE)
        ) {
          acc[index + 1] = value;

          return acc;
        }

        if (
          value.answer_type &&
          (value.answer_type === ANSWERS_TYPES.SINGLE_CHOICE ||
            value.answer_type === ANSWERS_TYPES.SINGLE_CHOICE_CUSTOM)
        ) {
          acc[value.question_id] = value;

          return acc;
        }

        if (value.possible_answer_id) {
          if (isItMultiChoice(value)) {
            acc[value.possible_answer_id] = { ...value, answer_value: !!value.answer_value };
          } else {
            acc[value.possible_answer_id] = value;
          }

          return acc;
        }

        if (value.answer_value === 0) {
          acc[value.question_id] = { ...value, answer_value: '0' };

          return acc;
        }

        acc[value.question_id] = value;

        return acc;
      }, {});

      if (Object.keys(children).length) {
        accum[item.id] = children;
      }
    }

    return accum;
  }, {});
};

export const checkDoNotWeHaveCustomTextValueForOtherFieldType = (possibleItem, answers) => {
  return (
    possibleItem &&
    (!answers[possibleItem.question] ||
      !answers[possibleItem.question][possibleItem.question] ||
      !answers[possibleItem.question][possibleItem.question].custom_answer_value) &&
    (possibleItem.answer_type === ANSWERS_TYPES.SINGLE_CHOICE_CUSTOM ||
      possibleItem.answer_type === ANSWERS_TYPES.MULTI_CHOICE_CUSTOM)
  );
};

export const removeFields__MultiInput = (indexForRemove, answers) => {
  delete answers[indexForRemove];
  const answersLikeArray = Object.entries(answers);

  return answersLikeArray.reduce((acc, [, v], index) => {
    if (answersLikeArray.length === 1 && v === null) {
      acc[1] = null;

      return acc;
    }

    acc[index + 1] = v;

    if (acc[index + 1]) {
      acc[index + 1].possible_answer_id = index + 1;

      return acc;
    }

    return acc;
  }, {});
};

const isItIntegerRange5Custom = (possibleItem) => possibleItem.answer_type === ANSWERS_TYPES.INTEGER_RANGE_5_CUSTOM;

const isItIntegerRange5 = (possibleItem) => possibleItem.answer_type === ANSWERS_TYPES.INTEGER_RANGE_5;

const isItMultiChoiceCustom = (possibleItem) => possibleItem.answer_type === ANSWERS_TYPES.MULTI_CHOICE_CUSTOM;

const isItString3 = (item) => item.type === QUESTIONS_TYPES.STRING_3;

const isItString5 = (item) => item.type === QUESTIONS_TYPES.STRING_5;

const isItMultilineString3 = (item) => item.type === QUESTIONS_TYPES.MULTILINE_STRING_3;

const isItMultilineString5 = (item) => item.type === QUESTIONS_TYPES.MULTI_AUTOCOMPLETE_5;

const isItMultiChoice = (possibleItem) => possibleItem.answer_type === ANSWERS_TYPES.MULTI_CHOICE;

const isItMultiLocationAutocomplete = (item) => item.type === QUESTIONS_TYPES.MULTILINE_LOCATION_AUTOCOMPLETE;

const isItSingleChoiceCustom = (possibleItem) => possibleItem.answer_type === ANSWERS_TYPES.SINGLE_CHOICE_CUSTOM;

const isItSingleChoice = (possibleItem) => possibleItem.answer_type === ANSWERS_TYPES.SINGLE_CHOICE;

export const handleAnswer = ({ answers, item, value, text, possibleItem, setAnswers, object, preparedValue }) => {
  if (answers[item.id]) {
    if (value === '' && !text) {
      const answers_ = Object.entries(answers[item.id]).reduce((acc, [k, v]) => {
        if (((possibleItem && possibleItem.id) || item.id) !== Number(k)) {
          acc[k] = { ...v };
        }

        return acc;
      }, {});

      if (!Object.keys(answers_).length) {
        const answersCopy = { ...answers };

        // delete answersCopy[item.id];

        answersCopy[item.id][(possibleItem && possibleItem.id) || item.id].answer_value = '';

        return setAnswers({
          ...answersCopy,
        });
      }

      return setAnswers({
        ...answers,
        [item.id]: answers_,
      });
    }

    if (
      possibleItem &&
      (isItIntegerRange5Custom(possibleItem) ||
        isItIntegerRange5(possibleItem) ||
        isItMultiChoiceCustom(possibleItem) ||
        isItString3(item) ||
        isItString5(item) ||
        isItMultilineString3(item) ||
        isItMultilineString5(item) ||
        isItMultiChoice(possibleItem) ||
        isItMultiLocationAutocomplete(item))
    ) {
      if (text) {
        object.answer_value = isItMultiChoiceCustom(possibleItem)
          ? true
          : value || answers[item.id][possibleItem.id].answer_value;
        object.custom_answer_value = text;
      } else {
        object.answer_value =
          isItMultiChoice(possibleItem) || isItMultiChoiceCustom(possibleItem)
            ? value
            : value || answers[item.id][possibleItem.id].answer_value;
        object.custom_answer_value = '';
      }

      setAnswers({
        ...answers,
        [item.id]: {
          ...answers[item.id],
          [(possibleItem && possibleItem.id) || item.id]: object,
        },
      });
    } else if (
      possibleItem &&
      (isItSingleChoice(possibleItem) || isItSingleChoiceCustom(possibleItem) || isItIntegerRange5Custom(possibleItem))
    ) {
      object.custom_answer_value = text;
      object.answer_value = isItSingleChoiceCustom(possibleItem)
        ? true
        : value || answers[item.id][item.id].answer_value;

      if (isItSingleChoice(possibleItem)) {
        object.custom_answer_value = '';
      }

      setAnswers({
        ...answers,
        [item.id]: {
          [item.id]: object,
        },
      });
    } else {
      setAnswers({
        ...answers,
        [item.id]: {
          [(possibleItem && possibleItem.id) || item.id]: object,
        },
      });
    }
  } else {
    if (text) {
      object.custom_answer_value = text;
    }

    if (value) {
      object.answer_value = preparedValue;
    }

    if (possibleItem && (isItSingleChoice(possibleItem) || isItSingleChoiceCustom(possibleItem))) {
      setAnswers({
        ...answers,
        [item.id]: {
          [item.id]: object,
        },
      });
    } else {
      setAnswers({
        ...answers,
        [item.id]: {
          [(possibleItem && possibleItem.id) || item.id]: object,
        },
      });
    }
  }
};

export const getFilename = (url) => {
  const splittedUrl = url.split('/');

  return splittedUrl[splittedUrl.length - 1];
};

export function setFormData(question) {
  const formData = new FormData();

  formData.append('file', question.answer_value[0].file);
  formData.append('filename', question.answer_value[0].file.name);
  formData.append('type', 'resume');

  return formData;
}
