import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';

import { Logo } from '../logos/icons';
import { primary } from '../../styles/colors';

import AppBarMenuItems from './AppBarMenuItems';
import MobileHeader from './MobileHeader';
import useDesktop from '../../common/hooks/useBreakpoints';
import classNames from 'classnames';
import { TOOLBAR_HEIGHT } from '../../constants/global';

interface StyleProps {
  isCareerMapperTab?: boolean;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: ({ isCareerMapperTab }: StyleProps) =>
      isCareerMapperTab ? primary.dark_blue : primary.dark_purple,
    boxShadow: '4px 4px 35px rgba(0, 0, 0, 0.1)',
  },
  toolBarContainer: {
    color: ({ isCareerMapperTab }: StyleProps) => (isCareerMapperTab ? primary.milk : primary.coral),
    zIndex: theme.zIndex.drawer + 1,
    padding: theme.spacing(0, 3),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 5),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 10),
    },
  },
  content: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  logoImg: {
    width: '97px',
    height: '32px',
    color: ({ isCareerMapperTab }: StyleProps) => (isCareerMapperTab ? primary.milk : primary.coral),
  },
  itemsBlock: {
    width: '100%',
  },
  logoBox: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      paddingRight: '50px',
    },
  },
  toolBar: {
    height: TOOLBAR_HEIGHT,
  },
}));

const AppBarCustom = () => {
  const { pathname } = useLocation();
  const isCareerMapperTab = pathname.startsWith('/career-mapper') || pathname === '/onboarding-progress';
  const classes = useStyles({ isCareerMapperTab });
  const { isDesktop } = useDesktop();

  return (
    <>
      <AppBar classes={{ root: classes.appBar }} elevation={0}>
        <Toolbar className={classNames(classes.toolBarContainer, classes.toolBar)}>
          <Box className={classes.content}>
            <Box display="flex" alignItems="center" className={classes.logoBox}>
              <Link to={'/home'}>
                <Logo viewBox="0 0 97 32" className={classes.logoImg} />
              </Link>
            </Box>
            {isDesktop ? (
              <AppBarMenuItems isCareerMapperTab={isCareerMapperTab} />
            ) : (
              <MobileHeader isCareerMapperTab={isCareerMapperTab} />
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar className={classes.toolBar} />
    </>
  );
};

export default memo(AppBarCustom);
