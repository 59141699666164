import RootState from 'expert/common/interfaces/rootState';
import Review from 'expert/common/components/reviewItem/interfaces/review';

export const isReviewsLoadingSelector = ({ reviews }: RootState): boolean => reviews.isLoading;
export const reviewsSelector = ({ reviews }: RootState): Review[] => reviews.items;
export const reviewSelector =
  (reviewId: number) =>
  ({ reviews }: RootState): Review | null =>
    reviews.items.find(({ id }) => id === reviewId) || null;
export const loadingReviewsIdsSelector = ({ reviews }: RootState): number[] => reviews.loadingReviewsIds;
export const isReviewLoadingSelector =
  (reviewId: number) =>
  ({ reviews }: RootState): boolean =>
    reviews.loadingReviewsIds.some((loadingId) => loadingId === reviewId);
export const publishedReviewsSelector = ({ reviews }: RootState): Review[] =>
  reviews.items.filter(({ isPublished }) => isPublished);
