import Feedback from 'expert/modules/expertFeedback/interfaces/feedback';
import keyMirror from 'keymirror';

export const feedbackSagaActionTypes = keyMirror({
  updateFeedback: null,
  fetchLastFeedback: null,
  refuseFeedback: null,
});

export const updateFeedback = (props: Feedback) => ({
  type: feedbackSagaActionTypes.updateFeedback,
  payload: props,
});

export const fetchLastFeedback = () => ({
  type: feedbackSagaActionTypes.fetchLastFeedback,
});

export const refuseFeedback = (props: number) => ({
  type: feedbackSagaActionTypes.refuseFeedback,
  payload: props,
});
