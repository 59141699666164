import { put, call, takeLatest } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import { articlesActionTypes } from './Constants';
import * as articlesActions from './Actions';

export function* handleGetArticlesRequest({ payload }) {
  try {
    const { data } = yield call(processRequest, `landing/${payload.data.feature}/article`);

    yield put(
      articlesActions.getArticlesSuccess({
        articles: data.results,
      }),
    );
  } catch (e) {
    yield put(articlesActions.getArticlesError({ error: e }));
  }
}

const getArticlesRequest = [takeLatest(articlesActionTypes.GET_ARTICLES_REQUEST, handleGetArticlesRequest)];

export default getArticlesRequest;
