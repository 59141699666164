import keyMirror from 'keymirror';

export const webSagaActionTypes = keyMirror({
  SEARCH_SKILLS: null,
});

export function searchSkills(searchText: string) {
  return {
    type: webSagaActionTypes.SEARCH_SKILLS,
    payload: searchText,
  };
}
