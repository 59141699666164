import INIT_GOOGLE_EVENTS_STATE from 'expert/redux/googleEvents/configs/initGoogoleEventsState';
import { GoogleEventsActionsTypes, GoogoleEventsActions } from 'expert/redux/googleEvents/googleEventsActions';
import GoogoleEventsState from 'expert/redux/googleEvents/interfaces/googoleEventsState';

const googleEventsReducer = (
  state: GoogoleEventsState = INIT_GOOGLE_EVENTS_STATE,
  action: GoogoleEventsActions,
): GoogoleEventsState => {
  switch (action.type) {
    case GoogleEventsActionsTypes.ResetGoogleEvents:
      return action.payload;
    case GoogleEventsActionsTypes.SetIsConnectedToGoogle:
      return {
        ...state,
        isConnected: action.payload,
      };
    case GoogleEventsActionsTypes.SetGoogleLoginURL:
      return {
        ...state,
        loginUrl: action.payload,
      };
    case GoogleEventsActionsTypes.SetGoogleEvents:
      return {
        ...state,
        items: action.payload,
      };
    case GoogleEventsActionsTypes.SetGoogleEventsLoadingState:
      return {
        ...state,
        isLoading: action.payload,
      };
    case GoogleEventsActionsTypes.SetGoogleEventsAndStopLoading:
      return {
        ...state,
        items: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default googleEventsReducer;
