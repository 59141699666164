import Notification from 'expert/modules/notifications/interfaces/notification';
import resToFeedback from 'expert/sagas/utils/resToFeedback';
import resToPayoutsHistoryData from 'expert/sagas/utils/resToPayoutsHistoryData';
import resToSession from 'expert/sagas/utils/resToSession';
import resToReview from 'expert/sagas/utils/resToReview';
import notificationTypeMap from 'expert/sagas/configs/notificationTypeMap';
import NotificationRes from 'expert/sagas/interfaces/notificationRes';
import notificationTitlesMap from 'expert/sagas/configs/notificationTitlesMap';

const resToNotification = (source: NotificationRes): Notification => {
  return {
    id: source.id,
    createdAt: source.created_at,
    isUnRead: source.unread,
    type: notificationTypeMap[source.type],
    notificationTitle: notificationTitlesMap[source.type],
    review: source.review_about_expert ? resToReview(source.review_about_expert) : undefined,
    feedback: source.review_about_customer ? resToFeedback(source.review_about_customer) : undefined,
    session: source.session ? resToSession(source.session) : undefined,
    payout: source.payment ? resToPayoutsHistoryData(source.payment) : undefined,
  };
};

export default resToNotification;
