import FetchNotificationsProps from 'expert/sagas/interfaces/fetchNotificationsProps';
import keyMirror from 'keymirror';

export const notificationsSagaActionTypes = keyMirror({
  FETCH_NOTIFICATIONS: null,
  MARK_AS_READ: null,
  FETCH_UNREAD_COUNT: null,
});

export function fetchNotifications(payload: FetchNotificationsProps) {
  return {
    type: notificationsSagaActionTypes.FETCH_NOTIFICATIONS,
    payload,
  };
}

export function markNotificationAsRead(notificationId: number) {
  return {
    type: notificationsSagaActionTypes.MARK_AS_READ,
    payload: notificationId,
  };
}

export function fetchUnreadNotificationsCount() {
  return {
    type: notificationsSagaActionTypes.FETCH_UNREAD_COUNT,
  };
}
