import actionFn from '../../common/utils/actionFn';
import ActionPayload from '../../common/interfaces/actionPayload';
import ExpertSkillsState from 'expert/redux/expertSkills/interfaces/expertSkillsState';
import INIT_EXPERT_SKILLS from 'expert/redux/expertSkills/configs/initExpertSkills';

export enum ExpertSkillsActionTypes {
  SetExpertSkills = 'SetExpertSkills',
  ResetExpertSkills = 'ResetExpertSkills',
}

export type ResetExpertSkillsAction = {
  type: ExpertSkillsActionTypes.ResetExpertSkills;
  payload: ExpertSkillsState;
};

export const resetExpertSkills = (): ResetExpertSkillsAction => ({
  type: ExpertSkillsActionTypes.ResetExpertSkills,
  payload: INIT_EXPERT_SKILLS,
});

export type SetExpertSkillsAction = {
  type: ExpertSkillsActionTypes.SetExpertSkills;
  payload: ActionPayload<ExpertSkillsState>;
};

export const setExpertSkills = actionFn<SetExpertSkillsAction>(ExpertSkillsActionTypes.SetExpertSkills);

export type ExpertSkillsActions = SetExpertSkillsAction | ResetExpertSkillsAction;
