import { onboardingActionTypes } from './Constants';

export function getOnboardingDataRequest(data) {
  return {
    type: onboardingActionTypes.GET_ONBOARDING_DATA_REQUEST,
    payload: {
      data,
    },
  };
}

export function getOnboardingDataSuccess(data) {
  return {
    type: onboardingActionTypes.GET_ONBOARDING_DATA_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getOnboardingDataError() {
  return {
    type: onboardingActionTypes.GET_ONBOARDING_DATA_ERROR,
  };
}

export function getLocationRequest(data) {
  return {
    type: onboardingActionTypes.GET_LOCATION_REQUEST,
    payload: {
      data,
    },
  };
}

export function getLocationSuccess(data) {
  return {
    type: onboardingActionTypes.GET_LOCATION_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getLocationError() {
  return {
    type: onboardingActionTypes.GET_LOCATION_ERROR,
  };
}

export function getLocationUpdateRequest(data) {
  return {
    type: onboardingActionTypes.GET_LOCATION_UPDATE_REQUEST,
    payload: {
      data,
    },
  };
}

export function getLocationUpdateSuccess(data) {
  return {
    type: onboardingActionTypes.GET_LOCATION_UPDATE_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getLocationUpdateError() {
  return {
    type: onboardingActionTypes.GET_LOCATION_UPDATE_ERROR,
  };
}

export function clearLocation(data) {
  return {
    type: onboardingActionTypes.CLEAR_LOCATION,
    payload: {
      data,
    },
  };
}

export function saveAndRerun(data) {
  return {
    type: onboardingActionTypes.SAVE_AND_RERUN_REQUEST,
    payload: { data },
  };
}

export function saveAndRerunSuccess(data) {
  return {
    type: onboardingActionTypes.SAVE_AND_RERUN_SUCCESS,
    payload: { data },
  };
}

export function saveQuestionsRequest(data, saveFrom) {
  return {
    type: onboardingActionTypes.SAVE_QUESTIONS_REQUEST,
    payload: {
      data,
      saveFrom,
    },
  };
}

export function saveQuestionsSuccess(data, saveFrom) {
  return {
    type: onboardingActionTypes.SAVE_QUESTIONS_SUCCESS,
    payload: {
      data,
      saveFrom,
    },
  };
}

export function saveQuestionsError() {
  return {
    type: onboardingActionTypes.SAVE_QUESTIONS_ERROR,
  };
}

export function setCurrentPageId(data) {
  return {
    type: onboardingActionTypes.SET_CURRENT_PAGE_ID,
    payload: {
      data,
    },
  };
}

export function setShowFinish(data) {
  return {
    type: onboardingActionTypes.SET_SHOW_FINISH,
    payload: {
      data,
    },
  };
}

export function endSubmittingOnboarding() {
  return { type: onboardingActionTypes.END_SUBMITTING_ONBOARDING };
}
