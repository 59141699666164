import actionFn from '../../common/utils/actionFn';
import ActionPayload from '../../common/interfaces/actionPayload';
import Expert from '../../common/interfaces/expert';
import INIT_EXPERT_STATE from 'expert/redux/expert/configs/initExpertState';
import ExpertState from 'expert/redux/expert/interfaces/expertState';

export enum ExpertActionTypes {
  SetExpert = 'SetExpert',
  ResetExpert = 'ResetExpert',
  SetExpertTimeZone = 'SetExpertTimeZone',
  SetExpertPauseState = 'SetExpertPauseState',
  SetExpertTempPassword = 'SetExpertTempPassword',
  SetExpertAgreement = 'SetExpertAgreement',
  SetExpertProfileStep = 'SetExpertProfileStep',
  SetPauseUpdatingState = 'SetPauseUpdatingState',
}

export type ResetExpertAction = {
  type: ExpertActionTypes.ResetExpert;
  payload: ExpertState;
};

export const resetExpert = (): ResetExpertAction => ({
  type: ExpertActionTypes.ResetExpert,
  payload: INIT_EXPERT_STATE,
});

export type SetPauseUpdatingStateAction = {
  type: ExpertActionTypes.SetPauseUpdatingState;
  payload: boolean;
};

export const setPauseUpdatingState = actionFn<SetPauseUpdatingStateAction>(ExpertActionTypes.SetPauseUpdatingState);

export type SetExpertPauseStateAction = {
  type: ExpertActionTypes.SetExpertPauseState;
  payload: boolean;
};

export const setExpertPauseState = actionFn<SetExpertPauseStateAction>(ExpertActionTypes.SetExpertPauseState);

export type SetExpertProfileStepAction = {
  type: ExpertActionTypes.SetExpertProfileStep;
  payload: ActionPayload<number>;
};

export const setExpertProfileStep = actionFn<SetExpertProfileStepAction>(ExpertActionTypes.SetExpertProfileStep);

export type SetExpertAgreementAction = {
  type: ExpertActionTypes.SetExpertAgreement;
  payload: ActionPayload<true>;
};

export const setExpertAgreement = (): SetExpertAgreementAction => ({
  type: ExpertActionTypes.SetExpertAgreement,
  payload: { value: true },
});

export type SetExpertAction = {
  type: ExpertActionTypes.SetExpert;
  payload: ActionPayload<Expert | null>;
};

export const setExpert = actionFn<SetExpertAction>(ExpertActionTypes.SetExpert);

export type SetExpertTempPasswordAction = {
  type: ExpertActionTypes.SetExpertTempPassword;
  payload: ActionPayload<false>;
};

export const setExpertTempPassword = (): SetExpertTempPasswordAction => ({
  type: ExpertActionTypes.SetExpertTempPassword,
  payload: { value: false },
});

export type SetExpertTimeZoneAction = {
  type: ExpertActionTypes.SetExpertTimeZone;
  payload: string;
};

export const setExpertTimeZone = actionFn<SetExpertTimeZoneAction>(ExpertActionTypes.SetExpertTimeZone);

export type ExpertActions =
  | SetExpertTempPasswordAction
  | SetExpertProfileStepAction
  | SetExpertAgreementAction
  | SetExpertPauseStateAction
  | SetExpertTimeZoneAction
  | SetPauseUpdatingStateAction
  | ResetExpertAction
  | SetExpertAction;
