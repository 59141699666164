import Session from 'expert/common/interfaces/session';
import actionFn from 'expert/common/utils/actionFn';
import SessionsState from 'expert/redux/sessions/interfaces/sessionsState';
import INIT_SESSIONS_STATE from 'expert/redux/sessions/configs/intSessionsState';

export enum SessionsActionTypes {
  SetSessions = 'SetSessions',
  ResetSessions = 'ResetSessions',
  SetLoadingSessionsState = 'SetLoadingSessionsState',
  SetReschedulingSessions = 'SetReschedulingSessions',
  SetCancelingSessions = 'SetCancelingSessions',
  SetCalendarSessionsLoadingState = 'SetCalendarSessionsLoadingState',
  SetCalendarSessions = 'SetCalendarSessions',
}

export interface ResetSessionsAction {
  type: SessionsActionTypes.ResetSessions;
  payload: SessionsState;
}

export const resetSessions = (): ResetSessionsAction => ({
  type: SessionsActionTypes.ResetSessions,
  payload: INIT_SESSIONS_STATE,
});

export interface SetCalendarSessionsAction {
  type: SessionsActionTypes.SetCalendarSessions;
  payload: Session[];
}

export const setCalendarSessions = actionFn<SetCalendarSessionsAction>(SessionsActionTypes.SetCalendarSessions);

export interface SetCalendarSessionsLoadingStateAction {
  type: SessionsActionTypes.SetCalendarSessionsLoadingState;
  payload: boolean;
}

export const setCalendarSessionsLoadingState = actionFn<SetCalendarSessionsLoadingStateAction>(
  SessionsActionTypes.SetCalendarSessionsLoadingState,
);

export interface SetSessionsAction {
  type: SessionsActionTypes.SetSessions;
  payload: Session[];
}

export const setSessions = actionFn<SetSessionsAction>(SessionsActionTypes.SetSessions);

export interface SetReschedulingSessionsAction {
  type: SessionsActionTypes.SetReschedulingSessions;
  payload: number[];
}

export const setReschedulingSessions = actionFn<SetReschedulingSessionsAction>(
  SessionsActionTypes.SetReschedulingSessions,
);

export interface SetCancelingSessionsAction {
  type: SessionsActionTypes.SetCancelingSessions;
  payload: number[];
}

export const setCancelingSessions = actionFn<SetCancelingSessionsAction>(SessionsActionTypes.SetCancelingSessions);

export interface SetLoadingSessionsStateAction {
  type: SessionsActionTypes.SetLoadingSessionsState;
  payload: boolean;
}

export const setLoadingSessionsState = actionFn<SetLoadingSessionsStateAction>(
  SessionsActionTypes.SetLoadingSessionsState,
);

export type SessionsActions =
  | SetSessionsAction
  | SetReschedulingSessionsAction
  | SetCancelingSessionsAction
  | SetCalendarSessionsAction
  | SetCalendarSessionsLoadingStateAction
  | ResetSessionsAction
  | SetLoadingSessionsStateAction;
