import { useMediaQuery, useTheme } from '@material-ui/core';

const useDesktop = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return { isDesktop };
};

export default useDesktop;
