import { jobPostingsTypes } from './Constans';

export function getJobPostingsRequest(data) {
  return {
    type: jobPostingsTypes.GET_JOB_POSTINGS_REQUEST,
    payload: {
      data,
    },
  };
}

export function getJobPostingsSuccess(data) {
  return {
    type: jobPostingsTypes.GET_JOB_POSTINGS_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getJobPostingsError(data) {
  return {
    type: jobPostingsTypes.GET_JOB_POSTINGS_ERROR,
    payload: {
      data,
    },
  };
}

export function postJobPostingsFavoriteRequest(data) {
  return {
    type: jobPostingsTypes.POST_JOB_POSTINGS_FAVORITE_REQUEST,
    payload: {
      data,
    },
  };
}

export function postJobPostingsFavoriteSuccess(data) {
  return {
    type: jobPostingsTypes.POST_JOB_POSTINGS_FAVORITE_SUCCESS,
    payload: {
      data,
    },
  };
}

export function postJobPostingsFavoriteError(data) {
  return {
    type: jobPostingsTypes.POST_JOB_POSTINGS_FAVORITE_ERROR,
    payload: {
      data,
    },
  };
}
