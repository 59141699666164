import React, { FC } from 'react';

interface ToastWithMailProps {
  text: string;
  email: string;
}

const ToastWithMail: FC<ToastWithMailProps> = ({ email, text }) => {
  return (
    <>
      {text}
      <a href={`mailto:${email}`}>{email}</a>
    </>
  );
};

export default ToastWithMail;
