import React from 'react';
import Bugsnag from '@bugsnag/js';
import { toast } from 'react-toastify';
// import SomethingWentWrong from '../../../pages/errors/500';

const { REACT_APP_NODE_ENV } = process.env;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    // You can also log the error to an error reporting service

    if (REACT_APP_NODE_ENV !== 'local') {
      Bugsnag.notify(error);
      toast.error('Something went wrong! Check bugsnag');
    }
  }

  // componentWillUnmount() {
  //     this.setState({ hasError: false });
  // }

  // clearError() {
  //     this.setState({ hasError: false });
  // }

  render() {
    // if (this.state.hasError) {
    //     // You can render any custom fallback UI
    //     return (
    //         <>
    //             <SomethingWentWrong clearError={this.clearError} />
    //         </>
    //     );
    // }

    return this.props.children;
  }
}

export default ErrorBoundary;
