import actionFn from 'expert/common/utils/actionFn';
import INIT_ACCOUNT_SETTINGS_STATE from 'expert/redux/accountSettings/configs/intAccountSettingsState';
import AccountSettingsState from 'expert/redux/accountSettings/interfaces/accountSettingsState';

export enum AccountSettingsActionTypes {
  SetIsPasswordUpdating = 'SetIsPasswordUpdating',
  SetIsUserDeleting = 'SetIsUserDeleting',
  SetPasswordError = 'SetPasswordError',
  ResetAccountSettings = 'ResetAccountSettings',
}

export type ResetAccountSettingsAction = {
  type: AccountSettingsActionTypes.ResetAccountSettings;
  payload: AccountSettingsState;
};

export const resetAccountSettings = (): ResetAccountSettingsAction => ({
  type: AccountSettingsActionTypes.ResetAccountSettings,
  payload: INIT_ACCOUNT_SETTINGS_STATE,
});

export type SetPasswordErrorAction = {
  type: AccountSettingsActionTypes.SetPasswordError;
  payload: string | null;
};

export const setPasswordError = actionFn<SetPasswordErrorAction>(AccountSettingsActionTypes.SetPasswordError);

export type SetIsPasswordUpdatingAction = {
  type: AccountSettingsActionTypes.SetIsPasswordUpdating;
  payload: boolean;
};

export const setIsPasswordUpdating = actionFn<SetIsPasswordUpdatingAction>(
  AccountSettingsActionTypes.SetIsPasswordUpdating,
);

export type SetIsUserDeletingAction = {
  type: AccountSettingsActionTypes.SetIsUserDeleting;
  payload: boolean;
};

export const setIsUserDeleting = actionFn<SetIsUserDeletingAction>(AccountSettingsActionTypes.SetIsUserDeleting);

export type AccountSettingsActions =
  | SetIsPasswordUpdatingAction
  | ResetAccountSettingsAction
  | SetIsUserDeletingAction
  | SetPasswordErrorAction;
