import { takeLatest } from 'redux-saga/effects';
import {
  EXPERT_MATCHES_REQUEST,
  OTHER_EXPERTS_REQUEST,
  FAVORITE_EXPERTS_REQUEST,
  TOGGLE_FAVORITE_EXPERT,
  OTHER_EXPERTS_FILTERS_REQUEST,
} from './actionTypes';
import {
  getExpertMatches,
  getFavoriteExperts,
  getOtherExperts,
  toggleFavoriteExpert,
  getOtherExpertsFilters,
} from './sagas';

const expertAssistMatchesWatchers = [
  takeLatest(EXPERT_MATCHES_REQUEST, getExpertMatches),
  takeLatest(OTHER_EXPERTS_REQUEST, getOtherExperts),
  takeLatest(FAVORITE_EXPERTS_REQUEST, getFavoriteExperts),
  takeLatest(TOGGLE_FAVORITE_EXPERT, toggleFavoriteExpert),
  takeLatest(OTHER_EXPERTS_FILTERS_REQUEST, getOtherExpertsFilters),
];

export default expertAssistMatchesWatchers;
