import KeyMirror from 'keymirror';

export const coursesTypes = KeyMirror({
  GET_COURSES_REQUEST: null,
  GET_COURSES_SUCCESS: null,
  GET_COURSES_ERROR: null,

  POST_JOB_COURSES_FAVORITE_REQUEST: null,
  POST_JOB_COURSES_FAVORITE_SUCCESS: null,
  POST_JOB_COURSES_FAVORITE_ERROR: null,
});
