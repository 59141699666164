import { careerMapperTypes } from './Constants';

const initialState = {
  loading: false,
  upgradeCards: [],
  reports: [],
};

export default function careerMapperReducer(state = initialState, action) {
  const { type, payload } = action;
  const { data } = payload || {};

  switch (type) {
    case careerMapperTypes.GET_CAREER_MAPPER_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case careerMapperTypes.GET_CAREER_MAPPER_REPORT_SUCCESS:
    case careerMapperTypes.GET_UPGRADE_CARDS_SUCCESS:
      return {
        ...state,
        ...data,
        loading: false,
      };

    case careerMapperTypes.GET_CAREER_MAPPER_REPORT_ERROR:
      return {
        ...state,
        ...data,
        loading: false,
      };

    default:
      return state;
  }
}
