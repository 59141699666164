import { paymentsActionTypes } from './Constants';

export function checkPaymentRequest(data) {
  return {
    type: paymentsActionTypes.CHECK_PAYMENT_REQUEST,
    payload: {
      data,
    },
  };
}

export function checkPaymentSuccess(data) {
  return {
    type: paymentsActionTypes.CHECK_PAYMENT_SUCCESS,
    payload: {
      data,
    },
  };
}

export function checkPaymentError(data) {
  return {
    type: paymentsActionTypes.CHECK_PAYMENT_ERROR,
    payload: {
      data,
    },
  };
}
