import actionFn from 'expert/common/utils/actionFn';
import GoogleEvent from 'expert/redux/googleEvents/interfaces/googleEvent';
import GoogoleEventsState from 'expert/redux/googleEvents/interfaces/googoleEventsState';
import INIT_GOOGLE_EVENTS_STATE from 'expert/redux/googleEvents/configs/initGoogoleEventsState';

export enum GoogleEventsActionsTypes {
  SetGoogleLoginURL = 'SetGoogleLoginURL',
  SetGoogleEvents = 'SetGoogleEvents',
  ResetGoogleEvents = 'ResetGoogleEvents',
  SetIsConnectedToGoogle = 'SetIsConnectedToGoogle',
  SetGoogleEventsLoadingState = 'SetGoogleEventsLoadingState',
  SetGoogleEventsAndStopLoading = 'SetGoogleEventsAndStopLoading',
}

export interface ResetGoogleEventsAction {
  type: GoogleEventsActionsTypes.ResetGoogleEvents;
  payload: GoogoleEventsState;
}

export const resetGoogleEvents = (): ResetGoogleEventsAction => ({
  type: GoogleEventsActionsTypes.ResetGoogleEvents,
  payload: INIT_GOOGLE_EVENTS_STATE,
});

export interface SetIsConnectedToGoogleAction {
  type: GoogleEventsActionsTypes.SetIsConnectedToGoogle;
  payload: boolean;
}

export const setIsConnectedToGoogle = actionFn<SetIsConnectedToGoogleAction>(
  GoogleEventsActionsTypes.SetIsConnectedToGoogle,
);

export interface SetGoogleLoginURLAction {
  type: GoogleEventsActionsTypes.SetGoogleLoginURL;
  payload: string;
}

export const setGoogleLoginURL = actionFn<SetGoogleLoginURLAction>(GoogleEventsActionsTypes.SetGoogleLoginURL);

export interface SetGoogleEventsAction {
  type: GoogleEventsActionsTypes.SetGoogleEvents;
  payload: GoogleEvent[];
}

export const setGoogleEvents = actionFn<SetGoogleEventsAction>(GoogleEventsActionsTypes.SetGoogleEvents);

export interface SetGoogleEventsLoadingStateAction {
  type: GoogleEventsActionsTypes.SetGoogleEventsLoadingState;
  payload: boolean;
}

export const setGoogleEventsLoadingState = actionFn<SetGoogleEventsLoadingStateAction>(
  GoogleEventsActionsTypes.SetGoogleEventsLoadingState,
);

export interface SetGoogleEventsAndStopLoadingAction {
  type: GoogleEventsActionsTypes.SetGoogleEventsAndStopLoading;
  payload: GoogleEvent[];
}

export const setGoogleEventsAndStopLoading = actionFn<SetGoogleEventsAndStopLoadingAction>(
  GoogleEventsActionsTypes.SetGoogleEventsAndStopLoading,
);

export type GoogoleEventsActions =
  | SetGoogleLoginURLAction
  | SetGoogleEventsAndStopLoadingAction
  | SetIsConnectedToGoogleAction
  | SetGoogleEventsLoadingStateAction
  | ResetGoogleEventsAction
  | SetGoogleEventsAction;
