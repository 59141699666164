import INIT_REVIEWS_STATE from 'expert/redux/reviews/configs/initReviewsState';
import { ReviewsActions, ReviewsActionTypes } from 'expert/redux/reviews/reviewsActions';
import ReviewsState from 'expert/redux/reviews/interfaces/reviewsState';

const reviewsReducer = (state: ReviewsState = INIT_REVIEWS_STATE, action: ReviewsActions): ReviewsState => {
  switch (action.type) {
    case ReviewsActionTypes.ResetReviews:
      return action.payload;
    case ReviewsActionTypes.SetLoadingReviewsIds:
      return {
        ...state,
        loadingReviewsIds: action.payload,
      };
    case ReviewsActionTypes.SetReviewsLoadingState:
      return {
        ...state,
        isLoading: action.payload.value,
      };
    case ReviewsActionTypes.SetReviews:
      return {
        ...state,
        items: action.payload.value,
      };
    case ReviewsActionTypes.SetReviewsAndStopLoading:
      return {
        ...state,
        items: action.payload.value,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reviewsReducer;
