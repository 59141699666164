import WidgetsState from './interfaces/widgetsState';
import { WidgetsActions, WidgetsActionTypes } from './widgetsActions';
import INIT_WIDGETS_STATE from './configs/initWidgetsState';

const widgetsReducer = (state: WidgetsState = INIT_WIDGETS_STATE, action: WidgetsActions): WidgetsState => {
  switch (action.type) {
    case WidgetsActionTypes.ResetWidgets:
      return action.payload;
    case WidgetsActionTypes.SetWidgets:
      return {
        ...state,
        data: action.payload,
      };
    case WidgetsActionTypes.SetWidgetsAndStopLoading:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case WidgetsActionTypes.SetWidgetsLoading:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default widgetsReducer;
