import { feedbackActionTypes } from './Constants';

export function postFeedbackRequest(data) {
  return {
    type: feedbackActionTypes.POST_FEEDBACK_REQUEST,
    payload: {
      data,
    },
  };
}

export function postFeedbackSuccess(data) {
  return {
    type: feedbackActionTypes.POST_FEEDBACK_SUCCESS,
    payload: {
      data,
    },
  };
}

export function postFeedbackError(error) {
  return {
    type: feedbackActionTypes.POST_FEEDBACK_ERROR,
    payload: {
      error,
    },
  };
}

export function postCancelFeedbackRequest(data) {
  return {
    type: feedbackActionTypes.POST_CANCEL_FEEDBACK_REQUEST,
    payload: {
      data,
    },
  };
}

export function postCancelFeedbackSuccess(data) {
  return {
    type: feedbackActionTypes.POST_CANCEL_FEEDBACK_SUCCESS,
    payload: {
      data,
    },
  };
}

export function postCancelFeedbackError(error) {
  return {
    type: feedbackActionTypes.POST_CANCEL_FEEDBACK_ERROR,
    payload: {
      error,
    },
  };
}

export function setFeedbackType(data) {
  return {
    type: feedbackActionTypes.SET_FEEDBACK_TYPE,
    payload: {
      data,
    },
  };
}

export function toggleModal(data) {
  return {
    type: feedbackActionTypes.TOGGLE_MODAL,
    payload: {
      data,
    },
  };
}

export function postFeedbackInfoRequest(data) {
  return {
    type: feedbackActionTypes.POST_FEEDBACK_INFO_REQUEST,
    payload: {
      data,
    },
  };
}

export function postFeedbackInfoSuccess(data) {
  return {
    type: feedbackActionTypes.POST_FEEDBACK_INFO_SUCCESS,
    payload: {
      data,
    },
  };
}

export function postFeedbackInfoError(error) {
  return {
    type: feedbackActionTypes.POST_FEEDBACK_INFO_ERROR,
    payload: {
      error,
    },
  };
}

export function getFeedbackInfoRequest(data) {
  return {
    type: feedbackActionTypes.GET_FEEDBACK_INFO_REQUEST,
    payload: {
      data,
    },
  };
}

export function getFeedbackInfoSuccess(data) {
  return {
    type: feedbackActionTypes.GET_FEEDBACK_INFO_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getFeedbackInfoError(error) {
  return {
    type: feedbackActionTypes.GET_FEEDBACK_INFO_ERROR,
    payload: {
      error,
    },
  };
}
