import { put, call, takeLatest, select, all } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import { processRequest } from '../../services/Api';
import { onboardingActionTypes, SAVE_FROM } from './Constants';
import * as onboardingActions from './Actions';
import * as signInActions from '../signIn/Actions';
import { prepareIndexes, prepareOnboarding, setFormData } from './Services';
import { getLimitOffset, getLocations, getPreviousPage } from './Selectors';
import { QUESTIONS_TYPES, PROGRESS_BAR_PERCENT } from '../../services/Constants';
import { calculateProgress } from '../../services/Helper';
import { endSubmittingOnboarding } from './Actions';
import { getUserSelector } from '../../selectors';

export function* handleGetOnboardingDataRequest() {
  try {
    const user = yield select(getUserSelector);
    const { data } = yield call(processRequest, 'customer/get_customer_questionary');
    const {
      preparedOnboarding,
      inprogressPageId,
      inprogressGroupId,
      pagesCount,
      finishedPagesCount,
      previousPageId,
      previousGroupId,
    } = prepareOnboarding(data, !!user?.path_mapper_tariff);

    yield put(
      signInActions.updateUserRequest({
        questionary_progress: calculateProgress(pagesCount, finishedPagesCount),
      }),
    );

    yield put(
      onboardingActions.getOnboardingDataSuccess({
        onboarding: preparedOnboarding,
        inprogressGroupId,
        inprogressPageId,
        pagesCount,
        finishedPagesCount,
        previousPageId,
        previousGroupId,
      }),
    );
  } catch (e) {
    yield put(onboardingActions.getOnboardingDataError());
  }
}

export function* handleGetLocationRequest({ payload }) {
  try {
    const { data } = yield call(processRequest, 'web/dict/locations', 'GET', payload.data);

    yield put(onboardingActions.getLocationSuccess({ locations: data }));
  } catch (e) {
    yield put(onboardingActions.getLocationError());
  }
}

export function* handleGetLocationUpdateRequest({ payload }) {
  try {
    const locations = yield select(getLocations);
    const { offset } = yield select(getLimitOffset);
    const howManyLeft = locations.count - offset;
    let offset_ = 0;

    if (offset <= howManyLeft) {
      offset_ = offset + locations.results.length;
    } else {
      offset_ = howManyLeft + locations.results.length;
    }

    const payloadData = {
      search: payload.data.search,
      offset: offset_,
    };
    const { data } = yield call(processRequest, 'web/dict/locations', 'GET', payloadData);

    yield put(onboardingActions.getLocationUpdateSuccess({ locations: data }));
  } catch (e) {
    yield put(onboardingActions.getLocationUpdateError());
  }
}

export function* saveAndRerunSaga({ payload }) {
  const dataForSave = [...payload.data];

  try {
    if (dataForSave[0]?.question_type === QUESTIONS_TYPES.FILE_DOC && Array.isArray(dataForSave[0].answer_value)) {
      const formData = setFormData(dataForSave[0]);

      const fileData = yield call(
        processRequest,
        'user/files',
        'POST',
        { file: formData, type: 'application/pdf' },
        true,
      );

      dataForSave[0].answer_value = fileData.data.file;
    }

    yield call(processRequest, 'customer/post_customer_questionary', 'POST', dataForSave);
    yield call(processRequest, 'customer/refresh_user_ml_data', 'POST');

    const userPayload = {
      questionary_progress: PROGRESS_BAR_PERCENT,
      is_started_onboarding: false,
    };

    const { data: user } = yield call(processRequest, 'user/', 'PUT', userPayload);

    yield all([
      put(signInActions.getUserSuccess({ user })),
      put(endSubmittingOnboarding()),
      put(replace('/career-mapper/overview')),
    ]);
  } catch {
    yield put(endSubmittingOnboarding());
  }
}

export function* handleSaveQuestionsRequest({ payload }) {
  try {
    const isFinish = payload.saveFrom === SAVE_FROM.FINISH;
    const isSaveAndExit = payload.saveFrom === SAVE_FROM.SAVE_AND_EXIT;
    const userData = yield select(getUserSelector);
    const isEdit = !!userData.path_mapper_tariff;
    const { inprogressPageId: previousPageId, inprogressGroupId: previousGroupId } = yield select(getPreviousPage);

    const dataForSave = [...payload.data];

    if (
      dataForSave[0] &&
      dataForSave[0].question_type === QUESTIONS_TYPES.FILE_DOC &&
      Array.isArray(dataForSave[0].answer_value)
    ) {
      const formData = setFormData(dataForSave[0]);

      const fileData = yield call(
        processRequest,
        'user/files',
        'POST',
        { file: formData, type: 'application/pdf' },
        true,
      );

      dataForSave[0].answer_value = fileData.data.file;
    }

    const { data } = yield call(processRequest, 'customer/post_customer_questionary', 'POST', dataForSave);

    const { preparedOnboarding, inprogressPageId, inprogressGroupId, pagesCount, finishedPagesCount } =
      prepareOnboarding(data);

    const userDataForUpdate = {
      questionary_progress:
        isFinish || isEdit ? PROGRESS_BAR_PERCENT : calculateProgress(pagesCount, finishedPagesCount),
      is_started_onboarding: isEdit ? false : !isFinish,
    };

    const { data: user } = yield call(processRequest, 'user/', 'PUT', userDataForUpdate);

    yield put(signInActions.getUserSuccess({ user }));

    if (isSaveAndExit) {
      return yield put(push(isEdit ? '/profile' : '/onboarding-progress'));
    }

    if (isFinish) {
      const { data: dataDiscount } = yield call(processRequest, 'payment/check_discount');

      if (dataDiscount.discount) {
        yield put(replace('/career-mapper'));
      } else {
        yield put(replace('/payments/info'));
      }

      return yield put(endSubmittingOnboarding());
    }

    const { inprogressPageIndex, inprogressGroupIndex } = prepareIndexes({
      inprogressPageId,
      inprogressGroupId,
      previousGroupId,
      previousPageId,
      onboarding: preparedOnboarding,
    });

    yield put(
      onboardingActions.saveQuestionsSuccess({
        onboarding: preparedOnboarding,
        inprogressGroupId: inprogressGroupIndex,
        inprogressPageId: inprogressPageIndex,
        pagesCount,
        finishedPagesCount,
      }),
    );
  } catch {
    yield put(endSubmittingOnboarding());
  }
}

const onboardingSagas = [
  takeLatest(onboardingActionTypes.GET_ONBOARDING_DATA_REQUEST, handleGetOnboardingDataRequest),
  takeLatest(onboardingActionTypes.GET_LOCATION_REQUEST, handleGetLocationRequest),
  takeLatest(onboardingActionTypes.GET_LOCATION_UPDATE_REQUEST, handleGetLocationUpdateRequest),
  takeLatest(onboardingActionTypes.SAVE_QUESTIONS_REQUEST, handleSaveQuestionsRequest),
  takeLatest(onboardingActionTypes.SAVE_AND_RERUN_REQUEST, saveAndRerunSaga),
];

export default onboardingSagas;
