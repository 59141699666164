import AgreementState from 'expert/redux/agreement/interfaces/agreementState';
import INIT_AGREEMENT_STATE from 'expert/redux/agreement/configs/initAgreementState';
import { AgreementActions, AgreementTypes } from 'expert/redux/agreement/agreementActions';

const agreementReducer = (state: AgreementState = INIT_AGREEMENT_STATE, action: AgreementActions): AgreementState => {
  switch (action.type) {
    case AgreementTypes.ResetAgreement:
      return action.payload;
    case AgreementTypes.SetAgreementOpened:
      return {
        ...action.payload.value,
      };
    case AgreementTypes.SetAgreementClosed:
      return {
        ...state,
        isOpened: action.payload.value,
      };
    default:
      return state;
  }
};

export default agreementReducer;
