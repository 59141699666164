import EditAvailabilityProps from 'expert/sagas/interfaces/editAvailabilityProps';
import EditAvailabilityQuery from 'expert/sagas/interfaces/editAvailabilityQuery';

const editAvailabilityPropsToParams = (source: EditAvailabilityProps): EditAvailabilityQuery => ({
  start: source.start,
  end: source.end,
  repeat_start: source.repeatStart,
  weekdays: source.weekdays,
  modify: source.editVariant,
});

export default editAvailabilityPropsToParams;
