import Paths from '../../sagas/interfaces/paths';

const EXPERT_PATHS: Paths = {
  setExpertProfileStep: 'expert/post_expert_profile_step',
  getExpertInfo: 'expert/',
  saveExpert: 'expert/',
  fetchReviews: 'expert/reviews',
  fetchReview: 'expert/reviews',
  updateReviewPublish: 'expert/reviews/',
  fetchWidgets: 'expert/dashboard/widget',
  cancelSession: 'expert/session_cancel',
  rescheduleSession: 'expert/session_reschedule',
  pauseExpert: 'expert/pause',
  fetchCalendarSessions: 'expert/session',
  lastCustomerFeedback: 'expert/reviews/unread',
  refuseCustomerFeedback: 'expert/reviews/read',
  saveCustomerFeedback: 'expert/reviews',
  getCustomerFeedback: 'expert/reviews',
  checkIsRescheduleAvailable: 'expert/is_available',
  getStripeSignUpLink: 'expert/stripe_sign_up_link',
};

export default EXPERT_PATHS;
