import { notificationsSelector } from 'expert/redux/notifications/notificationsSelectors';
import FetchNotificationsProps from 'expert/sagas/interfaces/fetchNotificationsProps';
import updateNotifications from 'expert/sagas/utils/updateNotifications';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  setNotifications,
  setNotificationsAndTotalCount,
  setNotificationsLoadingStatus,
  setUnreadNotificationsCount,
} from 'expert/redux/notifications/notificationsActions';
import { toast } from 'react-toastify';
import MESSAGES from 'expert/common/configs/messages';
import { processRequest } from 'services/Api';
import notificationsPaths from './notificationsPaths';
import resToNotification from 'expert/sagas/utils/resToNotification';
import { notificationsSagaActionTypes } from './notificationsSagasActions';
import shouldHandleError from 'expert/sagas/utils/shouldHandleError';

const handleFetchNotifications = function* ({ payload }: { payload: FetchNotificationsProps }) {
  try {
    yield put(setNotificationsLoadingStatus(true));
    const { data } = yield call(processRequest, notificationsPaths.fetchNotifications, 'GET', payload);

    yield put(setNotificationsAndTotalCount({ items: data.results.map(resToNotification), totalCount: data.count }));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.fetchNotifications);
  } finally {
    yield put(setNotificationsLoadingStatus(false));
  }
};

const handleMarkAsRead = function* ({ payload }: { payload: number }) {
  try {
    const { data } = yield call(processRequest, `${notificationsPaths.markAsRead}${payload}`, 'POST');

    yield put(setNotifications(updateNotifications(yield select(notificationsSelector), resToNotification(data))));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.markAsRead);
  }
};

const handleFetchUnreadNotifications = function* () {
  try {
    const { data } = yield call(processRequest, notificationsPaths.fetchUnreadCount);

    yield put(setUnreadNotificationsCount(data.unread_notifications_count));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.markAsRead);
  }
};

const notificationsSagas = [
  takeLatest(notificationsSagaActionTypes.FETCH_NOTIFICATIONS, handleFetchNotifications),
  takeLatest(notificationsSagaActionTypes.MARK_AS_READ, handleMarkAsRead),
  takeLatest(notificationsSagaActionTypes.FETCH_UNREAD_COUNT, handleFetchUnreadNotifications),
];

export default notificationsSagas;
