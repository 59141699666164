import ActionPayload from '../../common/interfaces/actionPayload';
import actionFn from '../../common/utils/actionFn';
import PrivacyTermsState from './interfaces/privacyTermsState';
import INIT_PRIVACY_TERMS_STATE from 'expert/redux/privacyTerms/configs/initPrivacyTermsState';

export enum PrivacyTermsTypes {
  ResetPrivacyTerms = 'ResetPrivacyTerms',
  SetPrivacyTermsOpened = 'SetPrivacyTermsOpened',
  SetPrivacyTermsClosed = 'SetPrivacyTermsClosed',
}

export type ResetPrivacyTermsAction = {
  type: PrivacyTermsTypes.ResetPrivacyTerms;
  payload: PrivacyTermsState;
};

export const resetPrivacyTerms = (): ResetPrivacyTermsAction => ({
  type: PrivacyTermsTypes.ResetPrivacyTerms,
  payload: INIT_PRIVACY_TERMS_STATE,
});

export type SetPrivacyTermsOpenedAction = {
  type: PrivacyTermsTypes.SetPrivacyTermsOpened;
  payload: ActionPayload<PrivacyTermsState>;
};

export const setPrivacyTermsOpened = actionFn<SetPrivacyTermsOpenedAction>(PrivacyTermsTypes.SetPrivacyTermsOpened);

export type SetPrivacyTermsClosedAction = {
  type: PrivacyTermsTypes.SetPrivacyTermsClosed;
  payload: ActionPayload<false>;
};

export const setPrivacyTermsClosed = (): SetPrivacyTermsClosedAction => ({
  type: PrivacyTermsTypes.SetPrivacyTermsClosed,
  payload: { value: false },
});

export type PrivacyTermsActions = SetPrivacyTermsClosedAction | ResetPrivacyTermsAction | SetPrivacyTermsOpenedAction;
