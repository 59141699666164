import INIT_PAYOUT_STATE from 'expert/redux/payout/configs/initPayoutState';
import PayoutState from 'expert/redux/payout/interfaces/payoutState';
import { PayoutActions, PayoutActionTypes } from 'expert/redux/payout/payoutActions';

const payoutReducer = (state: PayoutState = INIT_PAYOUT_STATE, action: PayoutActions): PayoutState => {
  switch (action.type) {
    case PayoutActionTypes.SetStripeSetUpUrlLoading:
      return { ...state, stripeSetUpUrlLoading: action.payload };
    case PayoutActionTypes.SetStripeSetUpUrl:
      return { ...state, stripeSetupUrl: action.payload };
    case PayoutActionTypes.SetPayoutHistoryRes:
      return {
        ...state,
        history: action.payload.history,
        historyTotalCount: action.payload.historyTotalCount,
        isHistoryLoading: false,
      };
    case PayoutActionTypes.SetPayoutHistory:
      return {
        ...state,
        history: action.payload,
      };
    case PayoutActionTypes.SetPayoutHistoryLoadingState:
      return {
        ...state,
        isHistoryLoading: action.payload,
      };
    default:
      return state;
  }
};

export default payoutReducer;
