import { put, call, takeLatest, take } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { processRequest } from '../../services/Api';
import { paymentsActionTypes } from './Constants';
import * as paymentsActions from './Actions';
import * as signInActions from '../signIn/Actions';
import { signInActionTypes } from '../signIn/Constants';

export function* handleCheckPaymentRequest({ payload }) {
  try {
    const { paymentIntentResponse } = payload.data;

    yield call(processRequest, 'payment/check_payment', 'POST', paymentIntentResponse);
    yield put(signInActions.getUserRequest());
    yield take(signInActionTypes.GET_USER_SUCCESS);
    yield put(replace('/career-mapper'));
  } catch (e) {
    yield put(paymentsActions.checkPaymentError({ error: e }));
    yield put(replace('/career-mapper'));
  }
}

const paymentsSagas = [takeLatest(paymentsActionTypes.CHECK_PAYMENT_REQUEST, handleCheckPaymentRequest)];

export default paymentsSagas;
