import Review from 'expert/common/components/reviewItem/interfaces/review';
import Session from 'expert/common/interfaces/session';

interface UpdatePastSessionReviewProps {
  pastSessions: Session[];
  newReview: Review;
}

const updatePastSessionReview: (props: UpdatePastSessionReviewProps) => Session[] = ({ newReview, pastSessions }) =>
  pastSessions.map((session) => (session.review?.id === newReview.id ? { ...session, review: newReview } : session));

export default updatePastSessionReview;
