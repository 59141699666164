import { createTheme } from '@material-ui/core';
import typography from 'expert/theme/expertTypography';
import palette from 'expert/theme/expertPalette';

const textDialogTheme = createTheme({
  overrides: {
    MuiDialog: {
      // Name of the component ⚛️ / style sheet
      paperWidthSm: {
        // Name of the rule
        maxWidth: '1060px',
      },
    },
  },
  palette,
  typography,
});

export default textDialogTheme;
