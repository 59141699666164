import * as types from './actionTypes';

// saga listeners
export const getExpertAssistOnboarding = () => ({ type: types.GET_EXPERT_ASSIST_ONBOARDING });
export const setExpertAssistOnboarding = (payload, currentStepProgress: number, nextUrl?: string | null) => ({
  type: types.SET_EXPERT_ASSIST_ONBOARDING,
  payload,
  currentStepProgress,
  nextUrl,
});
export const eaFinishOnboarding = (payload, isSaveAndExit) => ({
  type: types.EA_FINISH_ONBOARDING,
  payload,
  isSaveAndExit,
});
export const eaSaveAndRerun = (payload) => ({ type: types.EA_SAVE_AND_RERUN, payload });
export const eaStartOnboarding = () => ({ type: types.EA_START_ONBOARDING });
export const eaSetCongratulationsViewed = () => ({ type: types.EA_CONGRATULATIONS_VIEWED });

// simple action creators
export const getExpertAssistOnboardingSuccess = (payload) => ({
  type: types.GET_EXPERT_ASSIST_ONBOARDING_SUCCESS,
  payload,
});
export const getExpertAssistOnboardingError = (payload) => ({
  type: types.GET_EXPERT_ASSIST_ONBOARDING_ERROR,
  payload,
});

export const setExpertAssistOnboardingSuccess = (payload) => ({
  type: types.SET_EXPERT_ASSIST_ONBOARDING_SUCCESS,
  payload,
});
export const setExpertAssistOnboardingError = (payload) => ({
  type: types.SET_EXPERT_ASSIST_ONBOARDING_ERROR,
  payload,
});

export const setSaveAndExit = () => ({ type: types.SET_SAVE_AND_EXIT });
export const clearSaveAndExit = () => ({ type: types.CLEAR_SAVE_AND_EXIT });
export const saveAndExitSuccess = () => ({ type: types.EA_SAVE_AND_EXIT_SUCCESS });

export const clearCongratulation = () => ({ type: types.EA_CLEAR_CONGRATULATION });

export const eaFinishOnboardingSuccess = (showCongratulations: boolean) => ({
  type: types.EA_FINISH_ONBOARDING_SUCCESS,
  payload: showCongratulations,
});
export const eaFinishOnboardingError = (payload) => ({ type: types.EA_FINISH_ONBOARDING_ERROR, payload });

export const eaSaveAndRerunError = (payload) => ({ type: types.EA_SAVE_AND_RERUN_ERROR, payload });

export const eaStartOnboardingSuccess = () => ({ type: types.EA_START_ONBOARDING_SUCCESS });
export const eaStartOnboardingError = (payload) => ({ type: types.EA_START_ONBOARDING_ERROR, payload });

export const eaSetResume = (payload) => ({ type: types.EA_SET_RESUME, payload });
