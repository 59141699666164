import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import rootSagaCreator from './rootSaga';
import rootReducer from './rootReducer';
import history from './history';

const middlewares = [];
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancers = composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history), ...middlewares));

const store = createStore(rootReducer, enhancers);

sagaMiddleware.run(rootSagaCreator);
export { store };
