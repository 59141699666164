import KeyMirror from 'keymirror';

export const jobPostingsTypes = KeyMirror({
  GET_JOB_POSTINGS_REQUEST: null,
  GET_JOB_POSTINGS_SUCCESS: null,
  GET_JOB_POSTINGS_ERROR: null,

  POST_JOB_POSTINGS_FAVORITE_REQUEST: null,
  POST_JOB_POSTINGS_FAVORITE_SUCCESS: null,
  POST_JOB_POSTINGS_FAVORITE_ERROR: null,
});
