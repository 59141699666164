import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import expertTheme from 'expert/theme/expertTheme';
import { ThemeProvider } from '@material-ui/core/styles';
import useStyles from './ExpertAgreement.styles';
import { Button } from '@material-ui/core';

interface ExpertAgreementProps {
  onAccept?: () => void;
}

const ExpertAgreement: FC<ExpertAgreementProps> = ({ onAccept }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={expertTheme}>
      <Typography variant="h2" className={classes.title}>
        Partnership Agreement
      </Typography>
      <Typography variant="body1" className={classes.date}>
        Effective Date: November 1, 2021
      </Typography>
      <div id="modal_desc">
        <span className="reqular__text">
          This Pave Provider Agreement (this “Agreement”) is dated as of the date digitally signed below by Provider
          (the “Effective Date”) and is between you, the individual that electronically signs this Agreement, and Arable
          Ventures LLC, a New York limited liability company doing business as Pave (“Pave”).
        </span>
        <br />
        <br />
        <span className="reqular__text">The parties agree as follows:</span>
        <br />
        <ol type="1">
          <li>
            <span className="reqular__text">
              <span className="bold">
                <span className="underline__text">Definitions</span>.
              </span>
            </span>
            <br />
            <ol type="a">
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Client</span>. A User that uses the Pave Solution to seek services
                  from Provider and has agreed to a User Agreement.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Coach</span>. A User that is trained or certified as a career coach.
                  A Coach will provide coaching services on the Pave Solution.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Expert</span>. A User with at least five years’ experience in a
                  specific industry, role, or company who is willing to share such experience with or answer questions
                  from Clients on the Pave Solution.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Fee</span>. The total fee charged for providing Provider Services to
                  Clients. The Fee consists of the Provider Fee and the Pave Fee.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Interactions</span>. Calls, meetings or contacts between Provider
                  and a Client.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Pave Fee</span>. The portion of the Fee to be paid to Pave.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Pave Agreements</span>. This Agreement, the User Agreement, the
                  Terms of Use, the Privacy Policy, any other agreements or documents incorporated into any of the
                  foregoing, any Pave rules, policies, or procedures, and/or Pave community guidelines.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Pave Solution</span>. An on-line platform made available to Users
                  via a PAVE account through the Pave website or app, which provide Users with access to and use of PAVE
                  programs, information, services, and products, including the opportunity to purchase certain products
                  or other services. “<span className="underline__text">Pave Solution</span>” is synonymous with “
                  <span className="underline__text">Services</span>” in the User Agreement.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Privacy Policy</span>. The document that sets forth Pave’s privacy
                  policies and procedures.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Provider</span>. An Expert or Coach who provides Provider Services
                  to Clients on the Pave Solution and has agreed to a User Agreement. For purposes of this Agreement,
                  you are referred to as “<span className="underline__text">Provider</span>.”
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Provider Fee</span>. The portion of the Fee to be paid to Provider.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text"> Provider Services</span>. The coaching or expert services that
                  Provider provides to Clients through the Pave Solution.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Terms of Use</span>. The document that sets forth the terms for
                  access by any User or other visitor to Pave’s website and/or mobile app.{' '}
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">User</span>. Any individual or organization that uses the Pave
                  Solution, including any Client, any other user, and any Provider.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">User Agreement.</span>. An agreement between a User and Pave to use
                  the Pave Solution.
                </span>
                <br />
                <br />
              </li>
            </ol>
            <br />
            <br />
            <br />
          </li>
          <li>
            <span className="reqular__text">
              <span className="bold">
                <span className="underline__text">Provider’s Relationship with Pave</span>.
              </span>
            </span>
            <br />
            <span className="reqular__text">
              Pave uses digital technology, marketing, and networking skills to connect individuals seeking career and
              job advice to providers of expert and coaching services through the proprietary Pave Solution. Pave does
              not, and does not try to, direct or control Provider with respect to Provider’s performance and
              Interactions with Clients. Provider is responsible for their Interactions with Clients including
              scheduling and duration of meetings, declining or ignoring Client requests for a call, and canceling an
              accepted request. Pave, in its discretion, may deactivate or otherwise restrict a Provider from accessing
              the Pave Solution if Provider breaches or violates the Pave Agreements or if Provider threatens or causes
              harm to Pave, the Pave Solution, Users, or other visitors. Provider will use the Pave Solution in
              conformity with the Pave Agreements.
            </span>
            <br />
            <br />
            <br />
          </li>
          <li>
            <span className="reqular__text">
              <span className="bold">
                <span className="underline__text">Provider’s Relationship with Pave</span>.
              </span>
            </span>
            <br />
            <ol type="a">
              <li>
                <span className="reqular__text">
                  After using the Provider Services and Interacting with a Provider, a Client may be prompted by Pave to
                  provide a rating of the Provider Services and Provider, and, optionally, to provide comments or
                  feedback. Provider may not ask or influence Clients to provide specific ratings, comments, or
                  feedback.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  To continue to receive access to the Pave Solution, Provider acknowledges that he or she must maintain
                  an average rating above one star. If Provider’s average rating falls below two stars, Pave may, in its
                  discretion, give Provider a limited period to raise their average rating. If Provider does not
                  increase the average rating as required within the specified period, Pave may deactivate Provider’s
                  access to the Pave Solution.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  Provider understands that repeated failure to accept Client requests for Provider Services creates a
                  negative experience for Clients. Accordingly, Provider will strive to accept a substantial portion of
                  Client requests for Provider Services. If Provider does not desire to provide Provider Services for a
                  period of time, Provider will update their calendar to reflect such preferences.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  Pave may share and display ratings and comments in any manner in connection with the business without
                  attribution to or further approval by Provider. Pave is not the publisher of Client ratings and
                  comments but reserves the right, but is not obligated, to edit or remove comments if they: (i) include
                  obscenities or other objectionable content; (ii) contain an individual’s name or other personal
                  information, or (iii) violate any privacy laws or standards.
                </span>
                <br />
                <br />
              </li>
            </ol>
            <br />
            <br />
            <br />
          </li>
          <li>
            <span className="reqular__text">
              <span className="bold">
                <span className="underline__text">Provider Requirements</span>.
              </span>
            </span>
            <br />
            <ol type="a">
              <li>
                <span className="reqular__text">
                  Provider represents and warrants that Provider shall perform all of Provider’s obligations under this
                  Agreement: (i) in accordance with the terms of the Pave Agreements; and (ii) in a professional,
                  timely, commercially diligent basis, in accordance with generally accepted industry and professional
                  standards, procedures and practices, and to the reasonable satisfaction of Pave. Pave may, in its
                  discretion, deactivate or restrict Provider’s access or use of the Pave Solution if Provider fails to
                  meet the requirements of this Agreement or for any other reason.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  Provider represents and warrants that the information Provider provides to Pave is true, accurate,
                  current, and complete. Provider shall ensure that this information is always kept accurate and up to
                  date. This is especially important with respect to Provider’s email address, since Provider’s email
                  address is the primary way Pave will communicate with Provider about accounts, correspondence, and
                  orders.
                </span>
                <br />
                <br />
              </li>
            </ol>
            <br />
            <br />
            <br />
          </li>
          <li>
            <span className="reqular__text">
              <span className="bold">
                <span className="underline__text">Privacy</span>.
              </span>
            </span>
            <br />
            <span className="reqular__text">
              Provider will keep all Client information confidential and private unless Provider receives written
              consent for disclosure by both Client and Pave. As noted above, Provider shall abide by the terms of
              Pave’s Privacy Policy which can be viewed here.
            </span>
            <br />
            <br />
            <br />
          </li>
          <li>
            <span className="reqular__text">
              <span className="bold">
                <span className="underline__text">Provider Relationship with Clients</span>.
              </span>
            </span>
            <br />
            <ol type="a">
              <li>
                <span className="reqular__text">
                  Provider is solely responsible for all of Provider’s actions or inactions with respect to Clients.
                  Provider shall indemnify and hold harmless, and upon Pave’s request, defend, Pave, its owners,
                  officers, directors, employees, agents, licensors, suppliers and any third-party service providers,
                  for any claims, liabilities, losses, costs, or expenses (including reasonable attorneys’ fees) arising
                  out of Provider’s actions or inactions with respect to Clients, any Interactions, use of the Pave
                  Solution, or breach of any of the Pave Agreements.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  rovider hereby consents that Pave may disclose information about Provider to a Client upon the
                  Client’s reasonable request (e.g., Provider deactivates the Pave Solution in the middle of providing
                  the Provider Services to a Client).
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  Provider understands that Clients are contractual clients of Pave and not Provider. Provider will not
                  circumvent the contractual and financial relationship between Pave and any Client. Provider will not
                  engage in any business directly and independently with any Client outside of the Pave Solution and
                  this Agreement.
                </span>
                <br />
                <br />
              </li>
            </ol>
            <br />
            <br />
            <br />
          </li>
          <li>
            <span className="reqular__text">
              <span className="bold">
                <span className="underline__text">Fees – Provider and Pave</span>.
              </span>
            </span>
            <br />
            <ol type="a">
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Fee</span>. The Fee will be the fee paid by a Client for Provider
                  Services. Subject to <span className="underline__text">Section 7(e)</span>, Provider will set the Fee
                  within limits set by Pave (e.g., not more than three times the average rate for all Provider Services)
                  (“Pave Limits”). The Fee will consist of the Provider Fee and the Pave Fee. Upon reasonable advance
                  notice, Pave, in its sole discretion, may, at any time, set and change the acceptable fee range and
                  Pave Limits.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Payment Process</span>.
                  <br />
                  <ol type="i">
                    <li>
                      <span className="reqular__text">
                        At the onset of Provider’s relationship with a Client, Pave will collect the Fee, deduct the
                        Pave Fee as a service fee, and remit to Provider the Provider Fee.
                      </span>
                    </li>
                    <li>
                      <span className="reqular__text">
                        As of the Effective Date, Pave will retain 12.5% of the total fees charged and will be used for,
                        among other things, the costs of doing business, marketing, platform administration, tech
                        maintenance, development, and operations. As of the Effective Date, the Provider shall receive
                        87.5% of the total Fee, until a minimum rating and usage is reached (see below).
                      </span>
                    </li>
                  </ol>
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Changes to Composition of Fee</span>. If, as determined by Pave in
                  its sole discretion, Provider receives high user ratings (such as an average of four stars or above)
                  and exhibits significant usage of the Pave Solution (such as participating in 30 completed sessions
                  within a 6 month period), Pave may reduce the Pave Fee to 10%, with the remaining 90% comprising the
                  Provider Fee. In addition, upon reasonable advance notice, Pave may, in its sole discretion, set and
                  alter the percentage of Fees that comprise each of the Pave Fee and Provider Fee.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Fee Collection</span>. Provider appoints Pave as Provider’s agent
                  for purposes of accepting fees and applicable taxes from Clients. Payment to Provider will be made
                  within thirty (30) days of Pave’s receipt of the total Fee from a Client.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Payment Adjustment</span>. Provider appoints Pave as Provider’s
                  agent for purposes of accepting fees and applicable taxes from Clients. Payment to Provider will be
                  made within thirty (30) days of Pave’s receipt of the total Fee from a Client.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Cancellation Charges</span>. Provider accepts that Clients may elect
                  to cancel requests for Provider Services that have been accepted by Provider at any time up to
                  twenty-four (24) hours prior to the scheduled time of such Provider Service. If a Client cancels an
                  accepted request for Provider Services with less than twenty-four (24) hours notice, Pave may charge
                  the Client a cancellation fee on behalf of Provider. If charged, this cancellation fee shall be deemed
                  the Fee for the cancelled Provider Services for the purpose of remittance to Provider.
                </span>
                <br />
                <br />
              </li>
            </ol>
            <br />
            <br />
            <br />
          </li>
          <li>
            <span className="reqular__text">
              <span className="bold">
                <span className="underline__text">Intellectual Property</span>.
              </span>
            </span>
            <br />
            <ol type="a">
              <li>
                <span className="reqular__text">
                  The Pave Solution, and all content, drawings, materials, specification, designs, methods, knowledge,
                  courses, teaching devices, educational and training programs, and other data furnished by Pave to
                  Provider, or owned by Pave and used by Provider, for the performance of the Provider Services may be
                  used by Provider only in connection with Provider’s performance of the Provider Services in accordance
                  with this Agreement, and, as between Pave and Provider, is and shall remain the property of Pave. Pave
                  shall retain all rights, title, and interest in and to such materials, including, without limitation,
                  patents, copyrights and other intellectual property rights in the Pave Solution and any ideas,
                  concepts, designs, inventions, and expressions embodied in such materials.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  Any original work product created by Provider for Pave and delivered to Pave for its use shall be
                  considered a “work-made-for-hire” owned by Pave. In the event the work product is found by a court of
                  competent jurisdiction not to be a “work-made-for-hire” within the meaning of the Copyright Act of
                  1976, as amended, Provider hereby expressly assigns to Pave all exclusive right, title and interest in
                  and to the work product without further consideration, and free from any claim or rights of retention
                  on the part of Provider. Provider shall execute all documents that may, in Pave’s sole discretion, be
                  required to perfect such assignment.
                </span>
                <br />
                <br />
              </li>
            </ol>
            <br />
            <br />
            <br />
          </li>
          <li>
            <span className="reqular__text">
              <span className="bold">
                <span className="underline__text">Term and Termination</span>.
              </span>
            </span>
            <br />
            <ol type="a">
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Term</span>. This Agreement shall be effective as of the Effective
                  Date and shall remain in effect for a period of one (1) year unless sooner terminated as provided
                  under this Agreement (the “<span className="underline__text">Initial Term</span>”). Upon expiration of
                  the Initial Term, the term of this Agreement shall be automatically renewed for successive one-year
                  periods (each, a “<span className="underline__text">Renewal Term</span>” and together with the Initial
                  Term, the “<span className="underline__text">Term</span>”).
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Termination for Convenience.</span>. Pave may terminate this
                  Agreement at any time with or without cause upon delivery of written notice. In the event of such
                  termination by Pave, Pave shall pay Provider in accordance with this Agreement for all Provider
                  Services satisfactorily performed prior to the effective date of the termination. Provider may
                  terminate this Agreement at any time with or without cause upon thirty (30) days’ prior written
                  notice, provided that Provider has completed all of Provider’s obligations to Clients.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Termination for Cause</span>. Either Provider or Pave may terminate
                  this Agreement for cause immediately upon written notice to the other party in the event that such
                  other party materially breaches this Agreement and such breach remains uncured for thirty (30)
                  calendar days following written notice to such party of the deficiency.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Termination for Insolvency.</span>. f either party becomes
                  insolvent, or institutes or has instituted against such party a petition for bankruptcy or is
                  adjudicated bankrupt, then such party shall notify the other party of such event and such other party
                  shall be entitled to terminate this Agreement immediately upon written notice to the original party.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Survival of Obligations</span>. The termination or expiration of
                  this Agreement shall not affect the survival and continuing validity of any provision that by its
                  terms or purpose is intended to survive the termination of this Agreement.
                </span>
                <br />
                <br />
              </li>
            </ol>
            <br />
            <br />
            <br />
          </li>
          <li>
            <span className="reqular__text">
              <span className="bold">
                <span className="underline__text">Confidentiality</span>.
              </span>
            </span>
            <br />
            <ol type="a">
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Confidential Information</span>. “Confidential Information” shall
                  mean all information of a confidential nature relating to Users disclosed to Provider by or on behalf
                  of a User. It includes private and personal information that a User communicates to a Provider.
                  Confidential Information shall not include information which is: (i) known to Provider prior to
                  disclosure by a User and that is not subject to another obligation of secrecy; (ii) hereafter lawfully
                  obtained from other sources on a non-confidential basis; or (iii) otherwise generally available to the
                  public, absent any breach of this confidentiality section by Provider.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Restricted Disclosure and Use of Confidential Information</span>.
                  Provider shall keep strictly confidential and not disclose to any third-party Confidential
                  Information. Provider shall not use the Confidential Information except in accordance with this
                  Agreement. In the event Provider becomes aware of any breach of the confidentiality and non-use
                  obligation contained in this confidentiality section, Provider shall promptly notify Pave of such
                  breach.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Permitted Disclosures</span>. Notwithstanding the foregoing,
                  Confidential Information may be disclosed by Provider to the extent required: (i) for the performance
                  of Provider Services; (ii) in order to comply with professional standards of conduct to which Provider
                  may be bound by law for preservation of the public safety, health, and welfare; and (iii) in order to
                  comply with any court order, statute or governmental directive.
                </span>
                <br />
                <br />
              </li>
            </ol>
            <br />
            <br />
            <br />
          </li>
          <li>
            <span className="reqular__text">
              <span className="bold">
                <span className="underline__text">Notices</span>.
              </span>
            </span>
            <br />
            <span className="reqular__text">
              Any notice required to be given hereunder shall be in writing and deemed to have been sufficiently given,
              (i) when delivered in person, (ii) on the fifth business day after mailing by registered or certified
              mail, postage prepaid, return receipt requested, or (iii) on the next business day after mailing by
              overnight courier service or sending by email, to the addresses and emails specified below:
            </span>
            <dl>
              <dt>If to Pave:</dt>
              <dd>
                <span className="reqular__text">Arable Ventures LLC DBA Pave Careers</span>
                <br />
                <span className="reqular__text">244 Madison Ave, Suite #173</span>
                <br />
                <span className="reqular__text">New York, NY 10016</span>
                <br />
                <span className="reqular__text">
                  Email: <a href="mailto:experts@joinpave.com">experts@joinpave.com</a>
                </span>
                <br />
                <span className="reqular__text">Attn: CEO</span>
              </dd>
            </dl>
            <dl>
              <dt>with copy to:</dt>
              <dd>
                <span className="reqular__text">Attn: David M. Slater, General Counsel</span>
                <br />
                <span className="reqular__text">240 West 73rd Street, Suite 701</span>
                <br />
                <span className="reqular__text">New York, New York 10023</span>
                <br />
                <span className="reqular__text">
                  Email: <a href="mailto:mail@davidmslater.com">mail@davidmslater.com</a>
                </span>
              </dd>
            </dl>
            <dl>
              <dt>If to Provider:</dt>
              <dd>
                <span className="reqular__text">At the address listed in the Pave Solution.</span>
              </dd>
            </dl>
            <span className="reqular__text">
              Additionally, a copy of such notice may be served by email to the listed email addresses for the party.
              For service solely by email to be effective, the party serving the notice by email must request and
              receive a written email acknowledgment from the recipient that the notice was received. The recipient
              shall email such acknowledgement promptly upon receipt of the notice. Pave may, by notice to Provider
              pursuant to this Agreement, change its physical and email addresses for purpose of notice. Provider may,
              by updating its address within the Pave Solution, change its physical and email addresses for purpose of
              notice.
            </span>
            <br />
            <br />
            <br />
          </li>
          <li>
            <span className="reqular__text">
              <span className="bold">
                <span className="underline__text">Miscellaneous Legal Provisions</span>.
              </span>
            </span>
            <br />
            <ol type="a">
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Governing Law</span>. The validity, interpretation and performance
                  of this Agreement shall be governed by and construed in accordance with the laws of the State of New
                  York applying to contracts fully executed and performed in that state.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Arbitration</span>. The parties shall follow the arbitration process
                  set forth in the User Agreement.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Independent Contractor</span>. Provider shall perform the Provider
                  Services as an independent contractor with exclusive control of the manner and means of performing the
                  services in accordance with the requirements of this Agreement and Pave’s rules, regulations, and
                  policies. Provider has no authority to act or make any agreements or representations on behalf of
                  Pave.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Amendments</span>. Pave may change prospectively the terms of this
                  Agreement at any time and post the revised Agreement at this link. Provider is responsible for
                  checking back here periodically to review any important changes in this Agreement. Provider may not
                  amend or modify this Agreement without the written consent of Pave.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Severability</span>. If any provision of this Agreement is
                  determined to be invalid, illegal, or unenforceable, it shall not affect the enforceability of any
                  other provision of this Agreement. Rather, the invalid, illegal, or unenforceable provision shall be
                  modified so that it is valid, legal, and enforceable and to the fullest extent possible, reflects the
                  intention of the parties.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Force Majeure</span>. No party shall be liable for any failure to
                  perform or any delays in performance, and no party shall be deemed to be in breach or default of such
                  party’s obligations set forth in this Agreement, if, to the extent, and for as long as such failure or
                  delay is due to any causes that are beyond such party’s reasonable control and not due to such party’s
                  acts or omissions, including, without limitation, such causes as acts of God, flood, severe storm,
                  earthquake, civil disturbance, lockout, riot, order of any court or administrative body, embargo, acts
                  of government, war, acts of terrorism, pandemic, serious illness, family emergency, or other similar
                  causes (&quot;
                  <span className="underline__text">Force Majeure Event</span>&quot;). In the event of a Force Majeure
                  Event, the party prevented from or delayed in performing shall promptly give notice to the other party
                  and shall use commercially reasonable efforts to avoid or minimize the delay. The party affected by
                  the other party’s delay may elect to: (i) suspend performance and extend the time for performance for
                  the duration of the Force Majeure Event, or (ii) cancel all or any part of the unperformed part of
                  this Agreement.
                </span>
                <br />
                <br />
              </li>
              <li>
                <span className="reqular__text">
                  <span className="underline__text">Digital Signature</span>. This Agreement may be signed by the
                  parties digitally, and such digital signature will have the same binding effect as an original
                  signature.
                </span>
              </li>
            </ol>
          </li>
        </ol>
      </div>
      {onAccept && (
        <div className={classes.buttonWrap}>
          <Button type="submit" color="secondary" variant="contained" onClick={onAccept} className={classes.button}>
            Accept
          </Button>
        </div>
      )}
    </ThemeProvider>
  );
};

export default ExpertAgreement;
