import KeyMirror from 'keymirror';

export const signUpActionTypes = KeyMirror({
  SIGN_UP_REQUEST: null,
  SIGN_UP_SUCCESS: null,
  SIGN_UP_ERROR: null,

  GET_DESIRED_ROLE_REQUEST: null,
  GET_DESIRED_ROLE_SUCCESS: null,
  GET_DESIRED_ROLE_ERROR: null,

  GET_DESIRED_COMPANY_REQUEST: null,
  GET_DESIRED_COMPANY_SUCCESS: null,
  GET_DESIRED_COMPANY_ERROR: null,

  SET_FEATURE_REQUEST: null,
  SET_FEATURE_SUCCESS: null,
  SET_FEATURE_ERROR: null,
});

export const registrationData = {
  signIn: {},
  verificationEmail: {},
  forgotPassword: {},
  passwordWasSent: {},
  restorePassword: {},
  passwordRestored: {},
};

export const EMAIL = 'email';

export const MAX_LENGTH = 15;

export const PASSWORD_MAX_LENGTH = 20;
