import { call, put, takeLatest } from 'redux-saga/effects';
import { setExpertSkills } from 'expert/redux/expertSkills/expertSkillsActions';
import { processRequest } from 'services/Api';
import { webSagaActionTypes } from 'expert/sagas/web/webSagasActions';
import WEB_PATHS from 'expert/sagas/web/webPaths';
import { toast } from 'react-toastify';
import MESSAGES from 'expert/common/configs/messages';
import shouldHandleError from 'expert/sagas/utils/shouldHandleError';

const handleSearchExpertSkills = function* handleSearchExpertSkills({ payload }) {
  try {
    yield put(
      setExpertSkills({
        value: {
          skills: [],
          isLoading: true,
        },
      }),
    );

    if (payload.trim().length < 2) {
      yield put(
        setExpertSkills({
          value: {
            skills: [],
            isLoading: false,
          },
        }),
      );

      return;
    }

    const { data } = yield call(processRequest, `${WEB_PATHS.searchSkills}?search=${payload}`);

    yield put(
      setExpertSkills({
        value: {
          skills: data.results.map(({ name }) => name),
          isLoading: false,
        },
      }),
    );
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.getExpertSkills);
  }
};

const webSagas = [takeLatest(webSagaActionTypes.SEARCH_SKILLS, handleSearchExpertSkills)];

export default webSagas;
