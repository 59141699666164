import actionFn from 'expert/common/utils/actionFn';
import WidgetsData from './interfaces/widgetsData';
import WidgetsState from 'expert/redux/widgets/interfaces/widgetsState';
import INIT_WIDGETS_STATE from 'expert/redux/widgets/configs/initWidgetsState';

export enum WidgetsActionTypes {
  SetWidgets = 'SetWidgets',
  ResetWidgets = 'ResetWidgets',
  SetWidgetsAndStopLoading = 'SetWidgetsAndStopLoading',
  SetWidgetsLoading = 'SetWidgetsLoading',
}

export type ResetWidgetsAction = {
  type: WidgetsActionTypes.ResetWidgets;
  payload: WidgetsState;
};

export const resetWidgets = (): ResetWidgetsAction => ({
  type: WidgetsActionTypes.ResetWidgets,
  payload: INIT_WIDGETS_STATE,
});

export type SetWidgetsAction = {
  type: WidgetsActionTypes.SetWidgets;
  payload: WidgetsData;
};

export const setWidgets = actionFn<SetWidgetsAction>(WidgetsActionTypes.SetWidgets);

export type SetWidgetsAndStopLoadingAction = {
  type: WidgetsActionTypes.SetWidgetsAndStopLoading;
  payload: WidgetsData;
};

export const setWidgetsAndStopLoading = actionFn<SetWidgetsAndStopLoadingAction>(
  WidgetsActionTypes.SetWidgetsAndStopLoading,
);

export type SetWidgetsLoadingAction = {
  type: WidgetsActionTypes.SetWidgetsLoading;
  payload: boolean;
};

export const setWidgetsLoading = actionFn<SetWidgetsLoadingAction>(WidgetsActionTypes.SetWidgetsLoading);

export type WidgetsActions =
  | SetWidgetsAction
  | ResetWidgetsAction
  | SetWidgetsLoadingAction
  | SetWidgetsAndStopLoadingAction;
