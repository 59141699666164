import FetchGoogleCalendarEventsProps from 'expert/sagas/interfaces/fetchGoogleCalendarEventsProps';
import keyMirror from 'keymirror';

export const googleSagaActionTypes = keyMirror({
  getGoogleLoginUrl: null,
  fetchGoogleCalendarEvents: null,
  checkIsConnectedToGoogle: null,
});

export const checkIsConnectedToGoogle = () => ({
  type: googleSagaActionTypes.checkIsConnectedToGoogle,
});

export const getGoogleLoginUrl = () => ({
  type: googleSagaActionTypes.getGoogleLoginUrl,
});

export const fetchGoogleCalendarEvents = (props: FetchGoogleCalendarEventsProps) => ({
  type: googleSagaActionTypes.fetchGoogleCalendarEvents,
  payload: props,
});
