import { activityCoursesTypes } from './Conctsnts';

const initialState = {
  loading: false,
  coursesData: [],
};

export default function activityCoursesReducer(state = initialState, action) {
  const { type, payload } = action;
  const { data } = payload || {};

  switch (type) {
    case activityCoursesTypes.GET_CUSTOMER_JOB_COURSES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case activityCoursesTypes.GET_CUSTOMER_JOB_COURSES_SUCCESS:
      return {
        ...state,
        ...data,
        loading: false,
      };
    case activityCoursesTypes.GET_CUSTOMER_JOB_COURSES_ERROR:
      return {
        ...state,
        ...data,
        loading: false,
      };
    default:
      return state;
  }
}
