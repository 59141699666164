import ResumeState from 'expert/redux/resume/interfaces/resumeState';
import { ResumeActions, ResumeActionTypes } from 'expert/redux/resume/resumeActions';
import INIT_RESUME_STATE from 'expert/redux/resume/configs/initResumeState';

const resumeReducer = (state: ResumeState = INIT_RESUME_STATE, action: ResumeActions): ResumeState => {
  switch (action.type) {
    case ResumeActionTypes.ResetResume:
      return action.payload;
    case ResumeActionTypes.SetResumeFileName:
      return {
        ...state,
        fileName: action.payload.value,
      };
    default:
      return state;
  }
};

export default resumeReducer;
