import { careerMapperTypes } from './Constants';

export function getCareerMapperReportRequest(data, isFirstPathsFetch = false) {
  return {
    type: careerMapperTypes.GET_CAREER_MAPPER_REPORT_REQUEST,
    payload: {
      data,
      isFirstPathsFetch,
    },
  };
}

export function getCareerMapperReportSuccess(data) {
  return {
    type: careerMapperTypes.GET_CAREER_MAPPER_REPORT_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getCareerMapperReportError(data) {
  return {
    type: careerMapperTypes.GET_CAREER_MAPPER_REPORT_ERROR,
    payload: {
      data,
    },
  };
}

export function updateReportPathRequest(data) {
  return {
    type: careerMapperTypes.UPDATE_REPORT_PATH_REQUEST,
    payload: {
      data,
    },
  };
}

export function updateReportPathSuccess(data) {
  return {
    type: careerMapperTypes.UPDATE_REPORT_PATH_SUCCESS,
    payload: {
      data,
    },
  };
}

export function updateReportPathError(data) {
  return {
    type: careerMapperTypes.UPDATE_REPORT_PATH_ERROR,
    payload: {
      data,
    },
  };
}

export function getUpgradeCardsRequest(data) {
  return {
    type: careerMapperTypes.GET_UPGRADE_CARDS_REQUEST,
    payload: {
      data,
    },
  };
}

export function getUpgradeCardsSuccess(data) {
  return {
    type: careerMapperTypes.GET_UPGRADE_CARDS_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getUpgradeCardsError(data) {
  return {
    type: careerMapperTypes.GET_UPGRADE_CARDS_ERROR,
    payload: {
      data,
    },
  };
}
