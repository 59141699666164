import { put, call, takeLatest } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import { landingActionTypes } from './Constants';
import * as landingActions from './Actions';

export function* handleGetImpactsRequest() {
  try {
    const { data } = yield call(processRequest, 'landing/home/impact');

    yield put(landingActions.getImpactsSuccess({ impacts: data.results }));
  } catch (e) {
    yield put(landingActions.getImpactsError({ error: e }));
  }
}

export function* handleGetStoriesRequest() {
  try {
    const { data } = yield call(processRequest, 'landing/home/storie');

    yield put(landingActions.getStoriesSuccess({ stories: data.results }));
  } catch (e) {
    yield put(landingActions.getStoriesError({ error: e }));
  }
}

const landingSagas = [
  takeLatest(landingActionTypes.GET_IMPACTS_REQUEST, handleGetImpactsRequest),
  takeLatest(landingActionTypes.GET_STORIES_REQUEST, handleGetStoriesRequest),
];

export default landingSagas;
