import SessionsState from 'expert/redux/sessions/interfaces/sessionsState';

const INIT_SESSIONS_STATE: SessionsState = {
  isLoading: false,
  cancellingIds: [],
  items: [],
  reschedulingIds: [],
  isCalendarSessionsLoading: false,
  calendar: [],
};

export default INIT_SESSIONS_STATE;
