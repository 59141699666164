import { FEEDBACKS_TYPE } from '../../services/Constants';
import { feedbackActionTypes } from './Constants';

const initialState = {
  feedbackType: FEEDBACKS_TYPE.general,
  open: false,
  timeForSecond: 0,
  timerId: null,
  loading: false,
};

export default function feedbackReducer(state = initialState, action) {
  const { type, payload } = action;
  const { error, data } = payload || {};

  switch (type) {
    case feedbackActionTypes.POST_FEEDBACK_REQUEST:
    case feedbackActionTypes.POST_CANCEL_FEEDBACK_REQUEST:
    case feedbackActionTypes.POST_FEEDBACK_INFO_REQUEST:
    case feedbackActionTypes.GET_FEEDBACK_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case feedbackActionTypes.SET_FEEDBACK_TYPE:
    case feedbackActionTypes.TOGGLE_MODAL:
    case feedbackActionTypes.GET_FEEDBACK_INFO_SUCCESS:
      return {
        ...state,
        ...data,
        loading: false,
      };

    case feedbackActionTypes.POST_FEEDBACK_SUCCESS:
    case feedbackActionTypes.POST_CANCEL_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case feedbackActionTypes.POST_FEEDBACK_ERROR:
    case feedbackActionTypes.POST_CANCEL_FEEDBACK_ERROR:
    case feedbackActionTypes.GET_FEEDBACK_INFO_ERROR:
    case feedbackActionTypes.POST_FEEDBACK_INFO_ERROR:
      return {
        ...state,
        ...error,
      };

    default:
      return state;
  }
}
