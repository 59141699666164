import EditScheduleProps from 'expert/sagas/interfaces/editScheduleProps';
import EditScheduleQuery from 'expert/sagas/interfaces/editScheduleQuery';

const editSchedulePropsToParams = (source: EditScheduleProps): EditScheduleQuery => ({
  start: source.start,
  end: source.end,
  weekdays: source.weekdays,
  modify: source.editVariant,
  ...(source.repeatStart ? { repeat_start: source.repeatStart } : {}),
});

export default editSchedulePropsToParams;
