import { coursesTypes } from './Constants';

export function getJobPostingsRequest(data) {
  return {
    type: coursesTypes.GET_COURSES_REQUEST,
    payload: {
      data,
    },
  };
}

export function getJobPostingsSuccess(data) {
  return {
    type: coursesTypes.GET_COURSES_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getJobPostingsError(data) {
  return {
    type: coursesTypes.GET_COURSES_ERROR,
    payload: {
      data,
    },
  };
}

export function postCoursesFavoriteRequest(data) {
  return {
    type: coursesTypes.POST_JOB_COURSES_FAVORITE_REQUEST,
    payload: {
      data,
    },
  };
}

export function postCoursesFavoriteSuccess(data) {
  return {
    type: coursesTypes.POST_JOB_COURSES_FAVORITE_SUCCESS,
    payload: {
      data,
    },
  };
}

export function postCoursesFavoriteError(data) {
  return {
    type: coursesTypes.POST_JOB_COURSES_FAVORITE_ERROR,
    payload: {
      data,
    },
  };
}
