import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/effect-coverflow/effect-coverflow.min.css';
import 'swiper/swiper-bundle.css';

import App from './App';
import './styles/main.scss';

const root = document.getElementById('root');

ReactDOM.render(<App />, root);
