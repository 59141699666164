import DeleteAvailabilityProps from 'expert/sagas/interfaces/deleteAvailabilityProps';
import EditAvailabilityProps from 'expert/sagas/interfaces/editAvailabilityProps';
import EditScheduleProps from 'expert/sagas/interfaces/editScheduleProps';
import keyMirror from 'keymirror';
import AddAvailabilityProps from 'expert/sagas/interfaces/addAvailabilityProps';
import FetchAvailabilitiesProps from 'expert/sagas/interfaces/fetchAvailabilitiesProps';

export const scheduleSagaActionTypes = keyMirror({
  fetchAvailabilities: null,
  addAvailability: null,
  deleteAvailability: null,
  editAvailability: null,
  editSchedule: null,
  checkIsAvailabilitySet: null,
});

export const checkIsAvailabilitySet = () => ({
  type: scheduleSagaActionTypes.checkIsAvailabilitySet,
});

export const addAvailability = (props: AddAvailabilityProps) => ({
  type: scheduleSagaActionTypes.addAvailability,
  payload: props,
});

export const fetchAvailabilities = (props: FetchAvailabilitiesProps) => ({
  type: scheduleSagaActionTypes.fetchAvailabilities,
  payload: props,
});

export const deleteAvailability = (props: DeleteAvailabilityProps) => ({
  type: scheduleSagaActionTypes.deleteAvailability,
  payload: props,
});

export const updateAvailability = (props: EditAvailabilityProps) => ({
  type: scheduleSagaActionTypes.editAvailability,
  payload: props,
});

export const editSchedule = (props: EditScheduleProps) => ({
  type: scheduleSagaActionTypes.editSchedule,
  payload: props,
});
