import Session from 'expert/common/interfaces/session';
import sessionStatusMap from 'expert/sagas/configs/sessionStatusMap';
import SessionRes from 'expert/sagas/interfaces/sessionRes';
import moment from 'moment';
import SessionTypes from 'expert/common/enums/sessionTypes';

const resToSession = (source: SessionRes): Session => ({
  id: source.id,
  date: source.session_date,
  from: source.session_datetime_from,
  url: source.customer_session_url,
  status: sessionStatusMap[source.session_status],
  to: source.session_datetime_to,
  duration: source.session_type,
  notes: source.customer_notes,
  isActive: false,
  type: source.session_type === 'min_30' ? SessionTypes.min30 : SessionTypes.min60,
  oldFrom: source.old_session_datetime_from,
  oldTo: source.old_session_datetime_to,
  customer: {
    avatar: source.customer_user.avatar,
    lastName: source.customer_user.last_name,
    firstName: source.customer_user.first_name,
    position: source.customer_user.position || undefined,
  },
  ...(source.review
    ? {
        review: {
          id: source.review.id,
          isUpdating: false,
          isPublished: source.review.is_publish,
          createdAt: moment(source.review.created_at),
          user: {
            avatar: source.customer_user.avatar,
            lastName: source.customer_user.last_name,
            firstName: source.customer_user.first_name,
            position: source.customer_user.position || undefined,
          },
          well: source.review.what_like,
          notWell: source.review.what_dont_like,
          rating: source.review.rate,
        },
      }
    : {}),
});

export default resToSession;
