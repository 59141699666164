import FetchDashboardProps from 'expert/sagas/interfaces/fetchDashboardProps';
import keyMirror from 'keymirror';

export const dashboardSagaActionTypes = keyMirror({
  FETCH_PAYOUTS: null,
  FETCH_PAST_SESSIONS: null,
  FETCH_UPCOMING_SESSIONS: null,
});

export const fetchPayouts = (payload: FetchDashboardProps) => ({
  type: dashboardSagaActionTypes.FETCH_PAYOUTS,
  payload,
});

export const fetchPastSessions = (payload: FetchDashboardProps) => ({
  type: dashboardSagaActionTypes.FETCH_PAST_SESSIONS,
  payload,
});

export const fetchUpcomingSessions = (payload: FetchDashboardProps) => ({
  type: dashboardSagaActionTypes.FETCH_UPCOMING_SESSIONS,
  payload,
});
