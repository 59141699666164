import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import history from './history';
import landingReducer from './pages/landing/Reducer';
import signUpReducer from './pages/signUp/Reducer';
import signInReducer from './pages/signIn/Reducer';
import onboardingReducer from './pages/onboarding/Reducer';
import paymentsReducer from './pages/payments/Reducer';
import careerMapperDetailsReducer from './pages/careerMapperDetails/Reducer';
import careerMapperReducer from './pages/careerMapper/Reducer';
import paymentPackagesReducer from './commonComponents/paymentPackages/Reducer';
import articlesReducer from './commonComponents/articlesBlock/Reducer';
import expertsReducer from './commonComponents/featuredExperts/Reducer';
import jobPostingsReducer from './pages/careerMapperDetails/components/liveJobPostings/Reducer';
import coursesReducer from './pages/strengthenPage/Reducer';
import activityCoursesReducer from './pages/activity/components/coursesSection/Reducer';
import generalReducer from './pages/general/Reducer';
import feedbackReducer from './commonComponents/feedbackModalContent/Reducer';
import customModalReducer from './commonComponents/modals/customModal/Reducer';
import expertReducer from './expert/redux/expert/expertReducer';
import privacyTermsReducer from './expert/redux/privacyTerms/privacyTermsReducer';
import agreementReducer from './expert/redux/agreement/agreementReducer';
import expertInfoReducer from './expert/redux/expertInfo/expertInfoReducer';
import expertSkillsReducer from './expert/redux/expertSkills/expertSkillsReducer';
import resumeReducer from './expert/redux/resume/resumeReducer';
import payoutReducer from './expert/redux/payout/payoutReducer';
import accountSettings from './expert/redux/accountSettings/accountSettingsReducer';
import reviews from './expert/redux/reviews/reviewsReducer';
import widgets from './expert/redux/widgets/widgetsReducer';
import timeZones from './expert/redux/timeZones/timeZonesReducer';
import notifications from './expert/redux/notifications/notificationsReducer';
import expertFeedback from './expert/redux/expertFeedback/expertFeedbackReducer';
import customerFeedback from './commonComponents/customerSessionFeedback/redux/customerFeedback/customerFeedbackReducer';
import availabilities from './expert/redux/availabilities/availabilitiesReducer';
import upcomingSessions from './expert/redux/upcomingSessions/upcomingSessionsReducer';
import pastSessions from './expert/redux/pastSessions/pastSessionsReducer';
import sessions from './expert/redux/sessions/sessionsReducer';
import rescheduleAvailability from './expert/redux/rescheduleAvailability/rescheduleAvailabilityReducer';
import googleEvents from './expert/redux/googleEvents/googleEventsReducer';
import expertAssistOnboarding from './pages/expertAssistOnboarding/redux/reducer';
import expertAssistMatches from './pages/expertAssistMatches/redux/reducer';
import expertAssistSessionBooking from './pages/expertAssistSessionBooking/redux/reducer';

const USER_LOGOUT = 'USER_LOGOUT';

export const userLogout = () => ({ type: USER_LOGOUT });

const combinedReducers = combineReducers({
  accountSettings,
  expert: expertReducer,
  sessions,
  reviews,
  widgets,
  rescheduleAvailability,
  notifications,
  googleEvents,
  timeZones,
  availabilities,
  upcomingSessions,
  pastSessions,
  expertFeedback,
  customerFeedback,
  expertInfo: expertInfoReducer,
  payout: payoutReducer,
  expertSkills: expertSkillsReducer,
  resume: resumeReducer,
  privacyTerms: privacyTermsReducer,
  agreement: agreementReducer,
  form: formReducer,
  router: connectRouter(history),
  landingReducer,
  signUpReducer,
  signInReducer,
  onboardingReducer,
  careerMapperReducer,
  careerMapperDetailsReducer,
  jobPostingsReducer,
  paymentPackagesReducer,
  articlesReducer,
  expertsReducer,
  paymentsReducer,
  coursesReducer,
  activityCoursesReducer,
  feedbackReducer,
  customModalReducer,
  generalReducer,
  expertAssistOnboarding,
  expertAssistMatches,
  expertAssistSessionBooking,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    return combinedReducers(
      {
        ...state,
        accountSettings: undefined,
        expert: undefined,
        sessions: undefined,
        reviews: undefined,
        widgets: undefined,
        notifications: undefined,
        googleEvents: undefined,
        timeZones: undefined,
        rescheduleAvailability: undefined,
        availabilities: undefined,
        upcomingSessions: undefined,
        pastSessions: undefined,
        expertFeedback: undefined,
        customerFeedback: undefined,
        expertInfo: undefined,
        payout: undefined,
        expertSkills: undefined,
        resume: undefined,
        privacyTerms: undefined,
        agreement: undefined,
        form: undefined,
        landingReducer: undefined,
        signUpReducer: undefined,
        signInReducer: undefined,
        onboardingReducer: undefined,
        careerMapperReducer: undefined,
        careerMapperDetailsReducer: undefined,
        jobPostingsReducer: undefined,
        paymentPackagesReducer: undefined,
        articlesReducer: undefined,
        expertsReducer: undefined,
        paymentsReducer: undefined,
        coursesReducer: undefined,
        activityCoursesReducer: undefined,
        feedbackReducer: undefined,
        customModalReducer: undefined,
        generalReducer: undefined,
        expertAssistOnboarding: undefined,
        expertAssistMatches: undefined,
        expertAssistSessionBooking: undefined,
      },
      action,
    );
  }

  return combinedReducers(state, action);
};

export default rootReducer;
