import { DurationTypes } from '../../../expertAssistSessionBooking/redux/interfaces';

export enum SessionStatuses {
  InProgress = 'in_progress',
  Upcoming = 'upcoming',
  Canceled = 'canceled',
  Completed = 'completed',
  Pending = 'pending',
}

interface SessionExpertUser {
  avatar: string | null;
  first_name: string;
  id: number;
  expert_profile_id: number;
  last_name: string;
  current_job_title: string;
}

interface SessionCustomerUser {
  avatar: string | null;
  first_name: string;
  id: number;
  last_name: string;
}

export interface Session {
  created_at: string;
  customer_notes: string;
  customer_timezone: string;
  expert_notes: string;
  id: number;
  is_paid: false;
  pay_out_doc: null;
  session_date: string;
  session_datetime_from: string;
  session_datetime_to: string;
  session_status: SessionStatuses;
  session_type: DurationTypes;
  session_zoom_url: null;
  customer_session_url: null;
  expert_session_url: null;
  updated_at: string;
  customer_user: SessionCustomerUser;
  expert_user: SessionExpertUser;
  propose: string | null;
  meeting_id: string;
}

export interface SessionsResponse {
  count: 14;
  next: null;
  previous: null;
  results: Session[];
}
