import Review from 'expert/common/components/reviewItem/interfaces/review';

interface UpdateReviewProps {
  reviews: Review[];
  newReview: Review;
}

const updateReview: (props: UpdateReviewProps) => Review[] = ({ newReview, reviews }) =>
  reviews.map((review) => (review.id === newReview.id ? newReview : review));

export default updateReview;
