import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import expertTheme from 'expert/theme/expertTheme';
import { ThemeProvider } from '@material-ui/styles';
import useStyles from './Privacy.styles';

const Privacy: FC = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={expertTheme}>
      <Typography variant="h2" className={classes.title}>
        Privacy Policy
      </Typography>
      <Typography variant="body1" className={classes.date}>
        Effective Date: November 1, 2021
      </Typography>
      <div id="modal_desc">
        <span className={classes.text}>
          Arable Ventures LLC, a New York company doing business as Pave Careers (“PAVE”), respects your privacy and is
          committed to protecting your personal data. This privacy policy will inform you as to how we look after your
          personal data and tell you about your privacy rights and how the law protects you. This privacy policy applies
          to personal data that you may submit through PAVE’s website or by other means.
        </span>
        <br />
        <br />
        <span className="reqular__bold__text">Summary</span>
        <br />
        <br />
        <span className={classes.text}>
          1. IMPORTANT INFORMATION AND WHO WE ARE. <br /> 2. THE INFORMATION WE COLLECT ABOUT YOU. <br /> 3. USE OF
          PERSONAL DATA. <br /> 4. DISCLOSURE OF PERSONAL DATA. <br />
          5. CHILDREN. <br /> 6. MARKETING EMAILS. <br /> 7. SECURITY. <br /> 8. RETENTION OF YOUR PERSONAL DATA.
          <br /> 9. EXTERNAL LINKS. <br /> 10.YOUR RIGHTS. <br /> 11. DISPUTE RESOLUTION AND OTHER LEGAL MATTERS. <br />
          12. CHANGES TO THIS POLICY.
          <br />
          <br />
          1. <span className="bold underline">Important information and who we are.</span>
          <br />
          <br />
          <span className="bold underline">Purpose of this privacy notice</span>
          <br />
          <br />
          This privacy policy aims to give you information on how PAVE collects and processes your personal data through
          your use of our website,{' '}
          <a href={'https://joinpave.com'} className="email" target={'_blank'}>
            https://joinpave.com
          </a>
          , our technology systems, our apps or other PAVE portals which make reference to this policy. For purposes of
          this privacy policy, the terms “user”, “participant”, “customer”, “you” and “your” are meant to refer to the
          individuals about whom we may collect data.
          <br />
          <br />
          Our data operations and privacy policy endeavor to comply with the latest US and international privacy and
          data protection regulations. These regulations include those promulgated under the European Union’s General
          Data Protection Regulation (“GDPR”) and the California Consumer Privacy Act (“CCPA”) where applicable. While
          GDPR regulations and CCPA regulations may not apply to PAVE, we have decided to voluntarily adopt and
          implement these protections for all individuals who submit personal data to PAVE. By doing this, along with
          implementing strict data protection standards from other jurisdictions such as California, we provide you with
          expansive rights with respect to any personal data that we collect from you. Some of these rights include the
          following:
          <br />
          <br />
          <div className="sub_points">
            1. The right to have PAVE permanently delete any of your personal data. <br /> <br />
            2. The right to review any personal data that we’ve collected from you. <br /> <br />
            3. The right to voluntarily and affirmatively “opt-in” to any newsletters, emails, or marketing materials
            sent to you by PAVE. <br /> <br />
            4. The right to receive a copy of your personal data. <br /> <br />
            5. The right to have control and access over your personal data. <br /> <br />
            6. The right to have your personal data protected, transferred and stored in compliance with data protection
            requirements required by law.
            <br /> <br />
            7. The right to have your personal data automatically destroyed after it is no longer being actively used by
            PAVE. <br /> <br />
            8. The right to only receive communications from PAVE pursuant to a “Lawful Basis”. The two most common
            Lawful Bases pursuant to which you may receive PAVE communications are: (i) that you requested, subscribed
            or affirmatively consented to the receipt of the communications, and (ii) the communications are necessary
            for PAVE’s “legitimate interests” that are not overridden by your interests or fundamental rights and
            freedoms that require protection of personal data.
            <br /> <br />
            9. The right, if applicable, to have PAVE appoint a Data Protection Officer (“DPO”) to manage PAVE’s
            compliance with this privacy policy and to respond within 72 hours to any inquiries, complaints, or requests
            that you make regarding your personal data. At any time, you may contact our DPO at{' '}
            <a href={'mailto:admin@joinpave.com'} className="email">
              admin@joinpave.com
            </a>
            . We consider and respond to all of your inquiries, complaints, or requests in accordance with applicable
            law.
            <br /> <br />
            10. Our authorized subscribers have affirmatively and voluntarily “opted in” to receive newsletters, emails,
            and marketing communications from PAVE. If you believe that you did not opt in to receive these
            communications from PAVE, please contact our DPO as soon as possible at{' '}
            <a href={'mailto:admin@joinpave.com'} className="email">
              admin@joinpave.com
            </a>{' '}
            and we will arrange for you to either affirmatively opt in or not receive any further communications from
            us. Additionally, all our newsletters, emails to subscribers, and other related communications contain an
            opt-out/unsubscribe button at the bottom. At any time, you may unsubscribe from all PAVE communications by
            clicking on the button. <br /> <br />
            11. To the extent that any applicable international, federal, or state laws that apply to us are more
            restrictive than this privacy policywith respect to protecting your personal data and privacy, we strive to
            comply with such stricter regulations. If for any reason you believe we are not in compliance with any
            provision of these laws, please advise us immediately in writing so that we can review the laws in question
            and take immediate corrective action if necessary. You can advise us at{' '}
            <a href={'mailto:admin@joinpave.com'} className="email">
              admin@joinpave.com
            </a>
            .
          </div>
          <br />
          <br />
          We take your personal data and privacy seriously. If you have any questions regarding your rights, your
          personal data, or PAVE’s obligations, please contact PAVE’s Data Protection Officer at{' '}
          <a href={'mailto:admin@joinpave.com'} className="email">
            admin@joinpave.com
          </a>
          .
          <br />
          <br />
          Your rights under GDPR are further described below in Section 10. Some of your rights described in this policy
          may be subject to certain rights of PAVE such as legal, accounting, and archiving requirements.
          <br />
          <br />
          <span className="bold underline">Controller</span>
          <br />
          <br />
          When we mention "PAVE", "we", "us" or "our" in this privacy policy, we are referring to PAVE, the company that
          is responsible for processing your data. For individuals located in the European Economic Area, PAVE is the
          data controller, as that term is defined by GDPR.
          <br />
          <br />
          <span className="bold underline">Contact details</span>
          <br />
          <br />
          PAVE’s Data Protection Officer (“DPO”) is responsible for overseeing questions in relation to this privacy
          policy. If you have any questions about this privacy policy, including any requests to exercise any rights
          that may be afforded to you, please contact the DPO at{' '}
          <a href={'mailto:admin@joinpave.com'} className="email">
            admin@joinpave.com
          </a>{' '}
          or using the details set out below:
          <br />
          <br />
          Attention: Data Processing Officer
          <br />
          Arable Ventures LLC DBA Pave Careers
          <br />
          244 Madison Ave, Suite #173
          <br />
          New York, NY 10016
          <br />
          <br />
          <br />
          <span className="bold underline">The PAVE Website and Portals.</span>
          <br />
          <br />
          PAVE’s website, digital platform, apps, technology systems, content, services, trademark, and other
          intellectual property, are owned or licensed by PAVE. PAVE’s mission is to use proprietary content and
          technology to help people “find what they love to do and succeed in it.” This includes aligning work with self
          and using real-time market data across industries to discover new and unexpected ways to put talents to work.
          PAVE uses its technology, expertise, and research to recommend options and provide guidance based not only an
          individual’s skills and experience, but also on their personal aspirations and work style. The company uses
          technology, programs, expertise, and research to provide actionable next steps and multiple approaches to help
          its clients achieve their career objectives.
          <br />
          <br />
          Customers consisting of businesses and individuals register on the PAVE website and submit information that
          PAVE uses to assist them in achieving their career aspirations and goals. Customers license from PAVE its
          proprietary career guidance technology, programs and content according to various licensing terms or
          subscriptions that PAVE makes available. Your privacy in engaging with the PAVE website is very important to
          us. With that in mind, we have established information handling practices for PAVE intended to guard and
          respect your privacy. We believe these practices are consistent with GDPR and the best practices of websites
          providing career guidance services. This privacy policy applies to the PAVE career guidance platform including
          the information we collect about you, when and how we collect that information, how that information is used,
          how we safeguard that information, and our procedures for archiving and deleting that information.
          <br />
          <br />
          2. <span className="bold underline">The Information We Collect About You.</span>
          <br />
          <br />
          <span className="bold underline">Information We Collect Automatically</span>
          <br />
          <br />
          When you visit our website, our servers automatically collect certain browser or device generated information,
          which may in some cases constitute personal data, including but not limited to:
          <br />
          <br />
          <div className="sub_points">
            • your domain;
            <br />
            <br />
            • your IP address;
            <br />
            <br />
            • your log in;
            <br />
            <br />
            • your acceptance of our privacy policy and terms of use;
            <br />
            <br />
            • the date, time and duration of your visit;
            <br />
            <br />
            • your browser type;
            <br />
            <br />
            • your operating system;
            <br />
            <br />
            • your page visits;
            <br />
            <br />
            • information from third parties;
            <br />
            <br />
            • other information about your computer or device;
            <br />
            <br />• Internet traffic;
          </div>
          <br />
          <br />
          <span className="bold underline">Information You Provide</span>
          <br />
          <br />
          In order to access or use certain portions of PAVE’s website, the PAVE digital platform, other PAVE portals,
          or otherwise engage in activities with us, you may be prompted to provide certain personal data to us in the
          following ways:
          <br />
          <br />
          <div className="sub_points">
            • by filling in forms (for example, a 'Contact us' form) on our website or at a trade show or anywhere else
            we conduct business;
            <br />
            <br />
            • by downloading materials from our website;
            <br />
            <br />
            • by subscribing to newsletters or other communications;
            <br />
            <br />
            • by corresponding with us by phone, e-mail or otherwise using our contact detail
            <br />
            <br />
            • by registering or participating in PAVE webcasts, webinars, seminars, classes, or conferences;
            <br />
            <br />
            • by submitting questions or comments to us or requesting any other information from us; or
            <br />
            <br />• by participating in a PAVE webinar or program.
          </div>
          <br />
          <br />
          Typically, the personal data you give us may include name, address, telephone number, email address, work
          history, and aspirations, and any personal details required to resolve any inquiries or complaints. Your
          personal data may also include your login name and passcode, and information that you supply, and upload to
          PAVE digital servers as part of how the platform is designed to be used.
          <br />
          <br />
          Personal data may also be required to enter into an agreement with you or to perform an agreement with you
          (such as to provide services at your request), and failure to provide any information may result in our
          inability to provide requested services or products.
          <br />
          <br />
          <span className="bold underline">Third-Party Servers</span>
          <br />
          <br />
          PAVE relies on established and trustworthy third-party vendors located in the United States to provide much of
          the data storage and usage requirements for PAVE. The personal data you submit to us through our Contact Us
          page and email sign-ups on our website and other digital platforms are stored and processed at secure servers
          in compliance with GDPR. PAVE may change the third-party data processors it uses. PAVE and its technology team
          will perform due diligence on any new processors that it uses, and make sure that they meet the highest
          quality of privacy and security standards, including GDPR.
          <br />
          <br />
          <span className="bold underline">Information From Other Sources</span>
          <br />
          <br />
          We may receive information about you if you use any of the other websites and portals we operate, any other
          services we provide, seminars, webinars, or classes we teach, or from our business partners or data processors
          instructed to collect information on our behalf.
          <br />
          <br />
          <span className="bold underline">Cookies</span>
          <br />
          <br />
          Our website may use cookies. A cookie is a small file of letters and numbers that we put on your computer or
          smartphone if you agree. These cookies allow us to distinguish you from other users of our website, which
          helps us to provide you with a good experience when you browse our website or watch our videos, and also
          allows us to improve our site and platform. If you have any questions regarding our use of cookies, please
          contact our DPO at{' '}
          <a href={'mailto:admin@joinpave.com'} className="email">
            admin@joinpave.com
          </a>
          .
          <br />
          <br />
          3. <span className="bold underline">Use of Personal Data.</span>
          <br />
          <br />
          The following is an overview of our purposes for using your personal data. Additional details on how we
          process your personal data may be provided to you in a separate notice or agreement.
          <br />
          <br />
          In accordance with GDPR, all of our processing and use of your personal data is justified by a "condition" for
          processing. In the majority of cases, processing will be justified on the basis that:
          <br />
          <br />
          <div className="sub_points">
            • you have consented to the processing;
            <br />
            <br />
            • the processing is in our legitimate interests that are not overridden by your interests and fundamental
            rights; our legitimate interests are to use supplier, customer, client, student, program, website and
            digital platform user data to conduct and develop our business activities with them and with others while
            limiting the use of their personal data to purposes that support the conduct and development of our
            business;
            <br />
            <br />
            • the processing is part of our educational and research programs;
            <br />
            <br />
            • the processing is necessary to perform an agreement with you or take steps to enter into an agreement at
            your request, such as to fulfil an order, or to provide product information you have requested; or
            <br />
            <br />• the processing is necessary for us to comply with a relevant legal obligation, such as keeping
            accounting, legal, and archival records.
          </div>
          <br />
          <br />
          We use the personal data we collect to conduct and develop our business with you and with others, as more
          fully described below:
          <div className="sub_points">
            <br />
            • provide you with any services or products you have contracted for;
            <br />
            <br />
            • administer and manage performance of purchase or sales agreements with our suppliers and customers;
            <br />
            <br />
            • provide after-sales support;
            <br />
            <br />
            • process, evaluate and complete certain transactions involving the website, the platform, and more
            generally transactions involving PAVE's products and services;
            <br />
            <br />
            • operate, evaluate, maintain, improve and develop the website and PAVE digital platform (including by
            monitoring and analyzing trends, access to, and use of the website for advertising and marketing, and
            analyzing how our digital platform is functioning and delivering results);
            <br />
            <br />
            • research and educational purposes;
            <br />
            <br />
            • evaluate, improve and develop our products and services generally;
            <br />
            <br />
            • customize our website and digital platforms to users' needs;
            <br />
            <br />
            • engage you about events, promotions, the website, and PAVE's products and services;
            <br />
            <br />
            • provide you with documentation or communications which you have requested or which we believe you would be
            interested in;
            <br />
            <br />
            • correspond with users to resolve their queries or complaints;
            <br />
            <br />
            • send you marketing communications, where it is lawful for us to do so;
            <br />
            <br />
            • protect and ensure safety of the website, PAVE confidential and proprietary information, and PAVE
            employees;
            <br />
            <br />
            • manage, protect against and investigate fraud, risk exposure, claims and other liabilities, including but
            not limited to violation of our agreements or laws or regulations; and
            <br />
            <br />• share your personal data with third parties in connection with potential or actual sale of our
            company or any of our assets, in which case personal data held by us about our users may be one of the
            transferred assets.
          </div>
          <br />
          <br />
          PAVE respects and safeguards your personal data and will never sell or rent it to third parties.
          <br />
          <br />
          4. <span className="bold underline">Disclosure of Personal Data.</span>
          <br />
          <br />
          PAVE will share personal data with its appointed third-party service providers (who will operate under our
          instructions) to assist us in providing information, products or services to you, in conducting and managing
          our business, or in managing and improving our products, services, the website, or our PAVE digital platform
          and the licensing of PAVE content. PAVE may share your personal data with these affiliates and third parties
          to perform services that the third parties have been engaged by PAVE to perform on PAVE's behalf, subject to
          appropriate contractual restrictions and security measures, or if we believe it is reasonably necessary to
          prevent harm or loss, or if we believe that the disclosure will further an investigation of suspected or
          actual illegal activities.
          <br />
          <br />
          We are also subject to the investigatory and enforcement powers of the Federal Trade Commission (FTC). We may
          be required to disclose personal information that we handle in response to lawful requests by public
          authorities, including to meet national security or law enforcement requirements.
          <br />
          <br />
          If your personal data is transferred outside the EU to PAVE or its third-party service providers, we will take
          steps to ensure that your personal data receives the same level of protection as if it remained within the EU.
          The list of all the countries to which your personal data is transferred is set forth here: The United States.
          You hereby consent to the transfer of your personal data to the United States. You have a right to obtain
          details of the mechanism under which your personal data is transferred outside of the EU by contacting{' '}
          <a href={'mailto:admin@joinpave.com'} className="email">
            admin@joinpave.com
          </a>
          .
          <br />
          <br />
          PAVE reserves the right to share any information that you provide which is not deemed personal data or is not
          otherwise subject to contractual restrictions. These restrictions on the disclosure of your personal data will
          not affect our use of your data as stated in Section 3, above.
          <br />
          <br />
          5. <span className="bold underline">Children.</span>
          <br />
          <br />
          The website is not for use by children under the age of 16 years and PAVE does not knowingly collect, store,
          share or use the personal data of children under 16 years. If you are under the age of 16 years, please do not
          provide any personal data, even if prompted by the website to do so. If you are under the age of 16 years and
          you have provided personal data, please ask your parent(s) or guardian(s) to notify PAVE and PAVE will delete
          all such personal data.
          <br />
          <br />
          6. <span className="bold underline">Marketing Emails.</span>
          <br />
          <br />
          Where lawful to do so, and subject to your consent where required, we may communicate with you by email to
          tell you about our products and services. If you wish to opt-out of receiving marketing communications, please
          use the 'unsubscribe' link provided in our emails, or otherwise contact us directly and we will stop sending
          you communications.
          <br />
          <br />
          7. <span className="bold underline">Security.</span>
          <br />
          <br />
          PAVE strives to safeguard and protect your personal data from unauthorized access, improper use or disclosure,
          unauthorized modification or unlawful destruction or accidental loss, and PAVE utilizes and maintains certain
          reasonable processes, systems, and technologies to do so. However, you acknowledge that no transmission over
          the Internet is completely secure or error-free, and that these processes, systems, and technologies utilized
          and maintained by PAVE are subject to compromise. Accordingly, we cannot be held responsible for unauthorized
          or unintended access that is beyond our reasonable control.
          <br />
          <br />
          8. <span className="bold underline">Retention of Your Personal Data.</span>
          <br />
          <br />
          We apply a general rule of keeping personal data only for as long as required to fulfil the purposes for which
          it was collected. We also retain your personal data for a period of time corresponding to a statute of
          limitations, for example to maintain an accurate record of your dealings with us including licenses that you
          have entered with us.
          <br />
          <br />
          However, in some circumstances we may retain personal data for other periods of time, for instance where we
          are required to do so in accordance with legal, tax and accounting requirements, or if required to do so by a
          legal process, legal authority, or other governmental entity having authority to make the request, for so long
          as required.
          <br />
          <br />
          At any time, you can request our DPO to permanently delete your data not subject to legal or other
          requirements. You can also always unsubscribe to our emails, in which case we will delete your personal
          information other than licenses and other matters that survive your request for deletion.
          <br />
          <br />
          9. <span className="bold underline">External Links.</span>
          <br />
          <br />
          The website may contain links to third party sites. Since PAVE does not control nor is responsible for the
          privacy practices of those websites, we encourage you to review the privacy policies of these third-party
          sites. This policy applies solely to personal data collected by our website or in the course of our business
          activities.
          <br />
          <br />
          10. <span className="bold underline">Your Rights.</span>
          <br />
          <br />
          Under applicable law, you may have the following rights:
          <br />
          <br />
          <div className="sub_points">
            • Request access to your personal data (commonly known as a "data subject access request"). This enables you
            to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.
            <br />
            <br />
            • Request correction of the personal data that we hold about you. This enables you to have any incomplete or
            inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you
            provide to us.
            <br />
            <br />
            • Request erasure of your personal data. This enables you to ask us to delete or remove personal data where
            there is no good reason for us continuing to process it. You also have the right to ask us to delete or
            remove your personal data where you have successfully exercised your right to object to processing (see
            below), where we may have processed your information unlawfully or where we are required to erase your
            personal data to comply with local law. Note, however, that we may not always be able to comply with your
            request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of
            your request.
            <br />
            <br />
            • Object to processing of your personal data where we are relying on a legitimate interest (or those of a
            third party) and there is something about your particular situation which makes you want to object to
            processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the
            right to object where we are processing your personal data for direct marketing purposes. In some cases, we
            may demonstrate that we have compelling legitimate grounds to process your information which override your
            rights and freedoms.
            <br />
            <br />
            • Request restriction of processing of your personal data. This enables you to ask us to suspend the
            processing of your personal data in the following scenarios: (a) if you want us to establish the data&apos;s
            accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need
            us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal
            claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding
            legitimate grounds to use it.
            <br />
            <br />
            • Request the transfer of your personal data to you or to a third party. We will provide to you, or a third
            party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that
            this right only applies to automated information which you initially provided consent for us to use or where
            we used the information to perform a contract with you.
            <br />
            <br />• Withdraw consent at any time where we are relying on consent to process your personal data. However,
            this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you
            withdraw your consent, we may not be able to provide certain products or services to you. We will advise you
            if this is the case at the time you withdraw your consent.
          </div>
          <br />
          <br />
          11. <span className="bold underline">Dispute Resolution and Other Legal Matters.</span>
          <br />
          <br />
          You may also have the right to lodge a complaint with the supervisory authority of your habitual residence,
          place of work or place of alleged infringement, if you consider that the processing of your personal data
          infringes applicable law and PAVE is subject to the jurisdiction of such supervisory authority.
          <br />
          <br />
          Further, PAVE commits to promptly resolve complaints about privacy and our collection or use of personal
          information. Individuals with questions or concerns about the use of their personal data should contact us at:{' '}
          <a href={'mailto:admin@joinpave.com'} className="email">
            admin@joinpave.com
          </a>{' '}
          and identify the company or other organization with whom they are affiliated or for whom their data was
          collected, if collection was for a PAVE customer.
          <br />
          <br />
          If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, we will have
          the matter submitted to our US-based third-party dispute resolution provider, JAMS, Inc. in New York City. If
          GDPR or other applicable laws exclusively apply to the dispute and require us to pay for the arbitration, we
          shall do so.
          <br />
          <br />
          Governing Law. By choosing to visit our website, subscribe to our career guidance services, view or download
          content, or provide information to us, you agree that any dispute over privacy or the terms contained in this
          Privacy Policy will be governed by the laws of the State of New York and the United States of America. You
          also agree to abide by any limitation on damages contained in our Terms of Use, license agreements, or other
          agreements that we have with you.
          <br />
          <br />
          12. <span className="bold underline">Changes to this Policy.</span>
          <br />
          <br />
          Any changes or updates we may make to this policy will be posted as a revised policy and noted at the top of
          this document. You are responsible for checking back to review any revised policy. PAVE reserves the right to
          change this Policy at any time.
        </span>
      </div>
    </ThemeProvider>
  );
};

export default Privacy;
