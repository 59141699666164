import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import ErrorBoundary from './commonComponents/errorBoundary/components/ErrorBoundary';
import Router from './Router';
import Snackbar from './commonComponents/snackbar/components/Snackbar';
import CustomModal from './commonComponents/modals/customModal/components/CustomModal';
import { store } from './store';
import history from './history';
import { customTheme } from './styles/breakpoints';
import PrivacyTermsDialog from './expert/modules/privacyTermsDialog/PrivacyTermsDialog';
import AgreementDialog from './expert/modules/agreementDialog/AgreementDialog';
import ScrollToTop from './services/ScrollToTop';
import bugsnagInit from './services/bugsnag';
import Layout from './common/components/Layout';

const { REACT_APP_STRIPE_PUBLISHABLE_KEY } = process.env;

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);

bugsnagInit();

const App = () => {
  return (
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <CssBaseline />
        <ThemeProvider theme={customTheme}>
          <Snackbar />
          <Helmet>
            <title>Pave</title>
            <meta name="description" content="Here we can put on some description" />
          </Helmet>
          <ConnectedRouter history={history}>
            <ScrollToTop />
            <ErrorBoundary>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Layout>
                  <Router />
                </Layout>
                <CustomModal />
                <PrivacyTermsDialog />
                <AgreementDialog />
              </MuiPickersUtilsProvider>
            </ErrorBoundary>
          </ConnectedRouter>
        </ThemeProvider>
      </Elements>
    </Provider>
  );
};

export default App;
