import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';

import { processRequest } from 'services/Api';
import {
  setExpert,
  setExpertAgreement,
  setExpertTempPassword,
  setExpertTimeZone,
} from 'expert/redux/expert/expertActions';
import USER_PATHS from 'expert/sagas/user/userPaths';
import MESSAGES from 'expert/common/configs/messages';
import { userSagaActionTypes } from 'expert/sagas/user/userSagasActions';
import UpdateExpertProps from 'expert/sagas/interfaces/updateExpertProps';
import profileFormDataToParams from 'expert/sagas/utils/profileFormDataToParams';
import { getAccessToken } from 'services/AccessToken';
import * as signInActions from 'pages/signIn/Actions';
import { handleSetExpertProfileStepRequest } from 'expert/sagas/experts/expertSagas';
import { setResumeFileName } from 'expert/redux/resume/resumeActions';
import UpdatePasswordData from 'expert/sagas/interfaces/updatePasswordData';
import {
  setIsPasswordUpdating,
  setIsUserDeleting,
  setPasswordError,
} from 'expert/redux/accountSettings/accountSettingsActions';
import updatePasswordDataToParams from 'expert/sagas/utils/updatePasswordDataToParams';
import { removeCookie } from 'services/Helper';
import { COOKIES } from 'services/Constants';
import { setTimeZonesAndStopLoading, setTimeZonesLoadingState } from 'expert/redux/timeZones/timeZonesActions';
import resToSelectOption from 'expert/sagas/utils/resToSelectOption';
import resToExpert from 'expert/sagas/utils/resToExpert';
import ExpertRes from 'expert/sagas/interfaces/expertRes';
import { setExpertInfo } from 'expert/redux/expertInfo/expertInfoActions';
import resToExpertInfo from 'expert/sagas/utils/resToExpertInfo';
import shouldHandleError from 'expert/sagas/utils/shouldHandleError';

export const handleSaveUserProfileRequest = function* ({ payload: { data } }: { payload: UpdateExpertProps }) {
  try {
    const { data: user } = yield call(processRequest, USER_PATHS.user, 'PATCH', {
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phoneNumber,
      profile_details: profileFormDataToParams(data),
      linkedin_url: data.linkedinInfo,
    });

    return user;
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.saveUserProfile);
  }
};

export const uploadUserFile = function* (file: File, fileType: 'avatar' | 'resume') {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('type', fileType);

  yield call(processRequest, USER_PATHS.setExpertAvatar, 'POST', { file: formData, type: 'application/pdf' }, true);
};

export const handleFetchResume = function* () {
  try {
    const { data: files } = yield call(processRequest, USER_PATHS.fetchResume);

    yield put(
      setResumeFileName({
        value: files.find((item) => item.type === 'resume')?.file || '',
      }),
    );
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.fetchResume);
  }
};

const handleFetchExpert = function* () {
  try {
    const { data: user } = yield call(processRequest, USER_PATHS.user);

    yield put(
      setExpert({
        value: resToExpert(user as ExpertRes),
      }),
    );
    yield put(
      setExpertInfo({
        value: resToExpertInfo(user.profile_details),
      }),
    );
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.fetchExpert);
  }
};

const handleFetchTimezones = function* () {
  try {
    yield put(setTimeZonesLoadingState(true));
    const { data } = yield call(processRequest, USER_PATHS.fetchTimeZoneList);

    yield put(setTimeZonesAndStopLoading(data.map(resToSelectOption)));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.fetchTimeZone);
  } finally {
    yield put(setTimeZonesLoadingState(false));
  }
};

const handlePasswordChange = function* ({ payload }) {
  try {
    const token = yield getAccessToken();

    yield call(processRequest, USER_PATHS.changePassword, 'POST', {
      new_password: payload,
      token,
    });
    yield put(setExpertTempPassword());
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    yield put(signInActions.sendRestorePasswordError());
  }
};

const handleSetAgreementRequest = function* () {
  try {
    yield call(processRequest, USER_PATHS.postExpertAgreement, 'POST', {
      is_agreement: true,
    });
    yield handleSetExpertProfileStepRequest({ payload: 1 });

    yield put(setExpertAgreement());
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.postExpertAgreement);
  }
};

const handleUpdatePassword = function* ({ payload }: { payload: UpdatePasswordData }) {
  try {
    yield put(setIsPasswordUpdating(true));
    yield call(processRequest, USER_PATHS.updatePassword, 'PATCH', updatePasswordDataToParams(payload));
    yield put(setIsPasswordUpdating(false));
    toast.success(MESSAGES.success.updatePassword);
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    const errorMessage = e.response?.data?.old_password;

    if (errorMessage && errorMessage[0]) {
      yield put(setPasswordError('Current password is incorrect'));
    }

    yield put(setIsPasswordUpdating(false));
  }
};

const handleUpdateTimeZone = function* ({ payload: timezone }: { payload: string }) {
  try {
    yield call(processRequest, USER_PATHS.updateTimeZone, 'PATCH', { timezone });

    yield put(setExpertTimeZone(timezone));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.updateTimeZone);
  }
};

const handleUserDelete = function* () {
  try {
    yield put(setIsUserDeleting(true));
    yield call(processRequest, USER_PATHS.deleteUser, 'DELETE');
    yield put(setIsUserDeleting(false));
    toast.error(MESSAGES.success.deleteUser);
    removeCookie(COOKIES.accessToken);
    yield put(push('/sign-in'));
  } catch (e: any) {
    if (!shouldHandleError(e)) return;

    toast.error(MESSAGES.errors.deleteUser);
  } finally {
    yield put(setIsUserDeleting(false));
  }
};

const userSagas = [
  takeLatest(userSagaActionTypes.FETCH_EXPERT, handleFetchExpert),
  takeLatest(userSagaActionTypes.CHANGE_PASSWORD, handlePasswordChange),
  takeLatest(userSagaActionTypes.SET_EXPERT_AGREEMENT, handleSetAgreementRequest),
  takeLatest(userSagaActionTypes.FETCH_RESUME, handleFetchResume),
  takeLatest(userSagaActionTypes.UPDATE_PASSWORD, handleUpdatePassword),
  takeLatest(userSagaActionTypes.DELETE_USER, handleUserDelete),
  takeLatest(userSagaActionTypes.UPDATE_TIME_ZONE, handleUpdateTimeZone),
  takeLatest(userSagaActionTypes.FETCH_TIMEZONES, handleFetchTimezones),
];

export default userSagas;
