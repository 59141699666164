import { put, call, takeLatest } from 'redux-saga/effects';
import { processRequest } from '../../../../services/Api';
import { activityCoursesTypes } from './Conctsnts';
import * as activityCoursesActions from './Actions';

export function* handleActivityCoursesDataRequest({ payload }) {
  try {
    const { data } = yield call(processRequest, 'customer/get_job_courses', 'GET', payload.data);

    yield put(
      activityCoursesActions.getActivityCoursesSuccess({
        coursesData: data.results,
      }),
    );
  } catch (e) {
    yield put(activityCoursesActions.getActivityCoursesError({ error: e }));
  }
}

const coursesSectionSagas = [
  takeLatest(activityCoursesTypes.GET_CUSTOMER_JOB_COURSES_REQUEST, handleActivityCoursesDataRequest),
];

export default coursesSectionSagas;
