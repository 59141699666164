import { signUpActionTypes } from './Constants';

const initialState = {
  loading: false,
  user: null,
  desiredRoles: [],
  desiredCompanies: [],
  verificationSuccess: false,
  emailExist: {
    is: false,
    message: '',
  },
};

export default function signUpReducer(state = initialState, action) {
  const { type, payload } = action;
  const { data } = payload || {};

  switch (type) {
    case signUpActionTypes.SIGN_UP_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case signUpActionTypes.SIGN_UP_SUCCESS:
    case signUpActionTypes.GET_DESIRED_COMPANY_SUCCESS:
    case signUpActionTypes.GET_DESIRED_ROLE_SUCCESS:
      return {
        ...state,
        ...data,
        loading: false,
      };

    case signUpActionTypes.SIGN_UP_ERROR:
      return {
        ...state,
        ...data,
        loading: false,
      };

    default:
      return state;
  }
}
