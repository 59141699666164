import React, { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { getInitials } from '../../pages/userProfile/Services';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { getUserSelector } from '../../selectors';
import { heading14_17_700 } from '../../styles/fonts';
import { removeAccessToken } from '../../services/AccessToken';
import { LOGIN_MENU_ITEMS, PROFILE_HEADER_ITEMS } from '../../services/Constants';
import { primary } from '../../styles/colors';
import { userLogout } from '../../rootReducer';

const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

interface StyleProps {
  isCareerMapperTab?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    mobileListItem: {
      textAlign: 'center',
      padding: theme.spacing(1.5, 0),
    },
    mobileListItemText: {
      ...heading14_17_700,
      textTransform: 'uppercase',
    },
    drawerContent: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      width: 300,
      paddingBottom: theme.spacing(4),
    },
    drawerHeader: {
      backgroundColor: ({ isCareerMapperTab }: StyleProps) =>
        isCareerMapperTab ? primary.dark_blue : primary.dark_purple,
      padding: theme.spacing(1.5),
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      marginRight: theme.spacing(3),
    },
    fullName: {
      color: ({ isCareerMapperTab }: StyleProps) => (isCareerMapperTab ? primary.milk : primary.coral),
    },
    list: {
      flex: 1,
    },
    hamburger: {
      width: 34,
      height: 34,
    },
  }),
);

interface NavItem {
  text: string;
  path: string;
  allowPathes: string[];
  isDisabled?: boolean;
}

interface Props {
  isCareerMapperTab?: boolean;
}

function MobileHeader({ isCareerMapperTab }: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles({ isCareerMapperTab });
  const { pathname } = useLocation();
  const [drawerOpened, setDrawerOpened] = useState(false);
  const user = useSelector(getUserSelector);

  const navItems = useMemo(() => {
    if (!user) {
      return LOGIN_MENU_ITEMS;
    }

    return [...PROFILE_HEADER_ITEMS, ...LOGIN_MENU_ITEMS];
  }, [user]);

  const toggleDrawer = (value) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpened(value);
  };

  const handleLogout = () => {
    removeAccessToken();
    history.replace('/sign-in');
    dispatch(userLogout());
  };

  return (
    <>
      <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer(true)}>
        <MenuIcon className={classes.hamburger} />
      </IconButton>

      <SwipeableDrawer
        anchor="right"
        open={drawerOpened}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}>
        <Box
          className={classes.drawerContent}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}>
          <Box className={classes.drawerHeader}>
            <Avatar
              src={user?.avatar}
              className={classes.avatar}
              alt={user ? `${user.first_name} ${user.last_name}` : 'avatar'}>
              {user && !user.avatar ? getInitials(user.first_name, user.last_name) : null}
            </Avatar>
            {user && (
              <Typography className={classes.fullName} noWrap>
                {user.first_name} <br /> {user.last_name}
              </Typography>
            )}
          </Box>
          <List disablePadding className={classes.list}>
            {navItems.map(({ text, path, allowPathes }: NavItem) => (
              <ListItem
                key={text}
                button
                onClick={() => history.push(path)}
                divider
                selected={allowPathes.includes(pathname)}
                className={classes.mobileListItem}>
                <ListItemText>
                  <Typography
                    color={allowPathes.includes(pathname) ? 'primary' : 'inherit'}
                    className={classes.mobileListItemText}>
                    {text}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>

          <Box px={2} mt={4}>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              fullWidth
              endIcon={<ExitToAppOutlinedIcon />}
              onClick={handleLogout}>
              Sign out
            </Button>
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default MobileHeader;
