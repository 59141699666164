import INIT_EXPERT_STATE from 'expert/redux/expert/configs/initExpertState';
import { ExpertActions, ExpertActionTypes } from './expertActions';
import ExpertState from './interfaces/expertState';

const expertReducer = (state: ExpertState = INIT_EXPERT_STATE, action: ExpertActions): ExpertState => {
  switch (action.type) {
    case ExpertActionTypes.ResetExpert:
      return action.payload;
    case ExpertActionTypes.SetPauseUpdatingState:
      return {
        ...state,
        isPauseUpdating: action.payload,
      };
    case ExpertActionTypes.SetExpert:
      return {
        ...state,
        item: action.payload.value,
      };
    case ExpertActionTypes.SetExpertPauseState:
      return {
        ...state,
        item: state.item
          ? {
              ...state.item,
              isPaused: action.payload,
            }
          : state.item,
      };
    case ExpertActionTypes.SetExpertTimeZone:
      return {
        ...state,
        item: state.item
          ? {
              ...state.item,
              timeZone: action.payload,
            }
          : state.item,
      };
    case ExpertActionTypes.SetExpertAgreement:
      return {
        ...state,
        item: state.item
          ? {
              ...state.item,
              isAgreement: action.payload.value,
            }
          : state.item,
      };
    case ExpertActionTypes.SetExpertProfileStep:
      return {
        ...state,
        item: state.item
          ? {
              ...state.item,
              expertProfileStep: action.payload.value,
            }
          : state.item,
      };
    case ExpertActionTypes.SetExpertTempPassword:
      return {
        ...state,
        item: state.item
          ? {
              ...state.item,
              isTemporaryPass: action.payload.value,
            }
          : state.item,
      };
    default:
      return state;
  }
};

export default expertReducer;
