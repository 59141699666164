export const errors_messages = {
  user_not_found: "We couldn't find an account associated with the entered email address",
  wrong_pin: 'Wrong code value! Please check it and try again',
  invalid_credentials: 'Email or Password is invalid. Please try again',
  somethingWentWrong: 'Oops, something went wrong...',
};

export const info_messages = {
  check_email: 'Check your email!',
  accept_terms: 'Please accept the Terms of Use and Privacy Policy',
  choose_payment_package: 'Please choose package',
};
