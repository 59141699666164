import CustomerFeedbackState from 'commonComponents/customerSessionFeedback/redux/customerFeedback/interfaces/customerFeedbackState';
import INIT_CUSTOMER_FEEDBACK_STATE from 'commonComponents/customerSessionFeedback/redux/customerFeedback/configs/initCustomerFeedbackState';
import actionFn from 'expert/common/utils/actionFn';
import CustomerFeedback from 'commonComponents/customerSessionFeedback/interfaces/customerFeedback';

export enum CustomerInfoActionsTypes {
  SetCustomerFeedback = 'SetCustomerFeedback',
  ResetCustomerFeedback = 'ResetCustomerFeedback',
  SetCustomerFeedbackLoadingState = 'SetCustomerFeedbackLoadingState',
  CloseCustomerFeedback = 'CloseCustomerFeedback',
}

export interface ResetCustomerFeedbackAction {
  type: CustomerInfoActionsTypes.ResetCustomerFeedback;
  payload: CustomerFeedbackState;
}

export const resetCustomerFeedback = (): ResetCustomerFeedbackAction => ({
  type: CustomerInfoActionsTypes.ResetCustomerFeedback,
  payload: INIT_CUSTOMER_FEEDBACK_STATE,
});

export interface SetCustomerFeedbackAction {
  type: CustomerInfoActionsTypes.SetCustomerFeedback;
  payload: { feedback: CustomerFeedback | null; isFirst: boolean };
}

export const setCustomerFeedback = actionFn<SetCustomerFeedbackAction>(CustomerInfoActionsTypes.SetCustomerFeedback);

export interface SetCustomerFeedbackLoadingStateAction {
  type: CustomerInfoActionsTypes.SetCustomerFeedbackLoadingState;
  payload: boolean;
}

export const setCustomerFeedbackLoadingState = actionFn<SetCustomerFeedbackLoadingStateAction>(
  CustomerInfoActionsTypes.SetCustomerFeedbackLoadingState,
);

export interface CloseCustomerFeedbackAction {
  type: CustomerInfoActionsTypes.CloseCustomerFeedback;
}

export const closeCustomerFeedback = () => ({
  type: CustomerInfoActionsTypes.CloseCustomerFeedback,
});

export type CustomerFeedbackActions =
  | SetCustomerFeedbackAction
  | CloseCustomerFeedbackAction
  | ResetCustomerFeedbackAction
  | SetCustomerFeedbackLoadingStateAction;
