import { jobPostingsTypes } from './Constans';

const initialState = {
  loading: false,
  jobPostingsData: {},
};

export default function jobPostingsReducer(state = initialState, action) {
  const { type, payload } = action;
  const { data } = payload || {};

  switch (type) {
    case jobPostingsTypes.GET_JOB_POSTINGS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case jobPostingsTypes.GET_JOB_POSTINGS_SUCCESS:
      return {
        ...state,
        ...data,
        loading: false,
      };
    case jobPostingsTypes.POST_JOB_POSTINGS_FAVORITE_SUCCESS:
      return {
        ...state,
        jobPostingsData: {
          ...state.jobPostingsData,
          results: state.jobPostingsData.results.reduce(
            (accum, item) =>
              item.id === data.id ? [...accum, { ...item, is_favorite: data.data.data.is_favorite }] : [...accum, item],
            [],
          ),
        },
        loading: false,
      };
    case jobPostingsTypes.GET_JOB_POSTINGS_ERROR:
    case jobPostingsTypes.POST_JOB_POSTINGS_FAVORITE_ERROR:
      return {
        ...state,
        ...data,
        loading: false,
      };

    default:
      return state;
  }
}
