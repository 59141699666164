import PayoutState from 'expert/redux/payout/interfaces/payoutState';

const INIT_PAYOUT_STATE: PayoutState = {
  isLoading: false,
  history: [],
  stripeSetupUrl: '',
  stripeSetUpUrlLoading: false,
  isHistoryLoading: false,
  historyTotalCount: 0,
};

export default INIT_PAYOUT_STATE;
