import INIT_SESSIONS_STATE from 'expert/redux/sessions/configs/intSessionsState';
import SessionsState from 'expert/redux/sessions/interfaces/sessionsState';
import { SessionsActions, SessionsActionTypes } from 'expert/redux/sessions/sessionsActions';

const sessionsReducer = (state: SessionsState = INIT_SESSIONS_STATE, action: SessionsActions): SessionsState => {
  switch (action.type) {
    case SessionsActionTypes.ResetSessions:
      return action.payload;
    case SessionsActionTypes.SetCalendarSessions:
      return {
        ...state,
        calendar: action.payload,
      };
    case SessionsActionTypes.SetCalendarSessionsLoadingState:
      return {
        ...state,
        isCalendarSessionsLoading: action.payload,
      };
    case SessionsActionTypes.SetLoadingSessionsState:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SessionsActionTypes.SetSessions:
      return {
        ...state,
        items: action.payload,
      };
    case SessionsActionTypes.SetCancelingSessions:
      return {
        ...state,
        cancellingIds: action.payload,
      };
    case SessionsActionTypes.SetReschedulingSessions:
      return {
        ...state,
        reschedulingIds: action.payload,
      };
    default:
      return state;
  }
};

export default sessionsReducer;
