import moment from 'moment';
import * as types from './actionTypes';
import { DurationTypes, ExpertAssistSessionBooking } from './interfaces';

const initialState = {
  expertProfile: {
    data: null,
    isFetching: false,
    error: null,
  },
  schedule: {
    timezones: null,
    availability: {
      month: null,
      day: null,
    },
    selectedTimezone: null,
    defaultTimezone: null,
    selectedDate: moment().add(1, 'day'),
    selectedTime: null,
    selectedDuration: DurationTypes.Hour,
    isFetching: false,
    timezonesFetching: false,
    isConfirming: false,
    error: null,
  },
  editedSession: {
    data: null,
    isFetching: false,
  },
  session: null,
};

const expertAssistSessionBooking = (
  state: ExpertAssistSessionBooking = initialState,
  { type, payload, queries, selectedDate },
) => {
  switch (type) {
    case types.EA_GET_EXPERT_PROFILE:
      return {
        ...state,
        expertProfile: {
          ...state.expertProfile,
          isFetching: true,
          error: null,
        },
      };
    case types.EA_GET_EXPERT_PROFILE_SUCCESS:
      return {
        ...state,
        expertProfile: {
          ...state.expertProfile,
          isFetching: false,
          data: payload,
        },
        schedule: initialState.schedule,
        session: initialState.session,
      };
    case types.EA_GET_TIMEZONES:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          timezonesFetching: true,
          error: null,
        },
      };
    case types.EA_GET_TIMEZONES_SUCCESS:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          timezonesFetching: false,
          timezones: payload.timezones,
          selectedTimezone: state.editedSession.data?.customer_timezone
            ? payload.timezones.find((item) => item.timezone === state.editedSession.data!.customer_timezone) ??
              state.schedule.selectedTimezone
            : payload.timezone,
          defaultTimezone: payload.timezone,
        },
      };
    case types.EA_GET_TIMEZONES_ERROR:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          timezonesFetching: false,
          error: payload,
        },
      };
    case types.EA_SELECT_TIMEZONE:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          selectedTimezone: payload,
        },
      };
    case types.EA_SET_DURATION:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          selectedDuration: payload,
        },
      };
    case types.EA_SET_TIME:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          selectedTime: payload,
        },
      };
    case types.EA_CONFIRM_SESSION:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          isConfirming: true,
          error: null,
        },
      };
    case types.EA_CONFIRM_SESSION_SUCCESS:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          isConfirming: false,
        },
        session: payload,
      };
    case types.EA_CONFIRM_SESSION_ERROR:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          isConfirming: false,
          error: payload,
        },
      };
    case types.EA_GET_MONTH_AVAILABILITY_SUCCESS:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          availability: {
            ...state.schedule.availability,
            month: payload,
          },
          selectedDate: selectedDate || state.schedule.selectedDate,
        },
      };
    case types.EA_GET_DAY_AVAILABILITY:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          availability: {
            ...state.schedule.availability,
            day: null,
          },
          selectedDate: queries?.date || state.schedule.selectedDate,
          selectedTime: null,
        },
      };
    case types.EA_GET_DAY_AVAILABILITY_SUCCESS:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          availability: {
            ...state.schedule.availability,
            day: payload,
          },
        },
      };
    case types.EA_EDIT_SESSION:
      return {
        ...state,
        editedSession: {
          ...state.editedSession,
          data: payload,
        },
      };
    case types.EA_GET_SESSION:
      return {
        ...state,
        editedSession: {
          ...state.editedSession,
          data: null,
          isFetching: true,
        },
      };
    case types.EA_GET_SESSION_SUCCESS:
      return {
        ...state,
        editedSession: {
          ...state.editedSession,
          data: payload,
          isFetching: false,
        },
      };
    case types.EA_GET_SESSION_ERROR:
      return {
        ...state,
        editedSession: {
          ...state.editedSession,
          data: null,
          isFetching: false,
        },
      };
    case types.EA_CLEAR_BOOKING_DATA:
      return initialState;
    default:
      return state;
  }
};

export default expertAssistSessionBooking;
