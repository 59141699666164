import { put, call, takeLatest } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import { paymentPackagesActionTypes } from './Constants';
import * as paymentPackagesActions from './Actions';

export function* handleGetPaymentPackagesRequest({ payload }) {
  try {
    const { data } = yield call(processRequest, `payment/${payload.data.feature}/tariff`);

    yield put(
      paymentPackagesActions.getPaymentPackagesSuccess({
        paymentPackages: data.results,
      }),
    );
  } catch (e) {
    yield put(paymentPackagesActions.getPaymentPackagesError({ error: e }));
  }
}

const paymentPackagesSagas = [
  takeLatest(paymentPackagesActionTypes.GET_PAYMENT_PACKAGES_REQUEST, handleGetPaymentPackagesRequest),
];

export default paymentPackagesSagas;
