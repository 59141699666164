import axios from 'axios';
import queryString from 'qs';
import Bugsnag from '@bugsnag/js';
import history from '../history';
import { getAccessToken, removeAccessToken } from './AccessToken';

// eslint-disable-next-line no-undef
const { REACT_APP_API_URL, REACT_APP_NODE_ENV } = process.env;

export function processRequest(url = '', method = 'GET', data = {}, file = false) {
  let headers = {
    'Content-Type': file ? data.type : 'application/json',
  };

  const token = getAccessToken();

  if (token) headers = { ...headers, Authorization: `Token ${token}` };

  axios.interceptors.response.use(
    async (response) => {
      return response;
    },
    async (error) => {
      const { status, data: error_data } = error.response || {};

      if (status === 401) {
        removeAccessToken();
        history.replace('/sign-in');
      }

      if (REACT_APP_NODE_ENV !== 'local') {
        if (status === 404) {
          Bugsnag.notify(error_data);
          // history.replace('/404');
        }

        if (status === 500) {
          Bugsnag.notify(error_data);
          history.replace('/500');
        }

        if (status === 400) {
          Bugsnag.notify(error_data);
        }
      }

      return Promise.reject(error);
    },
  );

  return axios({
    headers,
    method,
    params: method === 'GET' ? data : {},
    // eslint-disable-next-line no-nested-ternary
    data: method === 'GET' ? {} : file ? data.file : data,
    crossDomain: true,
    mode: 'cors',
    url: `${REACT_APP_API_URL}${encodeURI(url)}`,
    paramsSerializer: (params) => queryString.stringify(params, { encodeValuesOnly: true, arrayFormat: 'repeat' }),
  });
}

export async function fetchRequest(url, data, method = 'GET', useApiUrl = true, file) {
  const token = getAccessToken();
  let headers = {
    'Content-Type': file ? 'application/pdf' : 'application/json',
  };

  if (!file) {
    headers = { ...headers, Authorization: `Token ${token}` };
  }

  const res = await fetch(useApiUrl ? `${REACT_APP_API_URL}${url}` : url, {
    method,
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers,
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: method === 'GET' ? undefined : JSON.stringify(data), // body data type must match "Content-Type" header
  });

  return file ? res : res.json();
}
