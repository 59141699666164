import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(6, 12),
      '& > svg': {
        width: 158,
        height: 'auto',
      },
    },
    logo: {
      marginBottom: theme.spacing(3),
      color: theme.palette.secondary.main,
    },
    buttonWrap: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      color: '#fff',
      width: 257,
      height: 45,
    },
  }),
  {
    name: 'TextDialog',
  },
);
