import { onboardingActionTypes, SAVE_FROM } from './Constants';

const initialState = {
  loading: false,
  locations: {},
  inprogressPageId: 0,
  inprogressGroupId: 0,
  previousPageId: 0,
  previousGroupId: 0,
  pagesCount: 0,
  finishedPagesCount: 0,
  offset: 0,
  limit: 25,
  onboarding: [],
  fileUrl: '',
  answers: {},
  showFinish: false,
  isSubmittingOnboarding: false,
};

export default function onboardingReducer(state = initialState, action) {
  const { type, payload } = action;
  const { data } = payload || {};

  switch (type) {
    case onboardingActionTypes.GET_ONBOARDING_DATA_REQUEST:
    case onboardingActionTypes.GET_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case onboardingActionTypes.SAVE_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        isSubmittingOnboarding: payload.saveFrom === SAVE_FROM.FINISH,
      };
    case onboardingActionTypes.SAVE_AND_RERUN_REQUEST:
      return {
        ...state,
        loading: true,
        isSubmittingOnboarding: true,
      };
    case onboardingActionTypes.SAVE_AND_RERUN_SUCCESS:
      return {
        ...state,
        loading: false,
        isSubmittingOnboarding: false,
      };
    case onboardingActionTypes.END_SUBMITTING_ONBOARDING:
      return {
        ...state,
        isSubmittingOnboarding: false,
      };
    case onboardingActionTypes.GET_ONBOARDING_DATA_SUCCESS:
    case onboardingActionTypes.SAVE_QUESTIONS_SUCCESS:
    case onboardingActionTypes.GET_LOCATION_SUCCESS:
    case onboardingActionTypes.CLEAR_LOCATION:
    case onboardingActionTypes.SET_CURRENT_PAGE_ID:
      return {
        loading: false,
        ...state,
        ...data,
      };

    case onboardingActionTypes.GET_LOCATION_UPDATE_SUCCESS: {
      const locations_ = data.locations;

      if (state.locations.results) {
        locations_.results = [...state.locations.results, ...locations_.results];
      }

      return {
        loading: false,
        ...state,
        locations: locations_,
      };
    }

    default:
      return state;
  }
}
