import Feedback from 'expert/modules/expertFeedback/interfaces/feedback';
import FeedbackRes from 'expert/sagas/interfaces/feedbackRes';
import resToSession from 'expert/sagas/utils/resToSession';

const resToFeedback = (source: FeedbackRes): Feedback => ({
  disliked: source.what_dont_like,
  liked: source.what_like,
  suggested: source.suggestion,
  id: source.id,
  session: resToSession(source.zoom_session),
  ...(source.is_like === null ? {} : { customerRate: source.is_like }),
});

export default resToFeedback;
