export const filterReports = (filters, reports) => {
  const hasSelectedFilter = Object.keys(filters).some((key) => filters[key].selected);

  if (!hasSelectedFilter) {
    return reports;
  }

  const { topMatch, goodMatch, lowMatch, favorite } = filters;

  return reports.reduce((acc, item) => {
    if (topMatch.selected && item.match_type === topMatch.type) {
      acc.push(item);

      return acc;
    }

    if (goodMatch.selected && item.match_type === goodMatch.type) {
      acc.push(item);

      return acc;
    }

    if (lowMatch.selected && item.match_type === lowMatch.type) {
      acc.push(item);

      return acc;
    }

    if (favorite.selected && item.is_favorite) {
      acc.push(item);

      return acc;
    }

    return acc;
  }, []);
};

export function normalizePathTitle(title) {
  return title.replaceAll('&', ' ').replaceAll(',', ' ').replaceAll(' ', '_');
}
