import { signInActionTypes } from './Constants';

export function loginRequest(data) {
  return {
    type: signInActionTypes.LOGIN_REQUEST,
    payload: {
      data,
    },
  };
}

export function loginError(data) {
  return {
    type: signInActionTypes.LOGIN_ERROR,
    payload: {
      data,
    },
  };
}

export function sendRestorePasswordError() {
  return {
    type: signInActionTypes.SEND_RESTORE_PASSWORD_ERROR,
  };
}

export function setAgreement() {
  return {
    type: signInActionTypes.SET_AGREEMENT,
  };
}

export function pinRequest(data) {
  return {
    type: signInActionTypes.PIN_REQUEST,
    payload: {
      data,
    },
  };
}

export function pinSuccess(data) {
  return {
    type: signInActionTypes.PIN_SUCCESS,
    payload: {
      data,
    },
  };
}

export function pinError(data) {
  return {
    type: signInActionTypes.PIN_ERROR,
    payload: {
      data,
    },
  };
}

export function getUserRequest(data) {
  return {
    type: signInActionTypes.GET_USER_REQUEST,
    payload: {
      data,
    },
  };
}

export function getUserSuccess(data) {
  return {
    type: signInActionTypes.GET_USER_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getUserError(data) {
  return {
    type: signInActionTypes.GET_USER_ERROR,
    payload: {
      data,
    },
  };
}

export function updateUserRequest(data) {
  return {
    type: signInActionTypes.UPDATE_USER_REQUEST,
    payload: {
      data,
    },
  };
}

export function goToPin() {
  return {
    type: signInActionTypes.PIN_START,
  };
}

export function updateUserSuccess(data) {
  return {
    type: signInActionTypes.UPDATE_USER_SUCCESS,
    payload: {
      data,
    },
  };
}

export function updateUserError(data) {
  return {
    type: signInActionTypes.UPDATE_USER_ERROR,
    payload: {
      data,
    },
  };
}
