import { generalActionTypes } from './Constants';

const initialState = {
  loading: false,
  discount: false,
  loadingNewPath: false,
};

export default function generalReducer(state = initialState, action) {
  const { type, payload } = action;
  const { data } = payload || {};

  switch (type) {
    case generalActionTypes.CHECK_DISCOUNT_REQUEST:
      return {
        ...state,
        ...data,
        loading: true,
      };

    case generalActionTypes.CHECK_DISCOUNT_SUCCESS:
      return {
        ...state,
        ...data,
        loading: false,
      };

    case generalActionTypes.CHECK_DISCOUNT_ERROR:
      return {
        ...state,
        ...data,
        loading: false,
      };

    default:
      return state;
  }
}
