import NotificationsState from 'expert/redux/notifications/interfaces/notificationsState';
import INIT_NOTIFICATIONS_STATE from 'expert/redux/notifications/configs/initNotificationsState';
import { NotificationsActions, NotificationsActionTypes } from 'expert/redux/notifications/notificationsActions';

const notificationsReducer = (
  state: NotificationsState = INIT_NOTIFICATIONS_STATE,
  action: NotificationsActions,
): NotificationsState => {
  switch (action.type) {
    case NotificationsActionTypes.ResetNotifications:
      return action.payload;
    case NotificationsActionTypes.SetUnreadNotificationsCount:
      return {
        ...state,
        unreadCount: action.payload,
      };
    case NotificationsActionTypes.SetNotifications:
      return {
        ...state,
        items: action.payload,
      };
    case NotificationsActionTypes.SetNotificationsAndTotalCount:
      return {
        ...state,
        ...action.payload,
      };
    case NotificationsActionTypes.SetNotificationsLoadingStatus:
      return {
        ...state,
        isLoading: action.payload,
      };
    case NotificationsActionTypes.SetNotificationsAndStopLoading:
      return {
        ...state,
        items: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
