import { takeLatest } from 'redux-saga/effects';
import {
  EA_GET_EXPERT_PROFILE,
  EA_GET_TIMEZONES,
  EA_CONFIRM_SESSION,
  EA_GET_MONTH_AVAILABILITY,
  EA_GET_DAY_AVAILABILITY,
  EA_GET_SESSION,
} from './actionTypes';
import {
  eaGetExpertProfile,
  eaGetTimezones,
  eaConfirmSession,
  eaGetMonthAvailability,
  eaGetDayAvailability,
  eaGetSession,
} from './sagas';

const expertAssistSessionBookingSagas = [
  takeLatest(EA_GET_EXPERT_PROFILE, eaGetExpertProfile),
  takeLatest(EA_GET_TIMEZONES, eaGetTimezones),
  takeLatest(EA_CONFIRM_SESSION, eaConfirmSession),
  takeLatest(EA_GET_MONTH_AVAILABILITY, eaGetMonthAvailability),
  takeLatest(EA_GET_DAY_AVAILABILITY, eaGetDayAvailability),
  takeLatest(EA_GET_SESSION, eaGetSession),
];

export default expertAssistSessionBookingSagas;
