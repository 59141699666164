import KeyMirror from 'keymirror';

export const onboardingActionTypes = KeyMirror({
  GET_ONBOARDING_DATA_REQUEST: null,
  GET_ONBOARDING_DATA_SUCCESS: null,
  GET_ONBOARDING_DATA_ERROR: null,

  END_SUBMITTING_ONBOARDING: null,

  GET_LOCATION_REQUEST: null,
  GET_LOCATION_SUCCESS: null,
  GET_LOCATION_ERROR: null,

  GET_LOCATION_UPDATE_REQUEST: null,
  GET_LOCATION_UPDATE_SUCCESS: null,
  GET_LOCATION_UPDATE_ERROR: null,

  CLEAR_LOCATION: null,

  SAVE_QUESTIONS_REQUEST: null,
  SAVE_QUESTIONS_SUCCESS: null,
  SAVE_QUESTIONS_ERROR: null,

  SAVE_AND_RERUN_REQUEST: null,
  SAVE_AND_RERUN_SUCCESS: null,

  SET_CURRENT_PAGE_ID: null,

  SET_SHOW_FINISH: null,
});

export const onboardingProccess = [
  {
    icon: 'icon-onboardOverview1',
    title: 'Part 1: Tell us about yourself',
    titleDescription: '(your career profile questionnaire)',
    button: '',
  },
  {
    icon: 'icon-onboardOverview2',
    title: 'Part 2: Upload your LinkedIn profile',
    titleDescription: '',
    button: '',
  },
  {
    icon: 'icon-onboardOverview3',
    title: 'Part 3: Upload your resume',
    titleDescription: '',
    button: '',
  },
];

export const MAX_FILE_SIZE = 5000000;
export const STRING_N_REGEX = /(^string_)([0-9])(?!.*\d)/;
export const MULTILINE_STRING_N_REGEX = /(^multiline_string_)([0-9])(?!.*\d)/;

export const SAVE_FROM = {
  SAVE_AND_EXIT: 'saveAndExitBtn',
  NEXT: 'nextBtn',
  BACK: 'backBtn',
  FINISH: 'finishBtn',
  SAVE_AND_RERUN: 'saveAndRerun',
};
