import Review from 'expert/common/components/reviewItem/interfaces/review';
import Notification from 'expert/modules/notifications/interfaces/notification';

interface UpdateNotificationReviewProps {
  notifications: Notification[];
  newReview: Review;
}

const updateNotificationReview: (props: UpdateNotificationReviewProps) => Notification[] = ({
  newReview,
  notifications,
}) =>
  notifications.map((notification) =>
    notification.review?.id === newReview.id ? { ...notification, review: newReview } : notification,
  );

export default updateNotificationReview;
