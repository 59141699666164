import KeyMirror from 'keymirror';

export const careerMapperDetailsTypes = KeyMirror({
  GET_CAREER_MAPPER_DETAILS_REQUEST: null,
  GET_CAREER_MAPPER_DETAILS_SUCCESS: null,
  GET_CAREER_MAPPER_DETAILS_ERROR: null,
});

export const MAX_WIDTH_FOR_CHART_CONTAINER = 300;
export const MAX_WIDTH_FOR_ACCORDION_CHART_CONTAINER = 224;
export const HEADER_HEIGHT = 80;
export const SUBHEADER_HEIGHT = 130;

export const CM_DETAILS_TABS_PATHS = {
  jobFamilyOverview: '/job-family-overview',
  aboutYourFit: '/about-your-fit',
  liveJobPosting: '/live-job-posting',
};

export const spiderChartTypes = {
  topSoftSkills: 'topSoftSkills',
  yourSkills: 'yourSkills',
  strongest: 'strongest',
  strengthening: 'strengthening',
};

export const spiderChartDependsOnPlotIndex = {
  0: spiderChartTypes.topSoftSkills,
  1: spiderChartTypes.yourSkills,
  2: spiderChartTypes.strongest,
  3: spiderChartTypes.strengthening,
};

export const TIME_FOR_SECOND_FM = 1000 * 60 * 15; // 15 min

export const MAX_CANCELED_FEEDBACKS = 2;
