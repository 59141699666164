import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Moment } from 'moment';
import { Session } from '../../activity/components/SessionsList/interfaces';

export interface AreasOfExpertiseItem {
  id: number;
  name: string;
  order_num: number;
  visible: boolean;
}

interface ReviewExpertCustomerData {
  avatar: string | null;
  first_name: string;
  last_name: string;
}

interface ReviewZoomSession {
  session_datetime_from: string;
  session_datetime_to: string;
}

export interface Review {
  created_at: string;
  id: number;
  is_publish: boolean;
  rate: number;
  suggestion: string;
  unread: boolean;
  updated_at: string;
  what_dont_like: string;
  what_like: string;
  customer: ReviewExpertCustomerData;
  expert: ReviewExpertCustomerData;
  zoom_session: ReviewZoomSession;
}

export interface TypesOfServicesOfferedItem {
  id: number;
  is_other: boolean;
  name: string;
  order_num: number;
  visible: boolean;
  other_text?: string;
}

interface ExpertProfileData {
  about_me: string;
  avatar: string | null;
  id: number;
  name: string;
  last_name: string;
  first_name: string;
  price_30: number;
  price_60: number;
  rate: number;
  current_job_title: string;
  areas_of_expertise: AreasOfExpertiseItem[];
  reviews: Review[];
  skills_and_competencies: string[];
  type_of_services_offered: TypesOfServicesOfferedItem[];
}

interface ExpertProfile {
  data: ExpertProfileData | null;
  isFetching: boolean;
  error: string | null;
}

export interface Timezone {
  label: string;
  timezone: string;
}

export interface MonthAvailabilityItem {
  date: string;
  day: number;
  is_available: boolean;
}

interface DayAvailabilityItem {
  time_from: string;
  time_to: string;
}

interface Availability {
  month: MonthAvailabilityItem[] | null;
  day: DayAvailabilityItem[] | null;
}

export enum DurationTypes {
  HalfAnHour = 'min_30',
  Hour = 'min_60',
}

interface Schedule {
  timezones: Timezone[] | null;
  availability: Availability;
  selectedTimezone: Timezone | null;
  defaultTimezone: Timezone | null;
  selectedDate: MaterialUiPickersDate | Moment | Date;
  selectedTime: any;
  selectedDuration: DurationTypes;
  isFetching: boolean;
  timezonesFetching: boolean;
  isConfirming: boolean;
  error: string | null;
}

interface SessionData {
  client_secret: string;
}

interface EditedSession {
  data: Session | null;
  isFetching: boolean;
}

export interface ExpertAssistSessionBooking {
  expertProfile: ExpertProfile;
  schedule: Schedule;
  editedSession: EditedSession;
  session: SessionData | null;
}

export interface ExpertAssistSessionBookingReducer {
  expertAssistSessionBooking: ExpertAssistSessionBooking;
}
