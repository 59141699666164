import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const Logo = (props) => (
  <SvgIcon {...props}>
    <path d="M14.5791 27.4715H17.5164V15.2962C17.516 13.8795 16.953 12.521 15.9511 11.5194C14.9492 10.5178 13.5904 9.95508 12.1737 9.95508H0V12.8909H14.2259C14.3196 12.8909 14.4094 12.9281 14.4757 12.9943C14.5419 13.0606 14.5791 13.1504 14.5791 13.244V27.4715Z" />
    <path d="M24.5398 27.4712H27.4756V5.34269C27.4756 3.92598 26.9129 2.56728 25.9113 1.56537C24.9097 0.563467 23.5511 0.000401902 22.1344 0H0.00585938V2.93734H24.1866C24.2803 2.93734 24.3701 2.97455 24.4363 3.04078C24.5026 3.107 24.5398 3.19683 24.5398 3.29049V27.4712Z" />
    <path d="M19.5595 27.4715H22.4953V10.3173C22.4953 8.90059 21.9327 7.54189 20.931 6.53998C19.9294 5.53808 18.5709 4.97501 17.1542 4.97461H0V7.91044H19.214C19.3076 7.91044 19.3974 7.94764 19.4637 8.01387C19.5299 8.0801 19.5671 8.16992 19.5671 8.26358L19.5595 27.4715Z" />
    <path d="M9.60016 27.471H12.536V20.2762C12.5352 18.8597 11.9719 17.5016 10.9701 16.5003C9.96821 15.499 8.60974 14.9365 7.1933 14.9365H0V17.8723H9.24549C9.33916 17.8723 9.42898 17.9096 9.49521 17.9758C9.56143 18.042 9.59864 18.1318 9.59864 18.2255L9.60016 27.471Z" />
    <path d="M4.61972 27.4716H7.55554V25.2572C7.55554 23.8406 6.99281 22.4821 5.99115 21.4804C4.98949 20.4787 3.63094 19.916 2.21437 19.916H0V22.8518H4.26657C4.36023 22.8518 4.45005 22.889 4.51628 22.9553C4.58251 23.0215 4.61972 23.1113 4.61972 23.205V27.4716Z" />
    <path d="M41.0629 27.5632C40.6057 27.4565 40.1629 27.2952 39.7442 27.0827C39.4074 26.9068 39.0828 26.7083 38.7727 26.4886C38.5592 26.3402 38.3542 26.1798 38.1589 26.0081L37.9512 25.7853V31.9995H34.9199V12.8947H37.9512V14.5695C38.0897 14.4321 38.2847 14.2492 38.5363 14.0208C38.7879 13.7925 39.3239 13.4964 40.1444 13.1327C40.9307 12.7744 41.7842 12.5873 42.6482 12.584C44.5741 12.584 46.2459 13.3221 47.6635 14.7984C49.0812 16.2746 49.7885 18.0651 49.7855 20.1698C49.7855 22.2736 49.0782 24.0641 47.6635 25.5413C46.2489 27.0186 44.5772 27.7567 42.6482 27.7557C42.1139 27.7571 41.5814 27.6924 41.0629 27.5632ZM39.285 16.7551C38.4272 17.6914 37.9514 18.9151 37.9514 20.185C37.9514 21.4548 38.4272 22.6786 39.285 23.6149C40.1732 24.5637 41.1952 25.0381 42.3512 25.0381C43.5071 25.0381 44.5297 24.5637 45.4189 23.6149C46.2767 22.6786 46.7525 21.4548 46.7525 20.185C46.7525 18.9151 46.2767 17.6914 45.4189 16.7551C44.5287 15.8063 43.5061 15.3319 42.3512 15.3319C41.1962 15.3319 40.1742 15.8063 39.285 16.7551Z" />
    <path d="M59.8565 27.4603V25.9446L59.6564 26.1371C59.477 26.2927 59.2893 26.4384 59.0941 26.5736C58.806 26.7803 58.5019 26.9638 58.1847 27.1223C57.7904 27.3094 57.378 27.4557 56.954 27.5588C56.4656 27.6866 55.9628 27.7513 55.458 27.7513C53.9757 27.7513 52.746 27.3218 51.7689 26.463C50.7918 25.6041 50.3028 24.6209 50.3018 23.5135C50.3018 22.3192 50.6797 21.3168 51.4355 20.5064C52.1913 19.6961 53.226 19.1823 54.5395 18.965L59.858 18.0465C59.8646 17.6808 59.7919 17.318 59.6448 16.9831C59.4978 16.6482 59.2799 16.3491 59.0062 16.1065C58.4383 15.5821 57.7098 15.3199 56.8206 15.3199C56.0933 15.31 55.3744 15.4753 54.7244 15.8019C54.097 16.1232 53.6645 16.4465 53.427 16.7719L53.0284 17.2917L51.0581 15.3214C51.1172 15.2426 51.199 15.1425 51.3036 15.0183C51.521 14.7861 51.7534 14.5684 51.9993 14.3665C52.3589 14.0569 52.7431 13.7771 53.1482 13.5299C53.6528 13.2413 54.1929 13.0197 54.7548 12.8706C55.4266 12.6764 56.1227 12.5789 56.8221 12.5811C58.5995 12.5811 60.0565 13.107 61.1933 14.1589C61.7402 14.6465 62.1754 15.2464 62.4694 15.9176C62.7634 16.5888 62.9092 17.3154 62.8969 18.0481V27.4572L59.8565 27.4603ZM58.8122 23.9258C59.5084 23.1902 59.8565 22.1408 59.8565 20.7778V20.4746L55.1443 21.2324C54.6451 21.3091 54.1832 21.5427 53.8257 21.8993C53.6591 22.0721 53.5288 22.2764 53.4424 22.5002C53.3559 22.7241 53.3152 22.963 53.3225 23.2028C53.3225 23.6878 53.5569 24.1127 54.0257 24.4775C54.4946 24.8422 55.1246 25.0251 55.9158 25.0261C57.1495 25.0292 58.115 24.6624 58.8122 23.9258Z" />
    <path d="M68.8227 27.4609L62.8965 12.8955H66.0794L70.3232 23.5187L74.5761 12.8955H77.759L71.8313 27.4609H68.8227Z" />
    <path d="M79.9324 21.237C80.0993 22.2799 80.6376 23.2273 81.4481 23.9045C82.2851 24.6488 83.3716 25.0509 84.4915 25.0307C85.3198 25.0363 86.1366 24.8374 86.8696 24.4517C87.6062 24.0667 88.1276 23.6913 88.4338 23.3256L88.8885 22.7633L90.8588 24.7336C90.7997 24.8124 90.7073 24.9246 90.5845 25.067C90.3367 25.3264 90.0719 25.5689 89.7918 25.793C89.3903 26.134 88.9592 26.4383 88.5035 26.7024C87.9416 27.0093 87.3474 27.2528 86.7317 27.4284C86.0043 27.6513 85.2477 27.7637 84.487 27.7619C82.4227 27.7619 80.6423 27.004 79.1458 25.4884C78.4222 24.7779 77.8502 23.9281 77.4644 22.9902C77.0785 22.0524 76.8869 21.046 76.9011 20.032C76.9075 18.0633 77.6917 16.1769 79.0828 14.7839C80.4739 13.3908 82.3592 12.6039 84.3278 12.5947C86.3679 12.5947 88.067 13.2737 89.425 14.6318C90.783 15.9898 91.462 17.6914 91.462 19.7365L91.2984 21.2521L79.9324 21.237ZM81.5709 16.1489C80.8181 16.8075 80.2916 17.6861 80.0658 18.6604H88.4974C88.3483 17.686 87.8586 16.7961 87.1151 16.1489C86.3735 15.5023 85.4449 15.1789 84.3294 15.1789C83.2351 15.1779 82.3156 15.5012 81.5709 16.1489Z" />
    <path d="M92.6465 11.1676C92.6465 11.3304 92.5875 11.4607 92.4735 11.5604C92.3575 11.6601 92.1927 11.709 91.979 11.709C91.8792 11.709 91.7836 11.6967 91.69 11.6703C91.5963 11.6438 91.521 11.6153 91.4661 11.5848C91.4111 11.5543 91.3602 11.5176 91.3134 11.4769C91.2646 11.4362 91.2341 11.4057 91.2198 11.3894C91.2056 11.3731 91.1954 11.3609 91.1913 11.3528L91 11.5645L91.0387 11.6113C91.059 11.6398 91.0977 11.6764 91.1547 11.7212C91.2096 11.7639 91.2727 11.8066 91.344 11.8474C91.4172 11.8881 91.5088 11.9247 91.6208 11.9552C91.7347 11.9858 91.8528 12 91.9769 12C92.2843 12 92.5244 11.9247 92.6974 11.77C92.8725 11.6153 92.9579 11.4159 92.9579 11.1676C92.9579 11.0251 92.9294 10.905 92.8704 10.8033C92.8134 10.7015 92.7361 10.6242 92.6425 10.5672C92.5488 10.5122 92.4471 10.4654 92.3351 10.4267C92.2232 10.3881 92.1113 10.3535 91.9973 10.3209C91.8853 10.2883 91.7815 10.2537 91.6879 10.213C91.5943 10.1744 91.519 10.1194 91.46 10.0482C91.403 9.97897 91.3725 9.89349 91.3725 9.79172C91.3725 9.65332 91.4294 9.53528 91.5434 9.43758C91.6574 9.33989 91.808 9.29104 91.9993 9.29104C92.0807 9.29104 92.1581 9.30326 92.2334 9.32361C92.3087 9.34396 92.3697 9.36839 92.4145 9.39484C92.4593 9.4213 92.502 9.45183 92.5427 9.4844C92.5834 9.51696 92.6099 9.54138 92.6201 9.5536C92.6323 9.56581 92.6404 9.57598 92.6445 9.58412L92.8541 9.37449L92.8195 9.33379C92.7992 9.30936 92.7626 9.27883 92.7117 9.2422C92.6628 9.20556 92.6058 9.17096 92.5407 9.13433C92.4776 9.09769 92.3982 9.06716 92.3026 9.04071C92.2069 9.01425 92.1052 9 91.9993 9C91.7185 9 91.4925 9.07734 91.3195 9.23202C91.1486 9.3867 91.0611 9.57191 91.0611 9.79172C91.0611 9.93419 91.0916 10.0543 91.1486 10.156C91.2076 10.2578 91.2829 10.3372 91.3765 10.3921C91.4701 10.4491 91.5719 10.4959 91.6839 10.5346C91.7978 10.5733 91.9098 10.6079 92.0217 10.6404C92.1357 10.673 92.2374 10.7076 92.3311 10.7463C92.4247 10.787 92.5 10.8419 92.557 10.9111C92.616 10.9824 92.6465 11.0678 92.6465 11.1676Z" />
    <path d="M96.2551 9.58412V11.9593H96.5665V9.04071H96.2958L95.0868 10.6262L93.8779 9.04071H93.6072V11.9593H93.9206V9.58412L95.0868 11.1248L96.2551 9.58412Z" />
  </SvgIcon>
);
