import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: ({ fullPage }: { fullPage?: boolean }) => (fullPage ? '100vh' : '100%'),
  },
});

export default useStyles;
