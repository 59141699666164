import CustomerFeedbackRes from 'expert/sagas/interfaces/customerFeedbackRes';
import CustomerFeedback from 'commonComponents/customerSessionFeedback/interfaces/customerFeedback';

const resToCustomerFeedback = (source: CustomerFeedbackRes): CustomerFeedback => ({
  id: source.id,
  expert: {
    avatar: source.expert.avatar,
    lastName: source.expert.last_name,
    firstName: source.expert.first_name,
    position: source.expert.position || undefined,
  },
  timeZone: source.zoom_session.customer_timezone,
  timeZoneLabel: source.zoom_session.customer_timezone_label,
  dateFrom: source.zoom_session.session_datetime_from,
  dateTo: source.zoom_session.session_datetime_to,
  date: source.zoom_session.session_datetime_from,
  liked: source.what_like,
  disliked: source.what_dont_like,
  suggested: source.suggestion,
  ...(source.rate ? { rate: source.rate } : {}),
});

export default resToCustomerFeedback;
