import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { isAuthenticated } from '../../../services/AccessToken';
import { getUserSelector } from '../../../selectors';
import Header from '../../../pages/landing/components/shared/Header';
import AppBarCustom from '../../../commonComponents/appBar/AppBarCustom';
import { noHeaderRoutes } from './constants';

interface Props {
  children: React.ReactNode;
}

function Layout({ children }: Props) {
  const isLoggedIn = isAuthenticated();
  const user = useSelector(getUserSelector);
  const { pathname } = useLocation();

  const noLayoutRoute = useMemo(() => noHeaderRoutes.find((item) => pathname.startsWith(item)), [pathname]);

  if (noLayoutRoute) {
    return children;
  }

  if (!isLoggedIn) {
    return (
      <>
        <Header />
        <Box display="flex" flexDirection="column">
          {children}
        </Box>
      </>
    );
  }

  if (user?.user_type === 'customer') {
    return (
      <>
        <AppBarCustom />
        <Box display="flex" flexDirection="column">
          {children}
        </Box>
      </>
    );
  }

  return children;
}

export default Layout;
