import { put } from 'redux-saga/effects';
import { AxiosError } from 'axios';

function extractAxiosError(error: AxiosError) {
  let errorMessage: string = '';

  if (error.response) {
    errorMessage = error.response.data?.detail;
  } else if (error.message && !errorMessage) {
    errorMessage = error.message;
  }

  return errorMessage || 'Oops, something went wrong';
}

export function exceptionCatcherWrap(originalFunc, failureAction) {
  return function* (...args) {
    try {
      yield* originalFunc(...args);
    } catch (error: any) {
      if (failureAction) {
        const payload = extractAxiosError(error);

        yield put(failureAction(payload));
      }
    }
  };
}
