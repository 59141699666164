import PrivacyTermsState from 'expert/redux/privacyTerms/interfaces/privacyTermsState';
import INIT_PRIVACY_TERMS_STATE from 'expert/redux/privacyTerms/configs/initPrivacyTermsState';
import { PrivacyTermsActions, PrivacyTermsTypes } from 'expert/redux/privacyTerms/privacyTermsActions';

const privacyTermsReducer = (
  state: PrivacyTermsState = INIT_PRIVACY_TERMS_STATE,
  action: PrivacyTermsActions,
): PrivacyTermsState => {
  switch (action.type) {
    case PrivacyTermsTypes.ResetPrivacyTerms:
      return action.payload;
    case PrivacyTermsTypes.SetPrivacyTermsOpened:
      return {
        ...action.payload.value,
      };
    case PrivacyTermsTypes.SetPrivacyTermsClosed:
      return {
        ...state,
        isOpened: action.payload.value,
      };
    default:
      return state;
  }
};

export default privacyTermsReducer;
