import ActionPayload from 'expert/common/interfaces/actionPayload';
import actionFn from 'expert/common/utils/actionFn';
import AgreementState from 'expert/redux/agreement/interfaces/agreementState';
import INIT_AGREEMENT_STATE from 'expert/redux/agreement/configs/initAgreementState';

export enum AgreementTypes {
  SetAgreementOpened = 'SetAgreementOpened',
  SetAgreementClosed = 'SetAgreementClosed',
  ResetAgreement = 'ResetAgreement',
}

export type ResetAgreementAction = {
  type: AgreementTypes.ResetAgreement;
  payload: AgreementState;
};

export const resetAgreement = (): ResetAgreementAction => ({
  type: AgreementTypes.ResetAgreement,
  payload: INIT_AGREEMENT_STATE,
});

export type SetAgreementOpenedAction = {
  type: AgreementTypes.SetAgreementOpened;
  payload: ActionPayload<AgreementState>;
};

export const setAgreementOpened = actionFn<SetAgreementOpenedAction>(AgreementTypes.SetAgreementOpened);

export type SetAgreementClosedAction = {
  type: AgreementTypes.SetAgreementClosed;
  payload: ActionPayload<false>;
};

export const setAgreementClosed = (): SetAgreementClosedAction => ({
  type: AgreementTypes.SetAgreementClosed,
  payload: { value: false },
});

export type AgreementActions = SetAgreementClosedAction | SetAgreementOpenedAction | ResetAgreementAction;
