import { put, call, takeLatest } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import { careerMapperDetailsTypes } from './Constants';
import * as careerMapperDetailsActions from './Actions';

export function* handleGetDetailsDataRequest({ payload }) {
  try {
    const { data } = yield call(processRequest, `customer/path/${payload.data.id}/`);

    yield put(
      careerMapperDetailsActions.getCareerMapperDetailsSuccess({
        detailsData: data,
      }),
    );
  } catch (e) {
    console.log('>>> ERROR <<<', e);
    yield put(careerMapperDetailsActions.getCareerMapperDetailsError({ error: e }));
  }
}

const careerMapperDetailsSagas = [
  takeLatest(careerMapperDetailsTypes.GET_CAREER_MAPPER_DETAILS_REQUEST, handleGetDetailsDataRequest),
];

export default careerMapperDetailsSagas;
