import { landingActionTypes } from './Constants';

export function getImpactsRequest() {
  return {
    type: landingActionTypes.GET_IMPACTS_REQUEST,
  };
}

export function getImpactsSuccess(data) {
  return {
    type: landingActionTypes.GET_IMPACTS_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getImpactsError(error) {
  return {
    type: landingActionTypes.GET_IMPACTS_ERROR,
    payload: {
      error,
    },
  };
}

export function getStoriesRequest() {
  return {
    type: landingActionTypes.GET_STORIES_REQUEST,
  };
}

export function getStoriesSuccess(data) {
  return {
    type: landingActionTypes.GET_STORIES_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getStoriesError(error) {
  return {
    type: landingActionTypes.GET_STORIES_ERROR,
    payload: {
      error,
    },
  };
}
