/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
// import _ from 'lodash';
import Cookies from 'js-cookie';
import LazyLinePainter from 'lazy-line-painter';
import { gsap, ScrollTrigger } from 'gsap/all';
import CryptoJS from 'crypto-js';
import { COOKIES } from './Constants';

// import { COOKIES } from './Constants';

export const getCookie = (key) => Cookies.get(key);

export const setCookie = (key, val, options = {}) => {
  Cookies.set(key, val, options);
};

export const removeCookie = (key, options = {}) => {
  Cookies.remove(key, options);
};

export const getLocalStorageItem = (key) => {
  return localStorage.getItem(key);
};

export const setLocalStorageItem = (key, data) => {
  return localStorage.setItem(key, data);
};

export const removeLocalStorageItem = (key) => {
  localStorage.removeItem(key);
};

export const isSecure = () => {
  return window.location.protocol === 'https:';
};

export const getCountFieldsForRender = (type) => {
  const splittedType = type.split('_');

  return [...Array(Number(splittedType[splittedType.length - 1])).keys()];
};

export const encrypt = (field) => {
  return CryptoJS.AES.encrypt(field, 'key-321312-23dda242423').toString();
};

export const decrypt = (field) => {
  if (!field) return;

  return CryptoJS.AES.decrypt(field, 'key-321312-23dda242423').toString(CryptoJS.enc.Utf8);
};

export const getEmailPassword = () => {
  const email = getCookie(COOKIES.tempEm);
  const password = getCookie(COOKIES.tempP);

  return { email, password };
};

export const removeEmailPassword = () => {
  removeCookie(COOKIES.tempEm);
  removeCookie(COOKIES.tempP);
};

export const setEmailPassword = (email, password) => {
  setCookie(COOKIES.tempEm, email);
  setCookie(COOKIES.tempP, password);
};

export const encryptFields = (values) => {
  const { email, password } = values;
  const encryptedEmail = encrypt(email);
  const encryptedPassword = encrypt(password);

  return {
    encryptedEmail,
    encryptedPassword,
  };
};

// for modal window //

let isMirror;

const isMirrorFunc = (maxWindiwWidth, clickCoordinate, modalWindowWidth) => {
  isMirror = maxWindiwWidth - clickCoordinate < modalWindowWidth;

  return isMirror;
};

export const handleOpenModal = (props) => {
  const { event, onClick, data, modalWindowWidth, setModalData, setOpenModal, setOpenHoverModal } = props;
  const { clientX, clientY, view } = event;
  const { title, description, text, name } = data;

  isMirrorFunc(view?.innerWidth, clientX, modalWindowWidth);
  data
    ? setModalData({ title, description, clientX, clientY, isMirror, text, name })
    : setModalData({ clientX, clientY, isMirror });
  onClick ? setOpenModal(true) : setOpenHoverModal(event.currentTarget);
};

export const calculateProgress = (pagesCount, finishedPagesCount) => (100 / pagesCount) * finishedPagesCount;

export const createAnimationSvgLines = (elementSelector) => {
  const elements = document.getElementsByClassName(elementSelector);

  Array.from(elements).map(
    (item) =>
      new LazyLinePainter(item, {
        ease: 'easeLinear',
        strokeWidth: '2px',
        strokeCap: 'round',
        strokeLinejoin: 'round',
        strokeDash: '1, 8',
        delay: 1000,
        duration: 5000,
      }),
  );
};

const animateFrom = (elem, direction = 1) => {
  let x = 0;
  let y = direction * 200;

  if (elem.classList.contains('gs_reveal_fromLeft')) {
    x = -200;
    y = 0;
  } else if (elem.classList.contains('gs_reveal_fromRight')) {
    x = 200;
    y = 0;
  }

  elem.style.transform = `translate(${x}px,${y}px)`;
  elem.style.opacity = '0';
  gsap.fromTo(
    elem,
    { x, y, autoAlpha: 0 },
    {
      duration: 2.25,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: 'expo',
      overwrite: 'auto',
    },
  );
};

const animateFromLine = (elem) => {
  elem.classList.add('activeLine');
  elem.style.opacity = '0';
  gsap.fromTo(
    elem,
    { autoAlpha: 0 },
    {
      duration: 2.25,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: 'expo',
      overwrite: 'auto',
    },
  );
};

const animationFromFunctions = {
  animateFrom,
  animateFromLine,
};

export const createAnimationForElementsGSAP = (elementSelector, animateFromKey) => {
  const hide = (elem) => {
    gsap.set(elem, { autoAlpha: 0 });
  };

  gsap.utils.toArray(`.${elementSelector}`).forEach((elem) => {
    hide(elem); // assure that the element is hidden when scrolled into view

    ScrollTrigger.create({
      trigger: elem,
      onEnter: () => {
        animationFromFunctions[animateFromKey](elem);
      },
      onEnterBack: () => {
        animationFromFunctions[animateFromKey](elem, -1);
      },
      onLeave: () => {
        hide(elem);
      }, // assure that the element is hidden when scrolled into view
    });
  });
};
