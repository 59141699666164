import SessionStatuses from 'expert/common/components/sessionStatus/enums/sessionStatuses';

const sessionStatusMap: Record<string, SessionStatuses> = {
  upcoming: SessionStatuses.Upcoming,
  in_progress: SessionStatuses.InProgress,
  pending: SessionStatuses.Pending,
  canceled: SessionStatuses.Canceled,
  completed: SessionStatuses.Completed,
};

export default sessionStatusMap;
