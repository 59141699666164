import Session from 'expert/common/interfaces/session';
import actionFn from 'expert/common/utils/actionFn';
import PastSessionsState from 'expert/redux/pastSessions/interfaces/pastSessionsState';
import INIT_PAST_SESSIONS_STATE from 'expert/redux/pastSessions/configs/initPastSessionsState';

export enum PastSessionsActionTypes {
  SetPastSessionsLoading = 'SetPastSessionsLoading',
  SetPastSessions = 'SetPastSessions',
  ResetPastSessions = 'ResetPastSessions',
  SetPastSessionsRes = 'SetPastSessionsRes',
}

interface ResetPastSessionsAction {
  type: PastSessionsActionTypes.ResetPastSessions;
  payload: PastSessionsState;
}

export const resetPastSessions = (): ResetPastSessionsAction => ({
  type: PastSessionsActionTypes.ResetPastSessions,
  payload: INIT_PAST_SESSIONS_STATE,
});

interface SetPastSessionsLoadingAction {
  type: PastSessionsActionTypes.SetPastSessionsLoading;
  payload: boolean;
}

export const setPastSessionsLoading = actionFn<SetPastSessionsLoadingAction>(
  PastSessionsActionTypes.SetPastSessionsLoading,
);

interface SetPastSessionsAction {
  type: PastSessionsActionTypes.SetPastSessions;
  payload: Session[];
}

export const setPastSessions = actionFn<SetPastSessionsAction>(PastSessionsActionTypes.SetPastSessions);

interface SetPastSessionsResAction {
  type: PastSessionsActionTypes.SetPastSessionsRes;
  payload: {
    sessions: Session[];
    totalCount: number;
  };
}

export const setPastSessionsRes = actionFn<SetPastSessionsResAction>(PastSessionsActionTypes.SetPastSessionsRes);

export type PastSessionsActions =
  | SetPastSessionsLoadingAction
  | ResetPastSessionsAction
  | SetPastSessionsResAction
  | SetPastSessionsAction;
