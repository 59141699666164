import React, { FC } from 'react';
import DialogTypes from 'expert/redux/privacyTerms/enums/dialogTypes';
import Terms from 'expert/common/components/terms/Terms';
import Privacy from 'expert/common/components/privacy/Privacy';
import TextDialog from 'expert/common/components/textDialog/TextDialog';
import usePrivacyTermsDialog from 'expert/modules/privacyTermsDialog/hooks/usePrivacyTermsDialog';

const PrivacyTermsDialog: FC = () => {
  const { isOpened, dialogType, close } = usePrivacyTermsDialog();

  return (
    <TextDialog onClose={close} isOpened={isOpened}>
      {dialogType === DialogTypes.Terms && <Terms />}
      {dialogType === DialogTypes.Privacy && <Privacy />}
    </TextDialog>
  );
};

export default PrivacyTermsDialog;
