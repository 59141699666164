import { all, put, call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { processRequest } from '../../services/Api';
import { signInActionTypes } from './Constants';
import * as signInActions from './Actions';
import * as privacyTermsActions from '../../commonComponents/modals/customModal/Actions';
import { setAccessToken } from '../../services/AccessToken';
import { getCookie } from '../../services/Helper';
import { COOKIES, PRIVACY_TERMS_AGREEMENT } from '../../services/Constants';
import { errors_messages } from '../../services/messages';
import ToastWithMail from '../../expert/common/components/toastWithMail/ToastWithMail';

export function* handleLoginRequest({ payload }) {
  try {
    const { email: username, password } = payload.data;
    const pin = getCookie(COOKIES.pin);
    const { data } = yield call(processRequest, 'api-token-auth/login/', 'POST', {
      username,
      password,
      use_pin: Boolean(!pin),
    });

    if (payload.data.withoutRedirect) return;

    if (!pin) {
      yield all([put(push('/verification-code')), put(signInActions.goToPin())]);

      return;
    }

    const { user_type } = data.user;

    setAccessToken(data.token);
    yield put(signInActions.pinSuccess({ user: data.user, error: '' }));
    if (user_type === 'expert') {
      yield put(push('/expert'));
    } else {
      yield put(push('/home'));
      yield put(privacyTermsActions.setModalDataAction({ modalData: PRIVACY_TERMS_AGREEMENT.agreement }));
    }
  } catch (e) {
    let error = errors_messages.user_not_found;

    if (e.response?.data?.result) {
      if (e.response.data.result.indexOf('wrong_credentials') !== -1) {
        error = errors_messages.invalid_credentials;
      }

      if (e.response.data.result.indexOf('account_deactivated') !== -1) {
        error = (
          <ToastWithMail
            text="You’ve been deactivated. Please contact customer service "
            email="experts@joinpave.com"
          />
        );
      }
    }

    toast.error(error);

    yield put(signInActions.loginError({ error }));
  }
}

export function* handlePinRequest({ payload }) {
  try {
    const { pin, email } = payload.data;

    const { data } = yield call(processRequest, 'api-token-auth/login/', 'POST', {
      username: email,
      pin,
    });

    yield put(privacyTermsActions.setModalDataAction({ modalData: PRIVACY_TERMS_AGREEMENT.agreement }));
    setAccessToken(data.token);
    yield put(signInActions.pinSuccess({ user: data.user, error: '' }));
    yield put(push('/home'));
  } catch (e) {
    let error = '';

    if (e.response.data && e.response.data.result && e.response.data.result.indexOf('wrong_credentials') !== -1) {
      error = errors_messages.wrong_pin;
    }

    toast.error(errors_messages.wrong_pin);
    yield put(signInActions.pinError({ error }));
  }
}

export function* handleGetUserRequest() {
  try {
    const { data: user } = yield call(processRequest, 'user/');

    yield put(signInActions.getUserSuccess({ user }));
  } catch (e) {
    yield put(signInActions.getUserError());
  }
}

export function* handleUpdateUserRequest({ payload }) {
  try {
    const { data: user } = yield call(processRequest, 'user/', 'PUT', payload.data);

    yield put(signInActions.getUserSuccess({ user }));
  } catch (e) {
    yield put(signInActions.getUserError());
  }
}

export function* handleSetAgreementRequest() {
  try {
    yield call(processRequest, 'user/post_agreement', 'POST', {
      is_agreement: true,
    });
  } catch (e) {
    console.log(e);
  }
}

const signInSagas = [
  takeLatest(signInActionTypes.LOGIN_REQUEST, handleLoginRequest),
  takeLatest(signInActionTypes.PIN_REQUEST, handlePinRequest),
  takeLatest(signInActionTypes.GET_USER_REQUEST, handleGetUserRequest),
  takeLatest(signInActionTypes.SET_AGREEMENT, handleSetAgreementRequest),
  takeLatest(signInActionTypes.UPDATE_USER_REQUEST, handleUpdateUserRequest),
];

export default signInSagas;
