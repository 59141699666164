import React, { memo } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { primary } from '../../../styles/colors';
import { BTN_TYPES } from '../../../services/Constants';
import { heading14_17_700 } from '../../../styles/fonts';

const useStyles = (color, colorSecond, colorBorder, width, height) =>
  makeStyles(() => ({
    btnWithArrow: {
      ...heading14_17_700,
      color: colorSecond,
      border: `1px solid ${colorSecond}`,
      backgroundColor: color,
      borderRadius: '4px',
      width: width || '257px',
      height: height || '45px',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 23px',
      cursor: 'pointer',
      '&:hover': {
        color,
        border: `1px solid ${color}`,
        backgroundColor: colorSecond,
      },
    },
    btn: {
      ...heading14_17_700,
      color: colorSecond,
      border: `1px solid ${colorBorder || color}`,
      backgroundColor: color,
      borderRadius: '4px',
      width: width || '257px',
      height: height || '45px',
      display: 'flex',
      padding: '0 23px',
      cursor: 'pointer',
      '&:hover': {
        color,
        backgroundColor: colorSecond,
      },
    },
    btnWithOutBorderWithArrow: {
      ...heading14_17_700,
      color: colorSecond,
      border: `1px solid ${color}`,
      backgroundColor: color,
      borderRadius: '4px',
      width: width || '257px',
      height: height || '45px',
      display: 'flex',
      padding: '0 23px',
      cursor: 'pointer',
      '&:hover': {
        color,
        border: `1px solid ${colorSecond}`,
        backgroundColor: colorSecond,
      },
    },
  }));

const CustomButton = ({
  type = 'button',
  title,
  endIcon = '',
  typeBtn,
  // startIcon,
  onClick,
  color = primary.dark_purple,
  colorSecond = primary.milk,
  colorBorder,
  disabled = false,
  additionalClasses = {},
  width = 0,
  height = 0,
}) => {
  const classes = useStyles(color, colorSecond, colorBorder, width, height)();

  return (
    <Button
      className={classNames(
        {
          [classes.btnWithArrow]: typeBtn === BTN_TYPES.btnWithArrow,
        },
        {
          [classes.btn]: typeBtn === BTN_TYPES.btn,
        },
        {
          [classes.btnWithOutBorderWithArrow]: typeBtn === BTN_TYPES.btnWithOutBorderWithArrow,
        },
        additionalClasses?.noactive,
      )}
      endIcon={endIcon}
      onClick={onClick}
      disabled={disabled}
      type={type}>
      {title}
    </Button>
  );
};

export default memo(CustomButton);
