import INIT_UPCOMING_SESSIONS_STATE from 'expert/redux/upcomingSessions/configs/initUpcomingSessionsState';
import UpcomingSessionsState from 'expert/redux/upcomingSessions/interfaces/upcomingSessionsState';
import {
  UpcomingSessionsActions,
  UpcomingSessionsActionTypes,
} from 'expert/redux/upcomingSessions/upcomingSessionsActions';

const upcomingSessionsReducer = (
  state: UpcomingSessionsState = INIT_UPCOMING_SESSIONS_STATE,
  action: UpcomingSessionsActions,
): UpcomingSessionsState => {
  switch (action.type) {
    case UpcomingSessionsActionTypes.ResetUpcomingSessions:
      return action.payload;
    case UpcomingSessionsActionTypes.SetUpcomingSessionsRes:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case UpcomingSessionsActionTypes.SetUpcomingSessions:
      return {
        ...state,
        sessions: action.payload,
      };
    case UpcomingSessionsActionTypes.SetUpcomingSessionsLoading:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default upcomingSessionsReducer;
