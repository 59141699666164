enum NotificationSourceTypes {
  'CustomerLeftAFeedback' = 'customer-left-a-feedback',
  'CustomerBookedTheMeeting' = 'customer-booked-the-meeting',
  'MeetingIn5Minutes' = 'meeting-in-5-minutes',
  'CustomerRescheduledTheMeeting' = 'customer-rescheduled-the-meeting',
  'CustomerCanceledTheMeeting' = 'customer-canceled-the-meeting',
  'GotAPayout' = 'got-a-payout',
  'HaveToSetAvailability' = 'have-to-set-availability',
  'ShouldLeaveAFeedbackForCustomer' = 'should-leave-a-feedback-for-customer',
}

export default NotificationSourceTypes;
