import { createTheme } from '@material-ui/core/styles';

export const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      smd: 1280,
      mmd: 1335,
      lgmd: 1370,
      standart: 1440,
      slg: 1536,
      lg: 1535,
      xl: 1920,
      xxl: 2200,
    },
    keys: ['xs', 'sm', 'md', 'smd', 'mmd', 'lgmd', 'standart', 'slg', 'lg', 'xl', 'xxl'],
  },
  pallete: {
    primary: {
      dark_blue: '#0b1d2e',
      milk: '#faf7f2',
      blue: '#d1e0f0',
      coral: '#ff575a',
      purple: '#340a22',
      dark_purple: '#450A28',
      question_text: '#6E6E6E',
    },
  },
});
