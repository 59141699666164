import FetchDashboardProps from 'expert/sagas/interfaces/fetchDashboardProps';
import FetchPayoutsRequest from 'expert/sagas/interfaces/fetchPayoutsRequest';

const fetchDashboardPropsToParams = ({
  dateTo,
  dateFrom,
  limit,
  offset,
}: FetchDashboardProps): FetchPayoutsRequest => ({
  limit,
  offset,
  ...(dateTo ? { date_to: dateTo } : {}),
  ...(dateFrom ? { date_from: dateFrom } : {}),
});

export default fetchDashboardPropsToParams;
