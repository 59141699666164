import keyMirror from 'keymirror';

export const signInActionTypes = keyMirror({
  LOGIN_REQUEST: null,
  LOGIN_ERROR: null,

  PIN_REQUEST: null,
  PIN_SUCCESS: null,
  PIN_ERROR: null,

  FIRST_VERIFY_REQUEST: null,
  FIRST_VERIFY_SUCCESS: null,
  FIRST_VERIFY_ERROR: null,

  SEND_RESTORE_PASSWORD_ERROR: null,

  GET_USER_REQUEST: null,
  SET_AGREEMENT: null,
  GET_USER_SUCCESS: null,
  GET_USER_ERROR: null,

  UPDATE_USER_REQUEST: null,
  UPDATE_USER_SUCCESS: null,
  UPDATE_USER_ERROR: null,

  PIN_START: null,

  SAVE_ACCESS_TOKEN: null,
});

export const minPasswordLength = 8;
export const passwordRegExp = /^(?=.*?[a-zA-Z])(?=.*?\d).{8,}$/;
