import { landingActionTypes } from './Constants';

const initialState = {
  loading: false,
  stories: [],
  impacts: [],
};

export default function articlesReducer(state = initialState, action) {
  const { type, payload } = action;
  const { data } = payload || {};

  switch (type) {
    case landingActionTypes.GET_IMPACTS_REQUEST:
    case landingActionTypes.GET_STORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case landingActionTypes.GET_IMPACTS_SUCCESS:
    case landingActionTypes.GET_STORIES_SUCCESS:
      return {
        ...state,
        ...data,
      };

    default:
      return state;
  }
}
