import { activityCoursesTypes } from './Conctsnts';

export function getActivityCoursesRequest(data) {
  return {
    type: activityCoursesTypes.GET_CUSTOMER_JOB_COURSES_REQUEST,
    payload: {
      data,
    },
  };
}

export function getActivityCoursesSuccess(data) {
  return {
    type: activityCoursesTypes.GET_CUSTOMER_JOB_COURSES_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getActivityCoursesError(data) {
  return {
    type: activityCoursesTypes.GET_CUSTOMER_JOB_COURSES_ERROR,
    payload: {
      data,
    },
  };
}
