import { put, call, takeLatest } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import { profileActionTypes } from './Constants';
import * as profileActions from './Actions';
import * as signInActions from '../signIn/Actions';

export function* handleSaveAvatarRequest({ payload }) {
  try {
    const formData = new FormData();

    formData.append('file', payload.data.file);
    // formData.append('filename', payload.data.file.name);
    formData.append('type', 'avatar');

    yield call(processRequest, 'user/files', 'POST', { file: formData, type: 'application/pdf' }, true);

    const { data: user } = yield call(processRequest, 'user/');

    yield put(signInActions.getUserSuccess({ user }));
  } catch (e) {
    yield put(profileActions.saveAvatarError());
  }
}

const profileSagas = [takeLatest(profileActionTypes.SAVE_AVATAR_REQUEST, handleSaveAvatarRequest)];

export default profileSagas;
