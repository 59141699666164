import { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import expertTheme from 'expert/theme/expertTheme';
import { ThemeProvider } from '@material-ui/styles';
import useStyles from './Terms.styles';

const Terms: FC = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={expertTheme}>
      <Typography variant="h2" className={classes.title}>
        Terms of Use – PAVE Services and Website
      </Typography>
      <Typography variant="body1" className={classes.date}>
        Effective Date: November 1, 2021
      </Typography>
      <div id="modal_desc">
        <div>
          <span className={classes.text}>
            This Terms of Use (the “Terms” or “Agreement”) constitutes a binding agreement between you (“you” or
            “client”) and Arable Ventures LLC doing business as Pave Careers (“PAVE”, “we”, "us", and "our") with
            respect to your use of PAVE’s website (“Site”) and our related mobile application (the “App”), including all
            information, products and services promoted therein. PAVE provides the Site and App as a unique centralized
            network for individuals to find the career options that fit their skills, passions, and working needs. PAVE
            uses technology, expertise, research, and proprietary materials to help people align passion and purpose,
            navigating the ocean of careers through pivots, new starts, and transitions. This includes providing
            tailored career options, role specific deep dives, insider tips to interviews, access to industry experts,
            and career insights for the future.
            <br />
            <br />
          </span>
          <span className={classes.text}>
            The Site and App may also be provided as a portal to review, select, and purchase products and services from
            PAVE or its affiliates. The Site and App offer an on-line technology platform and access to and use of
            PAVE’s programs, information, services, and products, including an extensive network related to the Site and
            App.
            <br />
            <br />
          </span>
          <span className={classes.text}>
            PAVE may also provide clients and others with an on-line platform via a PAVE account. This Site and the App
            provide you with access to and use of PAVE programs, information, services, and products, including the
            opportunity to purchase certain products or other services (collectively, with the App, the “Services”).
            Before you continue using the Site, the Services, or any of the PAVE products or services, it is important
            that you review the Terms.
            <br />
            <br />
            1. <span className="bold underline">Ownership of Site and Services.</span> The Services and the Site are
            owned and operated by PAVE. Except as permitted under this Agreement, copying, storing, displaying,
            reproducing, distributing, selling, licensing, sublicensing, publishing or creating derivative works of any
            programs, content or materials on the Site or available through the Services are expressly prohibited
            without the prior written permission from PAVE. All programs, images, media, and content created by PAVE
            (“Content”) are the sole property of PAVE. PAVE retains ownership and licensing rights to all Content.
            <br />
            <br />
            2. <span className="bold underline">Acceptance of Terms.</span> By entering and using the Site or Services,
            you accept these Terms and agree to be bound by them. Acceptance of these Terms creates a binding contract
            between you and PAVE that you will use the Services only in a manner consistent with these Terms. Your use
            of the Site and the Services is entirely conditioned on and subject to your compliance with these Terms. If
            you do not agree with these Terms, do not access or use the Site or Services. Specific pages on the Site or
            features of the Services may set out additional terms and conditions which, in addition to these Terms,
            shall be binding upon you with respect to those pages or features. In the case of inconsistencies between
            these Terms and information included in other materials which are not agreements (for example, promotional
            materials and mailers), these Terms will control with respect to the Site and Services. We require that you
            be 18 years or older to use the Site or Services; if you are under 18 years old, you should not access or
            use the Site or Services.
            <br />
            <br /> 3. <span className="bold underline">Privacy Policy.</span> The PAVE Privacy Policy is located{' '}
            <a href={'/privacy-policy'} className="email" target="_blank">
              here
            </a>
            . The terms and conditions of our Privacy Policy are incorporated into and considered part of these Terms as
            if fully set forth herein. We take your privacy seriously, and our Privacy Policy complies with all
            applicable laws.
            <br />
            <br /> 4. <span className="bold underline">Patent Information.</span> Inventions, features, and services
            within PAVE products may be the subject matter of future patents. Such subject matter is confidential and
            protected by the Uniform Trade Secrets Act.
            <br />
            <br /> 5. <span className="bold underline">Trademark Information.</span> The following are some of the
            trademarks of PAVE: PAVE™, ARABLE™, and ARABLE VENTURES™. PAVE’s trademarks may be registered in the United
            States and other countries. PAVE reserves all rights with respect to its trademarks, service marks, logos,
            trade names, trade dress and other indicia (“Marks”). PAVE does not routinely accept or review specific
            requests for guidance on the use of its Marks. Any unauthorized use of any PAVE-owned Mark, or any use of a
            mark that is confusingly similar to, or likely to cause confusion with, a PAVE-owned Mark, would constitute
            infringement of PAVE’s exclusive trademark rights. All trademarks, service marks, and trade names are
            proprietary to PAVE and may not be exploited without the express permission of the owner of such marks. You
            may not utilize framing techniques to enclose any trademark, logo, or other materials on the Site and
            Services or use any meta tags or any other “hidden text” utilizing PAVE’s name or trademarks. <br />
            <br />
            All brand, product, and service names used on the Site or in these Services which identify PAVE or third
            parties and their products and services are proprietary marks of PAVE and/or the relevant third parties.
            Nothing in the Terms shall be deemed to confer on any person any license or right on the part of PAVE or any
            third party with respect to any such image, logo or name.
            <br />
            <br /> 6. <span className="bold underline">Copyright Information.</span> PAVE’s products, services, videos,
            content, and related materials (collectively, “Offerings”) are owned by PAVE and/or its licensors, and all
            rights in such Offerings are reserved by PAVE and/or its licensors. For information regarding permission to
            use screen displays, media, and other content, please email a request for PAVE’s Permissions Policy to{' '}
            <a href={'admin@joinpave.com'} className="email">
              admin@joinpave.com
            </a>
            . In your request, please state the reason(s) why you are requesting the information.
            <br />
            <br /> 7. <span className="bold underline">Notices of Infringement.</span> Persons who believe that their
            copyrights have been infringed by PAVE’s Site or Services or otherwise may notify PAVE’s Designated
            Copyright Agent. All notices must comply with the requirements of U.S. Copyright Act 17 U.S.C. § 512(c)(3)
            and regulations promulgated thereunder, each as amended. Such notices not complying with these requirements
            will be returned unprocessed. No other notices or inquiries of any type, including requests for information,
            purchase or technical support questions, notices of infringement of third-party trademark or patent rights,
            employment inquiries, press inquiries, or investor inquiries, will be read or answered under this Agent and
            Agent Address.
            <br />
            <br />
            Designated Copyright Agent and Address of Agent:
            <br />
            <br />
            Attn: David M. Slater, Esq. <br />
            Slater Law
            <br /> 240 West 73rd Street, Suite 701
            <br /> New York, NY 10023
            <br /> Phone: 212-489-6500
            <br /> Email:{' '}
            <a href={'mailto:mail@davidmslater.com'} className="email">
              mail@davidmslater.com
            </a>
            .
            <br />
            <br /> 8. <span className="bold underline">Licensing and Support.</span> For licensing and legal inquiries,
            please refer such inquiries to{' '}
            <a href={'mailto:admin@joinpave.com'} className="email">
              admin@joinpave.com
            </a>
            .
            <br />
            <br />
            9. <span className="bold underline">Amendment of Terms; Termination.</span>{' '}
            <div className="sub_points">
              <br />
              <br /> a. We reserve the right to change these Terms at any time. If we make any material changes to these
              Terms, we will post the new Terms on the Site and Services and update the effective date set forth above.
              <br />
              <br /> b. This Agreement and the Services provided hereunder shall continue until terminated as provided
              herein. You agree that PAVE may at any time without notice to you: terminate this Agreement; suspend or
              terminate the Services or Site; or terminate your account and delete any content stored in your account;
              and you agree that PAVE may take the foregoing actions for any reason, including if, in PAVE’s sole
              discretion, you fail to comply with any of these Terms or if a competent regulatory authority requires us
              to do so, or we may do so without cause.
              <br />
              <br /> c. Upon termination of this Agreement or termination or suspension of the Services, all rights and
              licenses granted by PAVE to you hereunder shall immediately terminate; however, termination or suspension
              shall not affect any services which have been agreed to and arranged separate and apart from these Terms
              of Use.
            </div>
            <br />
            <br />
            10. <span className="bold underline">Personal Use of Website and Services.</span> PAVE grants you a
            non-exclusive, non-transferable, limited right to access, view, use, display and print information on this
            Site and the Services, including all the materials provided hereon, for your personal, informational,
            noncommercial use only. You may not copy, modify, reproduce, create derivative works from, distribute,
            transmit, license, or sell any content or any portion of this Site or the Services without the prior express
            written consent of PAVE. PAVE will sometimes grant permission for such use so long as PAVE is properly
            acknowledged, but the prior express written consent of PAVE is required.
            <br />
            <br />
            11. <span className="bold underline">Password.</span> If we provide a function on the Site or Services which
            permits you to register as a PAVE user, you may be asked to provide a password. If so, you are the only
            person permitted to use this password to access and use the Services. As you will be responsible for all
            activities that occur under your password, you are responsible for maintaining the confidentiality of your
            passwords. You must notify PAVE immediately of any unauthorized use of your password or if you believe that
            your password is no longer confidential. We reserve the right to require you to alter your password if we
            believe that your password is no longer secure. We may rely on the authority of anyone using your password,
            and in no event and under no circumstances shall we be liable for any compromise of the confidentiality of
            your password or any unauthorized access using your password.
            <br />
            <br />
            12. <span className="bold underline">Information Provided by You.</span> You agree that the information that
            you provide to us on registration, if any, and at all other times, including all information you provide
            when using the Services, will be true, accurate, current and complete. You also agree that you will ensure
            that this information is always kept accurate and up to date. This is especially important with respect to
            your email address, since that is the primary way in which we will communicate with you about your account,
            your correspondence, and your orders, if applicable. You agree that PAVE is free to use any such information
            without further compensation, acknowledgement, or payment to you for any purpose whatsoever including, but
            not limited to, developing, modifying, manufacturing, and marketing products and services and modifying or
            improving the Site and Services.
            <br />
            <br /> 13. <span className="bold underline">Prohibited Conduct.</span> You agree that you will not:
            <div className="sub_points">
              <br />
              <br />
              a. use the Site or Services:
              <br />
              <br />
              <div className="sub_points">
                i. for any unlawful purposes;
                <br />
                <br />
                ii. to create, produce, or distribute any material that is obscene, offensive, blasphemous,
                pornographic, unlawful, threatening, menacing, malicious, fraudulent, misleading, abusive, harmful to
                any person or property, false, an invasion of privacy or publicity rights, defamatory, libelous, vulgar,
                illegal or otherwise objectionable;
                <br />
                <br />
                iii. to create, produce, or distribute any material that you do not have a right to transmit or
                communicate under any contractual or fiduciary relationship or which infringes any copyright, trademark,
                patent or other intellectual property right or any moral right of any party including, but not limited
                to PAVE; or
                <br />
                <br />
                iv. to harm minors in any way, including, but not limited to, content that violates child pornography
                laws, child sexual exploitation laws and laws prohibiting the depiction of minors engaged in sexual
                conduct;
              </div>
              <br />
              <br />
              b. interfere with, harm or disrupt (or attempt to interfere with or disrupt) the Site or Services or
              servers or networks connected to the Site or Services, or disobey any laws, requirements, procedures,
              policies or regulations governing access to or use of the Site or Services or networks connected to the
              Site or Services;
              <br />
              <br />
              c. access or attempt to access any information, documents or material that you are not authorized to
              access; or
              <br />
              <br />
              d. use any robot, spider, or other such programmatic or automatic device to obtain information from the
              Site or Services or otherwise monitor or copy any portion of the Site or Services;
            </div>
            <br />
            <br /> 14. <span className="bold underline">Disclaimers.</span>
            <br />
            <br /> THE SERVICES, THE SITE, THE PAVE CONTENT, AND ALL OTHER PAVE PRODUCTS AND SERVICES ARE PROVIDED BY US
            “AS IS” WITHOUT ANY OTHER WARRANTIES WHATSOEVER. ALL OTHER EXPRESS, IMPLIED, AND STATUTORY WARRANTIES,
            INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT, ARE EXPRESSLY DISCLAIMED. TO THE FULLEST EXTENT PERMITTED BY LAW, PAVE DISCLAIMS ANY
            WARRANTIES FOR THE SECURITY, RELIABILITY, TIMELINESS, AND PERFORMANCE OF ITS SERVICES. THIS WARRANTY GIVES
            YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE.
            <br />
            <br />
            THE PAVE CONTENT AND PROGRAMS, AND ALL OTHER INFORMATION ON THIS SITE AND SERVICES ARE FOR INFORMATIONAL
            PURPOSES ONLY AND ARE NOT INTENDED AS A SUBSTITUTE FOR PROFESSIONAL CAREER GUIDANCE OR THE SERVICES OF AN
            EMPLOYMENT AGENT. YOUR USE OF THE SITE OR SERVICES ARE FOR INFORMATION PURPOSES ONLY AND DO NOT CREATE AN
            AGENT-CLIENT RELATIONSHIP WITH PAVE OR ITS ADVISORS. PAVE DOES NOT GUARANTEE THE ACCURACY OF ANY OF THE
            CONTENT ON THIS SITE OR SERVICES, AND YOUR RELIANCE OR USE OF THE CONTENT IS AT YOUR OWN SOLE RISK.
            <br />
            <br />
            Submitted Content Disclaimer. – YOU MAY BE EXPOSED TO SUBMITTED CONTENT AND OTHER THIRD-PARTY CONTENT
            (“SUBMITTED CONTENT”) THAT IS INACCURATE, OFFENSIVE, INDECENT, OR OTHERWISE OBJECTIONABLE. PAVE DOES NOT
            ENDORSE ANY SUBMITTED CONTENT OR ANY OPINION, RECOMMENDATION OR ADVICE EXPRESSED THEREIN. UNDER NO
            CIRCUMSTANCES WILL PAVE BE LIABLE IN ANY WAY FOR OR IN CONNECTION WITH THE SUBMITTED CONTENT, INCLUDING, BUT
            NOT LIMITED TO, LIABILITY FOR ANY INACCURACIES, ERRORS, OR OMISSIONS IN ANY SUBMITTED CONTENT, FOR ANY
            INTELLECTUAL PROPERTY INFRINGEMENT WITH REGARD TO ANY SUBMITTED CONTENT, OR FOR ANY LOSS OR DAMAGE OF ANY
            KIND INCURRED AS A RESULT OF THE USE OF ANY SUBMITTED CONTENT. YOU BEAR ALL RISKS ASSOCIATED WITH THE USE OF
            ANY SUBMITTED CONTENT, INCLUDING ANY RELIANCE ON THE ACCURACY, COMPLETENESS, USEFULNESS, LEGALITY OF SUCH
            CONTENT OR ACCURATE IDENTIFICATION OF THE AUTHOR (AND/ OR THEIR AFFILIATION WITH ANY THIRD-PARTY).
            <br />
            <br />
            Submissions. – PAVE IS PLEASED TO HEAR FROM YOU AND WELCOMES YOUR COMMENTS REGARDING PAVE’S PROGRAMS AND
            SERVICES. IF YOU SEND COMMENTS, SUGGESTIONS, IDEAS, FEEDBACK, NOTES OR CONCEPTS OR OTHER MATERIALS TO PAVE
            (COLLECTIVELY, “SUBMISSIONS”), THEY SHALL BE DEEMED, AND SHALL REMAIN, THE PROPERTY OF PAVE. PAVE IS AND
            SHALL BE UNDER NO OBLIGATION TO: (I) MAINTAIN ANY OF YOUR SUBMISSIONS IN CONFIDENCE; (II) PAY TO YOU ANY
            COMPENSATION FOR ANY SUBMISSION; OR (III) RESPOND TO ANY OF YOUR SUBMISSIONS.
            <br />
            <br />
            15. <span className="bold underline">Limitations of Liability and Damages.</span>
            <br />
            <br />
            <div className="sub_points">
              a. PAVE, ITS AFFILIATES, EMPLOYEES, OFFICERS, REPRESENTATIVES, SERVICE PROVIDERS AND ITS SUPPLIERS, SHALL
              NOT UNDER ANY CIRCUMSTANCES, OR ANY LEGAL THEORY, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, TORTS, OR
              STRICT LIABILITY BE RESPONSIBLE FOR NOR BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
              SPECIAL, EXEMPLARY, PUNITIVE OR OTHER DAMAGES OR LOSSES ARISING OUT OF OR RELATING IN ANY WAY TO THE SITE,
              THE SERVICES, THE PRODUCTS SOLD, THE PAVE PROGRAMS, ANY THIRD-PARTY MATERIALS OR OTHER INFORMATION OR
              INFORMATION CONTAINED WITHIN THE SITE OR SERVICES, INCLUDING, BUT NOT LIMITED TO, YOUR DOWNLOADING OR
              UPLOADING OF ANY MATERIAL OR DOCUMENTS.
              <br />
              <br />
              b. PAID PRODUCT OR SERVICE: SOLE REMEDY. YOUR SOLE AND EXCLUSIVE REMEDY FOR FAILURE TO DELIVER A PAID
              PRODUCT OR SERVICE OR BREACH OF ANY OBLIGATION WITH RESPECT TO A PAID PRODUCT OR SERVICE SHALL BE A REFUND
              OF THE AMOUNT PAID FOR THE SPECIFIC PAID PRODUCT OR SERVICE AT ISSUE AND IN NO EVENT SHALL PAVE’S
              LIABILITY FOR FAILURE TO DELIVER SUCH ITEM EXCEED SUCH AMOUNT.
              <br />
              <br />
              c. TO THE MAXIMUM EXTENT ALLOWED BY LAW, THE DISCLAIMERS, WAIVERS AND LIMITATIONS CONTAINED IN THE TERMS,
              INCLUDING WITHOUT LIMITATION SECTIONS 14 AND 15, SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL
              PURPOSE OF ANY LIMITED REMEDY. APPLICABLE LAW MAY NOT ALLOW THE LIMITATIONS OF LIABILITY SET FORTH IN THIS
              SECTION 15, SO THIS LIMITATION OF LIABILITY MAY NOT APPLY TO YOU. IF THIS LIMITATION ON LIABILITY IS FOUND
              TO BE INVALID OR UNENFORCEABLE FOR ANY REASON, THEN THE AGGREGATE LIABILITY OF PAVE UNDER SUCH
              CIRCUMSTANCES FOR LIABILITIES THAT OTHERWISE WOULD HAVE BEEN LIMITED SHALL NOT EXCEED THE GREATER OF ONE
              HUNDRED DOLLARS ($100.00) OR THE LOWEST AMOUNT ALLOWED BY LAW.
            </div>
            <br />
            <br />
            16. <span className="bold underline">Indemnification.</span> You agree to indemnify, defend, and hold
            harmless PAVE, its officers, directors, employees, agents, licensors, suppliers and any third-party service
            providers to the Site and/or Services from and against all losses, expenses, damages and costs, including
            reasonable attorneys’ fees, arising out of (i) your access to, use or misuse of the Site or Services; (ii)
            your Submitted Content; (iii) your violation of the rights of any other person or entity; (iv) any
            unauthorized use of your Account; (v) any breach of these Terms or of any representation, warranty, or
            covenant you make herein; (vi) your use of the Services or purchase of Products; (vii) your participation in
            any PAVE program; (viii) your use of third-party content; and (ix) your grant of rights and licenses to PAVE
            pursuant to these Terms.
            <br />
            <br />
            You consent to receiving certain electronic communications from PAVE as further described in PAVE’s Privacy
            Policy. Any notices, agreements, disclosures, or other communications that PAVE sends to you electronically
            will satisfy any legal communication requirements, including that those communications be in writing.
            <br />
            <br />
            17. <span className="bold underline">Paid Products and Services: Prices/Cancellation.</span> The rate for
            any Paid Products or Services, if any, may be set forth in a PAVE proposal, order form, or other document.
            All rates are generally stated in U.S. dollars and are valid until altered by us. If a Paid Product or
            Service is mistakenly listed at an incorrect rate, PAVE reserves the right to refuse or cancel any orders
            placed for the Paid Product or Service listed at the incorrect rate. PAVE reserves the right to refuse or
            cancel any such orders whether the order has been confirmed and your credit card charged. If your credit
            card has already been charged for the purchase and your order is cancelled, PAVE shall issue a credit to
            your credit card account in the amount of the incorrect rate. Risk of loss and title to any products that
            you may purchase from us passes to you upon our delivery of such products to the carrier.
            <br />
            <br />
            18. <span className="bold underline">General.</span>
            <br />
            <br />
            <div className="sub_points">
              a. Entire Agreement. These Terms, including the Privacy Policy incorporated by reference, constitute the
              entire agreement between PAVE and you with respect to your use of the Site and the Services and the
              purchase of any Products or Services. PAVE’s failure to enforce any right or provision in these Terms
              shall not constitute a waiver of such right or provision. If a court should find that one or more rights
              or provisions contained in these Terms are invalid, you agree that the remainder of the Terms shall be
              enforceable.
              <br />
              <br />
              b. Choice of Law and Venue. This Agreement shall be governed and interpreted in accordance with the
              substantive law of the State of New York without regard to its conflict of law provisions. The parties
              agree that any litigation arising out of this Agreement or relating to the operation of the Site or
              Services or content appearing therein, shall be brought only in the state or federal courts located in New
              York, New York. The parties irrevocably submit to the exclusive jurisdiction of such courts and hereby
              waive any challenge to the propriety or convenience of jurisdiction or venue of such courts. Without
              limiting the effect of any disclaimer contained herein, any cause of action you may have with respect to
              your use of the Site or Services must be commenced within one (1) year after the claim or cause of action
              arises.
              <br />
              <br />
              c. Assignment. You may not assign the Agreement and/or any of the rights or obligations contained herein.
              PAVE may freely assign this Agreement and the rights and obligations contained herein.
              <br />
              <br />
              d. Notices. PAVE may notify you of certain events relating to the Site or Services, or your use of the
              Site or Services. We may choose to notify you of these matters via a general notice on the Site,
              electronic mail to your e-mail address on record in PAVE’s account information, or by written
              communication sent by U.S. mail, postage pre-paid, to your address on record in PAVE’s account
              information. If we notify you by mail, mail notice shall be deemed to have been given upon the expiration
              of three days after mailing; if we send you notice by email, such notice shall be deemed to have been
              given upon the expiration of twenty-four (24) hours after sending. You may give notice to PAVE (such
              notice shall be deemed given when received by PAVE) at any time only by letter delivered by nationally
              recognized overnight delivery service or first-class postage-prepaid mail to PAVE at the following
              address: Attn: General Counsel’s Office, Arable Ventures LLC DBA Pave Careers, 244 Madison Ave, Suite
              #173, New York, NY 10016.
              <br />
              <br />
              e. Miscellaneous
            </div>
            <br />
            <br />
            If any provision of these Terms shall be unlawful, void or for any reason unenforceable, then that provision
            shall be deemed severable from these Terms and shall not affect the validity and enforceability of any
            remaining provisions. No joint venture, partnership, employment, or agency relationship exists between you
            and PAVE as a result of these Terms or use of the Site or Services. All provisions which logically should
            survive these Terms shall survive the expiration or termination of these Terms. No waiver of any provision
            or any right granted hereunder shall be effective unless set forth in a written instrument signed by the
            waiving party. No waiver by either party of any breach or default hereunder shall be deemed a waiver of any
            subsequent breach or default. This is the entire agreement between you and PAVE relating to the subject
            matter herein and supersedes all previous communications, representations, understandings and agreements,
            either oral or written, between the parties with respect to said subject matter.
            <br />
            <br />
            If you have any comments or questions about these Terms, the Site or our Services, please contact us by
            email at{' '}
            <a href={'mailto:admin@joinpave.com'} className="email">
              admin@joinpave.com
            </a>
            .
          </span>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Terms;
