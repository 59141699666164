export const EXPERT_MATCHES_REQUEST = 'EXPERT_MATCHES_REQUEST';
export const EXPERT_MATCHES_REQUEST_SUCCESS = `${EXPERT_MATCHES_REQUEST}_SUCCESS`;
export const EXPERT_MATCHES_REQUEST_ERROR = `${EXPERT_MATCHES_REQUEST}_ERROR`;

export const OTHER_EXPERTS_REQUEST = 'OTHER_EXPERTS_REQUEST';
export const OTHER_EXPERTS_REQUEST_SUCCESS = `${OTHER_EXPERTS_REQUEST}_SUCCESS`;
export const OTHER_EXPERTS_REQUEST_ERROR = `${OTHER_EXPERTS_REQUEST}_ERROR`;

export const FAVORITE_EXPERTS_REQUEST = 'FAVORITE_EXPERTS_REQUEST';
export const FAVORITE_EXPERTS_REQUEST_SUCCESS = `${FAVORITE_EXPERTS_REQUEST}_SUCCESS`;
export const FAVORITE_EXPERTS_REQUEST_ERROR = `${FAVORITE_EXPERTS_REQUEST}_ERROR`;

export const TOGGLE_FAVORITE_EXPERT = 'TOGGLE_FAVORITE_EXPERT';
export const TOGGLE_FAVORITE_EXPERT_SUCCESS = `${TOGGLE_FAVORITE_EXPERT}_SUCCESS`;
export const TOGGLE_FAVORITE_EXPERT_ERROR = `${TOGGLE_FAVORITE_EXPERT}_ERROR`;

export const OTHER_EXPERTS_FILTERS_REQUEST = 'OTHER_EXPERTS_FILTERS_REQUEST';
export const OTHER_EXPERTS_FILTERS_REQUEST_SUCCESS = `${OTHER_EXPERTS_FILTERS_REQUEST}_SUCCESS`;
export const OTHER_EXPERTS_FILTERS_REQUEST_ERROR = `${OTHER_EXPERTS_FILTERS_REQUEST}_ERROR`;

export const EA_SET_SELECTED_FILTERS = 'EA_SET_SELECTED_FILTERS';
export const CLEAR_OTHER_EXPERTS_DATA = 'CLEAR_OTHER_EXPERTS_DATA';

export const CLEAR_EXPERT_MATCHES_TAB = 'CLEAR_EXPERT_MATCHES_TAB';
export const SELECT_TAB = 'EA_SELECT_TAB';
