import actionFn from 'expert/common/utils/actionFn';
import Availability from 'expert/modules/schedule/interfaces/availability';
import AvailabilitiesState from 'expert/redux/availabilities/interfaces/availabilitiesState';
import INIT_AVAILABILITIES_STATE from 'expert/redux/availabilities/configs/initAvailabilitiesState';

export enum AvailabilitiesActionsTypes {
  SetAvailabilities = 'SetAvailabilities',
  ResetAvailabilities = 'ResetAvailabilities',
  SetAvailabilitiesLoadingState = 'SetAvailabilitiesLoadingState',
  SetAvailabilitiesAndStopLoading = 'SetAvailabilitiesAndStopLoading',
  SetAvailabilitiesAddingState = 'SetAvailabilitiesAddingState',
  SetAvailabilitiesDeletingState = 'SetAvailabilitiesDeletingState',
  SetAvailabilitiesEditingState = 'SetAvailabilitiesEditingState',
}

export interface ResetAvailabilitiesAction {
  type: AvailabilitiesActionsTypes.ResetAvailabilities;
  payload: AvailabilitiesState;
}

export const resetAvailabilities = (): ResetAvailabilitiesAction => ({
  type: AvailabilitiesActionsTypes.ResetAvailabilities,
  payload: INIT_AVAILABILITIES_STATE,
});

export interface SetAvailabilitiesEditingStateAction {
  type: AvailabilitiesActionsTypes.SetAvailabilitiesEditingState;
  payload: boolean;
}

export const setAvailabilitiesEditingState = actionFn<SetAvailabilitiesEditingStateAction>(
  AvailabilitiesActionsTypes.SetAvailabilitiesEditingState,
);

export interface SetAvailabilitiesDeletingStateAction {
  type: AvailabilitiesActionsTypes.SetAvailabilitiesDeletingState;
  payload: boolean;
}

export const setAvailabilitiesDeletingState = actionFn<SetAvailabilitiesDeletingStateAction>(
  AvailabilitiesActionsTypes.SetAvailabilitiesDeletingState,
);

export interface SetAvailabilitiesAddingStateAction {
  type: AvailabilitiesActionsTypes.SetAvailabilitiesAddingState;
  payload: boolean;
}

export const setAvailabilitiesAddingState = actionFn<SetAvailabilitiesAddingStateAction>(
  AvailabilitiesActionsTypes.SetAvailabilitiesAddingState,
);

export interface SetAvailabilitiesAction {
  type: AvailabilitiesActionsTypes.SetAvailabilities;
  payload: Availability[];
}

export const setAvailabilities = actionFn<SetAvailabilitiesAction>(AvailabilitiesActionsTypes.SetAvailabilities);

export interface SetAvailabilitiesLoadingStateAction {
  type: AvailabilitiesActionsTypes.SetAvailabilitiesLoadingState;
  payload: boolean;
}

export const setAvailabilitiesLoadingState = actionFn<SetAvailabilitiesLoadingStateAction>(
  AvailabilitiesActionsTypes.SetAvailabilitiesLoadingState,
);

export interface SetAvailabilitiesAndStopLoadingAction {
  type: AvailabilitiesActionsTypes.SetAvailabilitiesAndStopLoading;
  payload: Availability[];
}

export const setAvailabilitiesAndStopLoading = actionFn<SetAvailabilitiesAndStopLoadingAction>(
  AvailabilitiesActionsTypes.SetAvailabilitiesAndStopLoading,
);

export type AvailabilitiesActions =
  | SetAvailabilitiesAction
  | SetAvailabilitiesAddingStateAction
  | SetAvailabilitiesDeletingStateAction
  | SetAvailabilitiesEditingStateAction
  | SetAvailabilitiesLoadingStateAction
  | ResetAvailabilitiesAction
  | SetAvailabilitiesAndStopLoadingAction;
