/* eslint-disable quotes */
import keyMirror from 'keymirror';
import howItWorksPM1 from '../../images/howItWorksPM1.svg';
import howItWorksPM2 from '../../images/howItWorksPM2.svg';
import howItWorksPM3 from '../../images/howItWorksPM3.svg';
import howItWorksED1 from '../../images/howItWorksED1.svg';
import howItWorksED2 from '../../images/howItWorksED2.svg';
import howItWorksED3 from '../../images/howItWorksED3.svg';
import howItWorksIW1 from '../../images/howItWorksIW1.svg';
import howItWorksIW2 from '../../images/howItWorksIW2.svg';
import howItWorksIW3 from '../../images/howItWorksIW3.svg';
import ourExpertiseED1 from '../../images/ourExpertiseED1.svg';
import ourExpertiseED2 from '../../images/ourExpertiseED2.svg';
import ourExpertiseED3 from '../../images/ourExpertiseED3.svg';
import ourExpertiseED4 from '../../images/ourExpertiseED4.svg';
import ourExpertiseED5 from '../../images/ourExpertiseED5.svg';
import ourExpertiseED6 from '../../images/ourExpertiseED6.svg';
import pathMapperSignBlock1 from '../../images/pathMapperSignBlock1.png';
import pathMapperSignBlock2 from '../../images/pathMapperSignBlock2.png';
import interviewWizardBlock1 from '../../images/interviewWizardBlock1.png';
import interviewWizardBlock2 from '../../images/interviewWizard3.png';
import expertsOnDemandBlock1 from '../../images/expertsOnDemandBlock1.png';
import expertsOnDemandBlock2 from '../../images/expertsOnDemand3.png';
import homeBlock1 from '../../images/gettyImages.png';

export const landingActionTypes = keyMirror({
  GET_IMPACTS_REQUEST: null,
  GET_IMPACTS_SUCCESS: null,
  GET_IMPACTS_ERROR: null,

  GET_STORIES_REQUEST: null,
  GET_STORIES_SUCCESS: null,
  GET_STORIES_ERROR: null,
});

export const helpOptions = [
  {
    id: '01',
    item: 'Tailored career options',
    description:
      'Serve up paths based on your goals, interests, skills and experiences, to find your strongest fitting paths.',
  },
  {
    id: '02',
    item: 'Role-specific deep dives',
    description:
      'Deeper understanding of what makes up various roles and clarity into what it takes to succeed in them.',
  },
  {
    id: '03',
    item: 'Secret sauce to interviews',
    description:
      'Interview with confidence through guidance on company info, industry news, and questions to help you prepare.',
  },
  {
    id: '04',
    item: 'Access to industry experts',
    description: 'Access to subject matter experts by function and industry to dish on the real inside scoop.',
  },
  {
    id: '05',
    item: 'Future-forward career insights',
    description: 'Relevant insights on hot careers of the future, industry shifts, and insider tips across industries.',
  },
];

export const howItWorksFeatures = {
  pathMapper: {
    description:
      'Career Mapper uses advanced ML technology to match your skills and preferences with the best-fit career paths based on current and future career trends - surfacing those you may not even be aware of yet. You’ll receive personalized career path and job family reports, along with recommendations to accelerate your journey.',
    features: [
      {
        icon: howItWorksPM1,
        title: 'Holistic Understanding',
        description:
          'Tell us about yourself so we can show career choices that will inspire you. Our onboarding process is designed to build a comprehensive picture of who you are and what matters to you when it comes to work.',
      },
      {
        icon: howItWorksPM2,
        title: 'Art of Analysis',
        description:
          'Our AI/ML technology analyzes your data against majority of possible career paths and thousands of trending jobs across industries and firm profiles, ranging from startups to Fortune 500 companies to provide matches that fit your unique skills & expertise.',
      },
      {
        icon: howItWorksPM3,
        title: 'Your Map & Guide',
        description:
          'Provides an in-depth assessment of each career path, roles along that path, and what it takes to succeed in them, with practical tips, resources and tools to help you level up your fit and increase your chance of entry.',
      },
    ],
  },
  expertsOnDemand: {
    description:
      'Schedule time with industry & domain experts across a range of industries, careers & companies.  Experts & coaches are screened & vetted to ensure quality interactions.  Book meetings through a streamlined process to accommodate your schedule.',
    features: [
      {
        icon: howItWorksED1,
        title: 'Objective Guidance & Insights',
        description:
          'With over 3000 experts in our network across 30+ industries & job families, access them in real time to explore the unknown, chart out a game plan, or practice for an upcoming interview and more!',
      },
      {
        icon: howItWorksED2,
        title: 'Your Deepest Questions Answered',
        description:
          'No questions are too big or small. Chatting with an expert to get the inside scoop can give you that advantage for finding the right career or landing your desired role.',
      },
      {
        icon: howItWorksED3,
        title: 'We meet you where you are',
        description:
          'Since we offer real time filtering & screening for availability, it`s no wonder that 78% of our members use our experts at least 2x/month and 53% of them use them 4x/month.',
      },
    ],
  },
  interviewWizard: {
    description:
      'A successful interview relies on preparation and practice. Let us save you time to practice, by conducting the research and prep for you. Simply enter information about your interview and receive a customized Interview Brief tailored to the company and role you’re interviewing for, with a summary of all that you need to know, along with questions to expect in the interview instantly.',
    features: [
      {
        icon: howItWorksIW1,
        title: 'Tell us about your interview',
        description:
          'Input your role, date, link to posting, why the role is interesting and your greatest area of uncertainty. We’ll use this information to build a prep brief to help you shine in your interview.',
      },
      {
        icon: howItWorksIW2,
        title: 'Our Magic Sauce',
        description:
          'Our data team does the research for you, by personalizing and distilling the most important information you need for your interview. This way, you can leave the uncertainty behind and practice showing up as your best self. ',
      },
      {
        icon: howItWorksIW3,
        title: 'Receive your instant brief',
        description:
          'We’ll provide you a brief that provides key insights about the role you’re interviewing for as well as some magic that you can use to stand out such as Q&As, interviewer questions, and insider perspectives.',
      },
    ],
  },
};

export const packages = {
  pathMapper: [
    {
      title: 'Basic',
      amount: '199',
      features: ['One customized career path report', 'Access to personalized insights '],
    },
    {
      title: 'Premium',
      amount: '299',
      features: ['Three distinct, customized career path reports', 'Access to personalized insights '],
    },
    {
      title: 'Refills',
      amount: '60/month',
      features: [
        'Refresh your career path reports to explore unlimited options',
        'Reflect changes in your profile of new skills & experiences',
        'Access to personalized insights ',
      ],
    },
  ],
  expertsOnDemand: [
    {
      title: 'Experts',
      features: [
        {
          amount: '99',
          period: '/session',
          text: 'No monthly commitment. Flexibility and access to industry experts as often as needed. ',
        },
        {
          amount: '299',
          period: '/month',
          text: 'Get up to 4 sessions with any industry expert during the month.',
        },
      ],
    },
    {
      title: 'Coaches',
      features: [
        {
          amount: '80',
          period: '/month',
          text: 'No monthly commitment. Flexibility and access to verified coaches as often as needed. ',
        },
        {
          amount: '200',
          period: '/month',
          text: '4 sessions with any of our verified coaches when needed. ',
        },
      ],
    },
  ],
  interviewWizard: [
    {
      title: 'Basic',
      amount: '40',
      features: ['One interview brief customized to the company and role you’ve applied for. '],
    },
    {
      title: 'Premium',
      amount: '100',
      features: [
        'One interview brief customized to the company and role you’ve applied for.',
        'One practice session with one of our industry experts or coaches.',
      ],
    },
  ],
};

export const signUpBottomBlock = {
  home: {
    firstTextBlock: {
      image: homeBlock1,
      text: 'Master the future of work with our suite of products. ',
      // subText: 'With Career Mapper and Expert Assist you can cover all the bases for the future. ',
      subText: 'With Career Mapper you can cover all the bases for the future. ',
      featureBtn: {
        text: 'Sign up',
        path: '',
      },
    },
  },
  pathMapper: {
    firstTextBlock: {
      image: pathMapperSignBlock1,
      text: 'Get access and support from an industry expert or coach.',
      subText: 'Explore new paths, get the inside scoop, or prepare to embark on a new path with support!',
      featureBtn: {
        text: 'Expert Assist',
        path: '/landing/experts-assist',
      },
      isButtonVisible: true,
    },
    secondTextBlock: {
      image: pathMapperSignBlock2,
      text: 'Already applying to jobs and setting up interviews?',
      subText: 'Let our Interview Boost be your secret weapon to make prep & practice easy & fast!',
      featureBtn: {
        text: 'Interview Boost',
        path: '/landing/interview-boost',
      },
      isButtonVisible: true,
    },
  },
  expertsOnDemand: {
    firstTextBlock: {
      image: expertsOnDemandBlock1,
      text: 'Discover paths for the future that aligns with your passion.  ',
      subText: 'Explore new paths, get the inside scoop, or prepare to embark on a new path with support!',
      featureBtn: {
        text: 'Career Mapper',
        path: '/landing/career-mapper',
      },
      isButtonVisible: true,
    },
    secondTextBlock: {
      image: expertsOnDemandBlock2,
      text: 'Already applying to jobs and setting up interviews?',
      subText: 'Let our Interview Boost be your secret weapon to make prep & practice easy & fast!',
      featureBtn: {
        text: 'Interview Boost',
        path: '/landing/interview-boost',
      },
      isButtonVisible: true,
    },
  },
  interviewWizard: {
    firstTextBlock: {
      image: interviewWizardBlock1,
      text: 'Get access and support from an industry expert or coach.',
      subText: 'Explore new paths, get the inside scoop, or prepare to embark on a new path with support!',
      featureBtn: {
        text: 'Expert Assist',
        path: '/landing/experts-assist',
      },
      isButtonVisible: true,
    },
    secondTextBlock: {
      image: interviewWizardBlock2,
      text: 'Discover  paths for the future that align with your passion.  ',
      subText: 'Celebrate your unique skills and aspirations. Map them to opportunity areas and roles.',
      featureBtn: {
        text: 'Career Mapper',
        path: '/landing/career-mapper',
      },
      isButtonVisible: true,
    },
  },
};

export const ourImpactItems = [
  {
    text: '10K+',
    description: 'Job Descriptions Analyzed',
  },
  {
    text: '10K+',
    description: 'Skills Decoded',
  },
  {
    text: '10K+',
    description: 'Expert Sessions Booked',
  },
  {
    text: '10K+',
    description: 'Job Interviews Supported',
  },
];

export const ourExpertise = [
  {
    title: 'Career Path Exploration',
    description:
      "Meet with an expert to get the inside scoop and explore what it's really like in a career path. Get advice on how to break into an industry, position your skills in a new space, or how to amplify what you're already doing to further advance your career.",
    image: ourExpertiseED1,
  },
  {
    title: 'Offer Negotiations',
    description:
      "You’ve got the job, congrats! Now let's help you maximize your offer package, perks, and negotiate with more confidence, with context around what to expect for this type of job. ",
    image: ourExpertiseED2,
  },
  {
    title: 'Industry & Role Deep Dives',
    description:
      'Think you’ve identified a potential job you’d like to reach for? Chat with an expert to decode performance expectations, required skills, and industry trends that may impact your ability to obtain and thrive in the role. ',
    image: ourExpertiseED3,
  },
  {
    title: 'Expert “AMA” Submissions',
    description:
      'Sometimes it’s just easier to submit a question at your convenience, or review trending questions from others.  Experts are also on hand to answer your ongoing questions in “Ask Me Anything” forums.',
    image: ourExpertiseED4,
  },
  {
    title: 'Interview Prep & Support',
    description:
      'Run through real life examples of common questions to expect in your interview. Leverage experts to role play, prepare your responses, anticipate tough questions, and pick up on nuances to ace the interview like a pro.  From breaking down jargon to building your story, get guidance on what works!',
    image: ourExpertiseED5,
  },
  {
    title: 'On-the-Job Support',
    description:
      "Once you've landed the role, now what? Set up for success with expert guidance on what to look for & tackle in your months on the job to hit a fast start.  Or leverage out network of experts to strategize on how to get you to the next level.",
    image: ourExpertiseED6,
  },
];

export const TO_BE_EXPERT = [
  'Apply to become an expert',
  'Review/edit your profile when approved',
  'Start sharing your expertise!',
];
