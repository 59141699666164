import { coursesTypes } from './Constants';

const initialState = {
  loading: false,
  coursesData: {},
};

export default function coursesReducer(state = initialState, action) {
  const { type, payload } = action;
  const { data } = payload || {};

  switch (type) {
    case coursesTypes.GET_COURSES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case coursesTypes.GET_COURSES_SUCCESS:
    case coursesTypes.POST_JOB_COURSES_FAVORITE_SUCCESS:
      return {
        ...state,
        ...data,
        loading: false,
      };

    case coursesTypes.GET_COURSES_ERROR:
    case coursesTypes.POST_JOB_COURSES_FAVORITE_ERROR:
      return {
        ...state,
        ...data,
        loading: false,
      };

    default:
      return state;
  }
}
