import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import expertTheme from 'expert/theme/expertTheme';
import { ThemeProvider } from '@material-ui/core/styles';
import useStyles from './UserAgreement.styles';
import { Button } from '@material-ui/core';

interface UserAgreementProps {
  onAccept?: () => void;
}

const UserAgreement: FC<UserAgreementProps> = ({ onAccept }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={expertTheme}>
      <Typography variant="h2" className={classes.title}>
        User Agreement
      </Typography>
      <Typography variant="body1" className={classes.date}>
        Effective Date: November 1, 2021
      </Typography>
      <div id="modal_desc">
        <span className={classes.text}>
          <span className="bold">
            PLEASE READ THIS USER AGREEMENT AND ALL OTHER DOCUMENTS REFERENCED HEREIN AS THE "TERMS OF SERVICE"
            CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS.
            THESE INCLUDE VARIOUS LIMITATIONS AND EXCLUSIONS AND A BINDING ARBITRATION AGREEMENT AND CLASS ACTION
            WAIVER.
          </span>{' '}
        </span>
        <br />
        <br />
        <span className={classes.text}>
          This User Agreement (this “<span className="bold">Agreement</span>”) is a contract between you (“
          <span className="bold">you</span>”) and Arable Ventures LLC doing business as Pave (“
          <span className="bold">Pave,</span>” “<span className="bold">we,</span>” or “<span className="bold">us</span>
          ”). You must read, agree to, and accept all of the terms and conditions contained in this Agreement to use any
          of our password-protected features of our website located at www.joinpave.com (the “
          <span className="bold">Site</span>”), our mobile application, or the Services (defined in the{' '}
          <a className="email" target="_blank" href="/terms-of-use">
            Site Terms of Use
          </a>
          ).{' '}
        </span>
        <br />
        <br />
        <span className={classes.text}>
          This Agreement includes and hereby incorporates by reference the{' '}
          <a className="email" target="_blank" href="/terms-of-use">
            Site Terms of Use
          </a>{' '}
          and{' '}
          <a className="email" target="_blank" href="/privacy-policy">
            Privacy Policy
          </a>
          , as they may be in effect and modified from time to time. These documents are collectively, with this
          Agreement, called the “<span className="bold">Terms of Service</span>”.{' '}
        </span>
        <br />
        <br />
        <span className={classes.text}>
          If you are an expert or a provider utilizing the Site or Services to provide services to individuals (each a “
          <span className="bold">Provider</span>”), your use of the Site and Services is subject to this Agreement and
          the Provider Agreement. Users that use the Site or Services to seek services from Providers are referred to as
          a “<span className="bold">Client.</span>” Any individual that uses the Site or Services, including you, any
          Client, any other user, and any Provider, is referred to as a “<span className="bold">User</span>”.{' '}
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Subject to the conditions set forth herein, Pave may, in its sole discretion, amend this Agreement and any of
          the other agreements that comprise the Terms of Service at any time by posting a revised version on the Site.
          Pave will provide reasonable advance notice of any change to the Terms of Service that reduces your rights or
          increases your responsibilities, by posting the updated Terms of Service on the Site or Services, providing
          notice on the Site or Services, and/or sending you notice by email. Any revisions to the Terms of Service will
          take effect on the noted effective date (each, as applicable, the “
          <span className="bold">Effective Date</span>”).
        </span>
        <br />
        <br />
        <span className={classes.text}>
          YOU UNDERSTAND THAT BY CLICKING “I ACCEPT”, YOU AGREE TO BE BOUND BY THE TERMS OF SERVICE, INCLUDING THE
          ARBITRATION AGREEMENT IN SECTION 13 OF THIS AGREEMENT (SUBJECT TO YOUR RIGHT TO OPT OUT OF THE ARBITRATION
          AGREEMENT AS PROVIDED IN SECTION 12). IF YOU DO NOT ACCEPT THE TERMS OF SERVICE IN ITS ENTIRETY, YOU MUST NOT
          ACCESS OR USE THE SITE OR THE SERVICES AFTER THE EFFECTIVE DATE EXCEPT AS PERMITTED BY THE SITE TERMS OF USE.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          IF YOU AGREE TO THE TERMS OF SERVICE ON BEHALF OF AN ENTITY OR AGENCY, OR IN CONNECTION WITH PROVIDING OR
          RECEIVING SERVICES ON BEHALF OF AN ENTITY OR AGENCY, YOU REPRESENT AND WARRANT THAT YOU HAVE THE AUTHORITY TO
          BIND THAT ENTITY OR AGENCY TO THE TERMS OF SERVICE AND AGREE THAT YOU ARE BINDING BOTH YOU AND THAT ENTITY OR
          AGENCY TO THE TERMS OF SERVICE. IN THAT EVENT, “YOU” AND “YOUR” WILL REFER AND APPLY TO YOU AND THAT ENTITY OR
          AGENCY.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          You hereby represent that you are either a legal entity or an individual who is 18 years or older (or have
          otherwise reached the age of majority in the jurisdiction in which you conduct business) in each case who can
          form legally binding contracts.
        </span>
        <br />
        <br />
        <span className="bold">1. PAVE ACCOUNTS AND PASSWORDS</span>
        <br />
        <br />
        <span className={classes.text}>
          To access and use certain portions of the Site and the Services, Users must register for an account (an{' '}
          <span className="bold">“Account”</span>). Subject to the Site Terms of Use, certain portions of the Site are
          available to Site visitors, including those portions before your Account registration is accepted. Pave
          reserves the right to decline a registration to join Pave or to create an Account. To register for an Account
          to join the Site or Services, each User must complete a User profile (each, a{' '}
          <span className="bold">“Profile”</span>), which you consent to be shown to other Users and, unless you change
          your privacy settings, to be shown to the public. You agree to provide true, accurate, and complete
          information on your Profile and all registration and other forms you access on the Site and Services or
          provide to us and to update your information to maintain its truthfulness, accuracy, and completeness. You
          agree not to provide any false or misleading information about your identity or location, your business, your
          skills, or the services your business provides and to correct any such information that is or becomes false or
          misleading.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          If you create an Account as an employee or agent on behalf of a company, you represent and warrant that you
          are authorized to enter into binding contracts, including the Terms of Service, on behalf of yourself and the
          company.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Pave offers accounts for Providers (<span className="bold">“Provider Accounts”</span>) and for Clients (
          <span className="bold">“Client Accounts”</span>). Each of the Provider Accounts and Client Accounts is
          referred to as an <span className="bold">“Account.”</span> Once you register for one Account type, you can add
          other Account types. For example, if you already have a Provider Account, you can add a Client Account as a
          separate account type. You agree not to have or register for more than one Account without express written
          permission from us. We reserve the right to revoke the privileges of the Account or access to or use of the
          Site or Services, and those of any and all linked Accounts without warning if, in our sole discretion, false
          or misleading information has been provided in creating, marketing, or maintaining your Profile or Account.
          You agree not to request or allow another person to create an Account on your behalf, for your use, or for
          your benefit. Each person that uses the Site and Services must register for their own Account.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          When you register for an Account and from time to time thereafter, your Account may be subject to
          verification, including, but not limited to, validation against third-party databases or the verification of
          one or more official government or legal documents that confirm, as applicable and without limitation, your
          identity, your location, and your ability to act on behalf of your business on Pave. You authorize Pave,
          directly or through third parties, to make any inquiries necessary to validate your identity, your location,
          and confirm your ownership of your email address or financial accounts, subject to applicable law. When
          requested, you must timely provide us with information we request.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          When you register for an Account, you will be asked to choose a username and password for the Account. You are
          entirely responsible for safeguarding and maintaining the confidentiality of your username and password. You
          agree not to share your username or password with any person, and, if you are a legal entity who is not a
          natural person, to only share your username and password with a person who is authorized to use your Account.
          You authorize Pave to assume that any person using the Site or Services with your username and password,
          either is you or is authorized to act for you. You agree to notify us immediately if you suspect or become
          aware of any unauthorized use of your Account or any unauthorized access to the password for any Account. You
          further agree not to use the Account or log in with the username and password of another User of the Site if
          (a) you are not authorized to use both or (b) the use would violate the Terms of Service.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className="bold">2. RELATIONSHIP WITH PAVE </span>{' '}
        </span>
        <br />
        <br />
        <span className={classes.text}>
          The Site is a marketplace for services where Clients and Providers can identify each other and advertise, buy,
          and sell services online. Pave uses the Site, digital technology, marketing, and networking knowledge to
          connect individuals seeking career and job advice (Clients) to providers of expert and coaching services
          (Providers). Subject to the Terms of Service, Pave provides the Services to Users, including hosting and
          maintaining the Site, facilitating the formation of Service Contracts (as defined below), and assisting Users
          in resolving disputes which may arise in connection with those Service Contracts. When a User enters a Service
          Contract, the User uses the Site to invoice and pay any amounts owed under the Service Contract.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Pave makes the Site and Services available to enable Providers and Clients to find and engage with each other
          through the Site and Services. When a Provider and a User interact with each other through our Site and
          Services, they have an <span className="bold">“Engagement”.</span> Pave also offers other services on the Site
          and Services for Users related to career development and interviewing. Pave does not introduce Providers to
          Clients, select Engagements for Providers, or select Providers for Clients. Through the Site and Services,
          Providers may be notified of Clients that may be seeking the services they offer, and Clients may be notified
          of Providers that may offer the services they seek.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Without limitation, Users are solely responsible for (a) determining the suitability of other Users for a
          Service Contract (such as any interviews, vetting, background checks, or similar actions); (b) negotiating,
          agreeing to, and executing any terms or conditions of Service Contracts; (c) performing Provider Services;
          and/or (d) paying for Provider Services. Pave does not make any representations about or guarantee the truth
          or accuracy of any Provider’s or Client’s listings or other User Content (as defined below) on the Site and
          Services; does not verify any feedback or information provided by Users about Providers or Clients; and does
          not perform background checks on or guarantee the work of Providers or Clients. You acknowledge, agree, and
          understand that Pave does not, in any way, supervise, direct, control, or evaluate Providers or their work and
          is not responsible for any Engagement or work product.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Pave makes no representations about and does not guarantee, and you agree not to hold Pave responsible for,
          the quality, safety, or legality of the services provided by the Provider (
          <span className="bold">“Provider Services”</span>); the qualifications, background, or identities of Users;
          the ability of Providers to deliver Provider Services; the ability of Clients to pay for Provider Services; or
          any content and statements or posts made by Users (<span className="bold">“Content”</span>). While Pave may
          provide certain ratings on Provider or Client Profiles, such ratings are not a guarantee or warranty of the
          quality or ability or willingness of the Provider or Client to complete a Service Contract and is not a
          guarantee of any kind, including, the quality of Provider Services or Client Engagement.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          You also acknowledge, agree, and understand that Providers are solely responsible for determining, and have
          the sole right to determine, which Engagements to accept; the time, place, manner, and means of providing any
          Provider Services; the type of services they provide; and the price they charge for their services or how that
          pricing is determined or set.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          You further acknowledge, agree, and understand that: (i) you are not an employee of Pave, and you are not
          eligible for any of the rights or benefits of employment (including unemployment and/or workers compensation
          insurance); (ii) Pave will not have any liability or obligations, including under or related to Service
          Contracts and/or Provider Services for any acts or omissions by you or other Users; and (iii) Pave does not,
          in any way, supervise, direct, or control any Provider or Provider Services; and does not impose quality
          standards or a deadline for completion of any Provider Services. Pave does not provide Providers with training
          or any equipment, labor, tools, or materials related to any Service Contract. Pave does not provide the
          premises at which Providers will perform the work; and Pave does not provide shipping services for any
          physical Work Product.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className="bold underline">Service Contracts </span>
        </span>
        <br />
        <br />
        <span className={classes.text}>
          If Client and a Provider decide to enter into an Engagement, the relationship is between the Client and the
          Provider. The Client and the Provider have complete discretion in determining whether to enter into an
          Engagement. With respect to the Engagement, Clients and Providers may enter into any written agreements that
          they deem appropriate (e.g., confidentiality agreements, invention assignment agreements, assignment of
          rights, etc.) (each, a <span className="bold">“Service Contract”</span>) provided that any such agreements do
          not conflict with, narrow, or expand Pave’s rights and obligations under the Terms of Service, including this
          Agreement. Pave is not a party to any Service Contract and is not responsible for, and expressly disclaims any
          and all liability for, either party’s performance, non-performance, acts, or omissions under any Service
          Contract, including for negligence or willful misconduct. Users are solely responsible for ensuring that their
          Service Contract complies with all applicable laws, rules, and regulations, and that their Service Contract is
          enforceable. Each situation is highly fact-specific and requirements vary by situation and jurisdiction and
          therefore you should seek legal advice from a licensed attorney in the relevant jurisdictions.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className="bold underline">Classification as Contractor </span>
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Nothing in this Agreement is intended to or should be construed to create a partnership, joint venture,
          franchisor/franchisee or employer-employee relationship between Pave and you. Clients are solely responsible
          for and have complete discretion with regard to selection of any Provider for any Engagement. Clients are
          solely responsible for and assume all liability for determining that Providers should be engaged as
          independent contractors and engaging them accordingly.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          You warrant that your decisions regarding classification are correct and your manner of engaging as
          independent contractors complies with applicable laws, regulations, and rules. Pave will have no input into,
          or involvement in, worker classification as between Clients and a Provider and all Users agree that Pave has
          no involvement in and will have no liability arising from or relating to the classification of a Provider
          generally or with regard to a particular Engagement.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className="bold underline">Contact Information </span>
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Users agree to use the communication services available on the Site and Services to communicate with other
          Users prior to entering into a Service Contract. Pave discourages you from providing your contact information
          to any other User.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className="bold">3. FEEDBACK AND CONTENT </span>
        </span>
        <br />
        <br />
        <span className={classes.text}>
          You hereby acknowledge and agree that Users publish and request Pave to publish on their behalf information on
          the Site and Services about other Users, such as feedback, composite feedback, geographical location, or
          verification of identity or credentials. However, such information is based solely on unverified data that
          Providers or Clients voluntarily submit to Pave and does not constitute and will not be construed as an
          introduction, endorsement, or recommendation by Pave; Pave provides such information as a convenience to
          Users.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          You acknowledge and agree that feedback benefits the marketplace, all Users, and the efficiency of the Site
          and Services, and you specifically request that Pave post composite or compiled feedback about Users,
          including yourself, on Profiles and elsewhere on the Site and Services. You acknowledge and agree that
          feedback results and other Content on the Site, Services, or otherwise (
          <span className="bold">“Composite Information”</span>), if any, may include comments, ratings, indicators of
          satisfaction, and other feedback left exclusively by other Users. You further acknowledge and agree that Pave
          will make Composite Information available to other Users, including Composite Information or compiled
          feedback. Pave provides its feedback system as a means through which Users can share their opinions of other
          Users publicly, and Pave does not monitor, influence, contribute to or censor these opinions. You acknowledge
          and agree that posted composite or compiled feedback and any other Composite Information relates only to the
          business advertised in the Profile and not to any individual person. You agree not to use the Composite
          Information to make any employment, credit, credit valuation, underwriting, or other similar decision about
          any other User.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Pave does not generally investigate any remarks posted by Users or other Content for accuracy or reliability
          and does not guarantee that Content is accurate. You are solely responsible for Content that you post,
          including the accuracy of any such Content, and are solely responsible for any legal action that may be
          instituted by other Users or third parties as a result of or in connection with Content that you post. Pave is
          not legally responsible for any feedback or comments posted or made available on the Site or Services by any
          Users or third parties, even if that information is defamatory or otherwise legally actionable. Pave reserves
          the right (but is under no obligation) to remove posted feedback or information that, in Pave’s sole judgment,
          violates the Terms of Service or negatively affects our marketplace, diminishes the integrity of the feedback
          system or otherwise is inconsistent with the business interests of Pave. You acknowledge and agree that you
          will notify Pave of any error or inaccurate statement in your feedback results, including the Composite
          Information, and that if you do not do so, Pave may rely on the accuracy of such information.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className="bold">4. CONTRACTUAL RELATIONSHIP BETWEEN CLIENT AND PROVIDER </span>
        </span>
        <br />
        <br />
        <span className={classes.text}>
          If a Client and Provider decide to enter into a Service Contract, the Service Contract is a contractual
          relationship directly between the Client and Provider. Client and Provider have complete discretion both with
          regard to whether to enter into a Service Contract with each other and with regard to the terms of any Service
          Contract. You acknowledge, agree, and understand that Pave is not a party to any Service Contract, that the
          formation of a Service Contract between Users will not, under any circumstance, create an employment or other
          service relationship between Pave and any User or a partnership or joint venture between Pave and any User.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className="bold">5. CONFIDENTIAL INFORMATION </span>
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Clients and Providers may agree to any terms they deem appropriate with respect to confidentiality. If and to
          the extent that Clients and Provider do not articulate any different agreement about confidentiality, then you
          agree that this Section 5 (Confidential Information) applies.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          To the extent a User provides Confidential Information to another User, the recipient will protect the secrecy
          of the discloser’s Confidential Information with the same degree of care as it uses to protect its own
          Confidential Information, but in no event with less than due care. On a User’s written request, the party that
          received Confidential Information will promptly destroy or return the disclosing party’s Confidential
          Information and any copies thereof contained in or on its premises, systems, or any other equipment otherwise
          under its control.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className="bold">“Confidential Information”</span> means any material or information provided to, or
          created by, a User or a Provider to evaluate an Engagement or the suitability of the other party to the
          Engagement, regardless of whether the information is in tangible, electronic, verbal, graphic, visual, or
          other form. Confidential Information does not include material or information that: (a) is generally known by
          third parties as a result of no act or omission of Provider or User; (b) was lawfully received by the
          recipient without restriction from a third party having the right to disseminate the information; (c) was
          already known to the recipient prior to receiving it from the other party and was not received from a third
          party in breach of that third party’s obligations of confidentiality; or (d) was independently developed by
          the recipient without use of another person’s Confidential Information.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className="bold">6. PAVE FEES </span>
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Clients shall pay Pave for access to the Services and for Engagements as set forth in Pave’s (
          <span className="bold">“Fees”</span>). In addition, you may be required to pay Pave a fee for payment
          processing and administration related to the Fees. From time to time, Pave may offer premium membership plans
          for additional fees for access to additional features and Services.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Pave may be required by applicable law to collect taxes or levies including, without limitation, withholding
          income tax or VAT (while some countries may refer to VAT using other terms, e.g. GST, we’ll just refer to VAT,
          GST and any local sales taxes collectively as <span className={'bold'}>“VAT”</span>) in the jurisdiction of
          the Provider (the <span className={'bold'}>“Taxes”</span>). In such instances, any amounts Pave is required to
          collect or withhold for the payment of any such Taxes shall be collected in addition to the Fees owed to Pave
          under the Terms of Service.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Pave does not introduce Clients to Providers and does not help Providers secure Engagements. Pave merely makes
          the Site and Services available to enable Providers to do so themselves and may from time to time highlight
          Engagements that may be of interest. Therefore, Pave does not charge a fee when a Provider finds a suitable
          Client or finds an Engagement. In addition, Pave does not charge any fee or dues for posting public feedback
          and composite or compiled feedback, including Composite Information.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className="bold">7. PAYMENT TERMS </span>
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Pave provides third-party financial services, such as Stripe, to Users to deliver and/or receive payment for
          Engagements and to pay Fees (<span className={'bold'}>“Financial Services”</span>). The Financial Services are
          intended for business use, and you agree to use the Financial Services only for business purposes.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Pave and its financial administrator, Stripe, will collect and disburse payments only in accordance with this
          Agreement. You agree to abide by any standard agreement of Pave or Stripe regarding the collection and
          disbursement of payments in accordance with the Terms of Service. Client and Provider may access current
          information regarding the status of payments on the Site, Services, or other location authorized by Pave.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Client will book an Engagement with Provider through the Pave website and pay Fees at that time through the
          connected Stripe payment portal. Users authorize Stripe to immediately disburse the payment to Pave as
          required by this Agreement. All payments are nonrefundable.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          To the extent permitted by applicable law, Client agrees not to ask its credit card company, bank, or other
          payment method provider to charge back any Fees charged pursuant to the Terms of Service for any reason. A
          chargeback in breach of the foregoing obligation is a material breach of the Terms of Service. If Client
          initiates a chargeback in violation of this Agreement, Client agrees that Pave, its affiliates, or Stripe may
          dispute or appeal the chargeback and institute collection action against Client and take such other action it
          deems appropriate.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          In order to use certain Services, Client must provide account information for at least one valid payment
          method. Client hereby authorizes Pave and Stripe, as applicable, to run credit card authorizations on all
          credit cards provided by Client, to store credit card and banking or other financial details as Client’s
          method of payment consistent with our{' '}
          <a className={'email'} href={'/privacy-policy'}>
            Privacy Policy
          </a>
          , and to charge Client’s credit card (or any other payment method) for the Fees and any other amounts owed
          under the Terms of Service. To the extent permitted by applicable law and subject to our Privacy Policy, you
          acknowledge and agree that we may use certain third-party vendors and service providers such as Stripe to
          process payments and manage your payment method information.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          By providing payment method information through the Site and authorizing payments with the Payment Method,
          Client represents, warrants, and covenants that: (a) Client is legally authorized to provide such information;
          (b) Client is legally authorized to make payments using the payment method(s); (c) if Client is an employee or
          agent of a company or person that owns the payment method, that Client is authorized by the company or person
          to use the payment method to make payments on Pave; and (d) such actions do not violate the terms and
          conditions applicable to Client’s use of such payment method(s) or applicable law.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          When Client authorizes a payment using a payment method via the Site or Services, Client represents and
          warrants that there are sufficient funds or credit available to complete the payment using the designated
          payment method. To the extent that any amounts owed under this Agreement or the other Terms of Service cannot
          be collected from Client’s payment method(s), Client is solely responsible for paying such amounts by other
          means.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Pave is not liable to any User if Pave does not complete a transaction as a result of any limit by applicable
          law or your financial institution, or if a financial institution fails to honor any credit or debit to or from
          an account associated with such payment method. Pave will make commercially reasonable efforts to work with
          any such affected Users to resolve such transactions in a manner consistent with this Agreement.
        </span>
        <br />
        <br />
        <span className={classes.text}>The Site and the Services operate in U.S. Dollars. </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className="bold">8. WARRANTY DISCLAIMER </span>
        </span>
        <br />
        <br />
        <span className={classes.text}>
          THE SERVICES, THE SITE, THE CONTENT, THE WORK PRODUCT, PROVIDER SERVICES, AND ALL OTHER PAVE PRODUCTS AND
          SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT ANY OTHER WARRANTIES WHATSOEVER. ALL OTHER EXPRESS,
          IMPLIED, AND STATUTORY WARRANTIES, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, ACCURACY,
          FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, ARE EXPRESSLY DISCLAIMED. TO THE FULLEST EXTENT
          PERMITTED BY LAW, PAVE DISCLAIMS ANY WARRANTIES FOR THE SECURITY, RELIABILITY, TIMELINESS, AND PERFORMANCE OF
          ITS SERVICES.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          THE SERVICES, THE SITE, THE CONTENT, THE WORK PRODUCT, PROVIDER SERVICES, AND ALL OTHER PAVE PRODUCTS AND
          SERVICES ARE FOR INFORMATIONAL PURPOSES ONLY AND ARE NOT INTENDED AS A SUBSTITUTE FOR PROFESSIONAL CAREER
          GUIDANCE OR THE SERVICES OF AN EMPLOYMENT AGENT. YOUR USE OF THE SITE OR SERVICES ARE FOR INFORMATION PURPOSES
          ONLY AND DO NOT CREATE AN AGENT-CLIENT RELATIONSHIP WITH PAVE OR ITS ADVISORS. PAVE DOES NOT GUARANTEE THE
          ACCURACY OF ANY OF THE CONTENT ON THIS SITE OR SERVICES, AND YOUR RELIANCE OR USE OF THE CONTENT IS AT YOUR
          OWN SOLE RISK.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          SOME JURISDICTIONS MAY NOT ALLOW FOR ALL OF THE FOREGOING LIMITATIONS ON WARRANTIES, SO TO THAT EXTENT, SOME
          OR ALL OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. SECTIONS 9B (LIMITATION OF LIABILITY) AND 12 (AGREEMENT
          TERM AND TERMINATION) STATE YOUR SOLE AND EXCLUSIVE REMEDIES AGAINST PAVE WITH RESPECT TO ANY DEFECTS,
          NON-CONFORMANCES, OR DISSATISFACTION.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className="bold">9. LIMITATION OF LIABILITY </span>
        </span>
        <br />
        <br />
        <span className={classes.text}>
          a. PAVE, ITS AFFILIATES, EMPLOYEES, OFFICERS, REPRESENTATIVES, SERVICE PROVIDERS AND ITS SUPPLIERS, SHALL NOT
          UNDER ANY CIRCUMSTANCES, OR ANY LEGAL THEORY, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, TORTS, OR STRICT
          LIABILITY BE RESPONSIBLE FOR NOR BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
          EXEMPLARY, PUNITIVE OR OTHER DAMAGES OR LOSSES (INCLUDING, WITHOUT LIMITATION, LITIGATION COSTS, LOST PROFITS,
          OR LOSS OF DATA) ARISING OUT OF OR RELATING IN ANY WAY TO THE SITE, THE SERVICES, THE PROVIDER SERVICES, THE
          PAVE PROGRAMS, ANY THIRD-PARTY MATERIALS OR OTHER INFORMATION OR INFORMATION CONTAINED WITHIN THE SITE OR
          SERVICES, INCLUDING, BUT NOT LIMITED TO, YOUR DOWNLOADING OR UPLOADING OF ANY MATERIAL OR DOCUMENTS.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          b. PAID PRODUCT OR SERVICE: SOLE REMEDY. YOUR SOLE AND EXCLUSIVE REMEDY FOR FAILURE TO DELIVER A PAID PRODUCT
          OR SERVICE OR BREACH OF ANY OBLIGATION WITH RESPECT TO A PAID PRODUCT OR SERVICE SHALL BE A REFUND OF THE
          AMOUNT PAID FOR THE SPECIFIC PAID PRODUCT OR SERVICE AT ISSUE AND IN NO EVENT SHALL PAVE’S LIABILITY FOR
          FAILURE TO DELIVER SUCH ITEM EXCEED SUCH AMOUNT.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          c. TO THE MAXIMUM EXTENT ALLOWED BY LAW, THE DISCLAIMERS, WAIVERS AND LIMITATIONS CONTAINED IN THE TERMS SHALL
          APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. APPLICABLE LAW MAY NOT ALLOW THE
          LIMITATIONS OF LIABILITY SET FORTH IN THIS SECTION 10, SO THIS LIMITATION OF LIABILITY MAY NOT APPLY TO YOU.
          IF THIS LIMITATION ON LIABILITY IS FOUND TO BE INVALID OR UNENFORCEABLE FOR ANY REASON, THEN THE AGGREGATE
          LIABILITY OF PAVE UNDER SUCH CIRCUMSTANCES FOR LIABILITIES THAT OTHERWISE WOULD HAVE BEEN LIMITED SHALL NOT
          EXCEED THE GREATER OF ONE HUNDRED DOLLARS ($100.00) OR THE LOWEST AMOUNT ALLOWED BY LAW.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Without limiting the generality of the foregoing, Pave is not liable, and you agree not to hold us
          responsible, for any damages or losses arising out of or in connection with the Terms of Service, including,
          but not limited to:
        </span>
        <br />
        <br />
        <div className="sub_points">
          <span className={classes.text}>● your use of or your inability to use our Site or Services; </span>
          <br />
          <br />
          <span className={classes.text}>● delays or disruptions in our Site or Services; </span>
          <br />
          <br />
          <span className={classes.text}>
            ● viruses or other malicious software obtained through our Site or Services;{' '}
          </span>
          <br />
          <br />
          <span className={classes.text}>
            ● glitches, bugs, errors, or inaccuracies of any kind in our Site or Services;{' '}
          </span>
          <br />
          <br />
          <span className={classes.text}>● damage to your hardware device from the use of the Site or Services; </span>
          <br />
          <br />
          <span className={classes.text}>
            ● the content, actions, or inactions of third parties’ use of the Site or Services;{' '}
          </span>
          <br />
          <br />
          <span className={classes.text}>● a suspension or other action taken with respect to your Account; and </span>
          <br />
          <br />
          <span className={classes.text}>
            ● your reliance on the quality, accuracy, or reliability of job postings, Profiles, ratings,
            recommendations, and feedback (including their content, order, and display), Composite Information, or
            metrics found on, used on, or made available through the Site or Services.{' '}
          </span>
        </div>
        <br />
        <br />
        <span className={classes.text}>
          SOME STATES AND JURISDICTIONS DO NOT ALLOW FOR ALL OF THE FOREGOING EXCLUSIONS AND LIMITATIONS, SO TO THAT
          EXTENT, SOME OR ALL OF THESE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className="bold">10. RELEASE </span>
        </span>
        <br />
        <br />
        <span className={classes.text}>
          In addition to the recognition that Pave is not a party to any Service Contract, you hereby release Pave, our
          affiliates, and our respective officers, directors, agents, subsidiaries, joint ventures, employees and
          service providers from claims, demands, and damages (actual and consequential) of every kind and nature, known
          and unknown, arising out of or in any way connected with any dispute you have with another User, whether it be
          at law or in equity that exist as of the time you enter into this agreement. This release includes, for
          example and without limitation, any disputes regarding the performance, functions, and quality of the Provider
          Services provided to Client by a Provider and requests for refunds based upon disputes.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className="bold">
            TO THE EXTENT APPLICABLE, YOU HEREBY WAIVE THE PROTECTIONS OF CALIFORNIA CIVIL CODE § 1542 (AND ANY
            ANALOGOUS LAW IN ANY OTHER APPLICABLE JURISDICTION) WHICH SAYS: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS
            THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
            EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT
            WITH THE DEBTOR OR RELEASED PARTY.”
          </span>
        </span>
        <br />
        <br />
        <span className={classes.text}>
          This release will not apply to a claim that Pave failed to meet our obligations under the Terms of Service.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className="bold">11. INDEMNIFICATION </span>
        </span>
        <br />
        <br />
        <span className={classes.text}>
          In addition to the indemnification obligations in the Site Terms of Use, you agree to indemnify, defend, and
          hold harmless Pave, its officers, directors, employees, agents, licensors, suppliers and any third-party
          service providers to the Site and/or Services from and against all losses, expenses, damages and costs,
          including reasonable attorneys’ fees, arising out of or arising from or relating to any claim, suit,
          proceeding, demand, or action brought by you or a third party arising out of (i) any payment obligations or
          default; (ii) the Provider Services; (iii) use of any work product or Content developed, provided, or
          otherwise related to your use of the Site or Services; (iv) any Service Contract, including, but not limited
          to, the classification of a Provider as an independent contractor or non-compliance with labor laws regarding
          classification of workers; the classification of Pave as an employer or joint employer of Provider; any
          employment-related claims, such as those relating to employment termination, employment discrimination,
          harassment, or retaliation; and any claims for unpaid wages or other compensation, overtime pay, sick leave,
          holiday or vacation pay, retirement benefits, worker’s compensation benefits, unemployment benefits, or any
          other employee benefits; (v) any unauthorized use of your Account; (vi) any violation of law; (vii) any breach
          of this User Agreement; (viii) negligence, willful misconduct, or fraud by you or your agents; (ix)
          defamation, libel, violation of privacy rights, unfair competition, or infringement of Intellectual Property
          Rights or allegations thereof to the extent caused by you; and/or (x) any claim by any other User.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          For purposes of this User Agreement, <span className={'bold'}>“Intellectual Property Rights”</span> means all
          patent rights, copyright rights, mask work rights, moral rights, rights of publicity, trademark, trade dress
          and service mark rights, goodwill, trade secret rights and other intellectual property rights as may now exist
          or hereafter come into existence, and all applications therefore and registrations, renewals and extensions
          thereof, in each case, under the laws of any state, country, territory or other jurisdiction.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className="bold">12. AGREEMENT TERM AND TERMINATION </span>
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Unless both you and Pave expressly agree otherwise in writing, either of us may terminate this Agreement in
          our sole discretion, at any time, without explanation, upon written notice to the other, which will result in
          the termination of the other Terms of Service as well, except as otherwise provided herein. You may provide
          written notice to{' '}
          <a className={'email'} href={'mailto:hello@joinpave.com'}>
            hello@joinpave.com
          </a>
          .
        </span>
        <br />
        <br />
        <span className={classes.text}>
          In the event you properly terminate this Agreement, your right to use the Site and Services is automatically
          revoked, and your Account will be closed. Pave is not a party to any Service Contract between Users.
          Consequently, you understand and acknowledge that termination of this Agreement (or attempt to terminate this
          Agreement) does not terminate or otherwise impact any Service Contract or Engagement entered into between
          Users. If you attempt to terminate this Agreement while having one or more open Engagements, you agree that:
          (a) you hereby instruct Pave to close any open contracts; (b) you will continue to be bound by this Agreement
          and the other Terms of Service until all such Engagements have closed on the Site or Services; (c) Pave will
          continue to perform those Services necessary to complete any open Engagement or related transaction between
          you and another User; and (d) you will continue to be obligated to pay any amounts accrued but unpaid as of
          the date of termination or as of the closure of any open Service Contracts, whichever is later, to Pave for
          any Services or such other amounts owed under the Terms of Service and to any Providers for any Provider
          Services.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Without limiting Pave’s other rights or remedies, we may, but are not obligated to, temporarily or
          indefinitely revoke or limit access to the Site or Services, deny your registration, or permanently revoke
          your access to the Site and Services and refuse to provide any or all Services to you if: (i) you breach the
          letter or spirit of any terms and conditions of this Agreement or any other provisions of the Terms of
          Service; (ii) we suspect or become aware that you have provided false or misleading information to us; (iii)
          we believe, in our sole discretion, that your actions may cause legal liability for you, other Users, or Pave
          or our affiliates; may be contrary to the interests of the Site, Services, or Pave community; or may involve
          illicit or illegal activity; or (iv) we are required to by law, legal process, or law enforcement. If your
          Account is temporarily or permanently closed, you may not use the Site or Services under the same Account or a
          different Account or re-register under a new Account without Pave’s prior written consent. If you attempt to
          use the Site under a different Account, we reserve the right to reclaim available funds in that Account and/or
          use an available payment method to pay for any amounts owed by you to the extent permitted by applicable law.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          You acknowledge and agree that the value, reputation, and goodwill of the Site and Services depend on
          transparency of User’s Account status to all Users, including both yourself and other Users who have entered
          into Service Contracts with you. You therefore agree as follows:{' '}
          <span className="bold">
            IF PAVE DECIDES TO TEMPORARILY OR PERMANENTLY CLOSE YOUR ACCOUNT, PAVE HAS THE RIGHT WHERE ALLOWED BY LAW
            BUT NOT THE OBLIGATION TO: (A) NOTIFY OTHER USERS THAT HAVE ENTERED INTO SERVICE CONTRACTS WITH YOU TO
            INFORM THEM OF YOUR CLOSED ACCOUNT STATUS, AND (B) PROVIDE THOSE USERS WITH A SUMMARY OF THE REASONS FOR
            YOUR ACCOUNT CLOSURE. YOU AGREE THAT PAVE WILL HAVE NO LIABILITY ARISING FROM OR RELATING TO ANY NOTICE THAT
            IT MAY PROVIDE TO ANY USER REGARDING CLOSED ACCOUNT STATUS OR THE REASON(S) FOR THE CLOSURE.
          </span>
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Except as otherwise required by law, if your Account is closed for any reason, you will no longer have access
          to data, messages, files, or other material you keep on the Site or Services and any closure of your Account
          may involve deletion of any content stored in your Account for which Pave will have no liability whatsoever.
          Pave, in its sole discretion and as permitted or required by law, may retain some or all of your Account
          information.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          After this Agreement terminates, the terms of this Agreement and the other Terms of Service that expressly or
          by their nature contemplate performance after this Agreement terminates or expires will survive and continue
          in full force and effect. Without limiting any other provisions of the Terms of Service, the termination of
          this Agreement for any reason will not release you or Pave from any obligations incurred prior to termination
          of this Agreement or that thereafter may accrue in respect of any act or omission prior to such termination.{' '}
        </span>
        <br />
        <br />
        <span className={classes.text}>
          For disputes arising between Clients and Providers, you agree to abide by the dispute process that is
          explained in your particular Service Contract. If the dispute process does not resolve your dispute, you may
          pursue your dispute independently, but you acknowledge and agree that Pave will not and is not obligated to
          provide any dispute assistance beyond what is provided in the Terms of Service.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className={'bold'}>13. DISPUTE PROCESS AND ARBITRATION </span>
        </span>
        <br />
        <br />
        <span className={'bold underline'}>Informal Dispute Resolution. </span>
        <br />
        <br />
        <span className={classes.text}>
          Before serving a demand for arbitration of a Claim, you and Pave agree to first notify each other of the
          Claim. You agree to notify Pave of the Claim at Attn: Attention Arable Ventures LLC, Legal Department, 244
          Madison Ave, Suite #173 New York, NY 10016 or by email to{' '}
          <a className={'email'} href={'mailto:hello@joinpave.com'}>
            hello@joinpave.com
          </a>
          , and Pave agrees to provide to you a notice at your email address on file (in each case, a{' '}
          <span className={'bold'}>“Notice”</span>). You and Pave then will seek informal voluntary resolution of the
          Claim. Any Notice must include pertinent account information, a brief description of the Claim, and contact
          information, so that you or Pave, as applicable, may evaluate the Claim and attempt to informally resolve the
          Claim. Both you and Pave will have 60 days from the date of the receipt of the Notice to informally resolve
          the other party’s Claim, which, if successful, will avoid the need for further action.
        </span>
        <br />
        <br />
        <span className={'bold underline'}>Arbitration Agreement. </span>
        <br />
        <br />
        <span className={classes.text}>
          By agreeing to the Terms of Service, you agree that you are required to resolve any claim that you may have
          against Pave in arbitration, as set forth in this Section (referred to herein as the{' '}
          <span className={'bold'}>“Arbitration Agreement”</span>). This will preclude you from bringing any class,
          collective, or representative action against Pave, and also preclude you from participating in or recovering
          relief under any current or future class, collective, consolidated, or representative action brought against
          Pave by someone else. You acknowledge and agree that you and Pave are each waiving the right to a trial by
          jury or to participate as a plaintiff or class member in any purported class action or representative
          proceeding. Unless both you and Pave otherwise agree in writing, any arbitration will be conducted only on an
          individual basis and not in a class, collective, consolidated, or representative proceeding.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          The arbitration will be administered by JAMS, Inc. (<span className={'bold'}>“JAMS”</span>) pursuant to its
          Streamlined Arbitration Rules and Procedures (the <span className={'bold'}>“Rules”</span>). The Rules are
          available at{' '}
          <a className={'email'} href={'https://www.jamsadr.com/rules-streamlined-arbitration/'} target={'_blank'}>
            https://www.jamsadr.com/rules-streamlined-arbitration/
          </a>
          .
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Any arbitration must be commenced by filing a demand for arbitration within two years after the date the party
          asserting the claim first knows or reasonably should know of the act, omission or default giving rise to the
          claim. If applicable law provides a different limitation period for asserting claims, any claim must be
          asserted within the shortest time period permitted by applicable law.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          These Terms of Service (including this Arbitration Agreement) shall be governed by and interpreted in
          accordance with the laws of the State of New York and United States federal laws applicable therein, without
          regard to principles of conflicts of laws. Any arbitration hearing (<span className={'bold'}>“Hearing”</span>)
          will be located at a site in New York, New York. The specific site will be selected by the arbitrator,
          pursuant to the Rules. The Hearing may be conducted telephonically or through a video conference, upon request
          from either party. The Hearing will be conducted in English.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          The arbitration will be conducted in front of a single arbitrator (the{' '}
          <span className={'bold'}>“Arbitrator”</span>), selected from the appropriate list of JAMS arbitrators pursuant
          to the Rules. The parties agree that the Arbitrator, and not any federal, state, or local court or agency,
          shall have exclusive authority to resolve any disputes relating to the interpretation, applicability,
          enforceability or formation of these Terms of Service, including any claim that all or any part of these Terms
          of Service (including this Arbitration Agreement) is void or voidable. The Arbitrator shall also be
          responsible for determining all threshold arbitrability issues, including issues relating to whether these
          Terms of Service (including the Arbitration Agreement) are unconscionable or illusory and any defense to
          arbitration, including waiver, delay, laches, or estoppel.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          If your claim does not exceed $10,000, then the arbitration will be conducted solely on the basis of the
          written submissions of the parties, unless the Arbitrator determines that a Hearing is necessary. If your
          claim exceeds $10,000, your right to a Hearing will be determined by the Rules.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          The Arbitrator may issue an arbitration award in accordance with the Rules and any limitations of liability
          set out in these Terms of Service. Judgment on the arbitration award may be entered in any court having
          competent jurisdiction to do so. The Arbitrator may award declaratory or injunctive relief only in favor of
          the claimant and only to the extent necessary to provide relief warranted by the claimant’s individual claim.
          An Arbitrator’s decision shall be final and binding on all parties. An Arbitrator’s decision and judgment
          thereon shall have no precedential effect. Attorneys’ fees and costs shall be borne by the unsuccessful party,
          as determined by the Arbitrator, and shall be awarded as part of the Arbitrator’s award.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Your responsibility to pay any filing, administrative, and arbitrator fees will be solely as set forth in the
          Rules. However, if your claim for damages does not exceed $50,000, we will pay all such fees, unless the
          Arbitrator finds that the substance of your claim or the relief sought by you was frivolous or was brought for
          an improper purpose (determined with respect to Federal Rule of Civil Procedure 11(b)).
        </span>
        <br />
        <br />
        <span className={classes.text}>
          Notwithstanding the provisions in these Terms of Service regarding consent to be bound by amendments to these
          Terms of Service, if Pave changes this Arbitration Agreement after the date you first agreed to the
          Arbitration Agreement (or to any subsequent changes to the Arbitration Agreement), you may reject any such
          change by providing Pave written notice of such rejection within thirty (30) calendar days of the date such
          change became effective. This written notice must be provided by email from the email address associated with
          your account to: admin@joinpave.com. In order to be effective, the notice must include your full name and
          clearly indicate your intent to reject changes to this Arbitration Agreement. By rejecting changes, you are
          agreeing that you will arbitrate any dispute between you and Pave in accordance with the provisions of this
          Arbitration Agreement as of the date you first agreed to the Arbitration Agreement (or to any subsequent
          changes to the Arbitration Agreement).
        </span>
        <br />
        <br />
        <span className={classes.text}>
          If any portion of this Arbitration Agreement is found to be unenforceable or unlawful for any reason, (1) the
          unenforceable or unlawful provision shall be severed from these Terms of Service; (2) severance of the
          unenforceable or unlawful provision shall have no impact whatsoever on the remainder of the Terms of Service
          (including this Arbitration Agreement) or the parties’ ability to compel arbitration of any remaining claims
          on an individual basis pursuant to the Arbitration Agreement; and (3) to the extent that any claims must
          therefore proceed on a class, collective, consolidated, or representative basis, such claims must be litigated
          in a civil court of competent jurisdiction and not in arbitration, and the parties agree that litigation of
          those claims shall be stayed pending the outcome of any individual claims in arbitration.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          If and only if this Arbitration Agreement is found not to apply to you or your claim, you and Pave agree that
          any judicial proceeding must be brought exclusively in the state courts and federal courts located within New
          York, New York, and you and Pave consent to venue and exclusive jurisdiction in those courts. Any claim not
          subject to arbitration must be commenced within two years after the date the party asserting the claim first
          knows or reasonably should know of the act, omission or default giving rise to the claim. If applicable law
          provides for a different limitation period for asserting claims, any claim must be asserted within the
          shortest time period permitted by applicable law.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          <span className="bold">14. GENERAL </span>
        </span>
        <br />
        <br />
        <span className={'bold underline'}>Entire Agreement </span>
        <br />
        <br />
        <span className={classes.text}>
          This Agreement, together with the other Terms of Service, sets forth the entire agreement and understanding
          between you and Pave relating to the subject matter hereof and thereof and cancels and supersedes any prior or
          contemporaneous discussions, agreements, representations, warranties, and other communications between you and
          us, written or oral, to the extent they relate in any way to the subject matter hereof and thereof. The
          section headings in the Terms of Service are included for ease of reference only and have no binding effect.
          Even though Pave drafted the Terms of Service, you represent that you had ample time to review and decide
          whether to agree to the Terms of Service. If an ambiguity or question of intent or interpretation of the Terms
          of Service arises, no presumption or burden of proof will arise favoring or disfavoring you or Pave because of
          the authorship of any provision of the Terms of Service.
        </span>
        <br />
        <br />
        <span className={'bold underline'}>Modifications; Waiver </span>
        <br />
        <br />
        <span className={classes.text}>
          No modification or amendment to the Terms of Service will be binding upon Pave unless they are agreed in a
          written instrument signed by a duly authorized representative of Pave or posted on the Site by Pave. Email
          will not constitute a written instrument as contemplated by this Section 15.2. Our failure to act with respect
          to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches.
          We do not guarantee we will take action against all breaches of this User Agreement.
        </span>
        <br />
        <br />
        <span className={'bold underline'}>Assignability </span>
        <br />
        <br />
        <span className={classes.text}>
          User may not assign the Terms of Service, or any of its rights or obligations hereunder, without Pave’s prior
          written consent in the form of a written instrument signed by a duly authorized representative of Pave. Pave
          may freely assign this Agreement and the other Terms of Service without User’s consent. Any attempted
          assignment or transfer in violation of this subsection will be null and void. Subject to the foregoing
          restrictions, the Terms of Service are binding upon and will inure to the benefit of the successors, heirs,
          and permitted assigns of the parties.
        </span>
        <br />
        <br />
        <span className={'bold underline'}>Severability; Interpretation </span>
        <br />
        <br />
        <span className={classes.text}>
          If and to the extent any provision of this Agreement or the other Terms of Service is held illegal, invalid,
          or unenforceable in whole or in part under applicable law, such provision or such portion thereof will be
          ineffective as to the jurisdiction in which it is illegal, invalid, or unenforceable to the extent of its
          illegality, invalidity, or unenforceability and will be deemed modified to the extent necessary to conform to
          applicable law so as to give the maximum effect to the intent of the parties. The illegality, invalidity, or
          unenforceability of such provision in that jurisdiction will not in any way affect the legality, validity, or
          enforceability of such provision in any other jurisdiction or of any other provision in any jurisdiction.
        </span>
        <br />
        <br />
        <span className={'bold underline'}>Force Majeure </span>
        <br />
        <br />
        <span className={classes.text}>
          The parties to this Agreement will not be responsible for the failure to perform, or any delay in performance
          of, any obligation hereunder for a reasonable period due to labor disturbances, accidents, fires, floods,
          telecommunications or Internet failures, strikes, wars, riots, rebellions, blockades, acts of government,
          pandemics, governmental requirements and regulations or restrictions imposed by law or any other conditions
          beyond the reasonable control of such party.
        </span>
        <br />
        <br />
        <span className={'bold underline'}>Location of Services </span>
        <br />
        <br />
        <span className={classes.text}>
          Pave makes no representations that the Site is appropriate or available for use outside of the United States.
          Those who access or use the Site from other jurisdictions do so at their own risk and are entirely responsible
          for compliance with all applicable foreign, United States, state, and local laws and regulations, including,
          but not limited to, export and import regulations, including the Export Administration Regulations maintained
          by the United States Department of Commerce and the sanctions programs maintained by the Department of the
          Treasury Office of Foreign Assets Control. You must not directly or indirectly sell, export, re-export,
          transfer, divert, or otherwise dispose of any software or service to any end user without obtaining any and
          all required authorizations from the appropriate government authorities. You also warrant that you are not
          prohibited from receiving U.S. origin products, including services or software.
        </span>
        <br />
        <br />
        <span className={classes.text}>
          In order to access or use the Site or Site Services, you must and hereby represent that you are not: (a) a
          citizen or resident of a geographic area in which access to or use of the Site or Site Services is prohibited
          by applicable law, decree, regulation, treaty, or administrative act; (b) a citizen or resident of, or located
          in, a geographic area that is subject to U.S. or other sovereign country sanctions or embargoes; or (c) an
          individual, or an individual employed by or associated with an entity, identified on the U.S. Department of
          Commerce Denied Persons or Entity List, the U.S. Department of Treasury Specially Designated Nationals or
          Blocked Persons Lists, or the U.S. Department of State Debarred Parties List or otherwise ineligible to
          receive items subject to U.S. export control laws and regulations or other economic sanction rules of any
          sovereign nation. You agree that if your country of residence or other circumstances change such that the
          above representations are no longer accurate, that you will immediately cease using the Site and Site Services
          and your license to use the Site or Site Services will be immediately revoked.
        </span>
        <br />
        <br />
        <span className={'bold underline'}>Consent To Use Electronic Records </span>
        <br />
        <br />
        <span className={classes.text}>
          In connection with the Site Terms of Use, you may be entitled to receive, or we may otherwise provide, certain
          records from Pave or our Affiliates, such as contracts, notices, and communications, in writing. To facilitate
          your use of the Site and the Services, you give us permission to provide these records to you electronically
          instead of in paper form.
        </span>
        <br />
        <br />
      </div>
      {onAccept && (
        <div className={classes.buttonWrap}>
          <Button type="submit" color="secondary" variant="contained" onClick={onAccept} className={classes.button}>
            Accept
          </Button>
        </div>
      )}
    </ThemeProvider>
  );
};

export default UserAgreement;
