import actionFn from 'expert/common/utils/actionFn';
import SelectOption from 'expert/common/components/select/interfaces/selectOption';
import TimeZonesState from 'expert/redux/timeZones/interfaces/timeZonesState';
import INIT_TIME_ZONES_STATE from 'expert/redux/timeZones/configs/initTimeZonesState';

export enum TimeZonesActionTypes {
  SetTimeZones = 'SetTimeZones',
  ResetTimeZones = 'ResetTimeZones',
  SetTimeZonesAndStopLoading = 'SetTimeZonesAndStopLoading',
  SetTimeZonesLoadingState = 'SetTimeZonesLoadingState',
}

export type ResetTimeZonesAction = {
  type: TimeZonesActionTypes.ResetTimeZones;
  payload: TimeZonesState;
};

export const resetTimeZones = (): ResetTimeZonesAction => ({
  type: TimeZonesActionTypes.ResetTimeZones,
  payload: INIT_TIME_ZONES_STATE,
});

export type SetTimeZonesAction = {
  type: TimeZonesActionTypes.SetTimeZones;
  payload: SelectOption[];
};

export const setTimeZones = actionFn<SetTimeZonesAction>(TimeZonesActionTypes.SetTimeZones);

export type SetTimeZonesAndStopLoadingAction = {
  type: TimeZonesActionTypes.SetTimeZonesAndStopLoading;
  payload: SelectOption[];
};

export const setTimeZonesAndStopLoading = actionFn<SetTimeZonesAndStopLoadingAction>(
  TimeZonesActionTypes.SetTimeZonesAndStopLoading,
);

export type SetTimeZonesLoadingStateAction = {
  type: TimeZonesActionTypes.SetTimeZonesLoadingState;
  payload: boolean;
};

export const setTimeZonesLoadingState = actionFn<SetTimeZonesLoadingStateAction>(
  TimeZonesActionTypes.SetTimeZonesLoadingState,
);

export type TimeZonesActions =
  | SetTimeZonesAction
  | ResetTimeZonesAction
  | SetTimeZonesLoadingStateAction
  | SetTimeZonesAndStopLoadingAction;
