import { signInActionTypes } from './Constants';

const initialState = {
  loading: false,
  token: '',
  userId: null,
  user: null,
  error: '',
};

export default function signInReducer(state = initialState, action) {
  const { type, payload } = action;
  const { data } = payload || {};

  switch (type) {
    case signInActionTypes.LOGIN_REQUEST:
    case signInActionTypes.UPDATE_USER_REQUEST:
    case signInActionTypes.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case signInActionTypes.PIN_SUCCESS:
    case signInActionTypes.GET_USER_SUCCESS:
    case signInActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        ...data,
        loading: false,
      };
    case signInActionTypes.PIN_START:
      return {
        ...state,
        loading: false,
      };

    case signInActionTypes.LOGIN_ERROR:
    case signInActionTypes.PIN_ERROR:
      return {
        ...state,
        ...data,
        loading: false,
      };

    default:
      return state;
  }
}
