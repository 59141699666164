import { careerMapperDetailsTypes } from './Constants';

export function getCareerMapperDetailsRequest(data) {
  return {
    type: careerMapperDetailsTypes.GET_CAREER_MAPPER_DETAILS_REQUEST,
    payload: {
      data,
    },
  };
}

export function getCareerMapperDetailsSuccess(data) {
  return {
    type: careerMapperDetailsTypes.GET_CAREER_MAPPER_DETAILS_SUCCESS,
    payload: {
      data,
    },
  };
}

export function getCareerMapperDetailsError(data) {
  return {
    type: careerMapperDetailsTypes.GET_CAREER_MAPPER_DETAILS_ERROR,
    payload: {
      data,
    },
  };
}
