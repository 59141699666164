import PayoutsHistoryData from 'expert/modules/payoutsHistory/interfaces/payoutsHistoryData';
import FetchPayoutsRes from 'expert/sagas/interfaces/fetchPayoutsRes';

const resToPayoutsHistoryData = (source: FetchPayoutsRes): PayoutsHistoryData => ({
  payouts: source.amount,
  date: source.date,
  sessions30Min: source.min_30,
  sessions60Min: source.min_60,
  id: source.id,
  allSessions: source.all_count,
  timeStart: source.start_date,
  timeEnd: source.end_date,
});

export default resToPayoutsHistoryData;
