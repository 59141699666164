import Paths from '../../sagas/interfaces/paths';

const schedulePaths: Paths = {
  fetchAvailabilities: 'schedule/',
  addAvailability: 'schedule/',
  checkIsAvailabilitySet: 'schedule/is_setted',
  deleteAvailability: 'schedule/info/',
  editAvailability: 'schedule/info/',
  editSchedule: 'schedule/',
};

export default schedulePaths;
