import PayoutsHistoryData from 'expert/modules/payoutsHistory/interfaces/payoutsHistoryData';
import actionFn from '../../common/utils/actionFn';

export enum PayoutActionTypes {
  SetPayoutHistory = 'SetPayoutHistory',
  SetStripeSetUpUrl = 'SetStripeSetUpUrl',
  SetStripeSetUpUrlLoading = 'SetStripeSetUpUrlLoading',
  SetPayoutHistoryRes = 'SetPayoutHistoryRes',
  SetPayoutHistoryLoadingState = 'SetPayoutHistoryLoadingState',
}

export type SetStripeSetUpUrlLoadingAction = {
  type: PayoutActionTypes.SetStripeSetUpUrlLoading;
  payload: boolean;
};

export const setStripeSetUpUrlLoading = actionFn<SetStripeSetUpUrlLoadingAction>(
  PayoutActionTypes.SetStripeSetUpUrlLoading,
);

export type SetStripeSetUpUrlAction = {
  type: PayoutActionTypes.SetStripeSetUpUrl;
  payload: string;
};

export const setStripeSetUpUrl = actionFn<SetStripeSetUpUrlAction>(PayoutActionTypes.SetStripeSetUpUrl);

export type SetPayoutHistoryResAction = {
  type: PayoutActionTypes.SetPayoutHistoryRes;
  payload: {
    history: PayoutsHistoryData[];
    historyTotalCount: number;
  };
};

export const setPayoutHistoryRes = actionFn<SetPayoutHistoryResAction>(PayoutActionTypes.SetPayoutHistoryRes);

export type SetPayoutHistoryAction = {
  type: PayoutActionTypes.SetPayoutHistory;
  payload: PayoutsHistoryData[];
};

export type SetPayoutHistoryLoadingStateAction = {
  type: PayoutActionTypes.SetPayoutHistoryLoadingState;
  payload: boolean;
};

export const setPayoutHistoryLoadingState = actionFn<SetPayoutHistoryLoadingStateAction>(
  PayoutActionTypes.SetPayoutHistoryLoadingState,
);

export type PayoutActions =
  | SetPayoutHistoryAction
  | SetPayoutHistoryResAction
  | SetPayoutHistoryLoadingStateAction
  | SetStripeSetUpUrlLoadingAction
  | SetStripeSetUpUrlAction;
