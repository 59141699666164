import React, { memo } from 'react';
import classNames from 'classnames';
import { Box, List, ListItem, makeStyles, Typography } from '@material-ui/core';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import PopoverProfile from '../popover/PopoverProfile';
import { primary } from '../../styles/colors';
import { removeAccessToken } from '../../services/AccessToken';
import { heading14_17_700, heading18_22_500 } from '../../styles/fonts';
import { getInitials } from '../../pages/userProfile/Services';
import { LOGIN_MENU_ITEMS } from '../../services/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSelector } from '../../selectors';
import { userLogout } from '../../rootReducer';

const useStyles = makeStyles({
  listMenu: {
    display: 'flex',
  },
  listMenuItems: {
    width: 'auto',
    marginRight: '48px',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 0,
    textTransform: 'uppercase',
    cursor: 'pointer',
    transition: 'opacity 0.3s',
    '&:hover': {
      opacity: 0.8,
    },
  },
  activeCoral: {
    borderBottom: `2px solid ${primary.coral}`,
  },
  activeMilk: {
    borderBottom: `2px solid ${primary.milk}`,
  },
  listMenuItemText: {
    ...heading14_17_700,
    padding: '10px 0',
  },
  customBadge: {
    backgroundColor: primary.coral,
    color: 'white',
  },
  bellsBox: {
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7EFF7',
    marginRight: '15px',
  },
  accountCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: primary.milk,
    width: '42px',
    height: '42px',
    borderRadius: '50%',
    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      objectFit: 'cover',
    },
  },
  becomeAnExpert: {
    color: primary.milk,
    textTransform: 'none',
  },
  initials: {
    color: primary.coral,
    ...heading18_22_500,
  },
});

const AppBarMenuItems = ({ isCareerMapperTab }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(getUserSelector);

  const handleMenuClick = (path) => {
    if (path === '/career-mapper/overview' && user?.is_started_onboarding && !user?.path_mapper_tariff) {
      return history.push('/onboarding-progress');
    }

    history.push(path);
  };

  const handleLogOut = () => {
    removeAccessToken();
    history.replace('/sign-in');
    dispatch(userLogout());
  };

  const handleActivity = () => {
    history.push('/activity');
  };

  const handleProfile = () => {
    history.push('/profile');
  };

  const getPopperItems = () => {
    return [
      {
        title: 'My Activity',
        handler: handleActivity,
      },
      {
        title: 'My Profile',
        handler: handleProfile,
      },
      {
        title: 'Sign out',
        handler: handleLogOut,
      },
    ];
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
        <List className={classes.listMenu}>
          {LOGIN_MENU_ITEMS.map((item, index) => {
            const active = item.allowPathes.find((path) =>
              matchPath(location.pathname, { path, exact: true, strict: true }),
            );
            const isExpertTab = item.path === '/become-an-expert';

            return (
              <ListItem
                button
                key={index}
                className={classNames(classes.listMenuItems, {
                  [classes.becomeAnExpert]: isExpertTab,
                })}
                onClick={() => handleMenuClick(item.path)}
                disabled={item.isDisabled}>
                <Typography
                  className={classNames(`${classes.listMenuItemText}`, {
                    [classes.activeCoral]: active && !isExpertTab,
                    [classes.activeMilk]: active && (isCareerMapperTab || isExpertTab),
                  })}>
                  {item.text}
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Box display="flex" alignItems="center">
        <PopoverProfile
          buttonTitle={user?.first_name}
          isCareerMapperTab={isCareerMapperTab}
          popperItems={getPopperItems()}
        />
        <Box className={classes.accountCircle}>
          {user?.avatar ? (
            <img src={user?.avatar} alt="avatar" />
          ) : (
            <Box className={classes.initials}>{user?.first_name && getInitials(user?.first_name, user?.last_name)}</Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default memo(AppBarMenuItems);
