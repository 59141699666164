import * as types from './actionTypes';
import { ToggleFavoriteData } from './interfaces';

interface Queries {
  offset?: number;
  limit?: number;
}

// saga listeners
export const getExpertMatches = (queries?: Queries, isInitialLoad?: boolean) => ({
  type: types.EXPERT_MATCHES_REQUEST,
  queries,
  isInitialLoad,
});
export const getOtherExperts = (queries?: Queries, isInitialLoad?: boolean) => ({
  type: types.OTHER_EXPERTS_REQUEST,
  queries,
  isInitialLoad,
});
export const getFavoriteExperts = (queries?: Queries, isInitialLoad?: boolean) => ({
  type: types.FAVORITE_EXPERTS_REQUEST,
  queries,
  isInitialLoad: !!isInitialLoad,
});
export const toggleFavoriteExpert = (payload: ToggleFavoriteData) => ({
  type: types.TOGGLE_FAVORITE_EXPERT,
  payload,
});
export const getOtherExpertsFilters = () => ({ type: types.OTHER_EXPERTS_FILTERS_REQUEST });

// simple action creators
export const getExpertMatchesSuccess = (payload, isInitialLoad?: boolean) => ({
  type: types.EXPERT_MATCHES_REQUEST_SUCCESS,
  payload,
  isInitialLoad,
});
export const getExpertMatchesError = (payload) => ({ type: types.EXPERT_MATCHES_REQUEST_ERROR, payload });

export const getOtherExpertsSuccess = (payload, isInitialLoad?: boolean) => ({
  type: types.OTHER_EXPERTS_REQUEST_SUCCESS,
  payload,
  isInitialLoad,
});
export const getOtherExpertsError = (payload) => ({ type: types.OTHER_EXPERTS_REQUEST_ERROR, payload });

export const getFavoriteExpertsSuccess = (payload, isInitialLoad?: boolean) => ({
  type: types.FAVORITE_EXPERTS_REQUEST_SUCCESS,
  payload,
  isInitialLoad,
});
export const getFavoriteExpertsError = (payload) => ({ type: types.FAVORITE_EXPERTS_REQUEST_ERROR, payload });

export const toggleFavoriteExpertSuccess = (payload) => ({ type: types.TOGGLE_FAVORITE_EXPERT_SUCCESS, payload });
export const toggleFavoriteExpertError = (payload) => ({ type: types.TOGGLE_FAVORITE_EXPERT_ERROR, payload });

export const getOtherExpertsFiltersSuccess = (payload) => ({
  type: types.OTHER_EXPERTS_FILTERS_REQUEST_SUCCESS,
  payload,
});
export const getOtherExpertsFiltersError = () => ({ type: types.OTHER_EXPERTS_FILTERS_REQUEST_ERROR });

export const setSelectedFilters = (payload: Record<string, string[]>) => ({
  type: types.EA_SET_SELECTED_FILTERS,
  payload,
});
export const clearOtherExpertsData = () => ({ type: types.CLEAR_OTHER_EXPERTS_DATA });
export const clearExpertMatchesTab = (payload: 'expertMatches' | 'otherExperts' | 'favoriteExperts') => ({
  type: types.CLEAR_EXPERT_MATCHES_TAB,
  payload,
});

export const selectTab = (payload: string) => ({
  type: types.SELECT_TAB,
  payload,
});
