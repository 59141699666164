const initialState = {
  loading: false,
  error: '',
};

export default function paymentReducer(state = initialState, action) {
  const { type } = action;

  switch (type) {
    default:
      return state;
  }
}
