import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './styles';

interface Props {
  fullPage?: boolean;
  delay?: number;
}

function Loader({ fullPage, delay }: Props) {
  const classes = useStyles({ fullPage });

  const [showLoader, setShowLoader] = useState(!delay);

  useEffect(() => {
    let timer;

    if (delay) {
      timer = setTimeout(() => setShowLoader(true), delay);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return <Box className={classes.root}>{showLoader ? <CircularProgress /> : null}</Box>;
}

export default Loader;
