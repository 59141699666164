import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const apiKey = process.env.REACT_APP_BUGSNAG_API_KEY;

const bugsnagInit = () => {
  if (apiKey && process.env.REACT_APP_ENV === 'production') {
    Bugsnag.start({
      apiKey,
      plugins: [new BugsnagPluginReact()],
      appVersion: '0.1.0',
    });
  }
};

export default bugsnagInit;
