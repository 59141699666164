import * as types from './actionTypes';
import { DurationTypes, Timezone } from './interfaces';

// saga listeners
export const getExpertProfile = (payload: string) => ({ type: types.EA_GET_EXPERT_PROFILE, payload });
export const getTimezones = () => ({ type: types.EA_GET_TIMEZONES });
export const confirmSession = (payload, sessionId, callback) => ({
  type: types.EA_CONFIRM_SESSION,
  payload,
  sessionId,
  callback,
});
export const getMonthAvailability = (queries) => ({ type: types.EA_GET_MONTH_AVAILABILITY, queries });
export const getDayAvailability = (queries) => ({ type: types.EA_GET_DAY_AVAILABILITY, queries });
export const getSession = (payload) => ({ type: types.EA_GET_SESSION, payload });

// simple action creators
export const getExpertProfileSuccess = (payload: string) => ({ type: types.EA_GET_EXPERT_PROFILE_SUCCESS, payload });

export const getTimezonesSuccess = (payload) => ({ type: types.EA_GET_TIMEZONES_SUCCESS, payload });
export const getTimezonesError = (payload: string) => ({ type: types.EA_GET_TIMEZONES_ERROR, payload });

export const setDuration = (payload: DurationTypes) => ({ type: types.EA_SET_DURATION, payload });
export const setTime = (payload) => ({ type: types.EA_SET_TIME, payload });
export const selectTimezone = (payload: Timezone | null) => ({ type: types.EA_SELECT_TIMEZONE, payload });

export const confirmSessionSuccess = (payload) => ({ type: types.EA_CONFIRM_SESSION_SUCCESS, payload });
export const confirmSessionError = (payload: string) => ({ type: types.EA_CONFIRM_SESSION_ERROR, payload });

export const getMonthAvailabilitySuccess = (payload, selectedDate?: string | null) => ({
  type: types.EA_GET_MONTH_AVAILABILITY_SUCCESS,
  payload,
  selectedDate,
});

export const getDayAvailabilitySuccess = (payload) => ({ type: types.EA_GET_DAY_AVAILABILITY_SUCCESS, payload });

export const getSessionSuccess = (payload) => ({ type: types.EA_GET_SESSION_SUCCESS, payload });
export const getSessionError = () => ({ type: types.EA_GET_SESSION_ERROR });
export const editSession = (payload) => ({ type: types.EA_EDIT_SESSION, payload });

export const clearBookingData = () => ({ type: types.EA_CLEAR_BOOKING_DATA });
