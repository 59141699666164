export const black = '#000000';
export const white = '#ffffff';

export const primary = {
  dark_blue: '#0b1d2e',
  milk: '#faf7f2',
  blue: '#d1e0f0',
  light_blue: '#E7EFF7',
  coral: '#ff575a',
  purple: '#340a22',
  light_purple: '#976F83',
  dark_purple: '#450A28',
  question_text: '#6E6E6E',
  label: '#6870FF',
  disabled: '#C7C7C7',
  dark: '#1d263a',
  light_green: '#21BE58',
  pink: '#FEEDEE',
};
